import React, { useEffect, useRef, useState } from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import { TOC, TOCProps, defineTOCElements } from '~/TOC';

const rv_kostky_omega = require('../assets/images/rv_kostky_omega.png');
const rv_kostky_mapping1 = require('../assets/images/rv_kostky_mapping1.png');
const rv_kostky_mapping2 = require('../assets/images/rv_kostky_mapping2.png');

const rv_prenos1 = require('../assets/images/rv_prenos1.png');
const rv_prenos2_graf = require('../assets/images/rv_prenos2_graf.png');
const rv_prenos_diagram = require('../assets/images/rv_prenos_diagram.png');
const rv_prenos_3d = require('../assets/images/rv_prenos_3d.png');
const rv_prenos_rotace = require('../assets/images/rv_prenos_rotace.png');
const rv_partial_knowledge1 = require('../assets/images/rv_partial_knowledge1.png');
const rv_partial_knowledge1_id = require('../assets/images/rv_partial_knowledge1_id.png');
const rv_partial_knowledge2 = require('../assets/images/rv_partial_knowledge2.png');


export const RandomVariableMeta = {
    title: "Náhodná veličina",
    shortTitle: "Náhodná veličina",
    path: Path.random_variable,
    element: (sectionNumber:string) => <RandomVariable sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}


const TOCSpec = [
    "rv-intro",
    "rv-definition",
    "rv-example-1",
    "rv-transferred-probability",
    "rv-practice",
    "rv-scenario-a",
    "rv-scenario-b",
    "rv-example-2",
];


export function RandomVariable({sectionNumber}: {sectionNumber: string}) {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);

    return (
        <MathJaxWrapper>
            <MathJax>
            <div className="chapter-container">
            <div className="centered-content">
            <div className="card" ref={chapterRef}>

            <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{RandomVariableMeta.title}</h1>

            <h2 id="rv-intro">Úvod</h2>
            <p>
            Pojem <strong>náhodná veličina</strong> je jeden z nejpoužívanějších v pravděpodobnosti a statistice.
            Jedná se o určitou specifickou matematickou konstrukci - která je vytvotřena velmi chytrým způsobem - ale na první
            pohled nejspíše není zřejmé, proč je ta konstrukce udělaná právě takto, a proč ji vůbec potřebujeme.
            Když už si na ni člověk zvykne a chvíli ji používá, tak je postupně schopný ji docenit.
            Proto na začátek nezoufejte, pokud vám to bude připadat divné - to se dá čekat.
            </p>

            <p><i>Pozn.: Místo "náhodná veličina" se někdy používá i název "náhodná proměnná". Anglicky je to "random variable".</i></p>

            <h2 id="rv-definition">Definice</h2>
            <p>Začneme záměrně zjednodušenou definicí - profesní matematikové prominou:</p>

            <p style={{color: "navy"}}>
            Náhodná veličina je funkce z {"$\\Omega$"} do {"$\\mathbb{R}$"}.
            </p>

            <ul className="abc-list">
                <li data-marker="A">
                    Jinými slovy, náhodná veličina je funkce, která každému elementárnímu
                    jevu {"$\\omega \\in \\Omega$"} přiřazuje reálné číslo - ohodnocuje tento jev nějakým číslem.
                    Náhodná veličina přiřazuje číslo každému možnému výsledku pokusu (elementárnímu jevu).
                </li>
                <li data-marker="B">
                    Náhodné proměnné se tradičně značí velkými písmeny, například {"$X$"}.
                </li>
                <li data-marker="C">
                    Náhodná veličina je "normální" funkce - není na ní nic "náhodného".
                    Má předpis/vzorec, nebo je např. daná tabulkou.
                </li>
            </ul>

            <p>
            Značení náhodné proměnné je specifické v tom, že:
            </p>

            <ul>
                <li>Hodnota (výsledek) funkce je obvykle značen {"$x$"}.
                To je v kontrastu s běžnou funkcí, kde {"$x$"} je naopak argument (vzor) - viz zápis {"$f(x)$"}.
                </li>
                <li>
                Funkce je značena jako {"$X$"}.
                </li>
            </ul>

            <p>
            Situaci shrnuje následující tabulka:
            </p>

            <table className="simple-table simple-table-basic">
                <thead>
                    <tr>
                        <th></th>
                        <th>Odkud zobrazujeme</th>
                        <th>Symbol proměnné (vzoru)</th>
                        <th>Funkční symbol</th>
                        <th>Symbol funkční hodnoty (výsledku, obrazu)</th>
                        <th>Kam zobrazujeme</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Běžná* funkce</td>
                        <td>{"$\\mathbb{R}$"}</td>
                        <td>{"$x$"}</td>
                        <td>{"$f$"}</td>
                        <td>{"$f(x)$"} nebo {"$y$"} <br/> {"$(y = f(x))$"}</td>
                        <td>{"$\\mathbb{R}$"}</td>
                    </tr>
                    <tr>
                        <td>Náhodná veličina</td>
                        <td>{"$\\Omega$"}</td>
                        <td>{"$\\omega$"}</td>
                        <td>{"$X$"}</td>
                        <td>{"$X(\\omega)$"} nebo {"$x$"} <br/> {"$(x = X(\\omega))$"}</td>
                        <td>{"$\\mathbb{R}$"}</td>
                    </tr>
                </tbody>
            </table>

            <p>
            *Běžnou funkcí zde myslíme reálnou funkci reálné proměnné, tj, funkci typu {"$\\sin(x), x^2$"}, atp.
            </p>

            <p>
            Běžně ale nastávají i situace, kdy symbol {"$x$"} je použitý pro argument náhodné veličiny. V principu je
            totiž jedno, jakým symbolem proměnné označujeme - a dle kontextu volíme to, co se zrovna hodí. Tuto situaci si níže ukážeme.
            Běžná je také situace, kdy {"$\\Omega = \\mathbb{R}$"}.
            </p>

            <h2 id="rv-example-1">Ukázka</h2>

            <p>
            Uvažujme náhodný pokus: současný hod dvěma různými kostkami.
            Elementární jevy {"$\\omega$"} jsou pak dvojice čísel (číslo-které-padlo-na-první-kostce, číslo-které-padlo-na-druhé-kostce),
            a prostor {"$\\Omega$"} elementárních jevů vypadá takto:
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={rv_kostky_omega} style={{width: 250}}/>
            </div>

            <p>
            Dejme tomu, že nás při tomto hodu zajímá součet čísel na kostkách.
            Pro každý možný výsledek {"$\\omega = (\\omega_1,\\omega_2)$"} je součet čísel na kostkách {"$\\omega_1 + \\omega_2$"}.
            Jakých hodnot tento součet může nabývat?
            Nejmenší možná hodnota součtu je 2 (když padne 1 na obou kostkách, tj. když {"$\\omega = (1,1)$"}),
            a největší možná hodnota je 12 (když padne 6 na obou kostkách, tj. když {"$\\omega = (6,6)$"}).
            Vidíme, že možné jsou i všechny celočíselné hodnoty mezi: 3, 4, ..., 11.
            Když hodíme kostkami víckrát, pokaždé hodíme jiná čísla (nastane jiný elementární jev {"$\\omega = (\\omega_1,\\omega_2)$"}),
            a pokaždé získáme jiný součet {"$\\omega_1 + \\omega_2$"}. (Samozřejmě může dojít i k tomu, nějaká dvojice čísel padne opakovaně...)
            Součet je definován takto:
            {`$$
                X(\\omega) = X((\\omega_1,\\omega_2)) = \\omega_1 + \\omega_2.
            $$`}
            Matematicky je to stejná funkce jako {"${f(x,y) = x + y}$"}, jen máme jiné značení proměnných.
            Následující obrázek ukazuje, jaký součet je přiřazen různým elementárním jevům - na jakou hodnotu součtu (v {"$\\mathbb{R}$"}, na ose x)
            funkce {"$X$"} daný jev {"$(\\omega_1,\\omega_2)$"} zobrazuje (vyznačeno šipkou).
            </p>

            <div style={{textAlign: "center", paddingBottom: 10, paddingTop: 20}}>
                <img src={rv_kostky_mapping1} style={{width: 500}}/>
            </div>

            <p>
            Je vidět, že součty prvků na diagonálách jsou stejné (např. třetí diagonála zleva má součet 4: 3+1 = 4, 2+2 = 4, 1+3 = 4). Následující
            obrázek barevně vypisuje součty (hodnoty {"$X(\\omega)$"}) u každého elementárního jevu {"$\\omega$"} pro větší názornost:
            </p>

            <div style={{textAlign: "center", paddingBottom: 10, paddingTop: 20}}>
                <img src={rv_kostky_mapping2} style={{width: 500}}/>
            </div>

            <p>
            Součet je číslo (hodnota, veličina), a když házíme kostkami opakovaně, tak vychází náhodně pokaždé jinak - odtud pochází název "náhodná veličina".
            Součet {"$X$"} je tedy náhodná veličina.
            Matematicky na funkci {"$X$"} nic náhodného není - pro každý výsledek {"$\\omega$"} je přesně určeno, jaká je hodnota {"$X(\\omega)$"}.
            Pouze tím, že při pokusu náhodně nastane právě jedno konkrétní {"$\\omega$"}, tak tím také získáme náhodně jednu z hodnot {"$X(\\omega)$"}.
            </p>

            <p>
            Matematika (teorie) popisuje všechny možné výsledky současně - má globální, statický pohled.
            Vylistuje vedle sebe souběžně všechny možné výsledky, spolu s jejich pravděpodobnostmi.
            V praxi (v experimentu) naopak nastane právě jeden konkrétní výsledek z oněch možných.
            </p>

            <h2 id="rv-transferred-probability">Přenos pravděpodobnosti</h2>

            <p>Prozatím předpokládejme, že kostky jsou férové a nezávislé, takže pravděpodobnost každého elementárníhno jevu je stejná (1/36).</p>

            <p>Je každá hodnota součtu {"$X$"} v příkladu výše stejně pravděpodobná? Intuititvně cítíme, že ne.
            Například součet {"$X = 2$"} nastává jen v jednom případě z 36 možných (když padne 1 na obou kostkách).
            Na druhou stranu, součet {"$X = 7$"} nastává v celých 6 případech z 36 možných (když padne (1,6), (2,5), (3,4), (4,3), (5,2), (6,1)).
            Pravděpodobnostu součtu 1 je tedy 1/36, zatímco pravděpodobnost součtu 7 je 6/36 = 1/6.
            </p>

            <div style={{textAlign: "center", paddingBottom: 10, paddingTop: 20}}>
                <img src={rv_prenos1} style={{width: "100%"}}/>
            </div>

            <p>
            Z obrázku vidíme, jaké pravděpodobnosti příslušejí jednotlivým hodnotám součtu ... ale to je přece nějaké rozdělení pravděpodobnosti!:
            </p>

            <div style={{textAlign: "center", paddingBottom: 10, paddingTop: 20}}>
                <img src={rv_prenos2_graf} style={{width: "65%"}}/>
            </div>

            <p>
            Toto nové rozdělení pravděpodobnosti ale není definované na původním {"$\\Omega$"}!
            Je definované na novém {"$\\Omega' = \\{2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12\\}$"}.
            </p>

            <div style={{textAlign: "center", paddingBottom: 10, paddingTop: 20}}>
                <img src={rv_prenos_diagram} style={{width: "85%"}}/>
            </div>


            <p>Funkci dvou proměnných lze interpretovat jako výšku terénu {"$(z = f(x, y))$"}.
            Představme si, že naše funkce {"$X$"} udává výšku terénu - potom vrstevnice
            jsou právě oblasti se stejnou barvou. Výška terénu je náhodná veličina.
            (Protože náš příklad je diskrétní, náš terén není hladký, ale sestává ze schodů.)
            Přenos pravděpodobnosti spočívá v tom, že se podíváme, jaké výšky terénu nastávají - na ose {"$z$"} (osa hodnot)
            si vyznačíme možné výšky (jako když u futer/zdi tužkou vyznačujeme výšku dítěte).
            A u každé výšky si vyznačíme, jak často (s jakou pravděpodobností) se v terénu tato výška objevuje.
            Toto rozdělení výšek terénu je potom ono nové, "přenesené", rozdělení pravděpodobnosti.
            </p>

            <div style={{textAlign: "center", paddingBottom: 10, paddingTop: 20}}>
                <img src={rv_prenos_3d} style={{width: "60%"}}/>
            </div>

            <p>Zeleně <i><span style={{color: "green"}}>(x), (y), (z)</span></i> je vyznačeno tradiční označení os, na které jsme běžně zvyklí
            (osy x a y v podstavě, osa z je kolmá na podstavu a vyjadřuje výšku). V kontextu náhodné veličiny se ale právě využívá označení jiné - červené:
            osa <span style={{color: "red"}}>x</span> je svislá a je to osa hodnot (hodnoty, které nabývá veličina {"$X$"}),
            a zbylé osy/dimenze přísluší argumentu náhodné veličiny. V našem případě je argument náhodné veličiny dvourozměrný: {"$\\omega = (\\omega_1,\\omega_2)$"},
            takže v podstavě jsou dvě osy - jen se místo x, y jmenují {"$\\omega_1, \\omega_2$"}.
            </p>

            <p>V grafu je pro přehlednost barevně indikována jen hladina X = 8 (modré pozice). Plošky jsou centrované nad pozicemi (i, j).
            Striktně vzato by místo plošek měly být kresleny jen jejich středy, ale to by bylo špatně vidět.
            </p>

            <p>Následující obrázek (využijte zoom prohlížeče) ukazuje, proč se svislá osa (osa hodnot náhodné veličiny {"$X$"}) označuje právě jako osa {"$x$"}:
            poté, co si na každé její hladině (výšce) určíme pravděpodobnost, s kterou tato nastává, tak osu sklopíme do vodorovné polohy ("stane se osou x"),
            a můžeme si nad ní zobrazit pravděpodobnostní funkci (přenesené rozdělení pravděpodobnosti).
            </p>

            <div style={{textAlign: "center", paddingBottom: 10, paddingTop: 20}}>
                <img src={rv_prenos_rotace} style={{width: "100%"}}/>
            </div>

            <p>
            Když pracujeme s náhodnou veličinou, v principu tedy pracujeme se dvěma pravděpodobnostními prostory, a předpisem, který definuje náhodnou veličinu:
            </p>

            <ol>
                <li>
                Původní pravděpodobnostní prostor: {"$(\\Omega, \\mathcal{A}, P)$"}.
                </li>
                <li>
                "Přenesený pravděpodobnostní prostor" - tj. pravděpodobnostní prostor hodnot náhodné veličiny: {"$(\\Omega', \\mathcal{A'}, P')$"}.
                </li>
            </ol>

            <p>
            Přenesená pravděpodobnost {"$P'$"} je definována pomocí původní pravděpodobnosti {"$P$"} takto:
            {`$$
                P'(A') = P(X^{-1}(A')) = P(\\{\\omega \\in \\Omega: X(\\omega) \\in A'\\}).
            $$`}
            Tedy, pravděpodobnost jevu {"$A' \\subset \\Omega'$"} v přeneseném prostoru je rovna pravděpodobnosti
            jevu {"$X^{-1}(A') \\subset \\Omega$"} v původním prostoru. Jev {"$X^{-1}(A')$"} je množina všech elementárních jevů (vzorů)
            takových, že jejich obraz {"$X(\\omega)$"} padne do množiny {"$A'$"}.
            </p>

            <p>Ilustrujme si tento princip na našem příkladě.
            Zajímá nás, kolik je {"$p'(4)$"} - pravděpodobnost, že součet čísel na kostkách bude 4:
            {`$$
                p'(4) = P'(\\{4\\}) = P(X^{-1}(\\{4\\})) = P(\\{(1,3), (2,2), (3,1)\\}) = \\frac{3}{36}.
            $$`}
            </p>

            <p>
            Přenesená pravděpodbnost {"$P'$"} se také nazývá indukovaná - vzniká jednoznačně v důsledku původní pravděpodobností {"$P$"} a zobrazení {"$X$"}.
            </p>

            <h2 id="rv-practice">Praxe při používání konceptu náhodné veličiny</h2>

            <p>
            Když pracujeme s náhodnou veličinou, v principu pracujeme se dvěma pravděpodobnostními prostory,
            plus s předpisem (vzorcem), který onu náhodnou veličinu definuje. Tolik teorie ... a nyní k praxi:
            </p>

            <h3 id="rv-scenario-a">Scénář A - používáme jen přenesenou pravděpodobnost</h3>

            <p>Dočteme se (v zadání úlohy, v odborném textu, atp.), že:</p>

            <div style={{backgroundColor: "lightyellow", textAlign: 'center'}}>
                "Máme náhodnou veličinu {"$X$"} s normálním rozdělením pravděpodobnosti N(0,1)."
            </div>

            <p>
            <i>(Pozn.: To, že je rozdělení zrovna normální, zde nehraje roli - jde jen o příklad; stejně tak jsme mohli říct binomické, exponenciální, atp.)</i>
            </p>

            <p>
            Co s tím? Jak to interpretovat? Kde máme ony dva pravděpodnostní prostory?
            Kde máme předpis (vzorec) pro {"$X$"}? Odpověď je, že nemáme.
            </p>

            <p>
            Dostali jsme zadaný jen přenesený pravděpodobnostní prostor (prostor hodnot náhodné veličiny). <strong>Zadané
            rozdělení pravděpodobnosti je přenesené rozdělení pravděpodobnosti - tj. rozdělení pravděpodobnosti hodnot
            (výsledků) náhodné veličny.</strong> Na obrázku je část informace, kterou nemáme, přeškrtána červeným šrafováním:
            </p>

            <div style={{textAlign: "center", paddingBottom: 10, paddingTop: 20}}>
                <img src={rv_partial_knowledge1} style={{width: "30%"}}/>
            </div>

            <p>
            Vadí nám to? Většinou ne - pokud je to tak zadáno, tak "zatajenou" část informace nepotřebujeme.
            Např. pro výpočet sřední hodnoty: {"$E(X) = \\int x f(x) \\mathrm{d}x$"} využijeme hustotu {"$f(x)$"} přeneseného rozdělení - a původní rozdělení nepotřebujeme.

            Pokud bychom si přecejen přáli do obrazu doplnit původní pravděpodobnostní prostor a vzorec pro {"$X$"},
            potom se to dělá následujícím (kanonickým, defaultním) způsobem:
            </p>

            <ul>
                <li>
                Položíme {"$\\Omega = \\Omega'$"}.
                </li>
                <li>
                Položíme {"$P = P'$"}.
                </li>
                <li>
                Funkci {"$X$"} definujeme jako {"$X(\\omega) = \\omega$"}.
                </li>
            </ul>

            <div style={{textAlign: "center", paddingBottom: 10, paddingTop: 20}}>
                <img src={rv_partial_knowledge1_id} style={{width: "30%"}}/>
            </div>

            <p>
            Tím vlastně říkáme, že samotný jev {"$\\omega$"} identifikujeme s hodnotou náhodné veličiny.
            Např. pokud hážeme jednou kostkou, elementární jev {"$\\omega$"} je číslo které padlo,
            a je to zároveň hodnota náhodné veličiny {"$X$"}, jejíž slovní popis je "hodnota, která padla".
            Hodnotu veličiny {"$X(\\omega)$"} získáme prostě zopakováním hodnoty, kterou je elementární
            jev {"$\\omega$"} reprezentován: {"$X(\\omega) = \\omega$"}.
            </p>

            <p>Pozn.: Vztah {"$X(\\omega) = \\omega$"} pro definici náhodné veličiny není použitelný, když:</p>

            <ul>
                <li>
                Elementární jev {"$\\omega$"} není reálné číslo. Např. je to dvojice čísel (jako při hodu dvěma kostkami),
                nebo obecně vektor (n-tice) čísel. Hodnota náhodné veličiny musí být reálné číslo.
                </li>
                <li>
                Provádíme transformaci - např. měříme kinetickou enerigii, a přepočítáváme ji na rychlost.
                Pokud elemntární jev {"$\\omega$"} (přímo změřená hodnota) je energie, pak náhodná veličina
                "rychlost" je {"$X(\\omega) = \\sqrt{2\\omega/m}$"}. Aby byl naplněn logický/fyzikální význam
                náhodné veličiny "rychlost", bez transformace se neobejdeme.
                </li>
            </ul>

            <p>
            Princip náhodné veličiny jako identity na množině {"$\\Omega$"} nám umožňuje kdykoli místo formulace:
            </p>

            <div style={{backgroundColor: "lightgrey", textAlign: 'center'}}>
            "Mějme {'\u3008'}název{'\u3009'} rozdělení pravděpodobnosti s parametry {'\u3008'}...{'\u3009'}."<br/>
            <i style={{color: "grey"}}>(Př: "Mějme binomické rozdělení pravděpodobnosti s parametry n=5, p=3.")</i>
            </div>

            <p>
            použít formulaci:
            </p>

            <div style={{backgroundColor: "lightgrey", textAlign: 'center'}}>
            "Mějme náhodnou veličinu s {'\u3008'}název{'\u3009'} rozdělením pravděpodobnosti s parametry {'\u3008'}...{'\u3009'}."<br/>
            <i style={{color: "grey"}}>(Př: "Mějme náhodnou veličinu s binomickým rozdělením pravděpodobnosti s parametry n=5, p=3.")</i>
            </div>

            <p>Toto funguje za předpokladu, že mluvíme o rozdělení pravděpodobnosti na reálné ose.</p>

            <p>Pozn.: Mnoho různých pravděpodnostních prostorů a náhodných veličin může vést na stejné
            přenesené rozdělení pravděpodobnosti - a pokud nám tato informace není dána, tak ji v principu nemůžeme zjistit.
            Červeně vyšrafovaná část informace je tedy "nedostupná" - a my si ji nahradíme onou kanonickou konstukcí.
            </p>

            <h3 id="rv-scenario-b">Scénář B - používáme jen původní pravděpodobnost a vzorec pro {"$X$"}</h3>

            <div style={{backgroundColor: "lightyellow", textAlign: 'center'}}>
                "Máme {"$\\Omega = \\mathbb{R}$"} s normálním rozdělením pravděpodobnosti N(0,1). Zajímá nás, jakou střední hodnotu má veličina {"$|x|$"}."
            </div>

            <p><i>Interpretace: Pokud rozdělení pravděpodobnosti N(0,1) je např. rozdělení nějakých odchylek od požadované hodnoty, potom střední hodnota odchylek je sice 0,
            (záporné i kladné odchylky jsou zastoupeny symetricky), ale zde se ptáme, jaká je průměrná velikost odchylky v absolutní hodnotě.</i>
            </p>

            <p>Jakou informaci máme?:</p>
            <ul>
                <li>
                Máme informaci o původním pravděpodobnostním prostoru: známe {"$\\Omega$"} a {"$P$"}.
                </li>
                <li>
                Máme vzorec pro {"$X$"}: {"$X(\\omega) = |\\omega|$"}; zde zapsaný jako {"$X(x) = |x|$"}
                </li>
            </ul>

            <p>
            Co nemáme, je přenesený pravděpodobnostní prostor {"$\\Omega'$"} a rozdělení {"$P'$"} náhodné veličiny {"$X$"}. Bude normální? Určitě ne - protože nenabývá záporných hodnot; nebude symetrické...
            </p>

            <div style={{textAlign: "center", paddingBottom: 10, paddingTop: 20}}>
                <img src={rv_partial_knowledge2} style={{width: "30%"}}/>
            </div>

            <p><i>Pozn.: Všiměte si, jak toto stojí v protikladu k Scénáři A, kde naopak jediné, co jsme znali, bylo právě rozdělení {"$P'$"} náhodné veličiny.</i></p>

            <p>Obor hodnot {"$\\Omega'$"} snadno zjistíme, protože známe předpis pro {"$X$"};
            v tomto konkrétním případě je to {"$\\Omega' = \\langle 0, \\infty )$"} (absolutní hodnota nabývá nezáporných hodnot).
            Také rozdělení pravděpodobnosti {"$P'$"} můžeme zjistit - dopočítáme se k němu přes transformovanou distribuční funkci ... tento proces si ukážeme později.
            Důležité je, že informaci sice neznáme, ale v princupu je dostupná - dá se dopočítat.
            To je v kontrastu k scénáři A, kde informace o původním pravděpodobnostním prostoru z principu nebyla zjistitelná.
            </p>

            <p>Pro výpočet střední hodnoty ale informaci o přenesené pravděpodobnosti {"$P'$"} ale nepotřebujeme - využijeme původní pravděpodobnost {"$P$"}, jejíž hustota je {"$f(x) = \\frac{1}{\\sqrt{2\\pi}}e^{-\\frac{x^2}{2}}$"}:
            {`$$\\begin{align*}
                E(X) & = \\int |x| f(x) \\mathrm{d}x = \\int_{-\\infty}^{0} (-x) f(x) \\mathrm{d}x + \\int_{0}^{\\infty} x f(x) \\mathrm{d}x = 2 \\int_{0}^{\\infty} x f(x) \\mathrm{d}x = \\\\
                     & = 2 \\int_{0}^{\\infty} x \\frac{1}{\\sqrt{2\\pi}}e^{-\\frac{x^2}{2}} \\mathrm{d}x = \\sqrt{\\frac{2}{\\pi}}\\Big[ -e^{-\\frac{x^2}{2}} \\Big]_{0}^{\\infty} = \\sqrt{\\frac{2}{\\pi}}\\Big(0 - (-1)\\Big) = \\sqrt{\\frac{2}{\\pi}}.
            \\end{align*}$$`}
            </p>


            <h2 id="rv-example-2">Ukázka 2</h2>
            <i>připravujeme...</i>


        </div>
        </div>
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

