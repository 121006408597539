import React, { useEffect, useRef, useState } from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import { TOC, TOCProps, defineTOCElements } from '~/TOC';


export const IntervalEstimationMeta = {
    title: "Intervalové odhady",
    shortTitle: "Intervalové odhady",
    path: Path.interval_estimation,
    element: (sectionNumber:string) => <IntervalEstimation sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "summary",
];


export function IntervalEstimation({sectionNumber}: {sectionNumber: string}) {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);

    return (
        <MathJaxWrapper>
        <MathJax>
        <div className="chapter-container">
        <div className="centered-content">
        <div className="card" ref={chapterRef}>

            <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{IntervalEstimationMeta.title}</h1>

            {/* <h2 id="summary">Shrnutí</h2> */}

            <p><i>Připravujeme...</i></p>

            <p>

            Viz přednášky (poznámky, videa), a nebo např.

            &nbsp;<a href={"https://www.youtube.com/watch?v=EJe3jiZNwUU"} target="_blank" rel="noopener noreferrer" className="external-link">youtube 1</a>,

            &nbsp;<a href={"https://www.youtube.com/watch?v=9GtaIHFuEZU"} target="_blank" rel="noopener noreferrer" className="external-link">youtube 2</a>,

            &nbsp;<a href={"https://www.youtube.com/watch?v=BQ88ni4bJNA"} target="_blank" rel="noopener noreferrer" className="external-link">youtube 3</a>,

            <br/>nebo

            &nbsp;<a href={"https://is.muni.cz/do/rect/el/estud/prif/ps13/prav_stat/web_2/pages/04-inerval-odhady.html"} target="_blank" rel="noopener noreferrer" className="external-link">kurz MUNI</a>

            &nbsp;(matematičtější),

            nebo
            &nbsp;<a href={"https://www.stat.berkeley.edu/~stark/SticiGui/Text/confidenceIntervals.htm"} target="_blank" rel="noopener noreferrer" className="external-link">Stark</a>.

            </p>



        </div>
        </div>
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

