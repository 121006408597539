import React, { useEffect, useRef, useState } from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import { TOC, TOCProps, defineTOCElements } from '~/TOC';

const DeleniZnaku2 = require('../assets/images/deleni_znaku_2.png');


export const DataTypesMeta = {
    title: "Druhy znaků (druhy dat)",
    shortTitle: "Druhy znaků (druhy dat)",
    path: Path.data_types,
    element: (sectionNumber:string) => <DataTypes sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "datatypes-intro",
    "datatypes-basic-terms",
    "data-types-examples",
    "data-types-comments",
];

export function DataTypes({sectionNumber}: {sectionNumber: string}) {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);

    return (
        <MathJaxWrapper>
        <MathJax>
        <div className="chapter-container">
        <div className="centered-content">
        <div className="card" ref={chapterRef}>

            <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{DataTypesMeta.title}</h1>

            <h2 id="datatypes-intro">Úvod - nějak musíme začít</h2>

            <div className="example">
                <p><span className="ex-label">[národnost]</span>
                Na dálnici D1 se porouchal mezinárodní autobus jedoucí z Bratislavy do Londýna.
                Přivolaní policisté zjišťují národnost cestujících.
                Zjistili:  10 čechů, 8 slováků, 5 britů a 7 němců.
                </p>
            </div>

            <div className="example">
                <p><span className="ex-label">[pokročilost]</span>
                Na začátku jazykového kurzu jsou studenti rozřazeni dle
                úrovně své aktuální pokročilosti: A1 ("začátečníci"), A2 ("se základy"), B1 ("mírně pokročilí), B2 ("středně pokročilí"),
                C1 ("pokročilí"), C2 ("experti").
                Rozřazení 20 účastníků dopadlo takto: <br/>
                A1: 2 lidi, A2: nikdo, B1: 7 lidí, B2: 10 lidí, C1: 1 člověk, C2: nikdo
                </p>
            </div>

            <div className="example">
                <p><span className="ex-label">[zásahy terče]</span>
                Na závodech ve střelbě rozhodčí u každého závodníka zapisuje, kolikrát se trefil. Každý závodník má 10 pokusů.
                Výsledky:
                </p>
                <table id="data-types-shooting">
                    <tbody>
                        <tr><td>Pavel:</td><td>10 zásahů</td><td>(sdílené první místo)</td></tr>
                        <tr><td>Eliška:</td><td>10 zásahů</td><td>(sdílené první místo)</td></tr>
                        <tr><td>Anna:</td><td>9 zásahů</td><td>(třetí místo)</td></tr>
                        <tr><td>Martin:</td><td>7 zásahů</td><td>(čtvrté místo)</td></tr>
                        <tr><td>...</td><td></td><td></td></tr>
                    </tbody>
                </table>
            </div>

            <div className="example">
                <p><span className="ex-label">[hmotnost]</span>
                Na výstupu linky pro výrobu léčiv se provádí automatická kontrola hmotnosti tablet. Tableta by měla vážit 20mg :
                Výsledky kontroly v mg: 20.3, 20.1, 19.8, 20.0, 19.3, 20.1, ...
                </p>
            </div>

            <p>
            I když se jedná o různé situace, ve všech případech se děje stejná věc:

            Sleduje se (zjišťuje, zkoumá, měří) určitá vlastnost: "národnost", "pokročilost", "počet zásahů", "hmotnost".
            Tato vlastnost může nabývat hodnot z nějaké množiny: U národnosti je to {"$\\{$"} "čech", "slovák", "brit", ...{"$\\}$"},
            u hmotnosti jsou to kladná reálná čísla. Ve všech případech získaný datový soubor může obsahovat opakované hodnoty,
            a&nbsp;zároveň některé možné hodnoty nemusejí být zastoupeny.
            </p>
            <p>
            Situaci shrnuje následující tabulka:
            </p>
            <table className='data-table' id="data-types-ex-table">
                <thead>
                    <tr>
                        <th>Sledovaná vlastnost</th>
                        <th>Možné hodnoty</th>
                        <th>Data</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Národnost</td>
                        <td>čech (cz), slovák (sk), němec (d), brit (uk), ...</td>
                        <td>cz, cz, cz, cz, cz, cz, cz, cz, cz, cz,
                            sk, sk, sk, sk, sk, sk, sk, sk,
                            uk, uk, uk, uk, uk,
                            d, d, d, d, d, d, d
                        </td>
                    </tr>
                    <tr>
                        <td>Pokročilost</td>
                        <td>A1, A2, B1, B2, C1, C2</td>
                        <td>A1, A1, B1, B1, B1, B1, B1, B1, B1,  B2, B2, B2, B2, B2, B2, B2, B2, B2, B2, C1</td>
                    </tr>
                    <tr>
                        <td>Počet zásahů</td>
                        <td>1, 2, 3, ... , 10</td>
                        <td>10, 10, 9, 7, ...</td>
                    </tr>
                    <tr>
                        <td>Hmotnost</td>
                        <td>{"$\\mathbb{R}^+$"}</td>
                        <td>20.3, 20.1, 19.8, 20.0, 19.3, 20.1, ...</td>
                    </tr>
                </tbody>
            </table>

            <h2 id="datatypes-basic-terms">Základní pojmy</h2>
            <p>
            Jak už to bývá, pro jeden pojem (koncept) existuje více označení:
            </p>

            <p>
            <b>Sledovaná vlastnost</b>. Standardní označení je <b>statistický znak</b>, popř. jen <b>znak</b>. Další označení jsou:
            </p>
            <ul>
                <li>Statistická <b>proměnná</b> (variable) - tj. vlastnost, jejíž hodnota se liší (proměňuje) mezi různými jedinci nebo pozorováními.</li>
                <li><b>Veličina</b> - když je sledovaná vlastnost nějaká fyzikální veličina: hmotnost, objem, koncentrace, teplota, atd.</li>
                <li>Atribut, někdy i parametr (i když ten se ve statistice běžně používá spíš v jiném kontextu - např. parametr rozdělení).</li>
                <li>Charakteristika.</li>
            </ul>


            <p>
            <b>Možné hodnoty</b>. Standardní označení neexistuje, liší se dle kontextu:
            </p>
            <ul>
                <li>Jednotlivé hodnoty se někdy označují jako <b>úrovně</b> (levels of measurement) - toto je obzvláště
                    pochopitelné např. u úrovní pokročilosti, ale méně už např. u hmotnosti. Někdy se používá i označení <b>varianty</b>.
                </li>
                <li>Množina všech hodnot, které mohou nastat nebo být změřeny, se někdy označuje jako <b>stupnice</b> nebo <b>škála </b>
                (scale of measure), což je pochopitelné u fyzikálních veličin (např. teplotu můžeme měřit na celsiově nebo kelvinově stupnici),
                ale méně už u znaku typu národnost. Na druhou stranu, např. barvu očí můžeme měřit na barevné "škále".
                </li>
            </ul>


            <p>
            <b>Data.</b> Standardní označení je <b>statistický soubor</b>. Alternativně lze použít označení jako třeba:
            </p>

            <ul>
                <li>Soubor dat, vzorek, soubor měření, výsledky měření, získaná data, naměřená data, změřené hodnoty, ...
                </li>
            </ul>

            <p>
            Data jsou množinou jednotlivých naměřených hodnot, kde každá hodnota je z množiny možných hodnot, které daný znak může nabývat.
            (Např. číslo 2.3 by nemohlo být hodnotou znaku "počet zásahů".)
            </p>

            <p>
            <i>Pozn. pro znalce formálnější matematiky: Znak je nějaká funkce, škála je obor hodnot této funkce (označme ho {"$H$"}),
            hodnota je libovolný prvek z {"$H$"}, a data (velikosti {"$n$"}) jsou libovolný prvek
            kartézkého součinu {"$H \\times H \\times  \\cdots \\times H$"} ({"$n$"} krát).
            </i>
            </p>

            <p>
            Uvědomme si rozdíly:
            </p>

            <table>
                <tbody>
                    <tr>
                        <td>Znak {"$\\neq$"} škála</td><td>Veličina "hmotnost" není totéž co množina {"$\\mathbb{R}^+$"}.</td>
                    </tr>
                    <tr>
                        <td>Znak {"$\\neq$"} hodnota</td><td>Pojem "národnost" není totéž co slovo "česká".</td>
                    </tr>
                    <tr>
                        <td>Znak {"$\\neq$"} data</td><td>Veličina "hmotnost" není totéž co soubor dat (20.3, 20.1, 19.8, 20.0, 19.3, 20.1, ...).</td>
                    </tr>
                    <tr>
                        <td>Škála {"$\\neq$"} data</td><td>Škála A1-C2 není totéž co soubor dat (A1, A1, B1, B1, B1, ...).</td>
                    </tr>
                    <tr>
                        <td>...</td><td></td>
                    </tr>
                </tbody>
            </table>


            <h2 id="data-types-examples">Druhy znaků</h2>

            <p>
            Na příkladech jsme viděli, že škály měření (množiny hodnot, kterých znak může nabývat) jsou typově různé -
            názvy (národností), kódy (pokročilostí), přirozená čísla - tj. počty (zásahů), kladná reálná čísla (hmotnosti).
            </p>

            <p>
            Lidé se pokusili tuto různorodost nějak podchytit - kategorizovat statistické znaky do pojmenovaných druhů.
            Každá navržená kategorizace se však ukázala jako neúplná, popř. užitečná jen z určitého úhlu pohledu.
            Jedná se totiž o problém jen z části matematický - z druhé části je spíše praktický, závisející na kontextu
            (jak byla data získána a co znamenají) a záměru (co chceme s daty dělat).
            </p>

            <p>
            Někde uvidíte dělění znaků na <b>kategorický</b> (<b>nominální</b>), <b>ordinální</b>, <b>intervalový</b> a <b>poměrový</b>, <br/>
            jinde na <b>kategorický</b> (<b>nominální</b>), <b>ordinální</b>, <b>diskrétní</b> a <b>spojitý</b>, <br/>
            a ještě jinde potkáte <b>names</b>, <b>grades</b>, <b>ranks</b>, <b>counted fractions</b>, <b>amounts</b>, <b>balances</b>
            <br/>(viz např. <a href={"https://en.wikipedia.org/wiki/Level_of_measurement"} target="_blank" rel="noopener noreferrer" className="external-link">Wikipedie</a>).
            </p>

            <p>
            Jednotlivé zdroje (texty, knihy, weby) přitom mohou podávat informaci stylem "tohle je dělení statistických znaků, tečka"
            (tj. prezentovat určité dělení implicitně jako "jediné", "dané", "přirozené" a "správné") - ale není tomu tak.
            Při nekritickém přístupu by se člověk nějaké dělení namemoroval, a potom by se podivil, že jinde je uvedeno jiné
            (a ptal by se, jak je to teda správně).
            </p>

            <p>
            V matematice bývají většinou pojmy přesně vymezené pomocí definic. Všichni matematikové
            na světě se např. přesně shodnou na tom, co je spojitá reálná funkce reálné proměnné.
            Ale určitě se neshodnou na pojmu "typ statistického znaku". Nedá se ale říct, že by tu
            panovala úplná anarchie - existuje jakási shoda na základních pozorováních, i na různých
            dalších aspektech.
            </p>

            <p>
            Celá situace v podstatě vznikla tak, že si lidé všimli, že některé operace nemají u některých
            znaků/dat smysl. (Což je objektivní pozorování.) Začali se ptát, které operace mají za jakých
            podmínek smysl, a to vedlo k rozlišení jednotlivých případů. Ukážeme si to na příkladech:
            </p>

            <div className="example">
                <p>
                Vraťme se k příkladu s národnostmi. Za účelem uložení dat do databáze si národnosti očíslujeme:
                česká (1), slovenská (2), britská (3), německá (4). Naměřená data potom vypadají takto:
                </p>

                <table className='data-table' id="data-types-ex-table">
                    <thead>
                        <tr>
                            <th>Národnost</th>
                            <th>Počet cestujících</th>
                            <th>Pozn.</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr><td>1</td><td>10</td><td>(10 čechů)</td></tr>
                        <tr><td>2</td><td>8</td><td>(8 slováků)</td></tr>
                        <tr><td>3</td><td>5</td><td>(5 britů)</td></tr>
                        <tr><td>4</td><td>7</td><td>(7 němců)</td></tr>
                    </tbody>
                </table>

                <p>
                Spočítejme teď "průměrnou národnost": {"$(1 \\cdot 10 + 2 \\cdot 8 + 3 \\cdot 5 + 4 \\cdot 7)/30 = 2.3$"}.
                Vyjde nám, že "průměrný"/"typický" reprezentant skupiny je částečně slovák a částečně brit.
                To je samozřejmě nesmysl.
                </p>

                <p>
                Nehledě na to, že kdybychom si byli čísla národností na začátku zvolili jinak,
                např. britská (1), německá (2), česká (3), slovenská (4), tak by data vypadala takto:
                </p>

                <table className='data-table' id="data-types-ex-table">
                    <thead>
                        <tr>
                            <th>Národnost</th>
                            <th>Počet cestujících</th>
                            <th>Pozn.</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr><td>1</td><td>5</td><td>(5 britů)</td></tr>
                        <tr><td>2</td><td>7</td><td>(7 němců)</td></tr>
                        <tr><td>3</td><td>10</td><td>(10 čechů)</td></tr>
                        <tr><td>4</td><td>8</td><td>(8 slováků)</td></tr>
                    </tbody>
                </table>

                <p>
                a průměr by nám vyšel {"$(1 \\cdot 5 + 2 \\cdot 7 + 3 \\cdot 10 + 4 \\cdot 8)/30 = 2.7$"},
                čili "průměrný" reprezentant skupiny by teď byl čecho-němec (!).
                </p>

                <p>
                Znak typu národnost je <b>kvalitativní</b><sup>1</sup> <b>nominální</b>. Na jeho hodnotách nemá smysl
                provádět matematické operace; nelze je uspořádat podle velikosti a nelze z nich počítat průměr.
                A to ani tehdy, když je kódujeme čísly! Takové číslo totiž představuje jen jakýsi "tag", jehož
                číselná hodnota nenese žádnou informaci kromě toho, že je unikátní - že jednoznačně kóduje danou hodnotu.
                Termín 'nominální' říká, že hodnoty znaku jsou jen "názvy/jména/popisky" (latinsky nomen = jméno) a nic víc.
                </p>

                <p>
                Má ale např. smysl se zeptat: "Která národnost byla zastoupena nejvíc?" Odpověď: česká.
                Hodnota, která je v datech zastoupena nejvíce, se označuje jako <b>modus</b>.
                </p>
            </div>

            <div className="example">
                <p>U jazykových pokročilostí lze hodnoty evidentně seřadit od nejmenší po největší: A1, A2, ..., C2.
                Nelze ale říct, jestli je rozdíl mezi A1 a A2 stejný jako mezi B1 a B2, nebo jestli je rozdíl mezi A1 a B1 stejný jako mezi B1 a C1.
                Jak tento rozdíl měřit? Počtem slov v aktivní nebo pasivní slovní zásobě? Množstvím zvládnutých gramatických jevů?
                Zvládnutím přízvuku a výslovnosti? Nebo množstvím hodin studia potřebných k posunu na další úroveň?
                Nelze říci, že by student na úrovni B2 znal dvojnásobek slov než student na úrovni B1, nebo že by znal o 1000 slov než student na úrovni B1.
                Nelze říci, že by student na úrovni B2 byl dvakrát lepší než student na úrovni A2.
                Je klidně možné, že posun z C1 na C2 vyžaduje stejné úsilí jako posun z A1 na B2.
                Tento znak je <b>kvalitativní</b> <b>ordinální</b>. Jeho hodnoty má smysl seřadit,
                ale nemá smysl s nimi počítat - určovat jejich "rozdíly" nebo "poměry".
                Termín 'ordinální' se odvozuje z latiny (ordinalis = pořadový).
                </p>
            </div>
            <div className="example">
                <p>
                V Brně a v Jeseníku byly v poledne změřeny teploty vzduchu ve stínu. V Brně bylo 20°C (68°F, 293.15K), v Jeseníku 10°C (50°F, 283.15K).
                Pokud bychom počítali poměry těchto číselných hodnot, vyšly by v každé stupnici různé výsledky:
                {"$$\\mathrm{°C: } \\frac{20}{10} = 2, \\quad \\mathrm{°F: }\\frac{68}{50} = 1.36, \\quad  \\mathrm{K: }\\frac{293.15}{283.15} = 1,035.$$"}
                Nemá tedy smysl říkat, že v Brně bylo 'dvakrát tepleji' než v Jeseníku, protože to závisí na tom, v jaké stupnici měříme.
                Obzvláště je potřeba se podobných výroků vyvarovat ve vědeckém kontextu.
                Tento znak je <b>kvantitativní</b> <b>intervalový</b>. Srovnejte s následujícím příkladem s délkami.
                Termín 'intervalový' zhruba znamená, že hodnoty měříme na lineární stupnici, jejíž nula je ale umístěna libovolně/dle nějaké konvence.
                (U Kelvinovy stupnice samozřejmě pozice nuly libovolná není, ale je dána z fyzikální podstaty.)
                </p>
            </div>
            <div className="example">
                <p>Vzdálenost mezi Brnem a Prahou je 200 km (124,274 mil), mezi Brnem a Zlínem 100 km (62,137 mil).
                Poměry těchto číselných hodnot jsou stejné v obou stupnicích:
                {"$$\\textrm{km: } \\frac{200}{100} = 2, \\quad \\mathrm{míle: } \\frac{124.274}{62.137} = 2.$$"}
                Má tedy smysl říct, že vzdálenost mezi Brnem a Prahou je dvojnásobná než vzdálenost mezi Brnem a Zlínem.
                Tento znak je <b>kvantitativní</b> <b>poměrový</b>. Termín 'poměrový' indikuje, že počítání poměrů hodnot znaku má smysl - nese informaci.
                Vyžaduje stupnici s jednoznačně (logicky) definovanou nulou.
                </p>
            </div>
            <div className="example">
                <p>Jedna rodina má 2 děti, druhá 4 děti. Má smysl říct, že druhá rodina má dvakrát tolik dětí.
                Tento znak je opět kvantitativní a poměrový. Oproti předchozímu příkladu se vzdálenostmi se liší tím,
                že je <b>diskrétní</b> (počet dětí je nezáporné celé číslo), nikoli <b>spojitý</b> (vzdálenost je nezáporné reálné číslo).
                </p>
            </div>

            <p>
            Někde se běžně dělení znaků se uvádí takto:
            </p>
            <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={DeleniZnaku2} style={{maxWidth: 450}} alt={"Dělení znaků 2"} />
            </div>

            Toto dělení vychází z následujících bodů:
            <ul>
                <li>U kvalitativních znaků by nikoho nenapadlo mluvit o spojitosti (jsou intuitivně diskrétní).</li>
                <li>U kvantitativních znaků je diskrétní a spojitá varianta výrazně odlišná, takže má smysl je rozlišovat.
                    Zároveň, rozdíl mezi intervalovou nebo poměrovou povahou není pro mnoho výpočtů důležitý -
                    např. průměr a rozptyl se počítají stejně, histogram se konstruuje stejně, atd.
                </li>
            </ul>
            <p>
            Proto se v tomto dělení u kvalitativních znaků diskrétnost/spojitost nezmiňuje,
            a u kvantitativních znaků se neuvádí intervalovost/poměrovost, ale právě pouze diskrétnost/spojitost.
            </p>
            <p>
            Je ale dobré si uvědomit, že:
            </p>
            <ul>
                <li>Variační koeficient {"$\\frac{\\sigma}{\\overline{x}}$"} (poměr směrodatné odchylky a průměru) má smysl
                počítat pouze pro poměrová data. Některé knihy toto opíšou slovy: "Má smysl jen pro znaky, které
                nabývají pouze kladných nebo pouze záporných hodnot." Lze polemizovat o tom, do jaké míry je tento opis přesný.
                </li>
                <li>Další operace, jako třeba geometrický průměr, mají také smysl
                pouze pro poměrová data. Pokud se je však člověk nechystá při statistické analýze použít,
                nemusí se starat o to, je-li znak poměrový nebo ne.
                </li>
            </ul>

            <p>
            Další alternativní označení pro kvantitativní znak je: numerický, metrický (zřídka), kardinální (zřídka).
            </p>

            <p>
            <sup>1</sup> Pozn.: v angličtině slovo "quality" neoznačuje jakost, ale prostě vlastnost (povahu). Do češtiny přejaté slovo
            "kvalitativní" tedy neznamená "více či méně kvalitní", ale "týkající se vlastností".
            V češtině se tento význam někdy objevuje také: Když řekneme, že různí lidé mají různé kvality, znamená to, že mají různé schopnosti/dovednosti/charakterové vlastnosti
            (ne, že jsou "různě kvalitní"). Typické senzorické kvality (kvalitativní znaky) při degustaci jsou třeba "chuť", "vůně", "konzistence".
            </p>

            <hr/>
            <h2 id="data-types-comments">Pro zvídavé</h2>
            <p>
            Rozdělení znaků (přesněji jejich škál) na nominální, ordinální, intervalové a poměrové provedl S.S. Stevens,
            viz např. <a href={"https://en.wikipedia.org/wiki/Level_of_measurement"} target="_blank" rel="noopener noreferrer" className="external-link">Wikipedie</a>.
            Jedná se však jen o přibližné dělení, které je někdy užitečné a jindy ne.
            Prof. Wilkinson ve svém <a href={"https://www.cs.uic.edu/~wilkinson/Publications/stevens.pdf"} target="_blank" rel="noopener noreferrer" className="external-link">textu</a>
            &nbsp;uvádí [výběr, volný překlad]:
            </p>
            <p><i>
            Stevensova škálová typologie není nutně nějaká inherentní charakteristika proměnných [znaků],
            dokonce ani samotných dat, ale toho, jak s informacemi zacházíme - toho, k čemu je používáme.

            Tvrzení, společné mnoha tradičním statistickým textům, že „hodnoty dat jsou nominální, ordinální, intervalové nebo poměrové“,
            zjednodušuje věc natolik, že je nepravdivé. Typ měřítka, jak jej definoval Stevens, není atributem dat,
            ale spíše závisí na otázkách, které si hodláme ohldně dat položit, a na jakýchkoli dalších informacích, které můžeme mít k dispozici.
            </i></p>
            <p><i>
            Poměrně snadno lze konstruovat situace, kdy škálový typ dat závisí na jejich interpretaci nebo na tom,
            jaké dodatečné informace jsou k dispozici. Př.: Na statistické konferenci
            byly u dveří při vstupu účastníkům přidělovány po sobě jdoucí číslované vstupenky počínaje „1“, aby mohlo být uspořádáno slosování.
            Když bylo vybráno a oznámeno výherní číslo „126“, jedna účastnice ho porovnala se svým tiketem, aby zjistila,
            zda vyhrála, a tak „126“ správně interpretovala jako nominální hodnotu.
            Poté se rozhlédla po místnosti a poznamenala: „Nevypadá to, že by tu bylo 126 lidí“
            - nyní interpretovala stejnou hodnotu, opět správně (ale už s použitím dalších informací,
            tj. že vstupenky byly přidělovány postupně počínaje „1“), jako hodnotu poměrové stupnice.
            Jeden z účastníků porovnal číslo svého tiketu („56“) s výherní hodnotou a uvědomil si, že dorazil příliš brzy na to,
            aby vyhrál cenu, a tím hodnoty interpretoval ordinálně.
            Pokud by byly k dispozici údaje o rychlosti a pravidelnosti příchodů, mohl by se z rozdílu 70 mezi svým a vítězným
            tiketem pokusit odhadnout, o jakou dobu měl zpozdit svůj příchod - tím by interpretoval číslo tiketu za intervalové škále.
            </i></p>

        </div>
        </div>
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

// https://www.cs.uic.edu/~wilkinson/Publications/stevens.pdf

// https://www.freecodecamp.org/news/types-of-data-in-statistics-nominal-ordinal-interval-and-ratio-data-types-explained-with-examples/

// https://stats.stackexchange.com/questions/106393/should-types-of-data-nominal-ordinal-interval-ratio-really-be-considered-types