import React from 'react';


export function Sources()  {

    return (
        <div className="chapter-container">
        <div className="centered-content">
        <div className="card">
            <h1>Doporučená literatura</h1>

                <p>FREEDMAN, David, PISANI, Robert, PURVES, Roger. <i>Statistics.</i> 4. vyd. W. W. Norton & Company, 2007. ISBN 13-978-0-393-92972-0.</p>
                <p>ZAR, Jerrold H. <i>Biostatistical Analysis.</i> 5. vyd. Pearson Education Limited, 2014. ISBN 978-1-292-02404-2.</p>
                <p>KARPÍŠEK, Zdeněk. <i>Matematika IV.</i> 2. vyd. Brno: Akademické nakladatelství CERM, 2003. ISBN 80-214-2522-9.</p>


            <h1>Online zdroje</h1>

            <p>
            Kurz z Berkeley, autor <a href={"https://www.stat.berkeley.edu/~stark/SticiGui/index.htm"} target="_blank" rel="noopener noreferrer" className="external-link">P. B. Stark</a>.<br/>
            </p>

            <p>
            Kurz MU <a href={"https://is.muni.cz/do/rect/el/estud/prif/ps13/prav_stat/web_1/index.html"} target="_blank" rel="noopener noreferrer" className="external-link">Pravděpodobnost a statistika I</a>.<br/>
            Kurz MU <a href={"https://is.muni.cz/do/rect/el/estud/prif/ps13/prav_stat/web_2/index.html"} target="_blank" rel="noopener noreferrer" className="external-link">Pravděpodobnost a statistika II</a>.<br/>
            Interaktivní kurz MU <a href={"https://is.muni.cz/do/rect/el/estud/prif/ps15/statistika/web/index.html"} target="_blank" rel="noopener noreferrer" className="external-link">Statistika a pravděpodobnost</a>.<br/>
            Skripta k přednášce od <a href={"https://www.karlin.mff.cuni.cz/~komarek/vyuka/2023_24/nmsa331/ms1.pdf"} target="_blank" rel="noopener noreferrer" className="external-link">doc. Kulicha</a> na UK.<br/>
            Materiály <a href='https://www.karlin.mff.cuni.cz/~zichova/' target="_blank" rel="noopener noreferrer" className="external-link">na UK</a>.<br/>
            Materiály <a href={"https://math.fme.vutbr.cz/Home/karpisek/soubory-ke-stazeni"} target="_blank" rel="noopener noreferrer" className="external-link"> docenta Karpíška</a>.<br/>
            Materály <a href='https://cmp.felk.cvut.cz/~navara/stat/PMS_ebook.pdf' target="_blank" rel="noopener noreferrer" className="external-link">ČVUT</a> (prof. Navara).<br/>
            Online učebnice <a href={"https://publi.cz/books/201/Obsah.html"} target="_blank" rel="noopener noreferrer" className="external-link">LF UK</a> (doktor Záhora).<br/>
            Skripta <a href={"https://www.physics.muni.cz/ufkl/Vyuka/files/JosefHumlicek-StatZpracMereni-1984.pdf" } target="_blank" rel="noopener noreferrer" className="external-link">prof. Humlíčka</a>.<br/>
            </p>

            <p>
            Webový kurz <a href={'https://www.statology.org/'} target="_blank" rel="noopener noreferrer" className="external-link">Statology</a>,&nbsp;
            <a href={"https://www.statlect.com/"} target="_blank" rel="noopener noreferrer" className="external-link">StatLect</a>,&nbsp;
            <a href={"https://www.khanacademy.org/math/statistics-probability"} target="_blank" rel="noopener noreferrer" className="external-link">Khan Academy</a>.<br/>
            YouTube kanál <a href={"https://www.youtube.com/@zedstatistics"} target="_blank" rel="noopener noreferrer" className="external-link"> zedstatistics</a>.<br/>
            </p>

            <p>
            Článek o <a href={"https://www.cs.uic.edu/~wilkinson/Publications/stevens.pdf"} target="_blank" rel="noopener noreferrer" className="external-link">typech statistických znaků</a>.<br/>
            </p>

            <p>
            Nebojte se hojně využívat Wikipedii, YouTube, AI (ChatGPT, ollama) a další zdroje.
            </p>

            <h1>Software pro statistické výpočty</h1>

            Programovací jazyk <a href='https://www.r-project.org/' target="_blank" rel="noopener noreferrer" className="external-link">R</a> a
            jeho rozhraní <a href='https://posit.co/download/rstudio-desktop/' target="_blank" rel="noopener noreferrer" className="external-link">RStudio</a>.<br/>

            Programovací jazyk <a href='https://www.python.org/' target="_blank" rel="noopener noreferrer" className="external-link">Python</a>
            &nbsp;a balíky <a href='https://numpy.org/' target="_blank" rel="noopener noreferrer" className="external-link">NumPy</a>,&nbsp;
            <a href='https://scipy.org/' target="_blank" rel="noopener noreferrer" className="external-link">SciPy</a>
            &nbsp;a <a href='https://pandas.pydata.org/' target="_blank" rel="noopener noreferrer" className="external-link">Pandas</a>.<br/>
            Octave, Matlab, Maple.<br/>
            OpenOffice, LibreOffice, MS Excel.<br/>



            <h1>Klasická literatura - reference</h1>

            <p>ANDĚL, Jiří. <i>Statistické metody.</i> 5. vyd. Praha: MatfyzPress, 2019. 300 s. ISBN 978-80-7378-381-5.</p>
            <p>ANDĚL, Jiří. <i>Základy matematické statistiky.</i> 3. vyd. Praha: MatfyzPress, 2011. 360 s. ISBN  978-80-7378-162-0.</p>
            <p>ZVÁRA, Karel, ŠTĚPÁN Josef. <i>Pravděpodobnost a matematická statistika.</i> 6. vyd. Praha: MatfyzPress, 2019. 232 s. ISBN 978-80-7378-388-4.</p>
            <p>MELOUN, Milan, MILITKÝ, Jiří. <i>Kompendium statistického zpracování dat.</i> 2. vyd. Academia, 2006.</p>
            <p>RENYI, Alfred. <i>Probability Theory.</i> Dover Books on Mathematics, Dover Publications, 2007. 672 s. ISBN 978-0486458670.</p>
            <p>BAUER, Heinz. <i>Probability Theory and Elements of Measure Theory.</i> Academic Press, 1981. ISBN 0-12-082820-0.</p>

        </div>
        </div>
        </div>
    );
}
