import React, { useEffect, useRef, useState } from 'react';

import { erf } from 'mathjs'


import Plot from 'react-plotly.js';
import { Data } from 'plotly.js';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import { TOC, TOCProps, defineTOCElements } from '~/TOC';

import Slider from 'rc-slider';
import Handle from 'rc-slider/lib/Handles';
import 'rc-slider/assets/index.css';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { line } from 'd3';

// const density1 = require('../assets/images/density1.png');

// const SliderWithTooltip = Slider.createSliderWithTooltip(Slider);

export const DistributionFunctionMeta = {
    title: "Distribuční funkce",
    shortTitle: "Distribuční funkce",
    path: Path.distribution_function,
    element: (sectionNumber:string) => <DistributionFunction sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "distribf-principle",
    "distribf-continuous",
    "distribf-discrete",
    "distribf-properties",
    "distribf-transition",
    "distribf-quantiles",
];



type DistributionProps = {
    computeF: (x: number) => number;
    computef: (x: number) => number;
    xmin: number;
    xmax: number;
};




// function handle(
//     {index, prefixCls, value, dragging}:
//     {
//         index: number,
//         prefixCls: string,
//         value: number,
//         dragging: boolean
//     }
// ) {
//     return (
//         <Tooltip
//             prefixCls="rc-slider-tooltip"
//             overlay={value}
//             visible={dragging}
//             placement="top"
//             key={index}
//         >
//             <Handle value={value} {...restProps} />
//         </Tooltip>
//     );
// }

const markerColor = 'rgba(255,165,0,0.8)';
const mainColor = 'rgba(32,119,180,1)';
const transparentColor = 'rgba(255,255,255,0.0)';


function DistributionPlots ({ computeF, computef, xmin, xmax }: DistributionProps) {
    const [xStar, setXStar] = useState(xmin * 0.6 + xmax * 0.4);

    const n = React.useMemo(() => 100, []);

    const xValues = React.useMemo(
        () => Array.from({ length: n+1 }, (_, i) => xmin + i * (xmax - xmin) / n)
    , [xmin, xmax, n]);

    const fValues = React.useMemo(() => xValues.map(computef), [xValues, computef]);
    const FValues = React.useMemo(() => xValues.map(computeF), [xValues, computeF]);

    const handleSliderChange = React.useCallback((value: number | number[]) => {
        if (typeof value !== 'number') {
            return; // multi slider?
        }
        setXStar(value);
    }, []);


    return (
        <div>
            <Plot
                data={[
                {
                    x: xValues,
                    y: FValues,
                    type: 'scatter',
                    mode: 'lines',
                    name:  'F',
                },
                {
                    x: [xStar],
                    y: [computeF(xStar)],
                    type: 'scatter',
                    mode: 'text+markers',
                    text: [computeF(xStar).toFixed(2)],
                    textposition: 'top right',
                    name: 'F(x)',
                    marker: { color: markerColor, size: 10}
                },
                ]}
                layout={{
                    width: 700,
                    height: 200,
                    title: 'Distribuční funkce (F)',
                    titlefont: { size: 16 },
                    xaxis: { range: [xmin, xmax] },
                    yaxis: { range: [-0.1, 1.3] },
                    margin: { r: 150, t: 40, b: 20 },
                }}
            />
            <Plot
                data={[
                    {
                        x: xValues,
                        y: fValues,
                        type: 'scatter',
                        mode: 'lines',
                        name: 'f',
                    },
                    {
                        x: xValues.filter(x => x <= xStar),
                        y: fValues.slice(0, xValues.findIndex(x => x > xStar)),
                        fill: 'tozeroy',
                        type: 'scatter',
                        mode: 'none',
                        fillcolor: markerColor,
                        name: 'P((-∞, x⟩)',
                    },
                ]}
                layout={{
                    width: 700,
                    height: 200,
                    title: 'Hustota (f)',
                    titlefont: { size: 16 },
                    xaxis: { range: [xmin, xmax] },
                    margin: { r: 150, t: 40, b:20 },
                }}
            />
            <div style={{ paddingLeft: 80, paddingRight: 140 }}>
                {/* <label htmlFor="distribf-x-slider">x</label> */}
                <Slider
                    // id="distribf-x-slider"
                    min={xmin}
                    max={xmax}
                    step={(xmax - xmin) / n}
                    defaultValue={xStar}
                    onChange={handleSliderChange}
                    // handleRender={handle}
                    styles={ { handle: { width: 12, height: 12, borderWidth: 6} } }
                />
            </div>
        </div>
    );
}

const computeF = (x: number): number => {
    // Replace with your actual CDF computation
    return 0.5 * (1 + erf((x - 0) / (Math.sqrt(2) * 1)));
  };

const computef = (x: number): number => {
    // Replace with your actual PDF computation
    return (1 / (Math.sqrt(2 * Math.PI))) * Math.exp(-0.5 * x * x);
};

const compute = (x: number): number => {
    // Replace with your actual CDF computation
    return 0.5 * (1 + erf((x - 0) / (Math.sqrt(2) * 1)));
  };

const computef_quadra = (x: number): number => {
    if (x >= -2 && x <= -1) {
        return -x - 1;
    }
    else if (x >= 1 && x <= 2) {
        return x - 1;
    }
    else {
        return 0;
    }

};

const computeF_quadra = (x: number): number => {
    if (x <= -2) {
        return 0;
    }
    else if (x >= -2 && x <= -1) {
        return - x * x/2 - x
    }
    else if (x >= -1 && x <= 1) {
        return 0.5;
    }
    else if (x >= 1 && x <= 2) {
        return x * x/2 - x + 1;
    }
    else {
        return 1;
    }
}


type DiscreteDistributionProps = {
    xValues: number[];
    pValues: number[];
};

function DiscreteDistributionPlots({ xValues, pValues }: DiscreteDistributionProps) {
    const [xStar, setXStar] = useState(xValues[Math.floor(xValues.length / 3)]  + 0.3);

    const n = React.useMemo(() => 100, []);

    const handleSliderChange = (value: number | number[]) => {
        if (typeof value !== 'number') {
            return; // multi slider?
        }
        setXStar(value);
    };

    const FValues = React.useMemo(() => {
        let sum = 0;
        return xValues.map((x, i) => {
            sum += pValues[i];
            return sum;
        });
    }, [xValues, pValues]);

    const DivisionIndex = React.useMemo(
        () => {
            for (let i = xValues.length - 1; i >= 0; i--) {
                if (xValues[i] <= xStar) {
                    return i;
                }
            }
            return -1;
        },
        [xValues, xStar]
    );

    const xmin = React.useMemo(() => Math.min(...xValues) - 0.2 * (Math.max(...xValues) - Math.min(...xValues)), [xValues]);
    const xmax = React.useMemo(() => Math.max(...xValues) + 0.2 * (Math.max(...xValues) - Math.min(...xValues)), [xValues]);

    const xLeft = React.useMemo(() => xValues.slice(0, DivisionIndex + 1), [xValues, DivisionIndex]);
    const xRight = React.useMemo(() => xValues.slice(DivisionIndex + 1), [xValues, DivisionIndex]);

    const pLeft = React.useMemo(() => pValues.slice(0, DivisionIndex + 1), [pValues, DivisionIndex]);
    const pRight = React.useMemo(() => pValues.slice(DivisionIndex + 1), [pValues, DivisionIndex]);


    const computeF = (x: number): number => {
        for (let i = xValues.length - 1; i >= 0; i--) {
            if (xValues[i] <= x) {
                return FValues[i];
            }
        }
        return 0;
    };

    const stepData =  React.useMemo(
        () => {
            const result  = [[xmin, 0], [xValues[0], 0]];
            for (let i = 0; i < xValues.length - 1; i++) {
                result.push([xValues[i], FValues[i]]);
                result.push([xValues[i+1], FValues[i]]);
            }
            result.push([xValues[xValues.length - 1], FValues[xValues.length - 1]]);
            result.push([xmax, 1]);
            return result;
        }
    , [xmin, xmax, xValues, FValues]);

    const evenIndexes = React.useMemo(() => stepData.map((_, i) => i).filter(i => i % 2 === 0), [stepData]);


    const series: Data[] = React.useMemo(() => {
        return evenIndexes.slice(0, evenIndexes.length).map((i, _) => {
        console.log("Step", i, stepData[i], stepData[i+1]);
            return {
                x: [stepData[i][0], stepData[i+1][0]],
                y: [stepData[i][1], stepData[i+1][1]],
                type: 'scatter',
                mode: 'lines',
                line: { color: mainColor },
                showlegend: i == 0,
                name:  'F',
            };
        }) }, [stepData, evenIndexes]);

    return (
        <div>
            {/* Distribuční funkce: */}
            <Plot
                data={[
                    ...series,
                    {
                        x: xValues,
                        y: FValues,
                        type: 'scatter',
                        mode: 'markers',
                        marker: { color: mainColor },
                        showlegend: false,
                    },
                    {
                        x: xValues,
                        y: FValues.map((y, i) => y - pValues[i]),
                        type: 'scatter',
                        mode: 'markers',
                        marker: { color: 'white', line: { width: 1, color: mainColor}, },
                        showlegend: false,
                    },
                    {
                        x: [xStar],
                        y: [computeF(xStar)],
                        type: 'scatter',
                        mode: 'text+markers',
                        text: [computeF(xStar).toFixed(2)],
                        textposition: 'top right',
                        name: 'F(x)',
                        marker: { color: markerColor, size: 10}
                    },
                ]}
                layout={{
                    width: 700,
                    height: 200,
                    title: 'Distribuční funkce (F)',
                    titlefont: { size: 16 },
                    xaxis: { range: [xmin, xmax] },
                    yaxis: { range: [-0.1, 1.3] },
                    margin: { r: 160, t: 60, b: 20 },
                }}
            />

            {/* Pravdepodobnostni funkce: */}
            <Plot
                data={[
                    {
                        x: xValues,
                        y: pValues,
                        type: 'scatter',
                        mode: 'markers',
                        name: 'p',
                        marker: { color: mainColor },
                        showlegend: true,
                    },
                    {
                        x: xLeft,
                        y: pLeft,
                        type: 'scatter',
                        mode: 'text+markers',
                        name: 'P((-∞, x⟩)= ∑pᵢ',
                        marker: { color: transparentColor, size: 12, line: { width: 3, color: markerColor}, },
                        showlegend: true,
                        text: pLeft.map(p => p.toFixed(2)),
                        textposition: 'top right',
                    },
                ]}
                layout={{
                    width: 700,
                    height: 200,
                    title: 'Pravděpodobnostní funkce (p)',
                    titlefont: { size: 16 },
                    xaxis: { range: [xmin, xmax], tickmode: 'array', tickvals: xValues },
                    yaxis: { range: [-0.1, 1.1], tickmode: 'array', tickvals: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9] },
                    margin: { r: 160, t: 40, b:20 },
                    showlegend: true,
                }}
            />
            <div style={{ paddingLeft: 80, paddingRight: 155 }}>
                <Slider
                    min={xmin}
                    max={xmax}
                    step={(xmax - xmin) / n}
                    defaultValue={xStar}
                    onChange={handleSliderChange}
                    styles={ { handle: { width: 12, height: 12, borderWidth: 6} } }
                />
            </div>
        </div>
    );
}




export function DistributionFunction({sectionNumber}: {sectionNumber: string}) {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);


    return (
        <MathJaxWrapper>
            <MathJax>
            <div className="chapter-container">
            <div className="centered-content">
            <div className="card" ref={chapterRef}>

            <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{DistributionFunctionMeta.title}</h1>

            <h2 id="distribf-principle">Princip</h2>

            <p>
            Distribuční funkce je nezáporná funkce definovaná na celém {"$\\mathbb{R}$"},
            která je neklesající (tj. v každém místě buď roste, nebo je konstatní - ale nikde neklesá),
            "začíná od nuly" (její limita v {"$-\\infty$"} je 0) a "končí v jedničce" (její limita v {"$+\\infty$"} je 1).
            </p>

            <p>
            Máme v {"$\\mathbb{R}$"} (na borelovských podmnožinách) definované nějaké rozdělení pravděpodobnosti.
            Distribuční funkce {"$F(x)$"} tohoto rozdělení vyjadřuje v daném bodě {"$x$"} pravděpodobnost jevu {"$(-\\infty, x\\rangle$"}:
            {`$$
                F(x) = P\\big((-\\infty, x\\rangle\\big).
            $$`}
            </p>

            <p>
            Distribuční funkce vyjadřuje kumulovanou pravděpodobnost takto: Jdeme od {"$-\\infty$"} podél osy {"$x$"} doprava,
            postupně načítáme/kumulujeme nalezenou pravděpodobnost, a v každém bodě {"$x$"} si zaznamenáme, kolik jsme do této chvíle (včetně) načetli.
            </p>

            <h2 id="distribf-continuous">Spojité rozdělení</h2>
            <p>
            Princip je ukázán na následujícím grafu pro <strong>spojité</strong> rozdělení, kde {"$f(x)$"} je hustota pravděpodobnosti a pravděpodobnost
            je určena integrálem z hustoty {"$f(x)$"}. <i>Použijte slider pod grafy pro změnu bodu {"$x$"} a sledujte, jak se mění hodnoty distribuční funkce a hustoty.</i>
            {`$$
                F(x) = P\\big((-\\infty, x\\rangle\\big) = \\int_{-\\infty}^x f(t)\\,dt
            $$`}
            </p>

            <DistributionPlots
                computeF={computeF}
                computef={computef}
                xmin={-5}
                xmax={5}
            />

            <h2 id="distribf-discrete">Diskrétní rozdělení</h2>
            <p>Distribuční funkce <strong>diskrétního</strong> rozdělení vypadá jako <strong>schody</strong>.
            V bodech, kde je pravděpodobnostní funkce nulová, je distribuční funkce konstantní.
            V bodech, kde je pravděpodobnostní funkce nenulová, distribuční funkce poskočí nahoru o hodnotu pravděpodobnosti v daném bodě.
            {`$$
                F(x) = P\\big((-\\infty, x\\rangle\\big) = \\sum_{t\\leq x} p(t)
            $$`}
            </p>

            <DiscreteDistributionPlots
                xValues={[1, 2.2, 3, 3.7, 4.9]}
                pValues={[0.1, 0.15, 0.35, 0.25, 0.15]}
            />

            <h2 id="distribf-properties">Pravděpodobnost intervalů</h2>

            <p>
            Z distribuční funkce jsme schopni určit pravděpodobnost intervalů.
            Mějme {"$a < b$"} na ose {"$x$"}. Potom pro intervaly platí:
            {`$$\\begin{align*}
                (-\\infty, a\\rangle & \\subset (-\\infty , b\\rangle, \\\\
                (a, b\\rangle &= (-\\infty , b\\rangle \\setminus (-\\infty, a\\rangle.
            \\end{align*}$$`}
            Pro pravděpodobnosti pak platí:
            {`$$
                 P\\big((a, b\\rangle\\big) = P\\big((-\\infty , b\\rangle\\big) - P\\big((-\\infty, a\\rangle\\big) = F(b) - F(a).
            $$`}
            </p>

            <p>Ve <strong>spojitém</strong> případě je pravděpodobnost každého individuálního bodu nulová,
            takže nehraje roli jestli je interval otevřený nebo uzavřený. Máme:
            {`$$
                F(b) - F(a) = P\\big(\\textcolor{red}{(}a, b\\textcolor{red}{)}\\big) = P\\big(\\textcolor{red}{\\langle} a, b\\textcolor{red}{)}\\big) = P\\big(\\textcolor{red}{(}a, b\\textcolor{red}{\\rangle}\\big) = P\\big(\\textcolor{red}{\\langle} a, b\\textcolor{red}{\\rangle}\\big)
            $$`}
            </p>

            <p>V <strong>diskrétním</strong> případě může být pravděpodobnost jednotlivých bodů nenulová, takže to, zda je interval otevřený nebo uzavřený
            může hrát roli, pokud se jeho krajní bod/body zrovna trefily do bodů s nenulovou pravděpodobností.
            Obecně platí:
            {`$$\\begin{align*}
                P\\big(\\textcolor{red}{(}a, b\\textcolor{red}{\\rangle}\\big) &= F(b) - F(a), \\\\
                P\\big(\\textcolor{red}{\\langle} a, b\\textcolor{red}{\\rangle}\\big) &= F(b) - F(a) + p(a), \\\\
                P\\big(\\textcolor{red}{(}a, b\\textcolor{red}{)}\\big) &= F(b) - F(a) \\qquad \\quad - p(b), \\\\
                P\\big(\\textcolor{red}{\\langle} a, b\\textcolor{red}{)}\\big) &= F(b) - F(a) + p(a) - p(b).
            \\end{align*}$$`}
            </p>

            <h2 id="distribf-transition">Přechod od {"$F$"} k {"$f$"} nebo {"$p$"}</h2>

            <p>
            Ve <strong>spojitém</strong> případě platí, že hustota je derivací distribuční funkce:
            {`$$
                f(x) = F'(x).
            $$`}
            </p>

            <p>
            V <strong>diskrétním</strong> případě se pravděpodobnostní funkce získá změřením výšky schodů (diferencí) distribuční funkce:
            {`$$
                p(x) = \\lim_{t \\to x^{+}} F(t) - \\lim_{t \\to x^{-}} F(t).
            $$`}
            Limita z prava {"$\\lim_{t \\to x^{+}} F(t)$"} představuje y-ovou hodnotu na horní úrovni schodu,
            limita z leva {"$\\lim_{t \\to x^{-}} F(t)$"} představuje y-ovou hodnotu na dolní úrovni schodu.
            Jejich odečtením získáme výsku schodu v daném bodě {"$x$"}.
            Pokud v daném bodě není schod, obě limity vycházejí stejně (distribuční funkce je v takovém bodě spojitá a konstantní),
            takže zde {"$p(x) = c - c = 0$"}.
            </p>

            <h2 id="distribf-quantiles">Kvantily a kvantilová funkce</h2>

            <p>Kvantil {"$q_{\\alpha}$"} je 'ideálně' taková hodnota {"$x$"}, že
            {`$$\\begin{align*}
                P\\big((-\\infty, x\\rangle\\big) &= \\alpha \\quad \\text{a} \\          & P\\big((x, \\infty)\\big) & = 1 - \\alpha, \\text{tedy} \\\\
                P\\big((-\\infty, q_{\\alpha}\\rangle\\big) &= \\alpha \\quad \\text{a}   & P\\big((q_{\\alpha}, \\infty)\\big) & = 1 - \\alpha. \\\\
            \\end{align*}$$`}
            Protože {"$P\\big((-\\infty, x\\rangle\\big)$"} je z definice distribuční funkce rovno {"$F(x)$"},
            je kvantil taková hodnota {"$x$"}, že {"$F(x) = \\alpha$"}, tedy {"$F(q_{\\alpha}) = \\alpha$"}, a ztoho vidíme, že:
            {`$$
                q_{\\alpha} = F^{-1}(\\alpha).
            $$`}
            Funkce, která každému* {"$\\alpha \\in \\langle 0, 1 \\rangle$"} přiřadí
            kvantil {"$q_{\\alpha}$"} se nazývá kvantilová funkce, a je inverzní funkcí distribuční funkce {"$F$"}.
            </p>

            <p>
            Problém je v tom, že inverzní funkce k distibuční funkci nemusí existovat, pokud distribuční funkce není prostá
            (existuje více hodnot {"$x$"}, pro které je {"$F(x) = \\alpha$"}),
            nebo pokud je nespojitá (neexistuje žádná hodnota {"$x$"}, pro kterou by bylo {"$F(x) = \\alpha$"}).
            </p>

            <p>U diskrétního rozdělení nastávají obě tyto situace.</p>

            <p>U spojitého rozdělení je distribuční funkce spojitá, ale pořád memusí být prostá - pokud je na nějakém intervalu
            hustota nulová, potom je zde distribuční funkce konstantní (a tedy není prostá).
            Tento případ je ilustrován na obrázku níže, kde {"$F(x) = 0.5$"} platí na celém intervalu {"$\\langle-1, 1\\rangle$"},
            a tedy medián {"$q_{0.5}$"} není jednoznačně definován (může to být libovolná hodnota z tohoto intervalu; dle konvence
            se bere buď prostřední, nebo nejmenší z možných hodnot - viz dále). Kromě toho je hustota nulová
            (a {"$F$"} konstantní) i pro {"$x < -2$"} a {"$x > 2$"}.
            </p>


            <DistributionPlots
                computeF={computeF_quadra}
                computef={computef_quadra}
                xmin={-2.5}
                xmax={2.5}
            />
            <p>
            Na obrázku je číslo u žlutého bodu (jeho y-ová souřadnice) rovno {"$\\alpha$"}, a jeho x-ová souřadnice je rovna {"$q_\\alpha$"}.
            </p>

            <p>
            *<i>Je-li hustota všude nenulová, jako je tomu např. u normálního rozdělení, kvantil {"$q_{0}$"} je {"$-\\infty$"} a kvantil {"$q_{1}$"} je {"$+\\infty$"}.
            Pokud bychom pracovali čistě v {"$\\mathbb{R}$"}, museli bychom říct, že tyto kvantily neexistují, protože {"$-\\infty \\notin \\mathbb{R}$"}
            a {"$+\\infty \\notin \\mathbb{R}$"}. Kvantilová funkce by pak byla definovaná jen na intervalu {"$(0, 1)$"} (bez krajních bodů).
            Ale pokud rozšíříme reálnou osu na {"$\\bar{\\mathbb{R}} = \\mathbb{R} \\cup \\{-\\infty, +\\infty\\}$"},
            a kvantilovou funkci chápeme jako funkci z {"$\\langle 0, 1 \\rangle$"} do {"$\\overline{\\mathbb{R}}$"}, pak je definiční obor
            {"$\\langle 0, 1\\rangle$"} v pořádku.</i>
            </p>

            <p>Kvantilová funkce je slušně vysvětlena i na
            &nbsp;<a href={"https://en.wikipedia.org/wiki/Quantile_function"} target="_blank" rel="noopener noreferrer" className="external-link">Wikipedii</a>
            </p>

            <p>
            Nejběžnější definice kvantilu (a kvantilové funkce), která tyto problémy řeší, je tato:
            {`$$\\begin{align*}
                q_{\\alpha} &= \\inf\\{x \\in \\mathbb{R} : F(x) \\geq \\alpha\\}, \\\\
            \\end{align*}$$`}
            je to tedy první číslo {"$x$"} při průchodu po ose {"$x$"} z leva do prava,
            na kterém hodnota distribuční dosáhne hodnoty {"$\\alpha$"}, nebo se přes ni skokově přehoupne.
            </p>

            <i>TODO: grafy... [rozpracováno]</i>

        </div>
        </div>
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

