import React, { useEffect, useRef, useState } from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import { TOC, TOCProps, defineTOCElements } from '~/TOC';


export const VarianceMeta = {
    title: "Rozptyl a směrodatná odchylka",
    shortTitle: "Rozptyl",
    path: Path.variance,
    element: (sectionNumber:string) => <Variance sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "datavariance-behaviour",
    "datavariance-units",
    "datavariance-transformations",
    "datavariance-boxed-data",
    "datavariance-context-of-usage",
    "datavariance-estimates",
];


export function Variance({sectionNumber}: {sectionNumber: string}) {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);

    return (
        <MathJaxWrapper>
            <MathJax>
            <div className="chapter-container">
            <div className="centered-content">
            <div className="card" ref={chapterRef}>

            <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{VarianceMeta.title}</h1>

            <p><strong>Směrodatná odchylka</strong> (en: standard deviation) je číslo, které charakterizuje, jak moc data jsou (nebo nejsou) koncentrovaná kolem průměru.
            Spočítá se takto:
            {
                `$$
                s_n = \\sqrt{\\frac{1}{n} \\sum_{i=1}^n (x_i - \\overline{x})^2}
                $$`
            }
            Je to vlastně kvadratický průměr vzdáleností jednotlivých hodnot {"$x_i$"} od jejich průměru {"$\\overline{x}$"}.
            </p>

            <p><strong>Rozptyl</strong> (en: variance) je druhá mocnina směrodatné odchylky:
            {
                `$$
                s_n^2 = \\frac{1}{n} \\sum_{i=1}^n (x_i - \\overline{x})^2
                $$`
            }
            Je to aritmetický průměr čtverců vzdáleností jednotlivých hodnot {"$x_i$"} od jejich průměru {"$\\overline{x}$"}.
            </p>

            <p>Pokud máme k dispozici rozptyl {"$s_n^2$"}, směrodatnou odchylku spočítáme jako jeho odmocninu: {"$s_n = \\sqrt{s_n^2}$"}.</p>

            <p><i>
                Pozn. 1: Pokud Vám to značení přijde divné, je to tak. Pro rozptyl se prostě nezavádí dedikovaný symbol, ale používá se {"${s_n^2}$"},
                což lze číst zároveň jako atomický symbol pro rozptyl, a zároveň i jako vzorec pro jeho výpočet ze směrodatné odchylky {"$s_n$"}.
            </i></p>

            <p><i>
            Pozn. 2: Proč používáme dolní index {"$n$"}? Abychom nějak odlišili tento vzorec od vzorce pro 'výběrovou směrodatnou odchylku' {"$s$"},
            kterou uvidíte až níže. Její existence má dobrý důvod, který ale - pokud toto čtete poprvé - ještě nemůže být zřejmý.
            Některé texty nebo kalkulačky používají jiné konvence/symboly pro rozlišení těchto dvou konceptů.
            </i></p>

            <p><i>
            Pozn. 3: V teorii pravděpodobnosti se rozptyl značí {"$D(X)$"} nebo {"$\\text{var}(X)$"}, a směrodatná odchylka {"$\\sigma(X)$"}
            a počítají se Lebesgueovým integrálem. Velmi zhruba řečeno to jsou 'funkce náhodné veličiny', nikoli 'funkce dat'.
            </i></p>

            <p><i>
            Pozn. 4: Můžeme psát např. i {"$s_n(x)$"} nebo {"$s_n(y)$"}, pokud chceme zdůraznit,
            z jakých dat směrodatnou odchylku počítáme - z dat {"$\\{x_i\\}$"} nebo {"$\\{y_i\\}$"}.
            </i></p>

            <p>
            Alternativní vztahy pro výpočet rozptylu a směrodatné odchylky:

            {
                `$$
                s_n^2 = \\frac{1}{n} \\sum_{i=1}^n x_i^2 - \\overline{x}^2
                $$`
            }

            {
                `$$
                s_n = \\sqrt{\\frac{1}{n} \\sum_{i=1}^n x_i^2 - \\overline{x}^2}
                $$`
            }

            Odvození:
            {
                `$$\\begin{align*}
                s_n^2 & = \\frac{1}{n} \\sum_{i=1}^n (x_i - \\overline{x})^2 = \\frac{1}{n} \\sum_{i=1}^n (x_i^2 - 2x_i\\overline{x} + \\overline{x}^2) = \\\\
                              & = \\frac{1}{n} \\sum_{i=1}^n x_i^2 - 2\\overline{x}\\sum_{i=1}^n x_i + n\\overline{x}^2 = \\frac{1}{n} \\sum_{i=1}^n x_i^2 - 2n\\overline{x}^2 + n\\overline{x}^2 = \\frac{1}{n} \\sum_{i=1}^n x_i^2 - \\overline{x}^2
                \\end{align*}$$`
            }
            </p>

            <h2 id="datavariance-behaviour">Chování</h2>

            <p>
            Rozptyl vychází vždy <strong>nezáporný</strong>. Pokud by nám vyšel záporně, někde jsme udělali chybu.
            </p>

            <p>
            Rozptyl je nulový tehdy a jen tehdy, když jsou všechny hodnoty {"$x_i$"} stejné. To v praxi nastává málokdy,
            proto na reálných datech v praxi očkáváme, že rozptyl vyjde nenulový kladný.
            </p>

            <h2 id="datavariance-units">Jednotky</h2>
            <p>
            Mají-li data {"$\\{x_i\\}$"} jednotku {"$j$"}, potom průměr má jednotku {"$j$"}, rozptyl má jednotku {"$j^2$"} a směrodatná odchylka má jednotku {"$j$"}.
            Např. pro naměřené rychlosti v {"$ms^{-1}$"} je jednotka průměru {"$ms^{-1}$"}, rozptylu {"$m^2s^{-2}$"} a směrodatné odchylky {"$ms^{-1}$"}.
            </p>

            <h2 id="datavariance-transformations">Transformace</h2>
            <p>
            Pro transformaci dat platí:
            {
                `$$
                s_n^2(ax+b) = a^2 s_n^2(x)
                $$`
            }
            Aditiviní konstanta ({"$b$"}) se ztrácí (nemá vliv), multiplikativní konstanta ({"$a$"}) vyskočí před rozptyl umocněná na druhou.
            {
                `$$
                s_n(ax+b) = |a|s_n(x)
                $$`
            }
            Aditiviní konstanta ({"$b$"}) se ztrácí (nemá vliv), multiplikativní konstanta ({"$a$"}) vyskočí před směrodatnou odchylku v absolutní hondotě.
            </p>

            <h2 id="datavariance-boxed-data">Roztříděný statistický soubor</h2>

            <p>
            Absolutní četnosti značíme {"$c_j$"}, relativní četnosti {"$f_j$"}, středy tříd (popř. hodnoty diskrétního znaku) značíme {"$x_j^*$"}.

            </p>
            <p>
            {
                `$$
                s_n^2 = \\frac{1}{n} \\sum_{j=1}^m c_j (x_j^* - \\overline{x})^2 = \\sum_{j=1}^m f_j (x_j^* - \\overline{x})^2
                $$`
            }
            Alternativně:
            {
                `$$
                s_n = \\frac{1}{n} \\sum_{j=1}^m c_j x_j^{*2} - \\overline{x}^2 = \\sum_{j=1}^m f_j x_j^{*2} - \\overline{x}^2
                $$`
            }
            </p>

            <h2 id="datavariance-context-of-usage">Kontext použití</h2>
            <p>
            Vzorce uvedené výše jsou použitelné v těchto případech:
            </p>

            <ul>
                <li>
                Když data reprezentují celou populaci (nikoli výběr z populace).
                <br/>Můžeme použít název <strong><span style={{color: 'orange'}}>populační</span> rozptyl</strong> a <strong><span style={{color: 'orange'}}>populační</span> směrodatná odchylka</strong>.
                </li>

                <li>
                Nebo když se pohybujeme striktně v popisné statistice - neděláme žádný odhad parametru populace na základě našich dat - které jsou nejspíš sice výběrem,
                ale nás pro danou chvíli nezajímá, jde nám prostě jen o popis těch dat jako takových.
                <br/>Můžeme použít název <strong><span style={{color: 'orange'}}>empirický</span> rozptyl</strong> a <strong><span style={{color: 'orange'}}>empirická</span> směrodatná odchylka</strong>.
                Termín 'empirický' zde znamená "založený na konkrétním vzorku dat".
                </li>
            </ul>

            <h2 id="datavariance-estimates">Odhady</h2>
            <p>
            Pokud bychom dělali <strong>odhad</strong> směrodatné odchylky populace z nějakého výběru (už se nepohybujeme v popisné statistice, ale v <strong>inferenci</strong>), použili bychom vzorec:
            {
                `$$
                s^2 = \\frac{1}{\\textcolor{red}{n-1}} \\sum_{i=1}^n (x_i - \\overline{x})^2
                $$`
            }
            {
                `$$
                s = \\sqrt{\\frac{1}{\\textcolor{red}{n-1}} \\sum_{i=1}^n (x_i - \\overline{x})^2}
                $$`
            }
            </p>

            <p>
            Používáme symboly {"$s^2$"} a {"$s$"} (bez dolního indexu), a používáme označení&nbsp;
            <strong><span style={{color: 'red'}}>výběrový</span> rozptyl</strong> a <strong><span style={{color: 'red'}}>výběrová</span> směrodatná odchylka</strong>.
            </p>

            <p>
            Ukážeme si nyní na příkladě, k čemu dochází při výpočtu rozptylu na výběru:
            </p>
            <p>
            Mějme populaci {"$\\{1, 2, 3\\}$"}. Průměr je 2, rozptyl je {"$$\\frac{1}{3} \\big[(1 - 2)^2 + (2-2)^2 + (3-1)^2\\big] = \\textcolor{green}{\\frac{2}{3}}.$$"}
            </p>
            <p>
            Udělejme všechny možné výběry s opakováním o velikosti {"$n=2$"}, a spočítejme na nich vždy rozptyl.
            </p>
            <table className='simple-table'>
                <thead>
                    <tr>
                        <th>Výběr</th>
                        <th>Průměr výběru</th>
                        <th>Výpočet rozptylu</th>
                        <th>Rozptyl</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{"$(1, 1)$"}</td>
                        <td>1</td>
                        <td></td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td>{"$(1, 2), (2, 1)$"}</td>
                        <td>1.5</td>
                        <td>{"$\\frac{1}{2} (0.5^2 + 0.5^2)$"}</td>
                        <td>0.25</td>
                    </tr>
                    <tr>
                        <td>{"$(1, 3), (3, 1)$"}</td>
                        <td>2</td>
                        <td>{"$\\frac{1}{2} (1^2 + 1^2)$"}</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>{"$(2, 2)$"}</td>
                        <td>2</td>
                        <td></td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td>{"$(2, 3), (3, 2)$"}</td>
                        <td>2.5</td>
                        <td>{"$\\frac{1}{2} (0.5^2 + 0.5^2)$"}</td>
                        <td>0.25</td>
                    </tr>
                    <tr>
                        <td>{"$(3, 3)$"}</td>
                        <td>3</td>
                        <td></td>
                        <td>0</td>
                    </tr>
                </tbody>
            </table>
            <p>
            Vidíme, že ve většině případů nám rozptyl vyšel menší než 2/3.
            Každý výběr má stejnou pravděpodobnost 1/9 - podívejme se, jaký nám v průměru vychází rozptyl:
            </p>
            {
                `$$
                \\frac{1}{9} (0 + 2 \\cdot 0.25 + 2 \\cdot 1 + 0 + 2 \\cdot 0.25 + 0) = \\frac{1}{9} (0 + 0.5 + 2 + 0 + 0.5 + 0) = \\frac{3}{9} = \\frac{1}{3}.
                $$`
            }
            <p>
            V průměru tedy výrazně podhodnocujeme skutečný rozptyl populace, který je 2/3.
            Ukažme si stejný výpočet za použítí vzorce pro odhad rozptylu s {"$\\textcolor{red}{n-1}$"} ve jmenovateli:
            </p>
            <table className='simple-table'>
                <thead>
                    <tr>
                        <th>Výběr</th>
                        <th>Průměr výběru</th>
                        <th>Výpočet rozptylu</th>
                        <th>Rozptyl</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{"$(1, 1)$"}</td>
                        <td>1</td>
                        <td></td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td>{"$(1, 2), (2, 1)$"}</td>
                        <td>1.5</td>
                        <td>{"$\\frac{1}{\\textcolor{red}{1}} (0.5^2 + 0.5^2)$"}</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>{"$(1, 3), (3, 1)$"}</td>
                        <td>2</td>
                        <td>{"$\\frac{1}{\\textcolor{red}{1}} (1^2 + 1^2)$"}</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>{"$(2, 2)$"}</td>
                        <td>2</td>
                        <td></td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td>{"$(2, 3), (3, 2)$"}</td>
                        <td>2.5</td>
                        <td>{"$\\frac{1}{\\textcolor{red}{1}} (0.5^2 + 0.5^2)$"}</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>{"$(3, 3)$"}</td>
                        <td>3</td>
                        <td></td>
                        <td>0</td>
                    </tr>
                </tbody>
            </table>
            {
                `$$
                \\frac{1}{9} (0 + 2 \\cdot 0.5 + 2 \\cdot 2 + 0 + 2 \\cdot 0.5 + 0) = \\frac{1}{9} (0 + 1 + 4 + 0 + 1 + 0) = \\frac{6}{9} = \\textcolor{green}{\\frac{2}{3}}.
                $$`
            }
            <p>
            Vidíme, že nyní nám v průměru rozptyl vyšel přesně takový, jaký je rozptyl populace.
            </p>

            <p>
            Proč při 'naivním' výpočtu nástává vychylení?
            Je to proto, že u každého výběru používáme jeho vlastní průměr, místo průměru celé populace.
            Ten samozřejmě apriori neznáme. Kdybychom jej znali, vyšlo by nám to přesně:
            </p>

            <table className='simple-table' id='variance-with-popmean'>
                <thead>
                    <tr>
                        <th>Výběr</th>
                        <th>Průměr výběru</th>
                        <th>Průměr populace</th>
                        <th>Výpočet rozptylu</th>
                        <th>Rozptyl</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{"$(1, 1)$"}</td>
                        <td>1</td>
                        <td>{"$\\textcolor{red}{2}$"}</td>
                        <td>{"$\\frac{1}{2} \\big[(1-\\textcolor{red}{2})^2 + (1-\\textcolor{red}{2})^2\\big]$"}</td>
                        <td>{"$1$"}</td>
                    </tr>
                    <tr>
                        <td>{"$(1, 2), (2, 1)$"}</td>
                        <td>1.5</td>
                        <td>{"$\\textcolor{red}{2}$"}</td>
                        <td>{"$\\frac{1}{2} \\big[(1-\\textcolor{red}{2})^2 + (2-\\textcolor{red}{2})^2\\big]$"}</td>
                        <td>{"$\\frac{1}{2}$"}</td>
                    </tr>
                    <tr>
                        <td>{"$(1, 3), (3, 1)$"}</td>
                        <td>2</td>
                        <td>{"$\\textcolor{red}{2}$"}</td>
                        <td>{"$\\frac{1}{2} \\big[(1-\\textcolor{red}{2})^2 + (3-\\textcolor{red}{2})^2\\big]$"}</td>
                        <td>{"$1$"}</td>
                    </tr>
                    <tr>
                        <td>{"$(2, 2)$"}</td>
                        <td>2</td>
                        <td>{"$\\textcolor{red}{2}$"}</td>
                        <td></td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td>{"$(2, 3), (3, 2)$"}</td>
                        <td>2.5</td>
                        <td>{"$\\textcolor{red}{2}$"}</td>
                        <td>{"$\\frac{1}{2} \\big[(2-\\textcolor{red}{2})^2 + (3-\\textcolor{red}{2})^2\\big]$"}</td>
                        <td>{"$\\frac{1}{2}$"}</td>
                    </tr>
                    <tr>
                        <td>{"$(3, 3)$"}</td>
                        <td>3</td>
                        <td>{"$\\textcolor{red}{2}$"}</td>
                        <td>{"$\\frac{1}{2} \\big((3-\\textcolor{red}{2})^2 + (3-\\textcolor{red}{2})^2\\big)$"}</td>
                        <td>{"$1$"}</td>
                    </tr>
                </tbody>
            </table>
            {
                `$$
                \\frac{1}{9} (1 + 2 \\cdot \\frac{1}{2} + 2 \\cdot  1 + 0 + 2 \\cdot \\frac{1}{2} + 1) = \\frac{1}{9} \\cdot 6 = \\textcolor{green}{\\frac{2}{3}}.
                $$`
            }
            <p>
            Tím, že u každého výběru používáme jeho vlastní průměr - nikoli průměr populace - systematicky vychylujeme součet čtverců dolů (protože průměr minimalizuje součet čtverců).
            </p>
            <p>
            V praxi průměr populace neznáme (nemůžeme ho tedy použít).
            Používáme průměr výběru - který způsobí vychýlení dolů - a proto zároveň zapojíme způsob kompenzace: {"$n-1$"} místo {"$n$"} ve jmenovateli - což vychýlení
            naprosto přesně vyruší. Že je kompenzace z principu přesná (a funguje pro každou velikost výběru {"$n \\geq 2$"}) lze matematicky odvodit,
            ale odvození je mírně techničtější.
            </p>
            <p>
            Odvození:  <i>připravujeme...</i>
            </p>


        </div>
        </div>
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

