import React from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';


export const MomentsMeta = {
    title: "Momenty",
    shortTitle: "Momenty",
    path: Path.moments,
    element: (sectionNumber:string) => <Moments sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

export function Moments({sectionNumber}: {sectionNumber: string}) {
    return (
        <MathJaxWrapper>
            <MathJax>
            <div className="chapter-container">
            <div className="centered-content">
            <div className="card">

            <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{MomentsMeta.title}</h1>

            <p>
            Centrální momenty: Od hodnot odečítáme průměr (centrujeme je).
            </p>

            <p>
            Obecné momenty: Hodnoty bereme tak, jak jsou (neodečítáme od nich průměr).
            </p>

            <table className='simple-table'>
                <thead>
                    <tr>
                        <th>kontext</th>
                        <th>k-tý obecný moment {"$\\mu'_{k}$"}</th>
                        <th>k-tý centrální moment {"$\\mu_{k}$"}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>surová data</td>
                        <td>{"$\\frac{1}{n} \\sum_i x_i^k$"}</td>
                        <td>{"$\\frac{1}{n} \\sum_i \\big(x_i - \\bar{x}\\big)^k$"}</td>
                    </tr>
                    <tr>
                        <td>roztříděný soubor</td>
                        <td>{"$\\ \\sum_i f_i x_i^k$"}</td>
                        <td>{"$\\sum_i f_i \\big(x_i - \\bar{x}\\big)^k$"}</td>
                    </tr>
                    <tr>
                        <td>pravděpodobnost</td>
                        <td>{"$\\mathrm{E} \\left[ X^k \\right]$"}</td>
                        <td>{"$\\mathrm{E} \\Big[ \\big( X - \\mathrm{E} \\left[ X \\right] \\big)^k \\Big] $"}</td>
                    </tr>
                </tbody>
            </table>

            <p>
            První obecný moment: {"$\\mu'_1 = \\mu = \\bar{x}$"} (průměr).
            </p>

            <p>
            Druhý centrální moment: {"$\\mu_2 = \\sigma^2 $"} (rozptyl); {"$\\sigma = \\sqrt{\\mu_2}$"} (směrodatná odchylka).
            </p>

            <p>
            Šikmost: {"$\\gamma_1 = \\frac{\\mu_3}{\\left(\\mu_2\\right)^{\\frac{3}{2}}} = \\frac{\\mu_3}{\\sigma^3} = \\mathrm{E} \\Big[ \\Big( \\frac{X - \\mu}{\\sigma}\\Big)^3 \\Big]$"}.&nbsp;
            <a href="https://en.wikipedia.org/wiki/Skewness" target="_blank" rel="noopener noreferrer" className="external-link">Skewness</a>
            </p>

            <p>
            Špičatost: {"$\\gamma_2 = \\frac{\\mu_4}{\\left(\\mu_2\\right)^{2}} = \\frac{\\mu_4}{\\sigma^4} = \\mathrm{E} \\Big[ \\Big( \\frac{X - \\mu}{\\sigma}\\Big)^4 \\Big]$"}.&nbsp;
            <a href="https://en.wikipedia.org/wiki/Kurtosis" target="_blank" rel="noopener noreferrer" className="external-link">Kurtosis</a>
            </p>

        </div>
        </div>
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

