import React, { useEffect, useRef, useState } from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import { TOC, TOCProps, defineTOCElements } from '~/TOC';


export const ConceptComparisonDMeta = {
    title: "Srovnání konceptů (D)",
    shortTitle: "Srovnání konceptů (D)",
    path: Path.concept_comparison_d,
    element: (sectionNumber:string) => <ConceptComparisonD sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}


const TOCSpec = [
    //"compd-comparison-variance",
    "compd-distribution-variance",
    "compd-random-variable-variance",
    "compd-dataset-variance",
    "compd-sample-variance",
    "compd-symmetrization",
    "compd-demonstration",
];

export function ConceptComparisonD({sectionNumber}: {sectionNumber: string}) {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);


    return (
        <MathJaxWrapper>
            <MathJax>
            <div className="chapter-container">
            <div className="centered-content">
            <div className="card" ref={chapterRef}>

            <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{ConceptComparisonDMeta.title}</h1>

            <p>Cílem této kapitoly je porovnat bok po boku koncepty rozptylu a vysvětlit, jak spolu souvisí, a čím se liší.</p>

            <table className='data-table'>
                <thead>
                    <tr>
                        <th>pojem</th>
                        <th>značení</th>
                        <th>popis</th>
                        <th>výpočet</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>rozptyl datového souboru<br/>(empirický)</td>
                        <td>{"$s_n^2$"}</td>
                        <td><span style={{color: "navy"}}>číslo</span></td>
                        <td>
                            neroztříděný: {"$\\frac{1}{n}\\sum_i (x_i - \\bar{x})^2$"}<br/><br/>
                            roztříděný: {"$\\sum_i f_i (x_i - \\bar{x})^2$"}, kde {"$f_i$"} jsou relativní četnosti
                        </td>
                    </tr>
                    <tr>
                        <td>rozptyl rozdělení</td>
                        <td>{"$\\sigma^2$"}</td>
                        <td><span style={{color: "navy"}}>číslo</span> - statická charakteristika rozdělení</td>
                        <td>{"$\\int (x - \\mu)^2 f(x) \\mathrm{d}x,$"}<br/>{"$\\sum_{i} (x_i - \\mu)^2 p(x_i)$"}</td>
                    </tr>
                    <tr>
                        <td>rozptyl hodnota náhodné veličiny</td>
                        <td>{"$D(X)$"}</td>
                        <td><span style={{color: "navy"}}>číslo</span> - statická charakteristika rozdělení náhodné veličiny</td>
                        <td>{"$\\int (x - E(X))^2 f(x) \\mathrm{d}x,$"}<br/>{"$\\sum_{i} (x_i - E(X))^2 p(x_i)$"}</td>
                    </tr>
                    <tr>
                        <td>odhad rozptylu rozdělení z rozptylu datového souboru, který považujeme za výběr<br/></td>
                        <td>{"$s^2$"}</td>
                        <td><span style={{color: "navy"}}>číslo</span></td>
                        <td>
                            neroztříděný: {"$\\frac{1}{n-1}\\sum_i (x_i - \\bar{x})^2$"}<br/><br/>
                            roztříděný: {"$\\frac{n}{n-1}\\sum_i f_i (x_i - \\bar{x})^2$"}, kde {"$f_i$"} jsou relativní četnosti
                        </td>
                    </tr>
                    <tr>
                        <td>výběrový rozptyl</td>
                        <td>{"$S^2$"}</td>
                        <td><span style={{color: "red"}}>náhodná veličina</span> - funkce, která každému možnému výsledku náhodného výběru přiřazuje
                        odhad {"$s^2$"} rozptylu rozdělení (populace), spočítaný na tomto výsledku</td>
                        <td>{"$\\frac{1}{n-1}\\sum_i (X_i - \\bar{X}_n)^2$"}</td>
                    </tr>
                    <tr>
                        <td>střední hodnota výběrového rozptylu</td>
                        <td>{"$E(S^2)$"}</td>
                        <td><span style={{color: "navy"}}>číslo</span> - střední hodnota výběrového rozptylu jakožto náhodné veličiny - je stejná jako rozptyl původního rozdělení</td>
                        <td>{"$=D(X)$"}</td>
                    </tr>
                </tbody>
            </table>

            <p>
            Pozn.: Všimněte sí, že {"$D(X)$"} se od {"$\\sigma^2$"} liší jen tím, že říkáme,
            že rozdělení, jehož rozptyl počítáme, je konrétně rozdělení náhodné veličiny {"$X$"}.
            Je to tedy jen "objasnění původu" daného rozdělení - výpočet je stejný
            (je to prostě rozdělení pravděpodobnosti jako každé jiné).
            </p>

            <h3 id="compd-distribution-variance">Rozptyl rozdělení</h3>
            <p>
            Máme rozdělení pravděpodobnosti dané buď hustotou {"$f(x)$"} (ve spojitém případě),
            nebo pravděpodobnostní funkcí {"$p(x)$"} (v diskrétním případě).
            Rozptyl hodnota rozdělení je definován jako
            {`$$
                \\sigma^2 = \\int (x - \\mu)^2 f(x) \\mathrm{d}x, \\qquad  \\sigma^2 = \\sum_{x} (x - \\mu)^2 p(x) = \\sum_{i} (x_i - \\mu)^2 p(x_i).
            $$`}
            Transformací vzorce lze najít alternativní formu:
            {`$$
                \\sigma^2 = \\int x^2 f(x) \\mathrm{d}x - \\mu^2, \\qquad  \\sigma^2 = \\sum_{x} x^2 p(x) - \\mu^2 = \\sum_{i} x_i^2 p(x_i) - \\mu^2.
            $$`}

            Je to číslo, které charakterizuje rozdělení jako celek - jak moc je koncentrované okolo střední hodnoty,
            nebo naopak "rozptýlené"/"rozplizlé".<br/>
            Pozn: V diskrétním případě je suma {"$\\sum_{x} x p(x)$"} vždy přes nejvýše spočetnou
            množinu hodnot {"$x$"} - to nám dovoluje si je očíslovat indexem {"$i$"} a
            zapsat v podobě {"$\\sum_{i} x_i p(x_i)$"}.
            </p>

            <p>
            U některých parametrizovaných rozdělení se rozptyl {"$\\sigma^2$"} (a/nebo směrodatná odchylka {"$\\sigma = \\sqrt{\\sigma^2}$"})
            přímo  vyskytuje v definici rozdělení jako parametr (např. normální rozdělení):
            {`$$
                f(x) = \\frac{1}{\\sqrt{2\\pi} \\sigma} e^{-\\frac{(x-\\mu)^2}{2\\sigma^2}}.
            $$`}
            U jiných rozdělení se rozptyl nevyskytuje jako parametr, což ale neznamená, že by rozdělení nemělo rozptyl.
            Například pro binomické rozdělení s parametry {"$n$"} a {"$p$"}:
            {`$$
                p(x) = \\binom{n}{x} p^x (1-p)^{n-x} \\quad \\text{pro} \\quad x = 0, 1, 2, \\ldots, n,
            $$`}
            je rozptyl {"$\\sigma^2 = np(1-p)$"}.
            </p>
            <p>
            Suma sumárum: Rozptyl je číslo. Každé* rozdělení má rozptyl.
            Někdy se rozptyl  {"$\\sigma^2$"} přímo vyskytuje v definici (rodiny) rozdělení jako parametr
            (normální rozdělení), někdy ne (binomické, exponenciální). To nic nemění na tom,
            že rozdělení má rozptyl. Pokud se symbol {"$\\sigma^2$"} (popř. {"$\\sigma$"}) vyskytuje jako parametr v definici rozdělení,
            pak lze předpokládat, že se jedná o rozptyl (popř. směrodatnou odchylku). Je ale dobré to ověřit výpočtem.
            </p>

            <h3 id="compd-random-variable-variance">Rozptyl náhodné veličiny</h3>
            <p>
            Máme-li náhodnou veličinu {"$X: \\Omega \\rightarrow \\mathbb{R}$"},
            potom tato náhodná veličina indukuje rozdělení pravděpobnosti (pravděpodobnostní míru) na {"$\\mathbb{R}$"}.
            Toto rozdělení je popsáno buď pravděpodobnostní funkcí {"$p(x)$"} (v diskrétním případě), nebo hustotou {"$f(x)$"} (ve spojitém případě).
            Rozptyl hodnota {"$D(X)$"}náhodné veličiny {"$X$"} je definován jako rozptyl hodnota tohoto rozdělení (tj. stejně jako v případě výše):
            {`$$
                D(X) = \\int (x - E(X))^2 f(x) \\mathrm{d}x, \\qquad  D(X) = \\sum_{x} (x - E(X))^2 p(x) = \\sum_{i} (x_i - E(X))^2 p(x_i).
            $$`}
            Transformací vzorce lze najít alternativní formu:
            {`$$
                D(X) = \\int x^2 f(x) \\mathrm{d}x - E(X)^2, \\qquad  D(X) = \\sum_{x} x^2 p(x) - E(X)^2 = \\sum_{i} x_i^2 p(x_i) - E(X)^2.
            $$`}
            </p>
            <p></p>


            <h3 id="compd-dataset-variance">Rozptyly datového souboru</h3>
            <p>Máme soubor čísel {"$(x_1, x_2, \\ldots, x_n)$"}, a máme zde dva vztahy:</p>
            {`$$\\begin{align*}
                s_n^2 &= \\frac{1}{n}\\sum_i (x_i - \\bar{x})^2, \\\\
                s^2 &= \\frac{1}{\\textcolor{red}{n-1}}\\sum_i (x_i - \\bar{x})^2.
            \\end{align*}$$`}
            <p>

            <p>Je mezi nimi <strong>zásadní rozdíl</strong>:</p>

            <p>
            {"$s_n^2$"} používáme tehdy, když považujeme datový soubor za celou populaci, a chceme znát jeho (její) rozptyl.
            Znalost celé populace vlastně znamená, že data nám dávají celé rozdělení. (Např. vytáhneme všechny lístky z tomboly
            a zjistíme, jaké čísla jsou na nich napsaná.)
            </p>
            <p>
            {"$s^2$"} používáme tehdy, když považujeme datový soubor za <span style={{color: "red"}}>výběr</span> z nějaké populace (z nějakého rozdělení),
            a číslo které vyjde ze vzorce je nestranným odhadem rozptylu této populace (tohoto rozdělení).
            </p>

            <p>
            {"$s_n^2$"} je číslo, které charakterizuje konkrétní datový soubor. Nepotřebujeme k němu žádný pojem pravděpodobnosti, je to popisná statistika.
            </p>

            <p>
            {"$s^2$"} je výsledek pochopení, jak funguje inference. Je to odhad rozptylu populace, kterou
            nevidíme - z které vidíme jen malý výběr (náš datový soubor).</p>
            </p>

            <p>
            Alternativní vztahy pro výpočet jsou:
            {`$$\\begin{align*}
                s_n^2 &= \\frac{1}{n}\\sum_i x_i^2 - \\bar{x}^2, \\\\
                s^2 &= \\frac{1}{n-1}\\sum_i x_i^2 - \\frac{n}{n-1}\\bar{x}^2 = \\frac{1}{n-1}\\big(\\sum_i x_i^2 - n\\bar{x}^2\\big).
            \\end{align*}$$`}
            </p>

            <p>
            Hodnoty se vzájemně převádějí takto:
            {`$$
                s^2 = \\frac{n}{n-1} s_n^2, \\qquad s_n^2 = \\frac{n-1}{n} s^2.
            $$`}
            </p>

            <p>
            <strong>Odhad {"$s^2$"} je vždy větší</strong> než empirická hodnota {"$s_n^2$"}.
            </p>

            <p><strong>Značení</strong> napříč literaturou, softwarem a kalkulačkami <strong>není konsistentní</strong>.
            Na místo {"$(s_n, s)$"} se někdy používá {"$(\\sigma, s)$"} nebo {"$(s, \\hat{s})$"}.
            Praktický způsob ověření (kromě konzultace manuálu) je použít obě funkce na <strong>datový soubor velikosti 1</strong>.
            Jedna z funkcí vrátí nulu (to je empirická {"$s_n$"}) a druhá dá error ('E' na kalkulačce, NaN v prostředí R, ...),
            protože <strong>dělí nulou</strong> (to je výběrová {"$s$"}).
            </p>

            <h3 id="compd-sample-variance">Výběrový rozptyl</h3>
            <p>Výběrový rozptyl není číslo, ale je to náhodná veličina. Je to funkce náhodného výběru.
            Pokud například máme náhodný výběr velikosti 2 z množiny čísel {"$\\{1, 2, 3, 4, 5, 6\\}$"}, pak
            výběrový rozptyl je náhodná veličina, která každému možnému výsledku (např. (1,5)) přiřazuje hodnotu {"$s$"},
            zde {"$s = \\frac{1}{1}[(1-3)^2 + (5-3)^2] = 8$"}.
            Výběrový rozptyl nemá konstatní hodnotu - má různou hodnotu pro každou možnou realizaci náhodného výběru ((1,1), (1,2), (1,3)...).
            Protože realizace náhodného výběru jsou ohodnoceny pravděpodobnostmi,
            výběrový rozptyl má také rozdělení pravděpodobnosti - některé rozptyly jsou pravděpodobnější než jiné.
            </p>
            <p>Výběrový rozptyl je definován jako
            {`$$
                S^2 = \\frac{1}{n-1}\\sum_i (X_i - \\bar{X}_n)^2,
            $$`}
            kde {"$X_i$"} je i-tá náhodná veličina výběru (tj. i-tý prvek výběru).
            </p>

            <p>
            Pokud provádíme opakovaná nezávislá měření nějakého pokusu, a zapíšeme
            si  postupně výsledky měření (1,12, 3.4, 5.6, 7.8, ...), pak jsme
            získali <strong>realizaci</strong> (jednu kokrétní /vektorovou/ hodnotu) náhodného výběru.
            Výpočet {"$s^2$"} na těchto datech nám dá konstantní číslo - je to <strong>realizace</strong> (konrétní hodnota)
            výběrového rozptylu na této realizaci výběru.
            </p>

            <p>Alternativní vztahy pro výpočet jsou:
            {`$$
                S^2 = \\frac{1}{n-1}\\sum_i X_i^2 - \\frac{n}{n-1}\\bar{X}_n^2 = \\frac{1}{n-1}\\big(\\sum_i X_i^2 - n\\bar{X}_n^2\\big).
            $$`}

            </p>

            <h2 id="compd-symmetrization">Poznámka - symetrizace porovnání</h2>

            <p>Nic nám nebrání definovat i náhodnou veličinu {"$S_n^2$"}:
            {`$$
                S_n^2 = \\frac{1}{n}\\sum_i (X_i - \\bar{X}_n)^2,
            $$`}
            jejíž střední hodnota je {"$E(S_n^2) = \\frac{n-1}{n}D(X)$"}. Jenom to asi není moc užitečné - je vidět,
            že veličina {"$S_n^2$"} je jako odhad rozptylu {"$D(X)$"} vychýlená - systematicky ho podhodnocuje.
            To je koneckonců důvod, proč se používá výběrový rozptyl {"$S^2$"} - ten je nestranný ("spot on").
            </p>
            <p>Tím se nám "symetrizuje" celý obraz:</p>
            <table className="simple-table simple-table-basic" >
                <tbody>
                <tr>
                    <td>Skutečný rozptyl výchozí náhodné veličiny/rozdělení:</td>
                    <td></td>
                    <td></td>
                    <td>{"$\\textcolor{green}{\\sigma^2} = \\textcolor{green}{D(X)}$"}</td>
                </tr>
                <tr>
                    <td>Výběrový rozptyl ('n-1'):</td>
                    <td>{"$\\textcolor{green}{S^2}$"}</td>
                    <td>{"$\\textcolor{green}{s^2}$"}</td>
                    <td>{"$\\textcolor{green}{E(S^2)} = D(X)$"}</td>
                </tr>
                <tr>
                    <td>"Nekorigovaný" rozptyl ('n'):</td>
                    <td>{"$S_n^2$"}</td>
                    <td>{"$\\textcolor{green}{s_n^2}$"}</td>
                    <td>{"$E(S_n^2) = \\frac{n-1}{n}D(X)$"}</td>
                </tr>
                </tbody>
            </table>

            <p>Symetrie ale nenastává v užitečnosti použití:</p>
            <ul>
                <li><p>
                Výběrový rozptyl ('n-1'): {"$S^2, s^2$"} a {"$E(S^2)$"} jsou užitečné pro odhad {"$D(X)$"}.
                </p></li>
                <li><p>
                Z "nekorigovaného" rozptylu ('n'): {"$s_n^2$"} je užitečné pro výpočet
                rozptylu diskrétního rozdělení (když ho máme celé, ne jen výběr); ostatní užitečné nejsou.
                </p></li>
            </ul>
            <p>
            Tabulka na úvod této kapitly obsahuje jen užitečné vztahy (označené zeleně),
            a proto na první pohled může působit 'nesymetricky'.
            </p>

        <h2 id="compd-demonstration">Konkrétní příklad</h2>

        <p>
        Máme náhodnou veličinu {"$X$"} s rozdělením pravděpodobnosti:<br/>
        {"$p(1) = 0.3, \\ p(2) = 0.2, \\ p(3) = 0.5, \\ p(x) = 0 \\text{ pro } x \\neq 1,2,3$"}, a děláme výběr velikosti {"$n = 2$"}.
        </p>

        <p>Máme
        {`$$\\begin{align*}
            E(X) & = \\sum_x x p(x) = 1 \\cdot 0.3 + 2 \\cdot 0.2 + 3 \\cdot 0.5 = 2.2, \\\\
            D(X) & = \\sum_x x^2 p(x) - E(X)^2 = 1^2 \\cdot 0.3 + 2^2 \\cdot 0.2 + 3^2 \\cdot 0.5 - 2.4^2 = 0.76.
        \\end{align*}$$`}
        Zde jsme ve výpočtu {"$D(X)$"} využili vztah pro {"$s_2^2$"} (tj. pro {"$s_n^2$"} pro {"$n=2$"}) - protože analyzujeme celé rozdělení, nikoli výběr.
        </p>
        <p>Teď se podíváme na chování výběru:</p>
        <table className="simple-table">
            <thead>
                <tr>
                    <th>Realizace výběru {"$(x_1, x_2)$"}</th>
                    <th>Pravděpodobnost realizace</th>
                    <th>{"$\\bar{x} = \\bar{X}_2(x_1, x_2)$"}</th>
                    <th>{"$s^2=S^2(x_1, x_2)$"}</th>
                    <th>{"$s_2^2=S_2^2(x_1, x_2)$"}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>(1,1)</td>
                    <td>0.09</td>
                    <td>1</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>(1,2)</td>
                    <td>0.06</td>
                    <td>1.5</td>
                    <td>0.5</td>
                    <td>0.25</td>
                </tr>
                <tr>
                    <td>(1,3)</td>
                    <td>0.15</td>
                    <td>2</td>
                    <td>2</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>(2,1)</td>
                    <td>0.06</td>
                    <td>1.5</td>
                    <td>0.5</td>
                    <td>0.25</td>
                </tr>
                <tr>
                    <td>(2,2)</td>
                    <td>0.04</td>
                    <td>2</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>(2,3)</td>
                    <td>0.10</td>
                    <td>2.5</td>
                    <td>0.5</td>
                    <td>0.25</td>
                </tr>
                <tr>
                    <td>(3,1)</td>
                    <td>0.15</td>
                    <td>2</td>
                    <td>2</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>(3,2)</td>
                    <td>0.10</td>
                    <td>2.5</td>
                    <td>0.5</td>
                    <td>0.25</td>
                </tr>
                <tr>
                    <td>(3,3)</td>
                    <td>0.25</td>
                    <td>3</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td>{"$E(\\bar{X}_2) = 2.2$"}</td>
                    <td>{"$E(S^2) = 0.76$"}</td>
                    <td>{"$E(S_2^2) = 0.38$"}</td>
                </tr>
            </tbody>
            </table>
            <p>
            Vidíme:
            {`$$\\begin{align*}
                E(\\bar{X}_2^2) &= 2.2 && \\text{ jako potvrzení faktu } \\quad E(\\bar{X}_n) = E(X), \\\\
                E(S^2) &= 0.76 && \\text{ jako potvrzení faktu } \\quad E(S^2) = D(X), \\\\
                E(S_2^2) &= 0.38 = \\frac{2-1}{2} 0.76 && \\text{ jako potvrzení faktu } \\quad E(S_n^2) = \\frac{n-1}{n}D(X).
            \\end{align*}$$`}
            </p>
            <p>
            Sloupce 3, 4 a 5 tabulky zároveň ilustrují, že výběrový průměr {"$\\bar{X}_n$"}, výběrový rozptyl {"$S^2$"} i
            nekorigovaný výběrový rozptyl {"$S_n^2$"} jsou náhodné veličiny - jejich hodnoty závisejí na realizaci výběru (na konkrétním řádku tabulky).
            </p>
            <p>
            Výběrový průměr {"$\\bar{X}_2$"} má rozdělení:
            {`$$
                p(1) = 0.09, \\ p(1.5) = 0.12, \\ p(2) = 0.34, \\ p(2.5) = 0.20, \\ p(3) = 0.25.
            $$`}
            Výběrový rozptyl {"$S^2$"} má rozdělení:
            {`$$
                p(0) = 0.38, \\ p(0.5) = 0.32, \\ p(2) = 0.30.
            $$`}
            Nekorigovaný výběrový rozptyl {"$S_2^2$"} má rozdělení:
            {`$$
                p(0) = 0.38, \\ p(0.25) = 0.32, \\ p(1) = 0.30.
            $$`}
            </p>
        </div>
        </div>
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

