import React, { useEffect, useRef, useState } from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import { TOC, TOCProps, defineTOCElements } from '~/TOC';


export const EventsIndependenceMeta = {
    title: "Nezávislost jevů a podmíněná pravděpodobnost",
    shortTitle: "Nezávislost jevů",
    path: Path.events_independence,
    element: (sectionNumber:string) => <EventsIndependence sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "indep-motivation",
    "indep-conditional-prob",
];


export function EventsIndependence({sectionNumber}: {sectionNumber: string}) {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);

    return (
        <MathJaxWrapper>
            <MathJax>
            <div className="chapter-container">
            <div className="centered-content">
            <div className="card" ref={chapterRef}>

            <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{EventsIndependenceMeta.title}</h1>
            <p><i>Připravujeme...</i></p>

            <p>

            </p>

            <h2 id="indep-motivation">Motivace</h2>

            <p>
            Když hážeme kostkou, pravděpodobnost že padne šestka je {"$P(6) = 1/6$"}.
            Dejme tomu, že kamarád hází kostkou za rohem, kde na něj nevidíme, pouze slyšíme, že
            řekl "padlo sudé číslo". Kdybychom si teď měli tipnout, jaké číslo padlo,
            tipovali bychom 2, 4 nebo 6. Pokud bychom konstantně tipovali 6 pokaždé, kdy on řekne "padlo sudé číslo",
            tak bychom se trefili ve třetině těchto případů. Intuitivně bychom chtěli říct,
            že "pokud už víme, že padlo sudé číslo, tak pravděpodobnost, že to byla šestka, je 1/3".
            Co je to ale za pravděpodobnost? Pravděpodobnost, že padne šestka, je přece 1/6.
            </p>

            <p>Máme dvě kostky, červenou a modrou. Když hodíme oběma současně, je pravděpodobnost,
            že na červené kostce padne šestka, 1/6. Pravděpodobnost, že na modré kostce padne šestka, je také 1/6.
            Dejme tomu, že teď těmito kostkami hodí kamarád za rohem (nevidíme na něj), a my jen slyšíme,
            že řekne "na červené kostce padla šestka". Když tohle víme, co bychom tipovali, že padlo na druhé kostce?
            Intuitivně, výsledek červené kostky by neměl nijak ovlivnit, co padlo na modré kostce. Čili
            např. pravděpodobnost, že padne šestka na modré kostce, by měla být pořád 1/6.

            Toto chování je jiné než v předchozím případě, kdy nastoupení jednoho jevu (padlo sudé číslo) umožnilo vyloučit
            všechny jevy obsahující 1, 3, 5 a umožnilo nám více tipovat na 2, 4 nebo 6.
            </p>

            <p>Pokud by kamarád zahlásil "padl součet 10", jaká by byla 'pravděpobnost, že na modré kostce padlo číslo 6?
            Možné výsledky vedoucí k součtu 10 jsou (4,6), (5,5), (6,4). 'Pravděpodobnost', že padla šestka na druhé kostce, je tedy 1/3.
            </p>

            <p>Vidíme, že některé dvojice jevů se chovají nezávisle (to, že nastal jeden z jevů, nám neříká nic o tom, jestli druhý nastal nebo ne),
            a jiné dvojice jevů nezávislé nejsou (informace, že nastal jeden z jevů, nám umožňuje překalibrovat naše očekávání ohledně
            druhého jevu).
            </p>

            <p>'Pravděpodobnost' jsme zatím psali v úvozovkách, protože matematicky ještě nevíme, o pravděpodobost čeho přesně se vlastně jedná.
            To se vyjasní níže.
            </p>


            <h2 id="indep-conditional-prob">Podmíněná pravděpodobnost</h2>

            <p>Podmíněná pravděpodobnost jevu {"$A$"} za podmínky, že nastal jev {"$B$"} je definována jako
            {`$$
                P(A|B) = \\frac{P(A \\cap B)}{P(B)}.
            $$`}
            Toto značení do značné míry zavádějící, proto si zasluhuje detailní vysvětlení.
            Na začátek několik pozorování:
            </p>

            <ul>
                <li>Pravděpodobnost {"$P$"} je funkce, jejíž argumenty jsou množiny (nazývané jevy). V tomto zápisu ale
                do závorek {"$P(\\cdot)$"} nestrkáme množinu, ale nějakou 'věc', která má označení {"$A|B$"}. Dávalo
                by vám to smysl např. u sinu, kdyby zápis byl {"$\\sin(1|2)$"}? Co by to mělo znamenat? Na jakém argumentu
                bychom sinus vyhodnocovali? A na jaké množině zde tedy vyhodnocujeme funkci {"$P$"}?
                </li>
                <li>{"$P(A|B)$"} je slovně "pravděpodobnost jevu A, za podmínky, že nastal jev B". Jaktože najednou
                pravděpodoobnost jevu A závisí na tom jestli nastal jev B? Vždyť pravděpodobnost {"$P$"} je
                'staticky' definovaná funkce, která jevu {"$A$"} přiřazuje nějaké číslo, bez ohledu na to, jestli nastal jev {"$B$"},
                ba dokonce bez ohledu na to jestli vůbec proběhl pokus, kde by nějaký jev nastal (!).
                </li>
            </ul>

            <p>
            Co to tedy znamená? Odpověď je následující: Funkci vyhodnocujeme v argumentu A, ale nejedná se o původní pravděpodobnostní funkci P,
            ale o novou funkci. Do detailu:
            </p>

            <p>
            Vybereme si fixně jev {"$B$"}. Potom definujeme novou funkci {"$P_B$"} na každém jevu {"$A$"} takto:
            {`$$
                P_B(A) = \\frac{P(A \\cap B)}{P(B)}.
            $$`}
            Původní pravděpodobnostní funkce byla {"$P$"}, tato nová funkce je {"$P_B$"}. Říká se jí podmíněná pravděpodobnost (za podmínky, že nastal jev B).
            Je to funkce, která splňuje všechna kritéria pro to, aby mohla být nazvána pravděpodobností (axiomy pravděpodobnosti).
            Od původní pravděpodobnostní funkce {"$P$"} se liší (jen) tím, že jevům přiřazuje jiná čísla.
            K definici {"$P_B$"} jsme použili dvě ingredience - původní funkci {"$P$"} a jev {"$B$"}.
            </p>

            <p>
            Používané značení {"$P(A|B)$"} lze ho vysvětlit takto:
            {`$$
                \\textcolor{blue}{P(} \\textcolor{red}{A} \\textcolor{blue}{|B)} = \\frac{P(A \\cap B)}{P(B)}.
            $$`}
            {"$\\textcolor{blue}{P( \\ \\ \\, |B)}$"} je funkční symbol - a má jediný 'slot' pro argument - před svislítkem, zde označený tečkou:
            {"$\\textcolor{blue}{P(} \\, \\cdot \\, \\textcolor{blue}{|B)}$"}.
            Na toto místo pak vpisujeme argument {"$\\textcolor{red}{A}$"}: {"$\\textcolor{blue}{P(} \\textcolor{red}{A} \\textcolor{blue}{|B)}$"}.
            Toto 'divné' značení není v matematice úplně výjimkou.
            Např. integrál má také 'roztrženou' symboliku  {"$\\textcolor{blue}{\\int} \\, \\cdot \\ \\textcolor{blue}{\\textrm{d}x}$"}, kam
            doprostřed vpisujeme funkci: {"$\\textcolor{blue}{\\int} \\textcolor{red}{f(x)} \\, \\textcolor{blue}{\\textrm{d}x}$"}.
            </p>

            <p>
            Promíněných pravděpodobností (jakožto funkcí {"$P_B$"}) je na první pohled tolik, kolik je jevů {"$B$"}. Prakticky
            ale nelze použít jevy {"$B$"}, které mají nulovou pravděpodobnost - v definici bychom dělili nulou. To znamená, že
            přinejmenším funkce {"$P_{\\emptyset}$"} není definovaná; a pokud i pro další jevy je {"$P(B) = 0$"}, pak pro ně {"$P_B$"} není definovaná.
            </p>

        </div>
        </div>
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

