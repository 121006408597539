import React from 'react';


export function LandingPage()  {

    return (
        <div className="chapter-container">
        <div className="centered-content">

            <h1>Statistické myšlení a práce s daty</h1>
            <em>Toto je podpůrný učební materiál ke kurzu C2170 Statistické metody v chemii na MU Brno.</em>

            <p>
                Tento materiál prezentuje koncepty popisné statistiky, pravděpodobnosti a statistické inference
                způsobem, který by měl být (jak doufáme) srozumitelný pro studenty, jejichž oborem není odborná matematika.
                Nepoužívá příliš systém definice-věta-důkaz, přesto se snaží být co nejvíce korektní.
                Koncepty jsou ilustrovány na příkladech.
            </p>
            <p>
                Cílem prezentace je umožnit Vám - studentům -
                vyznat se v pojmech, a vědět, co děláte a proč to děláte, když počítáte nějakou pravděpodobnost,
                testujete hypotézu nebo např. určujete intervalový odhad hodnoty z nějakého měření.
            Zároveň se tento materiál nevyhýbá používání veškerých matematických pojmů - to aby
            Vás připravil na pozorumění klasickým matematickým textům ze statistiky, pokud
            byste to v budoucnu potřebovali.
            </p>

            <p>
                Vystavěli jsme tento text a kurz s ohledem na naše osobní zkušenosti z pozice přírodovědce,
                zabývajícího se fyzikální chemií (Dominik Heger), a aplikovaného matematika (Jan Novotný).
                Hledání společné mezioborové řeči je pro nás výzvou a také možností vlastního růstu.
                Doufáme, že tento kurz Vám umožní porozumět řeči statistiky, nezávisle řešit základní problémy
                a vědět, jakou literaturu otevřít při řešení složitějších problémů.
            </p>

            {/* <p style={{color: "red"}}>
            <em>Tento materiál je ve stádiu přípravy. Zatím jej, prosíme, nečtěte :)</em>
            </p> */}

        </div>
        </div>
    );
}
