
import * as d3 from 'd3';

import { Stats } from 'janstatistics';
import { CMD } from '~/visual/cmd';
import { Plotter } from '~/visual/plot';


type Coordinate = { x: number; y: number };


export class PlotterR extends Plotter {

    plot(svg: d3.Selection<SVGSVGElement, unknown, null, undefined> , data: Coordinate[], statsX: Stats, statsY: Stats, sameScale: boolean) {
        super.plot(svg, data, statsX, statsY, sameScale);

        if (!this.isDataSufficient(data)) {
            this.showMessageWhenNoSufficientData(svg);
            return;
        }

        // 600 === width - TODO
        this.drawSDLineMinus(this._mainGraphG!, this._xScale!, this._yScale!, 600, statsX, statsY);
        this.regressionLine(this._mainGraphG!, data, statsX, statsY);

    }

    drawSDLineMinus(g: d3.Selection<SVGGElement, unknown, null, undefined>, xScale: d3.ScaleLinear<number, number>, yScale: d3.ScaleLinear<number, number>, width: number, statsX: Stats, statsY: Stats) {
        const cmd = new CMD(xScale, yScale);
        const t = 1.5
        const [xMean, xSD] = [statsX.average, statsX.std];
        const [yMean, ySD] = [statsY.average, statsY.std];
        cmd.line(g, xMean - t*xSD, yMean +  t*ySD, xMean + t*xSD, yMean - t*ySD, 'green', 2).attr('stroke-dasharray', '10,10');
    }

    regressionLine(svg: d3.Selection<SVGGElement, unknown, null, undefined>, data: Coordinate[], statsX: Stats, statsY: Stats) {
        const xScale = this._xScale;
        const yScale = this._yScale;
        const cmd = new CMD(xScale, yScale);
        const [xMean, xSD] = [statsX.average, statsX.std];
        const [yMean, ySD] = [statsY.average, statsY.std];

        const cov = calculateCovariance(data.map(d => d.x), data.map(d => d.y));

        const a = cov / (xSD**2);
        const b = yMean - a * xMean;
        console.log(a, b)

        cmd.line(svg, statsX.min, a * statsX.min + b, statsX.max, a * statsX.max + b, 'magenta', 2);
    }
}

function calculateCovariance(array1: number[], array2: number[]): number {
    // if (array1.length !== array2.length) {
    //     throw new Error("Arrays must be of the same length");
    // }

    let mean1 = array1.reduce((acc, val) => acc + val, 0) / array1.length;
    let mean2 = array2.reduce((acc, val) => acc + val, 0) / array2.length;

    let covariance = 0;
    for (let i = 0; i < array1.length; i++) {
        covariance += (array1[i] - mean1) * (array2[i] - mean2);
    }

    return covariance / array1.length;
}