import React, { useEffect, useRef, useState } from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import { TOC, TOCProps, defineTOCElements } from '~/TOC';

const kalhoty_tricka = require('../assets/images/kalhoty_tricka.png');


export const SetsAMeta = {
    title: "Množiny (1)",
    shortTitle: "Množiny (1)",
    path: Path.sets_a,
    element: (sectionNumber:string) => <SetsA sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}


const TOCSpec = [
    "sets-cartesian-product",
    "sets-powerset",
];


export function SetsA({sectionNumber}: {sectionNumber: string}) {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);

    return (
        <MathJaxWrapper>
        <MathJax>
        <div className="chapter-container">
        <div className="centered-content">
        <div className="card" ref={chapterRef}>

        <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{SetsAMeta.title}</h1>

        <h2 id="sets-cartesian-product">Kartézský součin</h2>

        <p>
        V šatníku máme troje různé kalhoty a čtyři různá trička.
        Ráno na odchodu z domu 'zašmátráme' rukou ve skříni a vytáhneme náhodně
        jedny z kalhot a jedno z triček, které si pak oblékneme a vyrazíme ven.
        Kolik různých variant našeho outfitu může v průběhu času pozorovat náš/naše
        obdivovatel/ka, který/á by nás každý den sledoval/a?
        </p>

        <p>
        Množina kalhot: {"$K = \\{k_1, k_2, k_3\\}$"}. Množina triček: {"$T = \\{t_1, t_2, t_3, t_4\\}$"}.
        </p>

        <p>
        Když jsme už oblečení, máme na sobě např. kalhoty {"$k_2$"} a tričko {"$t_3$"} - to můžeme
        zaznamenat/zapsat jako <strong>uspořádanou dvojici</strong> {"$(k_2, t_3)$"}. Kolik takových dvojic lze vytvořit?
        </p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={kalhoty_tricka} style={{width: 300}}/>
        </div>
        <p>
        Vidíme, že můžeme zkombinovat každé kalhoty s každým tričkem - na první pozici máme 3 možnosti, na druhé 4 možnosti.
        Dohromady to dává {"$3 \\cdot 4 = 12$"} možností - můžeme vytvořit 12 různých dvojic:

        {`$$
            (k_1, t_1), (k_1, t_2), (k_1, t_3), (k_1, t_4), (k_2, t_1), (k_2, t_2), \\ldots, (k_3, t_3), (k_3, t_4).
        $$`}
        </p>

        <p>
        Množině všech těchto dvojic říkáme <strong>kartézský součin</strong> množin {"$K$"} a {"$T$"} a značíme ho {"$K \\times T$"}:

        {`$$
            K \\times T = \\big\\{(k, t) \\ | \\  k \\in K \\land t \\in T\\big\\}
        $$`}

        Obecně, máme-li množiny {"$A$"} a {"$B$"}, jejich kartézský součin je množina všech uspořádaných dvojic,
        kde první prvek je z množiny A a druhý prvek je z množiny B:

        {`$$
            A \\times B = \\big\\{(a, b) \\ | \\ a \\in A \\land b \\in B\\big\\}
        $$`}

        Analogicky pro kartézký součin tří množin {"$A, B, C$"}:

        {`$$
            A \\times B \\times C = \\big\\{(a, b, c) \\ | \\  a \\in A \\land b \\in B \\land c \\in C\\big\\}
        $$`}

        Kdybychom např. do šatníku přidali dvě čepice (množina čepic {"$C = \\{c_1, c_2\\}$"}), pak bychom naráz měli {"$3 \\cdot 4 \\cdot 2 = 24$"} variant, jak se obléknout.
        (A obrázek výše by získal další dimenzi - osu z  - dvě 'patra' - každé pro jednu čepici.)
        </p>

        <p>
        Obecně pro kartézský součin {"$n$"} množin {"$A_1, A_2, \\ldots, A_n$"}:

        {`$$
            A_1 \\times A_2 \\times \\ldots \\times A_n = \\big\\{(a_1, a_2, \\ldots, a_n) \\ | \\ a_1 \\in A_1 \\land a_2 \\in A_2 \\land \\ldots \\land a_n \\in A_n\\big\\}
        $$`}
        </p>

        <p>
        Pozn.: Kartézský součin {"$\\mathbb{R} \\times \\mathbb{R}$"} je množina všech uspořádaných dvojic reálných čísel,
        tedy bodů v rovině (2D), a běžně se značí {"$\\mathbb{R}^2$"}.
        Reálný 3D prostor {"$\\mathbb{R}^3$"} je kartézský součin {"$\\mathbb{R} \\times \\mathbb{R} \\times \\mathbb{R}$"}.
        Uspořádaná dvojice je totéž co vektor dimenze dva, uspořádaná trojice je vektor dimenze tři.
        </p>

        <h2 id="sets-powerset">Potenční množina</h2>

        <p>
        <strong>Množina všech podmnožin</strong> množiny {"$A$"} se nazývá <strong>potenční množina</strong> množiny {"$A$"} a značí se {"$\\mathcal{P}(A)$"}:

        {`$$
            \\mathcal{P}(A) = \\{X | X \\subseteq A\\}
        $$`}
        </p>

        <p>
        Jeden prvek: {"$A = \\{ a \\}$"}.<br/>
        Potenční množina: {"$\\mathcal{P}(A) = \\{ \\emptyset, \\{a\\} \\}$"}.<br/>
        </p>

        <p>
        Dva prvky: {"$A = \\{ a, b \\}$"}.<br/>
        Potenční množina: {"$\\mathcal{P}(A) = \\{ \\emptyset, \\{a\\}, \\{b\\}, \\{a, b\\} \\}$"}.
        </p>

        <p>
        Tři prvky: {"$A = \\{ a, b, c \\}$"}.<br/>
        Potenční množina: {"$\\mathcal{P}(A) = \\{ \\emptyset, \\{a\\}, \\{b\\}, \\{c\\}, \\{a, b\\}, \\{a, c\\}, \\{b, c\\}, \\{a, b, c\\} \\}$"}.
        </p>

        <p>
        Uvažujme modifikaci předchozích úvah s oblečením.
        Mějme teď jediné kalhoty (k), jediné tričko (t) a jedinou čepici (c). Při odchodu z domu se náhodně rozhodneme, jestli si oblékneme kalhoty anebo ne,
        jestli si oblékneme tričko anebo ne, a jestli si nasadíme čepici anebo ne.
        Kolik různých možností outfitu můžeme vytvořit? Když si daný kus oblečení oblékneme, označíme to symbolem 1 (true), když ne, označíme to symbolem 0 (false).
        </p>

        <table className='data-table data-table-powerset'>
            <thead>
                <tr>
                    <th>k</th>
                    <th>t</th>
                    <th>c</th>
                    <th>outfit</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>jsme nazí</td>
                </tr>
                <tr>
                    <td>0</td>
                    <td>0</td>
                    <td>1</td>
                    <td>jdeme jen v čepici</td>
                </tr>
                <tr>
                    <td>0</td>
                    <td>1</td>
                    <td>0</td>
                    <td>jdeme jen v tričku</td>
                </tr>
                <tr>
                    <td>0</td>
                    <td>1</td>
                    <td>1</td>
                    <td>jdeme v čepici a tričku</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>0</td>
                    <td>0</td>
                    <td>jdeme jen v kalhotech</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>0</td>
                    <td>1</td>
                    <td>jdeme v kalhotech a čepici</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>1</td>
                    <td>0</td>
                    <td>jdeme v kalhotech a tričku</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>jdeme v kalhotech, tričku a čepici</td>
                </tr>
            </tbody>
        </table>

        <p>
        Je vidět, že můžeme identifikovat 8 různých variant. Na každé pozici (k, t, c) máme 2 možnosti (1/vzít nebo 0/nevzít),
        dohromady je to {"$2 \\times 2 \\times 2 = 8$"} možností.
        Je to, jako bychom v předchozím případě měli dvoje kalhoty (reálné a neviditené), dvě trička (reálné a neviditelné)
        a dvě čepice (reálnou a neviditelnou) a udělali kartézský součin.
        </p>

        <p>
        Podívejme se nyní na to, jakou <i>množinu</i> oblečení máme v každém z případů na sobě:
        </p>
        <table className='data-table data-table-powerset'>
            <thead>
                <tr>
                    <th>k</th>
                    <th>t</th>
                    <th>c</th>
                    <th>outfit</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>{"$\\emptyset$"}</td>
                </tr>
                <tr>
                    <td>0</td>
                    <td>0</td>
                    <td>1</td>
                    <td>{"$\\{c\\}$"}</td>
                </tr>
                <tr>
                    <td>0</td>
                    <td>1</td>
                    <td>0</td>
                    <td>{"$\\{t\\}$"}</td>
                </tr>
                <tr>
                    <td>0</td>
                    <td>1</td>
                    <td>1</td>
                    <td>{"$\\{t, c\\}$"}</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>0</td>
                    <td>0</td>
                    <td>{"$\\{k\\}$"}</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>0</td>
                    <td>1</td>
                    <td>{"$\\{k, c\\}$"}</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>1</td>
                    <td>0</td>
                    <td>{"$\\{k, t\\}$"}</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>{"$\\{k, t, c\\}$"}</td>
                </tr>
            </tbody>
        </table>
        <p>
        Tabulkou jsme vyčerpali všechny možné podmnožiny oblečení. Když totiž konstruujeme libovolnou podmnožinu,
        můžeme to udělat tak, že projdeme po řadě všechny potenciální prvky, a u každého se rozhodneme,
        jestli ho do ní dáme (1) a nebo ne (0). Všechny varianty rozhodnutí nám pak dají všechny možné podmnožiny.
        </p>

        <p>
        Pro množinu s {"$n$"} prvky, má potenční množina {"$2^n$"} prvků: u každého z {"$n$"} prvků máme 2 možnosti
        - buď v podmnožině je, anebo ne - to je {"$2 \\cdot 2 \\cdot \\ldots \\cdot 2 = 2^n$"} variant:

        {`$$
            |A| = n \\quad \\Rightarrow \\quad |\\mathcal{P}(A)| = 2^n
        $$`}
        </p>

        <p>
        Dvěma 'krajními' případy jsou prázdná množina {"$\\emptyset$"} (žádný prvek v ní není) a celá množina {"$A$"} samotná (všechny prvky v ní jsou).
        </p>

        </div>
        </div>
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

