import React, { useEffect, useRef, useState } from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import { TOC, TOCProps, defineTOCElements } from '~/TOC';

const nv_3models_intro = require('../assets/images/nv_3models_intro.png');
const nv_3models_x_col = require('../assets/images/nv_3models_x_col.png');
const nv_3models_y_row = require('../assets/images/nv_3models_y_row.png');
const nv_3models_pxpy = require('../assets/images/nv_3models_pxpy.png');
const nv_3models_indep = require('../assets/images/nv_3models_indep.png');
const nv_Fxy_construction = require('../assets/images/nv_Fxy_construction.png');

const nv_Fxy_complete = require('../assets/images/nv_Fxy_complete.png');
const nv_Fxy_complete_marks = require('../assets/images/nv_Fxy_complete_marks.png');


export const RandomVectorsMeta = {
    title: "Náhodný vektor",
    shortTitle: "Náhodný vektor",
    path: Path.random_vectors,
    element: (sectionNumber:string) => <RandomVectors sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "rvec-intro",
    "rvec-need-for-joint-info",
    "rvec-independence",
    "rvec-distribution-function",
    "rvec-note-probfunc",
    "rvec-expectation",
    "rvec-covariance",
];


export function RandomVectors({sectionNumber}: {sectionNumber: string}) {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);

    return (
        <MathJaxWrapper>
            <MathJax>
            <div className="chapter-container">
            <div className="centered-content">
            <div className="card" ref={chapterRef}>

            <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{RandomVectorsMeta.title}</h1>

            <p><i>Připravujeme...</i></p>

            <h2 id="rvec-intro">Úvod</h2>

            <p>
            <i>Popíšeme zde zpočátku hlavně dvourozměrný náhodný vektor; vícerozměrný náhodný vektor se řeší analogicky a vztahy jsou uvedené dole.</i>
            </p>

            <p>
            Náhodná veličina je funce {"$X: \\Omega \\rightarrow \\mathbb{R}$"}, která každému elementárnímu jevu
            {"$\\omega \\in \\Omega$"} přiřazuje reálné číslo {"$X(\\omega)$"}. Pokud je {"$\\omega$"} nějaký prvek populace,
            z níž jsme jej náhodně vyberali, pak {"$X(\\omega)$"} je nějaký atribut tohoto prvku.
            Např. kontrolujeme-li nějaké nějaké tekuté vzorky, může být {"$X(\\omega)$"} hustota vzorku {"$\\omega$"}.
            </p>

            <p>
            Náhodný vektor získáme tehdy, když neměříme pouze jeden atribut, ale dva nebo více atributů současně.
            Například u zkoumaných vzorků měříme nejen hustotu, ale i např. stočení roviny polarizace světla.
            Náhodný vektor o dvou složkách je zobrazení, které náhodnému jevu {"$\\omega$"} přiřazuje dvojici reálných čísel {"$(X(\\omega), Y(\\omega))$"}.
            První složka {"$\\omega \\rightarrow X(\\omega)$"} je náhodná veličina, druhá složka {"$\\omega \\rightarrow Y(\\omega)$"} je také náhodná veličina.
            Na náhodný vektor (o dvou složkách) se tedy můžeme dívat jako na dvojici náhodných veličin.
            Důležité je, že obě náhodné veličiny <strong>jsou definované na společném</strong> {"$\\Omega$"}.
            </p>

            <p>
            Složky náhodného vektoru mohou být vzájemně závislé (korelované), nebo nezávislé.
            To záleží na tom, jaký pokus modelujeme - jaký pravděpodobnostní prostor a jaké veličiny zrovna zkoumáme.
            </p>

            <p>
            Př 1.: Ve skupině 10 lidí - {"$\\Omega = \\{1, \\ldots, 10\\}$"} - měříme výšku {"$X$"} v cm a váhu {"$Y$"} v kg. Dejme tomu, že situace ve skupině vypadá takto:
            </p>

            <table className="simple-table">
                <thead>
                    <tr>
                        <th>{"$\\omega$"}</th>
                        <th>1</th>
                        <th>2</th>
                        <th>3</th>
                        <th>4</th>
                        <th>5</th>
                        <th>6</th>
                        <th>7</th>
                        <th>8</th>
                        <th>9</th>
                        <th>10</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{"$X(\\omega)$"}</td>
                        <td>165</td>
                        <td>168</td>
                        <td>172</td>
                        <td>175</td>
                        <td>175</td>
                        <td>176</td>
                        <td>177</td>
                        <td>180</td>
                        <td>182</td>
                        <td>185</td>
                    </tr>
                    <tr>
                        <td>{"$Y(\\omega)$"}</td>
                        <td>60</td>
                        <td>72</td>
                        <td>80</td>
                        <td>72</td>
                        <td>75</td>
                        <td>85</td>
                        <td>80</td>
                        <td>85</td>
                        <td>84</td>
                        <td>90</td>
                    </tr>
                </tbody>
            </table>

            <p>
            Je intuitivně zřejmé, že složky náhodného vektoru (veličiny {"$X$"} a {"$Y$"}) nebudou nezávislé - vyšší osoby budou mít tendenci mít i vyšší hmotnost.
            Samozřejmě budou exitovat výjimky a extrémy, ale korelace bude kladná.
            </p>

            <p>
            Př 2.: Hážeme dvěma kostkami současně. {"$\\Omega = \\{(1, 1), (1, 2) \\ldots, (6, 6)\\}$"}. Označme {"$\\omega = (\\omega_1, \\omega_2)$"}.
            Náhodná veličina {"$X$"} představuje hodnotu na první kostce: {"$X(\\omega) = \\omega_1$"}, a náhodná veličina {"$Y$"} představuje hodnotu
            na druhé kostce: {"$Y(\\omega) = \\omega_2$"}. Náhodný vektor je potom vlastně identita: {"$(X(\\omega), Y(\\omega)) = (\\omega_1, \\omega_2) = \\omega$"}.
            Náhodné veličiny {"$X$"} a {"$Y$"} jsou zde intuitivně nezávislé. <br/> <br/>

            Ve stejném pokusu bychom mohli zkoumat např.
            součet {"$S(\\omega) = \\omega_1 + \\omega_2$"} a rozdíl {"$R(\\omega) = \\omega_1 - \\omega_2$"} hodnot na kostkách.
            Složky náhodného vektoru {"$(S, R)$"} nejsou nezávislé. To vidíme jednoduše z toho, že je li např. {"$S = 2$"}, pak s pravděpodobností 1 je {"$R = 0$"}.
            </p>

            <h2 id="rvec-need-for-joint-info">Potřeba 'sdružené' informace</h2>

            <p>
            Dejme tomu, že k nám někdo přijde a řekne, že dělá pokus, z kterého vzejde dvojice hodnot (x, y): První hodnota může být 1, 2 nebo 3, druhá hodnota může být také 1, 2 nebo 3.
            Rozdělení pravděpodbnosti první hodnoty je rovnoměrné - každá hodnota má pravděpodobnost {"$\\frac{1}{3}$"}. Totéž platí pro druhou hodnotu.
            Je tato informace dostatečná pro kompletní popchopení pravděpodobností v tomto pokusu?
            </p>

            <p>
            První hodnota představuje náhodnou veličinu {"$X$"} s pravděpodobnostní funkcí {"$p_x(x)$"}:
            </p>

            <table className="simple-table">
                <thead>
                    <tr>
                        <th>{"$x$"} (hodnota {"$X$"})</th>
                        <th>1</th>
                        <th>2</th>
                        <th>3</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{"$p_x(x)$"}</td>
                        <td>{"$\\frac{1}{3}$"}</td>
                        <td>{"$\\frac{1}{3}$"}</td>
                        <td>{"$\\frac{1}{3}$"}</td>
                    </tr>
                </tbody>
            </table>

            <p>
            Druhá hodnota představuje náhodnou veličinu {"$Y$"} s pravděpodobnostní funkcí {"$p_y(y)$"}:
            </p>

            <table className="simple-table">
                <thead>
                    <tr>
                        <th>{"$y$"} (hodnota {"$Y$"})</th>
                        <th>1</th>
                        <th>2</th>
                        <th>3</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{"$p_y(y)$"}</td>
                        <td>{"$\\frac{1}{3}$"}</td>
                        <td>{"$\\frac{1}{3}$"}</td>
                        <td>{"$\\frac{1}{3}$"}</td>
                    </tr>
                </tbody>
            </table>

            <p>
            Ukážeme si tři pokusy (modely), které se zásadně liší, ale přitom všechny odpovídají tomuto popisu:
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={nv_3models_intro} style={{width: 600}}/>
            </div>

            <p>
            V případě A nastává každá dvojice hodnot {"$(x, y)$"} se stejnou pravděpodobností {"$\\frac{1}{9}$"}.
            V případě B je možných 6 různých výsledků - výsledky {"$(1,1), (2,2), (3,3)$"} nejsou možné - a prakticky
            s nimi nakládáme tak, že jejich pravděpodobnost definujeme jako 0.
            </p>

            <p>
            Jaká je v těchto případech pravděpodobnost jevu, že {"$X = 1$"}? Je to součet pravděpodobností všech dvojic, kde {"$x = 1$"},
            tj. součet pravděpodobností v prvním sloupci (vyznačený žlutě):
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={nv_3models_x_col} style={{width: 600}}/>
            </div>

            <p>
            Jaká je v těchto případech pravděpodobnost jevu, že {"$Y = 1$"}? Je to součet pravděpodobností všech dvojic, kde {"$y = 1$"},
            tj. součet pravděpodobností v prvním řádku (vyznačený modře):
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={nv_3models_y_row} style={{width: 600}}/>
            </div>

            <p>
            Když stejným způsobem určíme součty všech sloupců, získáme pravděpodnostní funkci {"$p_x(x)$"},
            a když určíme součty všech řádků, získáme pravděpodobnostní funkci {"$p_y(y)$"}.
            Vidíme, že ve všech případech jsou pravděpodobnostní funkce stejné, a odpovídají původnímu popisu.
            Přesto se v každém případě jedná o jiný pokus.
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={nv_3models_pxpy} style={{width: 600}}/>
            </div>

            <p>
            Model A lze interpretovat jako výběr ze tří prvků s vracením. Máme v klobouku tři lístky s čísly 1, 2, 3.
            Vytáhneme náhodně jeden lístek, zaznamenáme číslo, a lístek vrátíme zpět. Pak výběr opakujeme a zaznamenáme druhé číslo.
            Veličiny {"$X$"} (první vytažené číslo) a {"$Y$"} (druhé vytažené číslo) jsou nezávislé.
            </p>

            <p>
            Model B lze interpretovat jako výběr ze tří prvků bez vracení. Máme v klobouku tři lístky s čísly 1, 2, 3.
            Vytáhneme náhodně jeden lístek, zaznamenáme číslo, a lístek nevracíme. Ze zbylých dvou lístků v klobouku
            vytáhneme náhodně druhý lístek, a zaznamenáme druhé číslo.
            Veličiny {"$X$"} a {"$Y$"} nejsou nezávislé.
            </p>

            <p>
            Model C je (nepříliš užitečný) případ, kdy náhodně vybereme číslo z množiny {"$\\{1, 2, 3\\}$"} a zaznamenáme ho dvakrát, jako dvojici {"$(x, x)$"}.
            V tomto případě jsou veličiny stoprocentně korelované.
            </p>

            <p>
            Čísla v tabulce (matici) jsou hodnoty {"$p(x, y) = P(X = x \\land Y = y)$"}, a jsou to hodnoty <strong>sdružené pravděpodobnostní funkce</strong>.
            Tato pravděpodobnostní funkce nám dává (u diskrétního náhodného vektoru) kompletní informaci.
            Pravděpodobnostní funkce {"$p_x(x)$"} se nazývá <strong>marginální pravděpodobnostní funkce</strong> náhodné veličiny {"$X$"},
            a pravděpodobnostní funkce {"$p_y(y)$"} je <strong>marginální pravděpodobnostní funkce</strong> náhodné veličiny {"$Y$"}.
            Slovo 'marginální' v doslovném překladu znamená 'okrajový', a odkazuje na skutečnost, že řádkové a sloupcové součty píšeme na okraje tabulky.
            </p>

            <p>
            U spojitého náhodného vektoru máme místo pravděpodobnostní funkce {"$p(x, y)$"} hustotu pravděpodobnosti {"$f(x, y)$"}.
            </p>

            <p>
            Vzorce pro výpočet marginálních pravděpodobnostních funkcí a marginálních hustot ze sdružených:
            {`$$
            \\begin{align*}
                p_x(x) &= \\sum_y p(x, y) \\qquad & f_x(x) &= \\int_{-\\infty}^{\\infty} f(x, y) \\textrm{d}y \\\\
                p_y(y) &= \\sum_x p(x, y) \\qquad & f_y(y) &= \\int_{-\\infty}^{\\infty} f(x, y) \\textrm{d}x \\\\
            \\end{align*}
            $$`}
            </p>

            <h2 id="rvec-independence">Nezávislost</h2>

            <p>
            Opakování: Dva jevy jsou nezávislé právě tehdy když platí {"$P(A \\cap B) = P(A)P(B)$"}.
            </p>

            <p>
            Dvě náhodné veličiny {"$X$"} a {"$Y$"} jsou nezávislé, pokud platí, že každý jev A, popsaný jen pomocí (hodnot) náhodné veličiny {"$X$"},
            a každý jev B, popsaný jen pomocí (hodnot) náhodné veličiny {"$Y$"}, jsou nezávislé.
            </p>

            <p>
            Jev "popsaný jen pomocí (hodnot) náhodné veličiny {"$X$"}" je např. jev {"$\\{X = 1\\}$"}, {"$\\{X \\leq 1\\}$"}, {"$\\{1 \\leq X \\leq 3\\}$"} atp.
            V grafu/tabulce se vždy jedná o svislý pás/sloupec nebo skupinu svislých pásů/sloupců.
            </p>

            <p>
            Jev "popsaný jen pomocí (hodnot) náhodné veličiny {"$Y$"}" je např. jev {"$\\{Y = 1\\}$"}, {"$\\{Y \\leq 1\\}$"}, {"$\\{1 \\leq Y \\leq 3\\}$"} atp.
            V grafu/tabulce se vždy jedná o vodorovný pás/řádek nebo skupinu vodorovných pásů/řádků.
            </p>

            <p>
            Bavíme-li se o průniku těchto jevů, jedná se o průnik horizontálních a vertikálních pásů, tedy o jeden nebo více bodů či obdélníků.
            </p>

            <p>
            V našem příkladě s tabulkou to znamená, že aby veličiny byly nezávislé, tak na průniku každého řádku a každého sloupce
            musí být pravděpodobnostní funkce {"$p(x, y)$"} rovna součinu marginálních pravděpodobnostních funkcí {"$p_x(x) p_y(y)$"}.
            Viz obrázek níže:
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={nv_3models_indep} style={{width: 600}}/>
            </div>

            <p>
            Pro náhodné veličiny {"$X, Y$"} platí, že jsou nezávislé právě tehdy když sdružená pravděpodobnostní funkce (sdružená hustota)
            je součinem marginálních pravděpodobnostních funkcí (marginálních hustot):
            {`$$
            \\begin{align*}
                p(x, y) &= p_x(x) p_y(y) \\qquad & f(x, y) &= f_x(x) f_y(y)
            \\end{align*}
            $$`}
            </p>

            <h2 id="rvec-distribution-function">Distribuční funkce</h2>

            <p>
            Mějme dvojici náhodných veličin {"$X, Y$"} definovaných na společném pravděpodobnostním prostoru.
            </p>

            <p>
            Sdružená distribuční funkce je funkce
            {`$$
            \\begin{align*}
                F(x,y) = P(X \\leq x, Y \\leq y) &=  P\\Big((X, Y) \\in (-\\infty, x) \\times (-\\infty, y)\\Big)  \\\\
                                                 &= P\\Big(\\{\\omega \\in \\Omega \\, | \\, X(\\omega) \\leq x \\land Y(\\omega) \\leq y\\}\\Big) \\\\
                                                 &= P'\\Big((-\\infty, x) \\times (-\\infty, y)\\Big)
            \\end{align*}
            $$`}
            kde {"$P'$"} je přenesená pravděpodobnostní míra z prostoru {"$\\Omega$"} na prostor {"$\\mathbb{R} \\times \\mathbb{R}$"}.
            Funkce má 'obvyklé' vlastnosti, které od distribuční očekáváme, jen jsou zobecněné do dvou dimenzí:
            </p>

            <ul>
                <li>
                    {"$0 \\leq F(x,y) \\leq 1$"} pro všechna {"$x, y$"}. <br/>&nbsp;
                </li>
                <li>
                    Je to neklesající funkce v každé proměnné {"$x$"} a {"$y$"}. Tj. <br/>
                    funkce {"$F(x, c)$"} je neklesající funkcí proměnné  {"$x$"} pro každou konstantu {"$c$"}, a <br/>
                    funkce {"$F(c, y)$"} je neklesající funkcí proměnné  {"$y$"} pro každou konstantu {"$c$"}. <br/>&nbsp;
                </li>
                <li>
                    {"$\\lim_{x\\rightarrow -\\infty}F(x, c) = 0$"} pro všechna {"$c$"}.  <br/>
                    {"$\\lim_{y\\rightarrow -\\infty}F(c, y) = 0$"} pro všechna {"$c$"}. <br/>&nbsp;
                </li>
                <li>
                    {"$\\lim_{(x,y)\\rightarrow (\\infty,\\infty)}F(x, y) = 1$"}.
                </li>
            </ul>

            <p>Distribuční funkci konstruujeme graficky tak, že posouváme interval {"$(-\\infty, x) \\times (-\\infty, y)$"} - označený na obrázku červeně -
            postupně více doprava a nahoru, a zaznamenáváme nakumulovanou pravděpodobnost - tj. pravděpodobnost, která padne do toho intervalu.
            Obrázek níže ukazuje konstrukci distribuční funkce {"$F(x,y)$"} pro model B z příkladu výše.
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={nv_Fxy_construction} style={{width: 500}}/>
            </div>

            <p>
            <strong>Marginální</strong> distribuční funkce jsou distribuční funkce náhodných veličin {"$X$"} a {"$Y$"} samostatně.
            Získají se kumulováním marginálních pravděpodobnostních funkcí / hustot:
            {`$$
            \\begin{align*}
                F_x(x) &= \\sum_{t\\leq x} p_x(t) = \\sum_{t\\leq x}\\sum_y p(t, y) \\qquad & F_x(x) &= \\int_{-\\infty}^{t} f_x(t) \\textrm{d}x = \\int_{-\\infty}^{t}\\int_{-\\infty}^{\\infty} f(t, y) \\textrm{d}x\\textrm{d}y \\\\
                F_y(y) &= \\sum_{t\\leq y} p_y(t) = \\sum_x \\sum_{t\\leq y} p(x, t)\\qquad & F_y(y) &= \\int_{-\\infty}^{t} f_y(t) \\textrm{d}y = \\int_{-\\infty}^{\\infty}\\int_{-\\infty}^{t} f(x, t) \\textrm{d}x\\textrm{d}y \\\\
            \\end{align*}
            $$`}
            Obrázek níže ukazuje kompletní příklad se marginálními pravděpodobnostními i distribučními funkcemi.
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={nv_Fxy_complete} style={{width: 260}}/>
            </div>

            <p>
            Další obrázek ilustruje, jak se marginální kumulativní distribuční funkce (fialově)
            rovněž vyskytují v tabulce {"$F(x,y)$"} jako poslední (horní) řádek a poslední (pravý) sloupec
            jejích hodnot.
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={nv_Fxy_complete_marks} style={{width: 600}}/>
            </div>

            <h2 id="rvec-note-probfunc">Poznámka k pravděpodobností funkci diskrétní n.v.</h2>

            <p>
            Př.: Řekněme, že diskrétní náhodný vektor {"$(X, Y)$"} může nabývat hodnot z této množiny: {"$M = \\Big\\{(1,1), (1,2), (2,2), (2,3)\\Big\\}$"}.
            Potom náhodná veličina {"$X$"} sama o sobě - značící první složku vektoru - může nabývat hodnot {"$M_X = \\{1, 2\\}$"},
            a náhodná veličina {"$Y$"} - druhá složka vektoru - může nabývat hodnot {"$M_Y=\\{1, 2, 3\\}$"}.
            </p>

            <p>
            Pravděpodobnost hodnoty {"$(x, y)$"} značíme {"$p(x, y)$"}.
            </p>

            <p>
            Z praktických důvodů (zápis sumací, zápis do tabulek, kreslení grafů) se místo množiny {"$M$"} používá množina {"$M_X \\times M_Y$"},
            která je potenciálně větší. V našem příkladě je to
            {`$$
                M_X \\times M_Y = \\{1, 2\\} \\times \\{1, 2, 3\\} = \\Big\\{(1,1), (1,2), \\textcolor{red}{(1,3)}, \\textcolor{red}{(2,1)}, (2,2), (2,3)\\Big\\}.
            $$`}
            Vidíme, že nám oproti {"$M$"} přibyly hodnoty {"$\\textcolor{red}{(1,3)}$"} a {"$\\textcolor{red}{(2,1)}$"}.
            Na těchto hodnotách dodefinujeme {"$p(x, y) = \\textcolor{red}{0}$"}:
            {`$$
                p(1,3) = 0, \\quad p(2, 1) = 0.
            $$`}
            </p>

            <p>Potom můžeme místo jedné sumy:
            {`$$
                \\sum_{(x,y) \\in M} p(x, y) = 1 \\qquad \\textrm{(sumace přes 4 elementy)}
            $$`}
            psát dvojitou sumu (a na pořadí nezáleží):
            {`$$
                \\sum_{x \\in M_X} \\sum_{y \\in M_Y} p(x, y) = 1 \\qquad \\textrm{(sumace přes 6 elementů)}
            $$`}
            </p>

            <h2 id="rvec-expectation">Střední hodnota</h2>

            <p>
            Střední hodnota náhodného vektoru {"$(X, Y)$"} je dvojice středních hodnot složek: {"$(E(X), E(Y))$"}.
            Počítáme ji buď pomocí marginálních pravděpodobnostních funkcí/hustot, nebo pomocí sdružené pravděpodobnostní funkce/hustoty.
            Diskrétní náhodný vektor:
            {`$$
                \\begin{align*}
                    E(X) & = \\sum_x x p_x(x) = \\sum_x \\sum_y x p(x, y), \\\\
                    E(Y) & = \\sum_y y p_y(y) = \\sum_x \\sum_y y p(x, y).
                \\end{align*}
            $$`}
            Spojitý náhodný vektor:
            {`$$
                \\begin{align*}
                    E(X) & = \\int_{-\\infty}^{\\infty} x f_x(x) = \\int_{-\\infty}^{\\infty} \\int_{-\\infty}^{\\infty} x f(x, y) \\textrm{d}x\\textrm{d}y, \\\\
                    E(Y) & = \\int_{-\\infty}^{\\infty} y f_y(y) = \\int_{-\\infty}^{\\infty} \\int_{-\\infty}^{\\infty} y f(x, y) \\textrm{d}x\\textrm{d}y.
                \\end{align*}
            $$`}
            </p>

            <h2 id="rvec-covariance">Rozptyl, kovariance, korelace</h2>

            <p>
            S rozptylem je to složitější.
            Rozptyl (variance) jednotlivých složek individuálně umíme spočítat:
            {`$$
            \\begin{align*}
                D(X) = E(X^2) - E(X)^2 = E(X X) - E(X)E(X) \\\\
                D(Y) = E(Y^2) - E(Y)^2 = E(Y Y) - E(Y)E(Y) \\\\
            \\end{align*}
            $$`}
            Kromě toho ale můžeme určit i <strong>kovarianci</strong> {"$\\textrm{cov}(X,Y)$"} složek {"$X$"} a {"$Y$"}:
            {`$$
            \\begin{align*}
                \\textrm{cov}(X, Y) &= E(XY) - E(X)E(Y) \\\\
                \\textrm{cov}(Y, X) &= E(YX) - E(Y)E(X) =   \\textrm{cov}(X, Y) \\\\
            \\end{align*}
            $$`}
            Pokud ve vztahu pro kovarianci formálně za {"$Y$"} dosadíme {"$X$"}, získáme výraz pro rozptyl {"$D(X)$"}; a analogicky pro {"$D(Y)$"}:
            {`$$
            \\begin{align*}
                \\textrm{cov}(X, X) &= E(X X) - E(X)E(X) = D(X), \\\\
                \\textrm{cov}(Y, Y) &= E(Y Y) - E(Y)E(Y) = D(Y). \\\\
            \\end{align*}
            $$`}
            Rozptyl (variance) náhodné veličiny je tedy kovariance veličiny samé se sebou.
            </p>
            <p>
            Všechny tyto hodnoty se tradičně zapisují do takvané <strong>kovarianční matice</strong>:
            {`$$
                \\begin{bmatrix}
                    \\textrm{cov}(X,X) & \\textrm{cov}(X,Y) \\\\
                    \\textrm{cov}(Y,X) & \\textrm{cov}(Y,Y)
                \\end{bmatrix} =
                \\begin{bmatrix}
                    D(X) & \\textrm{cov}(X,Y) \\\\
                    \\textrm{cov}(Y,X) & D(Y)
                \\end{bmatrix}
            $$`}
            Matice je symetrická, protože {"$\\textrm{cov}(X,Y) = \\textrm{cov}(Y,X)$"}.
            </p>

            <p>
            <strong>Korelace</strong> {"$\\rho(X,Y)$"} veličin {"$X$"} a {"$Y$"} je normalizovaná kovariance:
            {`$$
                \\rho(X,Y) = \\frac{\\textrm{cov}(X,Y)}{\\sqrt{D(X)D(Y)}}
            $$`}
            </p>

            <p>
            Výpočetní vzorce pro diskrétní a spojité veličiny:
            {`$$
            \\begin{align*}
                \\textrm{cov}(X,Y) &= \\sum_x \\sum_y (x - E(X))(y - E(Y)) p(x, y) = \\sum_x \\sum_y xy p(x, y) - E(X)E(Y) \\\\
                \\textrm{cov}(X,Y) &= \\int_{-\\infty}^{\\infty} \\int_{-\\infty}^{\\infty} (x - E(X))(y - E(Y)) f(x, y) \\textrm{d}x\\textrm{d}y  = \\int_{-\\infty}^{\\infty} \\int_{-\\infty}^{\\infty} xyf(x, y) - E(X)E(Y) \\textrm{d}x\\textrm{d}y
            \\end{align*}
            $$`}
            </p>

        </div>
        </div>
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

