
import {
    BarChartMeta,
    ChebychevInequalityMeta,
    CLTMeta,
    ConceptComparisonDMeta,
    ConceptComparisonEMeta,
    CountabilityMeta,
    DataTypesMeta,
    DensityMeta,
    DescriptiveStatisticsMeta,
    DiscreteOrContinuousMeta,
    DistributionFunctionMeta,
    DistributionMeta,
    ElementaryEventsMeta,
    EventsIndependenceMeta,
    EventsMeta,
    EventSpaceMeta,
    HistogramMeta,
    HypothesisTestingMeta,
    InferenceIntroMeta,
    IntervalEstimationMeta,
    LinearRegressionMeta,
    MappingMeta,
    MarkovInequalityMeta,
    MathSectionMeta,
    MeanCalculationMeta,
    MeanKindsMeta,
    MomentsMeta,
    NahodnyVyberMeta,
    NotationMeta,
    PointEstimationMeta,
    ProbabilityIntroMeta,
    ProbabilityMeta,
    QuantilesMeta,
    RandomVariable2Meta,
    RandomVariableMeta,
    RandomVectorsMeta,
    RegressionBasicsMeta,
    RVTransformationsMeta,
    SetsAMeta,
    SetsMeta,
    VarianceMeta,
} from "./chapters";


export interface Section {
    title: string,
    shortTitle: string,
    path: string,
    element: (sectionNumber:string) => JSX.Element,
    sectionNumber: string,
}

export interface MenuItem {
    section: Section,
    menuitems?: MenuItem[],
}

function m(section: Section, sections?: Section[]): MenuItem {
    if (sections === undefined) {
        return {
            section: section,
        };
    }
    return {
        section: section,
        menuitems: sections.map(s => m(s)),
    };
}

// ORDER MATTERS! This defines the order of items in the menu (!)
export const sections: MenuItem[] = [
    m(
        DescriptiveStatisticsMeta,
        [
            DataTypesMeta,
            BarChartMeta,
            HistogramMeta,
            MeanCalculationMeta,
            QuantilesMeta,
            MarkovInequalityMeta,
            VarianceMeta,
            MomentsMeta,
            ChebychevInequalityMeta,
            MeanKindsMeta,
            RegressionBasicsMeta,
        ],
    ),
    m(
        MathSectionMeta,
        [
            SetsAMeta,
            SetsMeta,
            MappingMeta,
            CountabilityMeta,
            NotationMeta,
        ],
    ),
    m(
        ProbabilityIntroMeta,
        [
            ElementaryEventsMeta,
            EventsMeta,
            EventSpaceMeta,
            ProbabilityMeta,
            DistributionMeta,
            DensityMeta,
            DistributionFunctionMeta,
            EventsIndependenceMeta,
            RandomVariableMeta,
            RandomVariable2Meta,
            RandomVectorsMeta,
            RVTransformationsMeta,
            NahodnyVyberMeta,
            ConceptComparisonEMeta,
            ConceptComparisonDMeta,
            CLTMeta,
            DiscreteOrContinuousMeta,
        ],
    ),
    m(
        InferenceIntroMeta,
        [
            PointEstimationMeta,
            IntervalEstimationMeta,
            HypothesisTestingMeta,
            LinearRegressionMeta,
        ]
    )
]


function setSectionNumbers(menuitems: MenuItem[], prefix="") {
    let n:number = 1;
    for (const m of menuitems) {
        m.section.sectionNumber = prefix + (prefix ? "." : "") + n.toString();
        // console.log("Setting section number", s.sectionNumber, "for", s.title);
        if (m.menuitems !== undefined) {
            setSectionNumbers(m.menuitems, m.section.sectionNumber);
        }
        n += 1;
    }
}

setSectionNumbers(sections, "");
