import React, { useEffect, useRef, useState } from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import { TOC, TOCProps, defineTOCElements } from '~/TOC';


export const RVTransformationsMeta = {
    title: "Transformace náhodných veličin a vektorů",
    shortTitle: "Transformace",
    path: Path.rv_transformations,
    element: (sectionNumber:string) => <RVTransformations sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "transf-overview",
    "transf-linear-expectation",
    "transf-linear-variance",
    "transf-sum-expectation",
    "transf-product-expectation",
    "transf-sum-variance",
    "transf-mean",
];



export function RVTransformations({sectionNumber}: {sectionNumber: string}) {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);

    return (
        <MathJaxWrapper>
            <MathJax>
            <div className="chapter-container">
            <div className="centered-content">
            <div className="card" ref={chapterRef}>

            <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{RVTransformationsMeta.title}</h1>

            <h2 id="transf-overview">Přehled</h2>

            <p>
            Všechny tyto vztahy jsou důležité, a tvoří základ praxe počítání
            s náhodnými veličinami. Zde si uvedeme jejich přehled, a níže jsou všechny
            odvozeny a okomentovány.
            </p>

            <p>
            {`$$
            \\begin{align*}
                E(aX + b) &= aE(X) + b, \\\\
                D(aX + b) &= a^2D(X). \\\\
            \\end{align*}
            $$`}
            Pro <strong>nezávislé</strong> náhodné veličiny platí:
            {`$$
            \\begin{align*}
                E(XY) &= E(X)E(Y), \\\\
                \\textrm{cov}(X,Y) &= 0.
            \\end{align*}
            $$`}
            Pro součet náhodných veličin platí:
            {`$$
            \\begin{align*}
                E(X + Y) &= E(X) + E(Y), \\\\
                D(X + Y) &= D(X) + 2\\textrm{cov}(X,Y) + D(Y) \\quad \\textrm{ obecně, } \\\\
                D(X + Y) &= D(X) + D(Y) \\quad \\textrm{ když } X \\textrm{ a } Y \\textrm{ jsou nezávislé.}
            \\end{align*}
            $$`}
            Pro průměr {"$\\bar{X} = \\frac{1}{n}(X_1 + \\cdots + X_n)$"} <strong>nezávislých stejně rozdělených</strong> náhodných veličin platí:
            {`$$
            \\begin{align*}
                E\\left(\\bar{X}\\right) &= E(X), \\\\
                D\\left(\\bar{X}\\right) &= \\frac{1}{n}D(X), \\\\
                \\sigma\\left(\\bar{X}\\right) &= \\frac{1}{\\sqrt{n}}\\sigma(X).
            \\end{align*}
            $$`}
            </p>

            <h2 id="transf-linear-expectation">Lineární transformace: Střední hodnota</h2>

            <p>Změna střední hodnoty při lineární transformaci náhodné veličiny:
            {`$$
                E(aX + b) = aE(X) + b
            $$`}
            Důkaz - diskrétní náhodná veličina:
            {`$$
            \\begin{align*}
                E(aX + b) &= \\sum_{x} (ax + b) p(x) = \\\\
                          &= \\sum_{x} a x p(x) + \\sum_{x} b p(x) =\\\\
                          &= a \\sum_{x} x p(x) + b \\sum_{x} p(x) = \\\\
                          &= a E(X) + b \\cdot 1 = \\\\
                          &= a E(X) + b
            \\end{align*}
            $$`}
            Důkaz - spojitá náhodná veličina:
            {`$$
            \\begin{align*}
                E(aX + b) &= \\int (ax + b) f(x) \\textrm{d}x = \\\\
                          &= \\int a x f(x) \\textrm{d}x + \\int b f(x) \\textrm{d}x = \\\\
                          &= a \\int x f(x) \\textrm{d}x + b \\int f(x) \\textrm{d}x = \\\\
                          &= a E(X) + b \\cdot 1 = \\\\
                          &= a E(X) + b
            \\end{align*}
            $$`}
            </p>

            <h2 id="transf-linear-variance">Lineární transformace: Rozptyl</h2>
            <p>Změna rozptylu při lineární transformaci náhodné veličiny:
            {`$$
                D(aX + b) = a^2D(X)
            $$`}
            Důkaz:
            {`$$
            \\begin{align*}
                D(aX + b) &= E\\left[(aX + b - E(aX + b))^2\\right] = \\\\
                          &= E\\left[(aX + b - aE(X) - b)^2\\right] = \\\\
                          &= E\\left[(a(X - E(X)))^2\\right] = \\\\
                          &= E\\left[a^2(X - E(X))^2\\right] = \\\\
                          &= a^2 E\\left[(X - E(X))^2\\right] = \\\\
                          &= a^2 D(X)
            \\end{align*}
            $$`}
            Má-li náhodná veličina jednotku {"$j$"}, pak má její rozptyl jednotku {"$j^2$"}.
            Je tedy intuitivní, že konstanta {"$a$"} vyskočí před rozptyl s druhou mocninou.
            Posunutí {"$b$"} nemá na rozptyl vliv - rozptyl je míra variability, nikoli polohy -
            a posunutím se mění pouze poloha, nikoli variabilita.
            </p>

            <h2 id="transf-sum-expectation">Součet náhodných veličin: Střední hodnota</h2>

            <p>
            Pro součet dvou náhodných veličin platí:
            {`$$
                E(X + Y) = E(X) + E(Y)
            $$`}
            Důkaz - diskrétní náhodné veličiny:
            {`$$
            \\begin{align*}
                E(X + Y) &= \\sum_{x, y} (x + y) p(x, y) = \\\\
                         &= \\sum_{x, y} x p(x, y) + \\sum_{x, y} y p(x, y) = \\\\
                         &= \\sum_{x} x \\sum_{y} p(x, y) + \\sum_{y} y \\sum_{x} p(x, y) = \\\\
                         &= \\sum_{x} x p_x(x) + \\sum_{y} y p_y(y) = \\\\
                         &= E(X) + E(Y)
            \\end{align*}
            $$`}
            Důkaz - spojité náhodné veličiny:
            {`$$
            \\begin{align*}
                E(X + Y) &= \\int \\int (x + y) f(x, y) \\textrm{d}x \\textrm{d}y = \\\\
                         &= \\int x \\int f(x, y) \\textrm{d}y \\textrm{d}x + \\int y \\int f(x, y) \\textrm{d}x \\textrm{d}y = \\\\
                         &= \\int x f_x(x) \\textrm{d}x + \\int y f_y(y) \\textrm{d}y = \\\\
                         &= E(X) + E(Y)
            \\end{align*}
            $$`}
            Tento vztah platí obecně, bez ohledu na to, jestli jsou náhodné veličiny nezávislé nebo ne.
            </p>

            <h2 id="transf-product-expectation">Součin náhodných veličin: Střední hodnota</h2>

            <p>
            Jsou-li náhodné veličiny nezávislé, pak platí:
            {`$$
                E(XY) = E(X)E(Y)
            $$`}
            Tento vztah je velmi důležitý pro odvození vztahů pro rozptyl součtu nezávislých náhodných veličin
            - viz níže.
            </p>

            <p>
            Důkaz - diskrétní náhodné veličiny:
            {`$$
            \\begin{align*}
                E(XY) &= \\sum_{x, y} x y p(x, y) = \\\\
                      &= \\sum_{x, y} x p_x(x) y p_y(y) = \\\\
                      &= \\sum_{x} x p(x) \\cdot \\sum_{y} y p(y) = \\\\
                      &= E(X) E(Y)
            \\end{align*}
            $$`}
            Nezávislost náhodných veličin jsme využili v kroku, kdy jsme rozložili sdruženou pravděpodobnostní funkci&nbsp;
            {"$p(x, y)$"} na součin marginálních pravděpodobnostních funkcí {"$p_x(x) p_y(y)$"}.
            Tím se funkce dvou proměnných stala takzavaně separabilní - stala se součinem jedné funkce {"$xp_x(x)$"},
            která závisí pouze na {"$x$"}, a druhé funkce {"$yp_y(y)$"}, která závisí pouze na {"$y$"}.
            To nám umožnilo spočítat sumy nezávisle a získali jsme požadovaný výsledek.
            </p>

            <p>
            Důkaz - spojité náhodné veličiny:
            {`$$
            \\begin{align*}
                E(XY) &= \\int \\int x y f(x, y) \\textrm{d}x \\textrm{d}y = \\\\
                      &= \\int x f_x(x) \\textrm{d}x \\cdot \\int y f_y(y) \\textrm{d}y = \\\\
                      &= E(X) E(Y)
            \\end{align*}
            $$`}
            Nezávislost náhodných veličin jsme využili v kroku, kdy jsme rozložili sdruženou hustotu
            {"$f(x, y)$"} na součin marginálních hustot {"$f_x(x) f_y(y)$"}. Princip viz diskrétní případ.
            </p>

            <p>
            Pokud by veličiny nebyly nezávislé, pak by vztah neplatil.
            Pokud náhodný vektor nabývá hodnot {"$(1,1), (2,2), (3,3)$"} s pravděpodobnostmi {"$1/3$"},
            potom složky {"$X$"} a {"$Y$"} nejsou nezávislé. Jejich střední hodnoty jsou {"$2$"}, a máme:
            {`$$
                E(XY) = 1 \\cdot 1 \\cdot 1/3 + 2 \\cdot 2 \\cdot 1/3 + 3 \\cdot 3 \\cdot 1/3 = 14/3 = \\textcolor{red}{4.\\overline{66} \\neq 4} = 2 \\cdot 2 = E(X)E(Y).
            $$`}
            </p>

            <h2 id="transf-sum-variance">Součet náhodných veličin: Rozptyl</h2>

            <p>
            Pro rozptyl součtu dvou náhodných veličin obecně platí:
            {`$$
                D(X + Y) = D(X) + 2\\textrm{cov}(X,Y) + D(Y)
            $$`}
            Je totiž:
            {`$$
            \\begin{align*}
                D(X + Y) &= E[(X + Y)^2] - E(X + Y)^2 \\\\
                         &= E(X^2 + 2XY + Y^2) - (E(X) + E(Y))^2 \\\\
                         &= E(X^2) + 2E(XY) + E(Y^2) - E(X)^2 - 2E(X)E(Y) - E(Y)^2 \\\\
                         &= \\textcolor{green}{E(X^2) - E(X)^2} + 2[E(XY) - E(X)E(Y)] + \\textcolor{blue}{E(Y^2) - E(Y)^2} \\\\
                         &= \\textcolor{green}{D(X)} + 2\\textrm{cov}(X,Y) + \\textcolor{blue}{D(Y)}
            \\end{align*}
            $$`}
            Všiměte si formální podobnosti s výrazem {"$(a+b)^2 = a^2 + 2ab + b^2$"}.
            </p>
            <p>Pro  <strong>nezávislé</strong> náhodné veličiny je kovariance nulová:
            {`$$
                \\textrm{cov}(X,Y) = E(XY) - E(X)E(Y) = E(X)E(Y) - E(X)E(Y) = 0
            $$`}
            a vztah pro {"$D(X + Y)$"} se zjednoduší na:
            {`$$
                D(X + Y) = D(X) + D(Y)
            $$`}
            </p>

            <p>
            Tento vztah je zásadní. Všiměme si explicitně rozdílu chování v následujících případech:
            </p>

            <ul>
                <li>
                    Vezměme dvojnásobek náhodné veličiny {"$X$"}: {"$2X = X+X$"}. Rozptyl vychází
                    {`$$
                        D(2X) = \\textcolor{red}{4}D(X)
                    $$`}
                    Zde v součtu {"$X+X$"} jsou složky závislé - stoprocentně korelované.
                    Pozn.: I kdybychom rozptyl počítali vzorcem pro součet, vyjde nám to stejně:
                    {`$$
                        D(X+X) = D(X) + 2\\textrm{cov}(X,X) + D(X) = D(X) + 2D(X) + D(X) = 4D(X).
                    $$`}
                </li>
                <li>
                    Vezměme součet dvou <strong>nezávislých</strong> náhodných veličin {"$X+Y$"} se stejným rozptylem {"$D(X) = D(Y)$"}.
                    Rozptyl vychází
                    {`$$
                        D(X + Y) = D(X) + D(Y) = \\textcolor{red}{2}D(X)
                    $$`}
                </li>
            </ul>

            <p>
            V případě nezávislých náhodných veličin je rozptyl menší (koeficient {"$\\textcolor{red}{2}$"}).
            Tento důležitý fakt je dále využitý níže při práci s průměrem nezávislých náhodných veličin.
            </p>

            <h2 id="transf-mean">Průměr náhodných veličin</h2>

            <p>
            Průměr dvou náhodných veličin {"$X$"} a {"$Y$"} je definován jako {"$\\frac{1}{2}(X + Y)$"}.
            Tento průměr je také náhodnou veličinou, a má svou střední hodnotu a rozptyl.
            </p>
            <p>
            Pro střední hodnotu platí:
            {`$$
                E\\left(\\frac{1}{2}(X + Y)\\right) = \\frac{1}{2}(E(X) + E(Y))
            $$`}
            Pokud tyto náhodné veličiny mají stejné rozdělení, mají i stejnou střední hodnotu {"$E(X) = E(Y)$"}.
            Potom je:
            {`$$
                E\\left(\\frac{1}{2}(X + Y)\\right) = \\frac{1}{2}\\left(E(X) + E(Y)\\right) = \\frac{1}{2}(E(X) + E(X)) = E(X)
            $$`}
            Obecně pro průměr {"$n$"} stejně rozdělených náhodných veličin platí:
            {`$$
                E\\left(\\frac{1}{n}(X_1 + \\cdots + X_n)\\right) = \\frac{1}{n}(nE(X)) = E(X)
            $$`}
            </p>

            <p>
            Pro rozptyl platí:
            {`$$
                D\\left(\\frac{1}{2}(X + Y)\\right) = \\frac{1}{4}(D(X) + 2\\textrm{cov}(X,Y) + D(Y))
            $$`}
            Pokud tyto náhodné veličiny mají stejné rozdělení, mají i stejný rozptyl {"$D(X) = D(Y)$"}.
            Pokud jsou i nezávislé, je kovariance nulová {"$\\textrm{cov}(X,Y) = 0$"}.
            Potom je:
            {`$$
                D\\left(\\frac{1}{2}(X + Y)\\right) = \\frac{1}{4}(D(X) + 0 + D(X)) = \\frac{1}{2}D(X)
            $$`}
            Obecně pro průměr {"$n$"} stejně rozdělených nezávislých náhodných veličin platí:
            {`$$
                D\\left(\\frac{1}{n}(X_1 + \\cdots + X_n)\\right) = \\frac{1}{n^2}(nD(X)) = \\frac{1}{n}D(X)
            $$`}
            </p>

            <p>
            Náhodný vektor {"$n$"} nezávislých náhodných veličin majících stejné rozdělení se nazývá&nbsp;
            <strong>náhodný výběr</strong>. Je to model {"$n$"} nezávislých opakování stejného pokusu.
            Vztah pro rozptyl průměru náhodného výběru nám říká, že průměr můžeme s výhodou použít pro odhad
            střední hodnoty původního rozdělení. Čím větší náhodný výběr, tím menší je rozptyl průměru = tím přesnější je odhad střední hodnoty.
            </p>
            <p>Pro intervalový odhad (s danou spolehlivostí) se používá směrodatná odchylka:
            {`$$
                \\sigma\\left(\\frac{1}{n}(X_1 + \\cdots + X_n)\\right) = \\sqrt{\\frac{1}{n}D(X)} = \\frac{1}{\\sqrt{n}}\\sigma(X)
            $$`}
            Směrodatná odchylka klesá s odmocninou z velikosti náhodného výběru.
            Tomuto faktu se někdy říká <strong>statistická kletba</strong> - protože
            pro dosažení dvojnásobné přesnosti je třeba čtyřnásobné velikosti náhodného výběru,
            pro dosažení desetinásobné přesnosti je třeba stonásobné velikosti náhodného výběru, atd.
            </p>

            <p>
            Soubor nezávislých (independent) stejně rozdělených (identically distributed) náhodných veličin
            se běžně označuje zkratkou <strong>i.i.d.</strong>.
            </p>
        </div>
        </div>
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

