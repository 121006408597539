import React, { useEffect, useRef, useState } from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import { TOC, TOCProps, defineTOCElements } from '~/TOC';


export const NahodnyVyberMeta = {
    title: "Náhodný výběr",
    shortTitle: "Náhodný výběr",
    path: Path.nahodny_vyber,
    element: (sectionNumber:string) => <NahodnyVyber sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}


const TOCSpec = [
    "nvyb-summary",
    "nvyb-definition",
    "nvyb-sample-mean",
];


export function NahodnyVyber({sectionNumber}: {sectionNumber: string}) {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);

    return (
        <MathJaxWrapper>
            <MathJax>
            <div className="chapter-container">
            <div className="centered-content">
            <div className="card" ref={chapterRef}>

            <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{NahodnyVyberMeta.title}</h1>

            <h2 id="nvyb-summary">Shrnutí</h2>

            <p>
            Cílem této části je 1) definovat pojem "náhodný výběr", 2) definovat výběrový průměr a ukázat, že je to náhodná veličina (!),
            a 3) definovat výběrový rozptyl a ukázat, že je to náhodná veličina.
            </p>

            <h2 id="nvyb-definition">Pojem "náhodný výběr"</h2>

            <p>
            Pojmem náhodný výběr se označuje <strong>náhodný vektor</strong> {"$(X_1, X_2, \\ldots, X_n)$"},
            kde jednotlivé složky (náhodné veličiny) mají <strong>stejné rozdělení pravděpodbnosti</strong> a jsou <strong>vzájemně nezávislé</strong>.
            V angličtině se používá termín "i.i.d." (independent and identically distributed),
            a místo dedikovaného pojmu "náhodný výběr" se používá opis typu "a collection of i.i.d. random variables",
            "a family of independent, identically distributed random variables" atp.
            </p>

            <p>
            Náhodný výběr je modelem <strong>opakovaného měření téže věci</strong> - <strong>opakování téhož pokusu</strong>.
            V praxi provádíme nějaké fyzikální měření opakovaně, abychom ze získaných hodnot udělali průměr.
            Děláme to proto, abychom 1) získali představu o rozptylu měření - jak moc si můžeme být jistí získanou hodnotou a
            2) zvýšili přesnost (čím více měření, tím "přesnější" je průměr - tj. tím menší má rozptyl: {"$D(\\bar{X}) = \\frac{1}{n}D(X)$"}).
            </p>

            <p>
            Aby tento postup - a matematický model - dával smysl, pak musí být splněny následující podmínky:
            </p>

            <ul>
                <li><p>
                Opakovaná měření měří pořád stejnou věc stejným způsobem - na pozadí "se odehrává" stejné rozdělení/rozložení pravděpodobnosti.<br/>

                Například výběr bez vracení toto nesplňuje, protože pravděpodobnostní rozdělení se mění s každým tahem.
                Výběr s vracením to splňuje. Pokud bychom měřili nějakou veličinu přístojem, jehož přesnost se v důsledku nějaké vady
                postupně zhoršuje, pak by měření pokaždé probíhalo jinak - větší vliv chyb by odpovídal většímu rozptylu při každém
                dalším měření - rozdělení pravděpodobnosti by v jednotlivých měřeních nebyla stejná.
                </p></li>
                <li><p>
                Opakovaná měření jsou nezávislá, tj. vzájemně se neovlivňují.<br/>

                Pokud by měřicí přístroj vykazoval nějakou hysterezi/paměť/vnitřní stav, pak by měření nebyla nezávislá.
                Změření velké hodnoty v jednom měření by např. znamenalo větší pravděpodobnost změření velké hodnoty (větší kladné výchylky) i v následujícím měření.
                </p></li>
            </ul>


            <h2 id="nvyb-sample-mean">Výběrový průměr</h2>

            <p>
            Výběrový průměr {"$\\bar{X}$"} je "průměr náhodného výběru" ({"$X_1, \\ldots, X_n$"}), a je to náhodná veličina definovaná takto:
            {`$$
                \\bar{X} = \\frac{1}{n} \\sum_{i=1}^n X_i = \\frac{1}{n}\\Big(X_1 + X_2 + \\ldots + X_n\\Big).
            $$`}

            Jako příklad si uvedeme výběrový průměr z alternativního rozdělení Alt(p). Toto rozdělení modeluje
            pokus se dvěma možnými výsledky: {"$\\left\\{0, 1\\right\\}$"}, kde {"$P(\\left\\{1\\right\\}) = p$"} a {"$P(\\left\\{0\\right\\}) = 1-p$"},
            viz následující praktické příklady:
            </p>

            <ul>
                <li>
                    Hod mincí: hlava (1), orel (0) - nebo naopak.
                    Je-li např. mince zmagnetizovaná a dopadá na kovovou podložku, pak může být {"$p \\neq 0.5$"}.
                </li>
                <li>Střelba na terč: zásah (1), minutí (0). Pravděpodobnost zásahu je {"p"}.</li>
                <li>Splnění nějakých podmínek: splňuje (1), nesplňuje (0).</li>
                <li>Měření přítomnosti nějaké látky ve vzorcích: přítomna (1), nepřítomna (0).</li>
                <li>Vsadíme v loterii: vyhrajeme (1), nevyhrajeme (0).</li>
            </ul>

            <p>
            Jedná se o všechny pokusy, kde jsou možné jen (právě) dva výsledky (pokud nenastane jeden, nastane ten druhý),
            a často jde o výsledky typu ano/ne, úspěch/neúspěch. Úspěch může být reprezentován jak jedničkou
            (např. přítomnost požadované látky), tak nulou (nepřítomnost škodlivé látky).
            </p>

            <p>
            Alternativní rozdělení
            (
            <a href='https://cs.wikipedia.org/wiki/Alternativn%C3%AD_rozd%C4%9Blen%C3%AD' target="_blank" rel="noopener noreferrer" className="external-link">Wikipedie</a>
            )
            má střední hodnotu
            {`$$
                E(X) = 0\\cdot (1-p) + 1 \\cdot p = p
            $$`}
            a rozptyl
            {`$$
                D(X) = 0^2\\cdot (1-p) + 1^2 \\cdot p - p^2 = p - p^2 = p(1-p)
            $$`}
            </p>
            <p>
            Dejme tomu, že jsme dostali 100 vzorků nějaké látky, a z toho je 20 kontaminovaných.
            To ale není na první pohled vidět, a našim úkolem je procento kontaminovaných vzorků zjistit
            měřením, přičemž máme k dispozici N namátkových měření.
            Pravděpodobnost, že náhodně vybraný vzorek je kontaminovaný, je 0.2 - ale to my na začátku nevíme.
            Náhodná veličina {"$X$"} která má hodnotu 1, pokud je vzorek kontaminovaný, a 0, pokud není, má
            alternativní rozdělení Alt(0.2). Parameter {"$p$"} je roven střední hodnotě této náhodné veličiny.
            </p>
            <p>Dejme tomu, že uděláme N = 10 měření vzorků, které jsme získali náhodným výběrem s vracením (
            náhodně vybereme jeden vzorek, zjistíme, zda je kontaminovaný, a pak ho vrátíme zpět do sady vzorků.
            Sadu vzorků promícháme, a pak opakujeme: vybereme vzorek, změříme, vrátíme, atd.).
            Pokud byl vybraný vzorek kontaminovaný, zapíšeme si 1, pokud ne, zapíšeme si 0.
            Po deseti měřeních máme např. následující výsledek:
            {`$$
                (1, 0, 1, 0, 0, 1, 0, 0, 0, 1)
            $$`}
            Pokud bychom chtěli odhadnout pravděpodobnost p kontaminace, podělíme počet změřených kontaminovaných vzorků (4)
            počtem změřených vzorků (10): {"$p \\frac{3}{10} = 0.3$"}. Tento výsledek je číselně totéž, jako bychom
            počítali průměr daných dat:
            {`$$
                \\bar{x} = \\frac{1}{10} (1+0+1+0+0++0+1+0+0) = 0.3
            $$`}.
            Kdybychom celý postup opakovali, je možné, že bychom dostali jiný výsledek. Mohlo by se stát, že bychom
            zrovna ve všech deseti případech vybrali kontaminovaný vzorek:
            {"$(1, 1, 1, 1, 1, 1, 1, 1, 1, 1)$"}, a pak bychom dostali {"$\\bar{x} = 1$"}.
            A opačný extrém - který by klidně také mohl nastat - je, že bychom ve všech případech
            zrovna vybrali nekontaminovaný vzorek: {"$(0, 0, 0, 0, 0, 0, 0, 0, 0, 0)$"}; a pak bychom dostali {"$\\bar{x} = 0$"}.
            </p>

            <p>
            Ukažme si, jak vypadá celá situace, na případě N=4 měření:
            </p>

            <table className='data-table'>
                <thead>
                    <tr>
                        <th>měření</th>
                        <th>průměr</th>
                    </tr>
                </thead>
                <tbody>
                    <tr><td>0000</td><td>0</td></tr>
                    <tr><td>0001</td><td>0.25</td></tr>
                    <tr><td>0010</td><td>0.25</td></tr>
                    <tr><td>0100</td><td>0.25</td></tr>
                    <tr><td>1000</td><td>0.25</td></tr>
                    <tr><td>0011</td><td>0.5</td></tr>
                    <tr><td>0101</td><td>0.5</td></tr>
                    <tr><td>0110</td><td>0.5</td></tr>
                    <tr><td>1001</td><td>0.5</td></tr>
                    <tr><td>1010</td><td>0.5</td></tr>
                    <tr><td>1100</td><td>0.5</td></tr>
                    <tr><td>0111</td><td>0.75</td></tr>
                    <tr><td>1011</td><td>0.75</td></tr>
                    <tr><td>1101</td><td>0.75</td></tr>
                    <tr><td>1110</td><td>0.75</td></tr>
                    <tr><td>1111</td><td>1</td></tr>
                </tbody>
            </table>

            <p>
            Tabulka ukazuje všechna možná měření, a jaký průměr v každém prípadě dostaneme.
            Je vidět, že průměr může nabývat hodnot 0, 0.25, 0.5, 0.75, 1.
            </p>

            <p>
            Na první pohled bychom mohli říct, že vzhledem k tomu, že průměr může vyjít "skoro jakkoli" (od 0 do 1),
            tak nám počítání průměru v odhadu čísla p nepomůže. Zároveň ale cítíme, že to není pravda:
            čekáme, že spíše nastane ten výsledek, kde průměr bude blízko ke skutečné střední hodnotě 0.2 (skutečné hodnotě p = 0.2).
            Čím je tato intuice podpořena? Doplňme si do tabulky pravděpodbnosti jednotlivých výsledků:
            </p>

            <table className='data-table'>
                <thead>
                    <tr>
                        <th>měření</th>
                        <th>průměr</th>
                        <th>pravděpodobnost</th>
                        <th>(výpočet)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>0000</td>
                        <td>0</td>
                        <td>{"$\\textcolor{magenta}{0.4096}$"}</td>
                        <td>{"$= 0.8\\cdot0.8\\cdot0.8\\cdot0.8 = 0.2^{0}(1-0.2)^{4}$"}</td>
                    </tr>
                    <tr>
                        <td>0001</td>
                        <td>0.25</td>
                        <td>{"$\\textcolor{blue}{0.1024}$"}</td>
                        <td>{"$= 0.8\\cdot0.8\\cdot0.8\\cdot0.2 = 0.2^{1}(1-0.2)^{3}$"}</td>
                    </tr>
                    <tr>
                        <td>0010</td>
                        <td>0.25</td>
                        <td>{"$\\textcolor{blue}{0.1024}$"}</td>
                        <td>{"$= 0.8\\cdot0.8\\cdot0.2\\cdot0.8 = 0.2^{1}(1-0.2)^{3}$"}</td>
                    </tr>
                    <tr>
                        <td>0100</td>
                        <td>0.25</td>
                        <td>{"$\\textcolor{blue}{0.1024}$"}</td>
                        <td>{"$= 0.8\\cdot0.2\\cdot0.8\\cdot0.8 = 0.2^{1}(1-0.2)^{3}$"}</td>
                    </tr>
                    <tr>
                        <td>1000</td>
                        <td>0.25</td>
                        <td>{"$\\textcolor{blue}{0.1024}$"}</td>
                        <td>{"$= 0.2\\cdot0.8\\cdot0.8\\cdot0.8 = 0.2^{1}(1-0.2)^{3}$"}</td>
                    </tr>
                    <tr>
                        <td>0011</td>
                        <td>0.5</td>
                        <td>{"$\\textcolor{green}{0.0256}$"}</td>
                        <td>{"$= 0.8\\cdot0.8\\cdot0.2\\cdot0.2 = 0.2^{2}(1-0.2)^{2}$"}</td>
                    </tr>
                    <tr>
                        <td>0101</td>
                        <td>0.5</td>
                        <td>{"$\\textcolor{green}{0.0256}$"}</td>
                        <td>{"$= 0.8\\cdot0.2\\cdot0.8\\cdot0.2 = 0.2^{2}(1-0.2)^{2}$"}</td>
                    </tr>
                    <tr>
                        <td>0110</td>
                        <td>0.5</td>
                        <td>{"$\\textcolor{green}{0.0256}$"}</td>
                        <td>{"$= 0.8\\cdot0.2\\cdot0.2\\cdot0.8 = 0.2^{2}(1-0.2)^{2}$"}</td>
                    </tr>
                    <tr>
                        <td>1001</td>
                        <td>0.5</td>
                        <td>{"$\\textcolor{green}{0.0256}$"}</td>
                        <td>{"$= 0.2\\cdot0.8\\cdot0.8\\cdot0.2 = 0.2^{2}(1-0.2)^{2}$"}</td>
                    </tr>
                    <tr>
                        <td>1010</td>
                        <td>0.5</td>
                        <td>{"$\\textcolor{green}{0.0256}$"}</td>
                        <td>{"$= 0.2\\cdot0.8\\cdot0.2\\cdot0.8 = 0.2^{2}(1-0.2)^{2}$"}</td>
                    </tr>
                    <tr>
                        <td>1100</td>
                        <td>0.5</td>
                        <td>{"$\\textcolor{green}{0.0256}$"}</td>
                        <td>{"$= 0.2\\cdot0.2\\cdot0.8\\cdot0.8 = 0.2^{2}(1-0.2)^{2}$"}</td>
                    </tr>
                    <tr>
                        <td>0111</td>
                        <td>0.75</td>
                        <td>{"$\\textcolor{darkorange}{0.0064}$"}</td>
                        <td>{"$= 0.8\\cdot0.2\\cdot0.2\\cdot0.2 = 0.2^{3}(1-0.2)^{1}$"}</td>
                    </tr>
                    <tr>
                        <td>1011</td>
                        <td>0.75</td>
                        <td>{"$\\textcolor{darkorange}{0.0064}$"}</td>
                        <td>{"$= 0.2\\cdot0.8\\cdot0.2\\cdot0.2 = 0.2^{3}(1-0.2)^{1}$"}</td>
                    </tr>
                    <tr>
                        <td>1101</td>
                        <td>0.75</td>
                        <td>{"$\\textcolor{darkorange}{0.0064}$"}</td>
                        <td>{"$= 0.2\\cdot0.2\\cdot0.8\\cdot0.2 = 0.2^{3}(1-0.2)^{1}$"}</td>
                    </tr>
                    <tr>
                        <td>1110</td>
                        <td>0.75</td>
                        <td>{"$\\textcolor{darkorange}{0.0064}$"}</td>
                        <td>{"$= 0.2\\cdot0.2\\cdot0.2\\cdot0.8 = 0.2^{3}(1-0.2)^{1}$"}</td>
                    </tr>
                    <tr>
                        <td>1111</td>
                        <td>1</td>
                        <td>{"$\\textcolor{red}{0.0016}$"}</td>
                        <td>{"$= 0.2\\cdot0.2\\cdot0.2\\cdot0.2 = 0.2^{4}(1-0.2)^{0}$"}</td>
                    </tr>
                </tbody>
            </table>

            <p>
            Odtud si můžeme agregací vizualizovat rozdělení pravděpodobnosti průměru:
            </p>

            {/* <table className='data-table'>
                <thead>
                    <tr>
                        <th>průměr</th>
                        <th>pravděpodobnost</th>
                        <th>(výpočet)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr> <td>0</td>     <td>{"$\\textcolor{magenta}{0.4096}$"}</td>     <td>{"$= 1\\cdot 0.4096$"}</td> </tr>
                    <tr> <td>0.25</td>  <td>{"$\\textcolor{blue}{0.4096}$"}</td>        <td>{"$= 4\\cdot 0.1024$"}</td> </tr>
                    <tr> <td>0.5</td>   <td>{"$\\textcolor{green}{0.1536}$"}</td>       <td>{"$= 6\\cdot 0.0256$"}</td> </tr>
                    <tr> <td>0.75</td>  <td>{"$\\textcolor{darkorange}{0.0256}$"}</td>  <td>{"$= 4\\cdot 0.0064$"}</td> </tr>
                    <tr> <td>1</td>     <td>{"$\\textcolor{red}{0.0016}$"}</td>         <td>{"$= 1\\cdot 0.0016$"}</td> </tr>
                </tbody>
            </table> */}

            {/* 0.4096 + 0.4096 + 0.1536 + 0.0256 + 0.0016 == 1*/}

            <p>
            Je vidět, že pokud s největší pravděpodobností získáme průměr 0 nebo 0.25; naopak průměr 1
            je velmi málo pravděpodobný (0.16%).
            </p>

            <p>
            Hlavně je ale vidět, že průměr výběru (ano, je to náš výběrový průměr) je <strong>náhodná veličina</strong>,
            a má své rozdělení pravděpodobnosti (!). Pravděpodobnostní funkce tohoto rozdělení je vlastně v tabulce výše.
            </p>

            <p>
            Protože je to náhodná veličina, můžeme se podívat, jakou má střední hodnotu:
            {`$$\\begin{align*}
                E(\\bar{X}) & = \\sum_{x} x P(\\bar{X} = x) = \\\\
                            & = 0\\cdot 0.4096 + 0.25\\cdot 0.4096 + 0.5\\cdot 0.1536 + 0.75\\cdot 0.0256 + 1\\cdot 0.0016 = 0.2
            \\end{align*}$$`}
            </p>

            {/* 0* 0.4096 + 0.25* 0.4096 + 0.5* 0.1536 + 0.75* 0.0256 + 1* 0.0016 == 0.2*/}

            <p>
            Střední hodnota výběrového průměru je identická se střední hodnotou původního rozdělení pravděpodobnosti:
            {`$$
                E(\\bar{X}) = E(X).
            $$`}
            Toto samozřejně plyne z vlastností střední hodnoty (viz kapitola 2.7 Transformace):
            {`$$
                E(\\bar{X}) = E\\Big(\\frac{1}{n}\\sum_i X_i\\Big) = \\frac{1}{n}\\sum_i E(X_i) = \\frac{1}{n} \\cdot n E(X) = E(X),
            $$`}
            a zároveň to vidíme ověřeno na našem konrétním příkladě.
            </p>

        </div>
        </div>
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

