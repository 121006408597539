import React from 'react';

export type TOCProps = Array<{ id: string; text: string }>;

// export function TOC({ref, spec}: { ref: React.RefObject<HTMLDivElement>, spec: string[] }) {

//     const [headers, setHeaders] = React.useState<TOCProps>([]);
//     React.useEffect(() => {
//         if (ref === undefined || ref === null) {
//             return;
//         }
//         defineTOCElements(ref, spec, setHeaders);
//     }, [ref, spec]);

//     return (
//         <TOCimpl headers={headers} />
//     );
// };

export function TOC({headers}: { headers: TOCProps })  {

    const handleClick = (id: string) => {
        const element = document.getElementById(id);
        console.log("Scrolling to", id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="toc">
            <p>Obsah:</p>
            <ul>
                {headers.map((header) => (
                    <li key={header.id} onClick={() => handleClick(header.id)}>
                        {header.text}
                    </li>
                ))}
            </ul>
        </div>
    );
};


export function defineTOCElements(
    chapterRef: React.RefObject<HTMLDivElement>,
    toc: string[],
    setHeaders: React.Dispatch<React.SetStateAction<Array<{ id: string; text: string }>>>) {
    const headersArray: Array<{ id: string; text: string }> = [];
    const chapterElement = chapterRef.current;
    if (chapterElement) {
        const headersElements = chapterElement.querySelectorAll('h1, h2, h3');
        headersElements.forEach((header) => {
            if (toc.includes(header.id)) {
                headersArray.push({ id: header.id, text: header.textContent || ""});
            }
        });
    }
    setHeaders(headersArray);
};
