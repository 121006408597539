import React from 'react';
import { Tooltip } from 'react-tooltip';
import { Home, Users, BookOpen, BarChart2, Shuffle,  } from 'react-feather';

import { useNavigate } from 'react-router-dom';


export function NavBar()  {
    const navigate = useNavigate();

    const navigateHome = () => {
        navigate('');
    };

    const navigateToAuthors = () => {
        navigate('/authors');
    };

    const navigateToSources = () => {
        navigate('/sources');
    };

    const navigateToHistogramApp = () => {
        navigate('/histogram-app');
    };

    const navigateToDictionary = () => {
        navigate('/dictionary');
    };

    return (
        <div style={{display: "flex", flexDirection: "row", justifyContent: "center", paddingTop: 10}}>
            <div>
                <button className="navigation-icon" data-tooltip-id="tooltip-home" data-tooltip-content="Home" onClick={navigateHome}>
                    <Home size={20}/>
                </button>
                <Tooltip id="tooltip-home"/>
            </div>
            <div>
                <button className="navigation-icon" data-tooltip-id="tooltip-sources" data-tooltip-content="Zdroje a literatura" onClick={navigateToSources}>
                    <BookOpen size={20}/>
                </button>
                <Tooltip id="tooltip-sources"/>
            </div>
            <div>
                <button className="navigation-icon" data-tooltip-id="tooltip-authors" data-tooltip-content="Autoři" onClick={navigateToAuthors}>
                    <Users size={20}/>
                </button>
                <Tooltip id="tooltip-authors"/>
            </div>
            <div>
                <button className="navigation-icon" data-tooltip-id="tooltip-histogram-app" data-tooltip-content="Histogram App" onClick={navigateToHistogramApp}>
                    <BarChart2 size={20}/>
                </button>
                <Tooltip id="tooltip-histogram-app"/>
            </div>
            <div>
                <button className="navigation-icon" data-tooltip-id="tooltip-dictionary" data-tooltip-content="Slovník cz-en" onClick={navigateToDictionary}>
                    <Shuffle size={20}/>
                </button>
                <Tooltip id="tooltip-dictionary"/>
            </div>
        </div>
    );
}
