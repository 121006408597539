import React, { useEffect, useRef, useState } from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import { TOC, TOCProps, defineTOCElements } from '~/TOC';

export const MarkovInequalityMeta = {
    title: "Markovova nerovnost",
    shortTitle: "Markovova nerovnost",
    path: Path.markov_inequality,
    element: (sectionNumber:string) => <MarkovInequality sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "datamarkov-intro",
    "datamarkov-note",
    "datamarkov-inequality-raw-data",
    "datamarkov-inequality-raw-data-variants",
    "datamarkov-inequality-boxed-data",
    "datamarkov-inequality-probability",
];

export function MarkovInequality({sectionNumber}: {sectionNumber: string}) {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);

    return (
        <MathJaxWrapper>
            <MathJax>
            <div className="chapter-container">
            <div className="centered-content">
            <div className="card" ref={chapterRef}>

            <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{MarkovInequalityMeta.title}</h1>

            <h2 id="datamarkov-intro">Motivace</h2>
            <p>
            Markovova nerovnost popisuje chování/rozložení <strong>nezáporných</strong> dat. Na začátek si ukažme jednoduchou úvahu:
            </p>
            <p>
            Máme 10 nezáporných hodnot, a jejich průměr nám vyšel {"$\\bar{x} = 20$"}.
            Může být nějaká hodnota ostře větší než 200?
            Ne: kdyby byla (např {"$x_i = 201$"}), ve výpočtu průměru by se vyskytoval sčítanec {"$\\frac{1}{n} x_i = \\frac{1}{10} \\cdot 201 = 20.1$"},
            takže průměr by nemohl být 20 - byl by ostře větší než 20! Je vidět, že maximálně jedna hodnota může být rovna 200, a v tom případě, pokud jsou zároveň všechny ostatní
            hodnoty rovny 0, tak průměr pořád ještě vyjde 20: {"$\\frac{1}{10}\\cdot 0 + \\cdots + \\frac{1}{10}\\cdot 0 + \\frac{1}{10}\\cdot 200 = 20$"}.
            </p>
            <p>
            Ukažme si totéž na čísle 40. Kolik hodnot může být 40 nebo větších? Je-li 5 hodnot rovno přesně 40, a 5 hodnot rovno přesně 0, potom ještě dosáhneme přesně průměru 20:&nbsp;
            {"$\\frac{5}{10}\\cdot 0 + \\frac{5}{10}\\cdot 40 = 20$"}. Jakmile jedna hodnota přesáhne 40 (a ostatní hodnoty zůstanou beze změny),
            průměr se zvýší striktně nad 20. Pokud se jedna hodnota zvýší nad 40 (např. 40.1), musí to jiná hodnota kompenzovat (30.9), aby průměr zůstal 20.
            Je tedy vidět, že hodnot větších nebo rovných 40 (<span style={{color: "green"}}>dvojnásobku</span> průměru) může být nejvýše 5 (<span style={{color: "green"}}>polovina</span> z 10).
            Toto je podstatou sdělení Markovovy nerovnosti.
            </p>

            <p>Př. Když průměrný věk zaměstatnců ústavu je 20 let, potom nejvýše  <span style={{color: "green"}}>čtvrtina</span> zaměstnanců může mít věk 80 let nebo více.
            (80 je <span style={{color: "green"}}>čtyřnásobek</span> průměru 20.) </p>

            <h2 id="datamarkov-note">Poznámka</h2>
            <p>Markovova nerovnost se obvykle uvádí v kontextu pravděpodobnosti.
            My si ji zde uvedeme postupně v různých kontextech - pro neroztříděná data, pro roztříděná data a až nakonec pro pravděpodobnost.
            </p>


            {/* <-h2>Značení</h2>

            <p>
            Velikost množiny {"$A$"} se značí {"$|A|$"}. Pro množiny
            s konečným počtem prvků je velikost množiny definovaná jako počet
            jejích prvků. Je tedy např. {"$|\\{1, 4, 9\\}| = 3$"} (velikost množiny je tři - množina má tři prvky).
            </p>

            <p>
            Uvažujme množinu indexů {"$I = \\{1, 2, 3, \\ldots n\\}$"}. Její velikost je {"$|I| = n$"}.
            Mějme čísla {"$x_i$"} indexovaná touto množinou: {"$x_1, x_2, \\ldots, x_n$"}.
            Sumu těchto čísel lze ekvivalentně zapsat takto:
            {"$$\\sum_{i=1}^n x_i = \\sum_{i \\in I} x_i$$"}
            Místo horní a dolní meze pro {"$i$"} tedy pod sumu můžeme napsat indexovou množinu,
            přes kterou se sumuje. Tato množina může být napsána mnoha způsoby, ale hlavní je,
            aby výraz pod sumou jendoznačně a srozumitelně definoval indexy, které v sumě chceme použít.
            </p>

            <p>
            Příklady:
            Dejme tomu, že udělat součet pouze přes sudé indexy z množiny {"$I$"}. To lze zapsat takto:
            {"$$\\sum_{i: \\ i \\, \\in \\, I \\ \\land \\ i \\, \\mathrm{mod} \\, 2 \\, = \\, 0} x_i.$$"}
            Součet pouze přes indexy 4 až 6 lze zapsat takto:
            {"$$\\sum_{4 \\leq i \\leq 6} x_i = x_4 + x_5 + x_6.$$"}
            </p> */}

            <h2 id="datamarkov-inequality-raw-data">Markovova nerovnost pro neroztříděná data</h2>

            <p>Měříme nějaký kvantitativní <strong>nezáporný</strong> znak -
            např. koncentraci nějaké látky ve vzorcích - a získali jsme {"$n$"} hodnot {"$x_1, x_2, \\ldots, x_n$"} a
            spočítali jsme jejich průměr {"$\\bar{x}$"}.
            </p>

            <p>Markovova nerovnost říká:
            {"$$\\big| \\{x_i : x_i \\geq k \\bar{x} \\} \\big| \\leq \\frac{n}{k}.$$"}
            Slovně: Hodnot, které jsou větší nebo rovné než {"$k$"}-násobek průměru, je nejvýše {"$\\frac{n}{k}$"}.<br/>
            </p>

            <p>
            Např. pro {"$k=2$"}: Hodnot, které jsou větší nebo rovné než dvojnásobek průměru, je nejvýše {"$\\frac{n}{2}$"}:
            {"$$\\big| \\{x_i : x_i \\geq 2 \\bar{x} \\} \\big| \\leq \\frac{n}{2}.$$"}

            Pro {"$k=3$"}: Hodnot, které jsou větší nebo rovné než trojnásobek průměru, je nejvýše {"$\\frac{n}{3}$"}:
            {"$$\\big| \\{x_i : x_i \\geq 3 \\bar{x} \\} \\big| \\leq \\frac{n}{3}.$$"}


            Máme-li data o rozsahu {"$n=100$"} a průměru {"$\\bar{x}=10$"}, pak nejvýše 50 hodnot
            může být větších nebo rovných než 20. Nejvýše 33 hodnot může být větších nebo rovných než 30. Atd.
            </p>

            <p>Proč to funguje?
            {"$$n\\bar{x} = \\sum_{i=1}^n x_i = \\textcolor{red}{\\sum_{i: \\ x_i < k \\bar{x}} x_i} + \\textcolor{green}{\\sum_{i: \\ x_i \\geq k \\bar{x}} x_i} \\geq \\textcolor{green}{\\sum_{i: \\ x_i \\geq k \\bar{x}} x_i} \\geq \\sum_{i: \\ x_i \\geq k \\bar{x}}  k \\bar{x} = k \\bar{x} \\sum_{i: \\ x_i \\geq k \\bar{x}} 1 = k \\bar{x} \\big|\\{x_i: x_i \\geq k \\bar{x}\\}\\big|.$$"}
            Sumu jsme rozdělili na dvě části:&nbsp;
            <span style={{color: "red"}}>součet těch hodnot {"$x_i$"}, které jsou menší než k-násobek průměru</span>
            &nbsp;a&nbsp;
            <span style={{color: "green"}}>součet těch hodnot {"$x_i$"}, které jsou větší nebo rovné než k-násobek průměru</span>.
            Každá hodnota {"$x_i$"} padne právě do jedné z těchto dvou skupin.
            V druhém kroku vypustíme červenou část (která je nezáporná) a tím dostaneme nerovnost {"$\\geq$"}.&nbsp;
            Každé {"$x_i$"} v zelené sumě pak nahradíme číslem {"$k\\bar{x}$"}, které je menší než {"$x_i$"} pro všechny indexy {"$i$"} v této sumě.
            Číslo {"$k\\bar{x}$"} vytkneme před sumu (nezávisí na indexu), a zůstane nám tedy sumace jedniček - jedna pro každý prvek
            množiny {"$\\{x_i: x_i \\geq k \\bar{x}\\}$"}. Suma tedy udává velikost této množiny (počet jejích prvků): {"$\\big|\\{x_i: x_i \\geq k \\bar{x}\\}\\big|$"}.
            Máme tedy:
            {"$$k \\bar{x} \\big|\\{x_i: x_i \\geq k \\bar{x}\\}\\big| \\leq n\\bar{x} \\quad \\Rightarrow \\quad \\big|\\{x_i: x_i \\geq k \\bar{x}\\}\\big| \\leq \\frac{n}{k}.$$"}
            </p>

            <h3 id="datamarkov-inequality-raw-data-variants">Varianty</h3>
            <p>A. Markovovu nerovnost můžeme vyjádřit i takto:
            {"$$\\frac{1}{n} \\big| \\{x_i : x_i \\geq k \\bar{x} \\} \\big| \\leq \\frac{1}{k}.$$"}
            Slovně: Podíl (zastoupení) hodnot, které jsou větší nebo rovné než {"$k$"}-násobek průměru, je v nejvýše {"$\\frac{1}{k}$"}.
            </p>
            <p>
            Např. pro {"$k=2$"}: Podíl (zastoupení) hodnot, které jsou větší nebo rovné než dvojnásobek průměru, je nejvýše {"$\\frac{1}{2}$"}.
            Nejvýše 50% hodnot je větších nebo rovných než dvojnásobek průměru:
            {"$$\\frac{1}{n} \\big| \\{x_i : x_i \\geq 2 \\bar{x} \\} \\big| \\leq \\frac{1}{2}.$$"}
            </p>

            <p>B. Markovovu nerovnost můžeme vyjádřit i takto:
            {"$$\\frac{1}{n} \\big| \\{x_i : x_i \\geq r \\} \\big| \\leq \\frac{\\bar{x}}{r}.$$"}
            Slovně: Podíl hodnot, které jsou větší nebo rovné než {"$r$"}, je v nejvýše {"$\\frac{1}{r}$"} krát průměr.
            </p>
            <p>
            Např. pro {"$\\bar{x} = 10, r = 80$"}: Podíl hodnot, které jsou větší nebo rovné než 80 je nejvýše {"$\\frac{10}{80} = \\frac{1}{8}$"},
            tedy nejvýše {"$12.5\\%$"} hodnot může být větší nebo rovno číslu 80.
            </p>

            <h2 id="datamarkov-inequality-boxed-data">Markovova nerovnost pro roztříděná data</h2>

            <p>Měříme nějaký kvantitativní diskrétní <strong>nezáporný</strong> znak -
            získali jsme {"$n$"} hodnot, a po seskupení (roztřídění) máme {"$m$"} různých hodnot {"$x_1, x_2, \\ldots, x_m$"} s relativním četnostmi {"$f_1, f_2, \\ldots, f_m$"},
            jejich je průměr {"$\\sum_j f_j x_j = \\bar{x}$"}.
            </p>

            <p>Markovova nerovnost říká:
            {"$$\\sum_{j: \\ x_j \\geq k \\bar{x}} f_j \\leq \\frac{1}{k}.$$"}
            Slovně: Součet vah (reltivních četností) těch hodnot, které jsou větší nebo rovné než {"$k$"}-násobek průměru, je nejvýše {"$\\frac{1}{k}$"}.
            </p>

            <p>Proč to funguje?
            {"$$\\bar{x} = \\sum_{i=1}^n f_j x_j = \\textcolor{red}{\\sum_{j: \\ x_j < k \\bar{x}} f_j x_j} + \\textcolor{green}{\\sum_{j: \\ x_j \\geq k \\bar{x}} f_j x_j} \\geq \\textcolor{green}{\\sum_{j: \\ x_j \\geq k \\bar{x}} f_j x_j} \\geq\\sum_{j: \\ x_j \\geq k \\bar{x}} f_j k \\bar{x} = k \\bar{x} \\sum_{j: \\ x_j \\geq k \\bar{x}} f_j.$$"}
            </p>
            <p>
            Máme tedy:
            {"$$k \\bar{x} \\sum_{j: \\ x_j \\geq k \\bar{x}} f_j \\leq \\bar{x} \\quad \\Rightarrow \\quad \\sum_{j: \\ x_j \\geq k \\bar{x}} f_j \\leq \\frac{1}{k}.$$"}
            </p>

            Pozn: Pokud bychom pracovali s roztříděným <i>spojitým</i> znakem, hodnoty {"$x_j$"} by byly středy tříd; veškerý zápis by byl stejný a fungoval by stejně.


            <h2 id="datamarkov-inequality-probability">Markovova nerovnost v pravděpodobnosti</h2>
            <p>
            Nechceme-li pracovat s náhodnou veličinou, můžeme vyjít z toho, že máme nějaké rozdělení pravděpodobnosti na reálné ose, které
            dává nenulové pravděpodobnosti pouze v nezáporném oboru. Pro spojité rozdělení by to znamenalo, že hustota je nulová na {"$(-\\infty, 0)$"},
            pro diskrétní rozdělení by to znamenalo, že body s kladnou pravděpodobností jsou všechny nezáporné.  Střední hodnotu rozdělení (analogie průměru dat) značíme {"$\\mu$"}.
            Potom Markovova nerovnost říká:
            {"$$P\\big(\\{x: x \\geq k \\mu\\}\\big) \\leq \\frac{1}{k}, \\qquad \\textrm{popř.} \\quad P\\big(\\{x: x \\geq k \\}\\big) \\leq \\frac{\\mu}{k}.$$"}
            </p>
            <p>
            Pokud chceme navíc pracovat s nějakou náhodnou veličinou {"$X$"}, dostáváme Markovovu nerovnost ve tvaru, jak se asi uvádí nejčastěji:
            {"$$P\\big(X \\geq k \\, \\mathrm{E}[X] \\big) \\leq \\frac{1}{k}, \\qquad \\textrm{popř.} \\quad P\\big(X \\geq k \\big) \\leq \\frac{\\mathrm{E}[X] }{k}.$$"}
            </p>

            <p>
            Pro diskrétní případ je odvození stejné jako pro roztříděná data, jen místo relativních četností {"$f_j$"} máme pravděpodobnosti {"$p_j$"}.
            Pro spojitý případ sumy nahradíme integrálem a součin {"$f_j x_j$"} součinem {"$f(x) x \\mathrm{d}x$"}, kde {"$f(x)$"} je hustota pravděpodobnosti.
            </p>
        </div>
        </div>
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

