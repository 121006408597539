import React, { useEffect, useRef, useState } from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import { TOC, TOCProps, defineTOCElements } from '~/TOC';


export const ChebychevInequalityMeta = {
    title: "Čebyševova nerovnost",
    shortTitle: "Čebyševova nerovnost",
    path: Path.chebychev_inequality,
    element: (sectionNumber:string) => <ChebychevInequality sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "chebyshev-raw-data",
    "chebyshev-raw-data-variants",
    "chebychev-boxed-data",
    "chebyshev-probability",
];


export function ChebychevInequality({sectionNumber}: {sectionNumber: string}) {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);

    return (
        <MathJaxWrapper>
            <MathJax>
            <div className="chapter-container">
            <div className="centered-content">
            <div className="card" ref={chapterRef}>

            <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{ChebychevInequalityMeta.title}</h1>

            <p><i>Připravujeme - zatím je zde jen stručná verze.</i></p>

            <p>Čebyševova nerovnost se obvykle uvádí v kontextu pravděpodobnosti.
            My si ji zde uvedeme postupně v různých kontextech - pro neroztříděná data, pro roztříděná data a až nakonec pro pravděpodobnost.
            </p>

            <h2 id="chebyshev-raw-data">Čebyševova nerovnost pro neroztříděná data</h2>


            <p>Čebyševova nerovnost říká:
            {"$$\\frac{ \\big| \\{x_i : |x_i -  \\bar{x}| \\geq k \\sigma \\} \\big| }{n} \\leq \\frac{1}{k^2}.$$"}
            Slovně: Hodnot, které jsou od průměru dále než {"$k$"}-násobek směrodatné odchylky, je nejvýše {"$\\frac{1}{k^2}$"} celkového počtu.<br/>
            </p>

            <p>Proč to funguje?
            {
                `$$
                \\begin{align*}
                    \\sigma^2 & = \\frac{1}{n} \\sum_{i=1}^n (x_i - \\bar{x})^2
                                = \\textcolor{red}   {\\frac{1}{n} \\sum_{i: \\ |x_i - \\bar{x}| < k \\sigma}     (x_i - \\bar{x})^2}
                                + \\textcolor{green}      {\\frac{1}{n} \\sum_{i: \\ |x_i - \\bar{x}| \\geq k \\sigma} (x_i - \\bar{x})^2} \\\\
                              & \\geq \\textcolor{green} {\\frac{1}{n} \\sum_{i: \\ |x_i - \\bar{x}| \\geq k \\sigma} (x_i - \\bar{x})^2}
                                \\geq                    \\frac{1}{n} \\sum_{i: \\ |x_i - \\bar{x}| \\geq k \\sigma} (k \\sigma)^2 = \\frac{1}{n} k^2 \\sigma^2 \\sum_{i: \\ |x_i - \\bar{x}| \\geq k \\sigma} 1 \\\\
                              & = \\frac{1}{n} k^2 \\sigma^2 \\big|\\{x_i: |x_i - \\bar{x}| \\geq k \\sigma\\}\\big|
                \\end{align*}
                $$`
            }

            Sumu jsme rozdělili na dvě části:&nbsp;
            <span style={{color: "red"}}>součet těch hodnot {"$x_i$"}, které jsou k průměr blíže než k-násobek směrodatné odchylky</span>
            &nbsp;a&nbsp;
            <span style={{color: "green"}}>součet těch hodnot {"$x_i$"}, které jsou od průměru k-násobek směrodatné odchylky nebo dál</span>.
            Každá hodnota {"$x_i$"} padne právě do jedné z těchto dvou skupin.
            V druhém kroku vypustíme červenou část (která je nezáporná) a tím dostaneme nerovnost {"$\\geq$"}.&nbsp;

            Každé {"$|x_i - \\bar{x}|$"} v zelené sumě pak nahradíme číslem {"$k\\sigma$"}, které je menší než {"$|x_i - \\bar{x}|$"} pro všechny indexy {"$i$"} v této sumě.

            Číslo {"$(k\\sigma)^2$"} vytkneme před sumu (nezávisí na indexu), a zůstane nám tedy sumace jedniček - jedna pro každý prvek
            množiny {"$\\{x_i: |x_i - \\bar{x}| \\geq k \\sigma \\}$"}. Suma tedy udává velikost této množiny (počet jejích prvků):
            {"$\\big| \\{x_i: |x_i - \\bar{x}| \\geq k \\sigma \\} \\big|$"}.

            Máme tedy:
            {`
                $$
                \\frac{1}{n} k^2 \\sigma^2 \\big|\\{x_i: |x_i - \\bar{x}| \\geq k \\sigma\\}\\big| \\leq \\sigma^2
                \\quad \\Rightarrow \\quad
                \\frac{ \\big|\\{x_i: |x_i - \\bar{x}| \\geq k \\sigma \\}\\big| }{n} \\leq \\frac{1}{k^2}.
                $$
            `}
            </p>

            <h3 id="chebyshev-raw-data-variants">Varianty</h3>
            <p>
            Varianta: {"$$\\frac{ \\big| \\{x_i : |x_i -  \\bar{x}| \\geq k \\} \\big| }{n} \\leq \\frac{\\sigma^2}{k^2}$$"}
            </p>

            <p>
            Varianta pro opačný jev: {"$$\\frac{ \\big| \\{x_i : |x_i -  \\bar{x}| < k \\sigma \\} \\big| }{n} > 1 - \\frac{1}{k^2}$$"}
            </p>


            <h2 id="chebychev-boxed-data">Čebyševova nerovnost pro roztříděná data</h2>

            {`
                $$
                \\sum_{j: \\ |x_j -  \\bar{x}| \\geq k \\sigma } f_j \\leq \\frac{1}{k^2}
                $$
            `}

            <h2 id="chebyshev-probability">Čebyševova nerovnost v pravděpodobnosti</h2>

            <p>
            Nechceme-li pracovat s náhodnou veličinou, můžeme vyjít z toho, že máme nějaké rozdělení pravděpodobnosti na reálné ose, které
            dává nenulové pravděpodobnosti pouze v nezáporném oboru. Pro spojité rozdělení by to znamenalo, že hustota je nulová na {"$(-\\infty, 0)$"},
            pro diskrétní rozdělení by to znamenalo, že body s kladnou pravděpodobností jsou všechny nezáporné. Střední hodnotu rozdělení (analogie průměru dat) značíme {"$\\mu$"}
            a směrodatnou odchylku rozdělení značíme {"$\\sigma$"}. Potom Čebyševova nerovnost říká:
            {`$$
                P\\big(\\{x: |x - \\mu| \\geq k \\sigma \\}\\big) \\leq \\frac{1}{k^2}, \\qquad \\textrm{popř.} \\quad P\\big(\\{x: |x - \\mu| \\geq k \\}\\big) \\leq \\frac{\\sigma^2}{k^2}
            $$`}
            </p>
            <p>
            Pokud chceme navíc pracovat s nějakou náhodnou veličinou {"$X$"}, dostáváme Čebyševovu nerovnost ve tvaru, jak se asi uvádí nejčastěji:
            {`$$
                P\\big(|X - \\mathrm{E}[X]| \\geq k \\, \\sigma(X) \\big) \\leq \\frac{1}{k}, \\qquad \\textrm{popř.} \\quad P\\big(|X - \\mathrm{E}[X]| \\geq k \\big) \\leq \\frac{\\mathrm{D}[X]}{k^2}.
            $$`}
            </p>

        </div>
        </div>
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

