import * as d3 from 'd3';


export class CMD<Range, Output, Unknown = never> {

    _xScale: d3.ScaleContinuousNumeric<Range, Output, Unknown>;
    _yScale: d3.ScaleContinuousNumeric<Range, Output, Unknown>;

    constructor (xScale: d3.ScaleContinuousNumeric<Range, Output, Unknown> | null, yScale: d3.ScaleContinuousNumeric<Range, Output, Unknown> | null) {
        if (xScale === null) {
            xScale = d3.scaleLinear()
        }
        if (yScale === null) {
            yScale = d3.scaleLinear()
        }
        this._xScale = xScale;
        this._yScale = yScale;
    }

    static x = (x: number) => x;
    static y = (y: number) => y;

    line(selection: any, x1: number, y1: number, x2: number, y2: number, color?: string, strokeWidth?: number) {
        let s = selection.append('line')
            .attr('x1', this._xScale(x1))
            .attr('y1', this._yScale(y1))
            .attr('x2', this._xScale(x2))
            .attr('y2', this._yScale(y2))

        if (color) {
            s = s.attr('stroke', color)
        }
        if (strokeWidth) {
            s = s.attr('stroke-width', strokeWidth)
        }
        return s;
    }
}


export function hLine(selection: any, x1: number,  x2: number, y: number, color: string, strokeWidth: number) {
    return selection.append('line')
        .attr('x1', x1)
        .attr('y1', y)
        .attr('x2', x2)
        .attr('y2', y)
        .attr('stroke', color)
        .attr('stroke-width', strokeWidth);
}

export function vLine(selection: any, x: number,  y1: number, y2: number, color: string, strokeWidth: number) {
    return selection.append('line')
        .attr('x1', x)
        .attr('y1', y1)
        .attr('x2', x)
        .attr('y2', y2)
        .attr('stroke', color)
        .attr('stroke-width', strokeWidth);
}

export function hLineM(selection: any, midX: number,  midY: number, length: number, color: string, strokeWidth: number) {
    return selection.append('line')
        .attr('x1', midX - length/2)
        .attr('y1', midY)
        .attr('x2', midX + length/2)
        .attr('y2', midY)
        .attr('stroke', color)
        .attr('stroke-width', strokeWidth);
}

export function vLineM(selection: any, midX: number,  midY: number, length: number, color: string, strokeWidth: number) {
    return selection.append('line')
        .attr('x1', midX)
        .attr('y1', midY - length/2)
        .attr('x2', midX)
        .attr('y2', midY + length/2)
        .attr('stroke', color)
        .attr('stroke-width', strokeWidth);
}


export function cross(selection: any, x: number, y: number, length: number, color: string, strokeWidth: number) {
    return selection
        .call((s: any) => hLineM(s, x, y, length, color, strokeWidth))
        .call((s: any) => vLineM(s, x, y, length, color, strokeWidth));

    // hLineM(mainGraph.append('line'), xScale(xMean), yScale(yMean), tickLength, 'red', 2);
    // vLineM(mainGraph.append('line'), xScale(xMean), yScale(yMean), tickLength, 'red', 2);
}