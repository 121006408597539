import React from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';

export const MathSectionMeta = {
    title: "Matematický aparát",
    shortTitle: "Matematický aparát",
    path: Path.math_section,
    element: (sectionNumber:string) => <MathSection sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

export function MathSection({sectionNumber}: {sectionNumber: string}) {
    return (
        <MathJaxWrapper>
            <MathJax>
            <div className="chapter-container">
            <div className="centered-content">
            <div className="card">

            <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{MathSectionMeta.title}</h1>
            <p>
            Základní matematický aparát v rozsahu <i>množiny, kartézský součin, rozklad, zobrazení</i>&nbsp;
            je extrémně užitečný pro chápání vztahů mezi jevy, pravděpodobnostmi, náhodnými veličinami atd.
            Tato sekce ho shrnuje pro případ, že by si ho někdo potřeboval osvěžit. Dále přidáváme
            kapitolu o spočetnosti, což je pokročilejší koncept, který ale v kontextu pravděpodobnosti
            hraje významnou roli.
            </p>
        </div>
        </div>
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

