import React, { useEffect, useRef, useState } from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import { TOC, TOCProps, defineTOCElements } from '~/TOC';

export const NotationMeta = {
    title: "Značení",
    shortTitle: "Značení",
    path: Path.notation,
    element: (sectionNumber:string) => <Notation sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}


const setNotationExamples = [
    {
        notation: "$\\{x \\, | \\, \\varphi(x) \\}$",
        example: "$\\{x \\, | \\, \\sin x = 0 \\}$",
        description: "Množina všech reálných (nebo komplexních) čísel, pro která platí $\\sin x = 0$",
    },
    {
        notation: "$\\{x: \\varphi(x) \\}$",
        example: "$\\{x: \\ x^2 \\geq 4 \\}$",
        description: "Množina všech reálných (nebo komplexních) čísel, pro která platí $x^2 \\geq 4$",
    },
    {
        notation: "$\\{x; \\, \\varphi(x) \\}$",
        example: "$\\{x; \\, x^2 - 2x + 3 = 0 \\}$",
        description: "Množina všech kořenů polynomu $x^2 - 2x + 3$",
    },
    {
        notation: "$\\{x \\in E \\, | \\, \\varphi(x) \\}$",
        example: "$\\{x \\in \\mathbb{N} \\, | \\, x^2 < 100 \\}$",
        description: "Přirozená čísla, jejichž druhé mocniny jsou menší než 100.",
    },
    {
        notation: "$\\{x \\, | \\, x \\in E \\land \\psi(x) \\}$",
        example: "$\\{x \\, | \\, x \\in \\mathbb{Z} \\land x^2 < 100 \\}$",
        description: "Celá čísla, jejichž druhé mocniny jsou menší než 100.",
    },
    {
        notation: "$\\{f(x) \\, | \\, \\varphi(x) \\}$",
        example: "$\\{x^2 \\, | \\, x \\in {1,\\ldots,10} \\}$",
        description: "Druhé mocniny čísel od 1 do 10.",
    },
    {
        notation: "$\\{ \\varphi(x) \\}$",
        example: "$\\{ \\frac{1}{2} \\leq \\sin x \\leq 1 \\}$",
        description: "Množina všech $x$, jejichž sinus je mezi 0.5 a 1 včetně.",
    },
];

// {"$$\\{f(x) \\, | \\, \\varphi(x) \\}$$"}
// {"$$\\{ \\varphi(x) \\}, \\quad \\mathrm{např.} \\left\\{ 0 \\leq X \\leq 2 \\right\\} = \\left\\{x \\, | \\, 0 \\leq X(x) \\leq 2 \\right\\}$$"}


const TOCSpec = [
    "notation-sets",
    "notation-sets-examples",
    "notation-min-max",
];


export function Notation({sectionNumber}: {sectionNumber: string}) {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);

    return (
        <MathJaxWrapper>
        <MathJax>
        <div className="chapter-container">
        <div className="centered-content">
        <div className="card" ref={chapterRef}>

                <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{NotationMeta.title}</h1>
                <h2 id="notation-sets">Množiny</h2>
                <p>Množiny se značí:</p>
                <ul>
                    <li>
                        <p>Plným výčtem prvků: {"$A = \\{1, 4, 9\\}$"}</p>
                    </li>
                    <li>
                        <p>Výčtem prvků s "intuitivním" vynecháním: {"$B = \\{1, 2, \\ldots, 10\\}$"}, {"$C = \\{x_1, x_2, \\ldots, x_{10}\\}$"}</p>
                    </li>
                    <li>
                        <p>Pomocí vlastností - viz anglicky&nbsp;
                        <a href={"https://en.wikipedia.org/wiki/Set-builder_notation"} target="_blank" rel="noopener noreferrer" className="external-link">{"set builder notation"}</a>.
                        Vlastnost, kterou {"$x$"} musí splňovat, se nazývá <i>predikát</i>, značí se obecně {"$\\varphi(x)$"}, {"$\\psi(x)$"} a podobně,
                        a je to formule predikátové logiky, která má pro každé {"$x$"} hodnotu 'pravda' nebo 'nepravda'.
                        Používají se následující zápisy (vyskytují se v různé literatuře):
                        </p>
                        (separátor je "svislítko")
                        {"$$\\{x \\, | \\, \\varphi(x) \\}$$"}
                        (separátor je dvojtečka)
                        {"$$\\{x: \\varphi(x) \\}$$"}
                        (separátor je středník)
                        {"$$\\{x; \\, \\varphi(x) \\}$$"}
                        (vlevo specifikujeme množinu, ze které prvky uvažujeme)
                        {"$$\\{x \\in E \\, | \\, \\varphi(x) \\}$$"}
                        (množinu specifikujeme vpravo, jako součást predikátu)
                        {"$$\\{x \\, | \\, x \\in E \\land \\psi(x) \\}$$"}
                        (prvky množiny jsou dané výrazem - vlevo)
                        {"$$\\{f(x) \\, | \\, \\varphi(x) \\}$$"}
                        (zkrácený zápis, někdy používaný v pravděpodobnosti a statistice)
                        {"$$\\{ \\varphi(x) \\}, \\quad \\mathrm{např.} \\left\\{ 0 \\leq X \\leq 2 \\right\\} = \\left\\{x \\, | \\, 0 \\leq X(x) \\leq 2 \\right\\}$$"}
                    </li>
                </ul>


                <h3 id="notation-sets-examples">Příklady:</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Zápis</th>
                            <th>Příklad</th>
                            <th>Slovní vyjádření</th>
                        </tr>
                    </thead>
                    <tbody>
                        {setNotationExamples.map((example, index) => (
                            <tr key={index}>
                                <td>{example.notation}</td>
                                <td>{example.example}</td>
                                <td>{example.description}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>



                <h2 id="notation-min-max">Miminum, maximum</h2>
                <p>
                Mimimum a maximum se vždy určuje z nějaké množiny, přesněji uspořádané množiny (tj. množiny, jejíž prvky můžeme porovnávat dle velikosti - např. množiny čísel).
                Minimum množiny {"$A$"} se značí {"$\\min A$"}, maximum {"$\\max A$"}. Níže je ukázka ekvivalentních zápisů:
                </p>
                <ul className='no-bullets'>
                    <li>{"$$\\min \\{4, 9, 16, 25\\} = 4$$"}</li>
                    <li>{"$$\\min \\{i^2 \\,|\\, i \\in \\mathbb{N} \\land 2 \\leq i \\leq 5 \\} = 4$$"}</li>
                    <li>{"$$\\min_{i \\in \\{2,3,4,5\\}} i^2 = 4$$"}</li>
                    <li>{"$$\\min_{2 \\leq i \\leq 5} i^2 = 4$$"}</li>
                </ul>
                <p>
                Poslední zápis ilustruje přípdad, kdy je např. z kontextu zřejmé, že pracujeme v množině přirozených čísel.
                Vidíme, že minimum {"$$\\min \\{f(x) \\, | \\, \\varphi(x) \\}$$"}
                se ekvivalentně zapisuje jako {"$$\\min_{\\varphi(x)} f(x)$$"}
                a oba zápisy lze číst jako "minimum hodnot {"$f(x)$"} přes všechny {"$x$"} splňující podmínku {"$\\varphi(x)$"}."
                </p>
                Další příklad zápisu:
                {"$$\\min_{j: \\, x_j \\geq z} x_j $$"}
                Čteme "minimum hodnot {"$x_j$"} přes všechny indexy {"$j$"} takové, že {"$x_j \\geq z$"}." Pod symbolem {"$\\min$"} je definice indexové
                množiny - tj. množiny indexů, která specifikuje vybrané prvky vektoru či posloupnosti {"$\\{x_j\\}$"}, jejichž minimum hledáme.

        </div>
        </div>
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

