import React, { useEffect, useRef, useState } from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import { TOC, TOCProps, defineTOCElements } from '~/TOC';


export const ProbabilityMeta = {
    title: "Pravděpodobnost",
    shortTitle: "Pravděpodobnost",
    path: Path.probability,
    element: (sectionNumber:string) => <Probability sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "summary",
    "why-events",
    "summary-1",
    "properties",
    "notes",
];


export function Probability({sectionNumber}: {sectionNumber: string}) {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);

    return (
        <MathJaxWrapper>
        <MathJax>
        <div className="chapter-container">
        <div className="centered-content">
        <div className="card" ref={chapterRef}>

            <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{ProbabilityMeta.title}</h1>

            <h2 id="summary">Shrnutí</h2>

            <p>
            Pravděpodobnost (pravděpodobnostní míra) je funkce, která každému jevu
            přiřazuje číslo mezi 0 a 1 (jeho pravděpodobnost).
            Pravděpodobnost je tedy funkce na jevovém poli {"$\\mathcal{A}$"} - na podmnožinách {"$\\Omega$"}:
            {`$$
                P: \\mathcal{A} \\to \\langle 0, 1\\rangle.
            $$`}
            <i>Chcete-li se vyhnout pojmu jevové pole, představte si na jeho místě
            množinu všech podmnožin {"$\\Omega$"}, tedy {"$\\mathcal{A} = \\mathcal{P}(\\Omega)$"}.</i> Abychom
            nějakou funkci mohli nazvat pravděpodobností, musí splňovat tyto požadavky:
            {`$$\\begin{align}
                P(\\Omega) & = 1, && \\textcolor{NavyBlue}{\\text{pravděpodobnost jistého jevu (celku) je 1}},\\\\
                P(A) & \\geq 0,  && \\textcolor{NavyBlue}{\\text{pravděpodobnost nemůže být záporná}} \\text{ pro žádné } A, \\\\
                P(\\bigcup_{n = 1}^{\\infty} A_n) & = \\sum_{n = 1}^{\\infty} P(A_n) && \\textcolor{NavyBlue}{\\text{pravděpodobnost se chová aditivně}} \\text{ pro každou} \\\\
                & && \\text{posloupnost} \\textbf{ disjunktních } \\text{ množin } \\{A_n\\}_{n=1}^{\\infty}, \\\\
                & && \\text{(tj. takovou, že } A_i \\cap A_j = \\emptyset \\text{ pro každé } i \\neq j \\text{).}
            \\end{align}$$`}
            </p>

            <p>
            Tento zápis je "kondenzovaným" zápisem požadavků - plynou z něj všechny
            další vlastnosti pravděpodobnosti, se kterými běžně počítáme, např. že:
            {`$$\\begin{align}
                P(\\emptyset) &= 0, && \\text{pravděpodobnost nemožného jevu je 0,} \\\\\
                P(A^\\complement) &= 1 - P(A), && \\text{pravděpodobnost opačného jevu je "jedna minus",} \\\\\
                A \\subseteq B & \\Rightarrow P(A) \\leq P(B), && \\text{~"pravděpodobnost menší množiny je menší",} \\\\
                P(A \\cup B) &= P(A) + P(B) - P(A \\cap B), && \\text{vztah pro sjednocení,} \\\\
                            &\\cdots && \\text{atd.}
            \\end{align}$$`}
            Třetí požadavek, formulovaný pomocí <i>spočetného</i> sjednocení ({"$\\bigcup_{n = 1}^{\\infty}$"}), v sobě zahrnuje i <i>konečná</i> sjednocení disjunktních množin:
            {`$$\\begin{align}
                P(A \\cup B) & = P(A) + P(B), && \\text{pro dvojici disjunktních množin,} \\\\
                P(A_1 \\cup \\cdots \\cup A_n) & = P(A_1) + \\cdots + P(A_n), && \\text{pro n disjunktních množin.}
            \\end{align}$$`}
            Pro dvojici disjunktních množin {"$A, B$"} to plyne z disjunktní posloupnosti {"$A, B, \\emptyset, \\emptyset, \\ldots$"},<br/>
            pro n-tici disjunktních množin {"$A_1, \\ldots A_n $"} to plyne z disjunktní posloupnosti {"$A_1, \\ldots, A_n, \\emptyset, \\emptyset, \\ldots$"}
            </p>
            {/* {"$A_1, A_2, \\ldots, A_n$"}, pak {"$P(\\bigcup_{i = 1}^n A_i) = \\sum_{i = 1}^n P(A_i)$"}. */}

            <p>Pravděpodobnost může být definována mnoha různými způsoby; dva často používané jsou tyto:</p>
            <table className='simple-table table-probability-overview '>
                <thead>
                    <tr>
                        <th>diskrétní</th>
                        <th>spojitá na {"$\\mathbb{R}$"} nebo na {"$\\mathbb{R}^n$"}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{"$P(A) = \\sum_{x \\in A} p(x)$"}</td>
                        <td>{"$P(A) = \\int_{A} f(x) \\textrm{d}x$"}</td>
                    </tr>
                    <tr>
                        <td>{"$p(x)$"} udává pravděpodobnosti bodů<br/></td>
                        <td>{"$f(x)$"} je hustota pravděpodobnosti</td>
                    </tr>
                    <tr>
                        <td>
                        <span style={{fontSize: 'small'}}>
                            <i>bodů musí být konečně mnoho: {"${(x_1, \\cdots, x_n)}$"}, <br/>nebo nejvýše spočetně mnoho: {"${(x_1, x_2, x_3, \\ldots)}$"}</i>
                        </span>
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>jevové pole jsou všechny podmnožiny ({"$\\mathcal{P}(\\Omega)$"})</td>
                        <td>jevové pole jsou borelovské množiny (zahrnují mj. všechny intervaly a všechna konečná a spočetná sjednocení intervalů)</td>
                    </tr>
                </tbody>
            </table>

            <p>Vlastnosti pravděpodobnosti ve větším detailu jsou prezentovány např.&nbsp;
            <a href={"https://is.muni.cz/do/rect/el/estud/prif/ps13/prav_stat/web_1/pages/01-kolmogorova-definice.html#vlastnosti-pravd" } target="_blank" rel="noopener noreferrer" className="external-link">zde (muni)</a>&nbsp;
            nebo na <a href={"https://en.wikipedia.org/wiki/Probability_axioms#Consequences"} target="_blank" rel="noopener noreferrer" className="external-link">Wikipedii</a>.
            </p>

            <p>
            <i>Další text vysvětluje, ilustruje nebo komentuje jednotlivé detaily zde shrnuté.</i>
            </p>

            <h2 id="why-events">Proč definujeme pravděpodobnost apriori na jevech (a ne na elementárních jevech)</h2>

            <p>
            Nabízí se (možná) otázka: Proč matematika používá tuto konstrukci,
            a ne například 'jednodušší' přístup, kdy by pravděpodobnost byla
            definována apriori přímo elementárních jevech - přímo na prvcích {"$\\Omega$"}?
            </p>

            <p>
            Konec konců, jak jsme už viděli na příkladech v kapitole "Jevové pole", u diskrétního rozdělení
            tak běžně postupujeme, zvolíme si čísla {"$p_i$"}, a řekneme, že pravděpodobnost každého
            jevu {"$A$"} je rovna součtu {"$p_i$"} přes všechny elementární jevy {"$i$"}, které do {"$A$"} patří:
            {`$$
                P(A) = \\sum_{i \\in A} p_i.
            $$`}
            Vypadá to například takto: Pro {"$\\Omega = \\{1, 2, 3\\}$"} např. zvolíme
            {"$p_1 = 0.1, p_2 = 0.3, p_3 = 0.6$"}, a pak určíme pravděpodobnost všech jevů {"$A \\subseteq \\Omega$"}:
            </p>

            <table className="simple-table simple-table-basic">
                <tbody>
                    <tr>
                        <td>{"$A:$"}</td>
                        <td>{"$\\emptyset$"}</td>
                        <td>{"$\\{1\\}$"}</td>
                        <td>{"$\\{2\\}$"}</td>
                        <td>{"$\\{3\\}$"}</td>
                        <td>{"$\\{1, 2\\}$"}</td>
                        <td>{"$\\{1, 3\\}$"}</td>
                        <td>{"$\\{2, 3\\}$"}</td>
                        <td>{"$\\Omega$"}</td>
                    </tr>
                    <tr>
                        <td>{"$P(A):$"}</td>
                        <td>0</td>
                        <td>0.1</td>
                        <td>0.3</td>
                        <td>0.6</td>
                        <td>0.4</td>
                        <td>0.7</td>
                        <td>0.9</td>
                        <td>1</td>
                    </tr>
                </tbody>
            </table>

            <p>
            Zpětně pak vidíme, že čísla {"$p_i$"} splňují {"$p_i = P(\\{i\\})$"},
            tedy je lze považovat za pravděpodobnosti elementárních jevů.
            </p>

            <p>
            Problém je v tom, že ve spojitém případě (kde elementárních jevů je
            nespočetně mnoho) tuto konstrukci nelze použít - a ukážeme si proč.
            </p>

            <p>
            Dejme tomu, že chceme definovat rovnoměrné rozdělení na intervalu {"$\\langle 0, 1\\rangle$"}.
            Toto rozdělení může například zachycení fotonu na určité souřadnici na lineárním detektoru délky 1cm.
            Chtěli bychom modelovat, že pravděpodobnost, že foton bude zachycen v pravé polovině
            (tj. na intervalu {"$\\langle 0.5, 1\\rangle$"}) bude rovna 0.5, a také pravděpodobnost,
            že bude zachycen v levé polovině (tj. na intervalu {"$\\langle 0, 0.5\\rangle$"}) bude rovna 0.5.
            I pravděpodobnost, že bude zachycen v prostření části - na intervalu {"$\\langle 0.25, 0.75 \\rangle$"}) -
            by měla být rovna 0.5.
            </p>

            <p>
            Jsme schopni postupovat stejně jako v diskrétním případě, a
            definovat {"$p_i$"} pro každé číslo {"$i$"} z intervalu {"$\\langle 0, 1\\rangle$"}?
            (V tomto kontextu bychom ho zapisovali spíše jako {"$p(i)$"}, a ještě spíše s označením proměnné
            jako {"$x$"} místo {"i"}, tedy jako {"$p(x)$"}).
            Tím, že chceme rovnoměrné rozložení, nedává smysl, aby se jednotlivé hodnoty {"$p_i$"} lišily
            (aby některé pozice byly pravděpodobnější než jiné),
            položme proto {"$p_i = const$"} pro všechna {"$i \\in \\langle 0, 1 \\rangle$"}.
            Pokud je {"$const > 0$"}, pak ale máme problém:
            V intervalu {"$A = \\langle 0, 0.5 \\rangle$"} je nespočetně mnoho bodů {"$i$"},
            takže vzorec
            {`$$
                P(A) = P(\\langle 0, 0.5 \\rangle) = \\sum_{i \\in A} p_i
            $$`}
            1) není definovaný (suma nespočetně mnoha čísel není jako koncept definovaná),<br/>
            2) i kdyby byl součet jen spočetný, tak by výsledek byl nekonečno (a ne 0.5, jak bychom chtěli):<br/>
            {`$$
                \\sum_{i = 1}^{\\infty} const = \\infty.
            $$`}
            Když vezmeme množinu bodů z intervalu {"$\\langle 0, 1 \\rangle$"} o velikosti
            větší než {"$\\frac{1}{const}$"} bodů (např. pro {"$const = 0.001$"} to bude
            množina více než 1000 bodů), potom pravděbodobnost této množiny
            bude větší než 1. To je nesmysl.
            Zároveň tato množina je podmnožinou intervalu {"$\\langle 0, 1 \\rangle$"},
            jehož pravděpodobnost je 1. To je nesmysl podruhé, protože
            chceme, aby platilo, že když {"$A \\subseteq B$"}, tak {"$P(A) \\leq P(B)$"}.
            </p>

            <p>
            Pokud je {"$const = 0$"}, pak máme jiný problém. Vzorec
            {`$$
                P(A) = P(\\langle 0, 0.5 \\rangle) = \\sum_{i \\in A} p_i
            $$`}
            1) pořád není definovaný (suma nespočetně mnoha čísel není jako koncept definovaná),<br/>
            2) i kdyby byl součet jen spočetný, tak by výsledek byl 0 (a ne 0.5, jak bychom chtěli):<br/>
            {`$$
                \\sum_{i = 1}^{\\infty} 0 = 0.
            $$`}
            (Toto vyjádření je formálně na hraně matematické korektnosti, ale ilustruje daný problém.)
            </p>

            <p>
            Je tedy vidět, že ačkoli bychom pořád chtěli používat pravděpodobnost na jevech
            (chceme např., aby platilo, že {"$P(\\langle 0, 0.5 \\rangle) = 0.5$"}),
            nemůžeme se k tomu dobrat tím, že bychom apriori definovali pravděpodobnosti jednotlivých
            elementárních jevů (bodů).
            </p>

            <p>
            Nabízí se definovat pravděpodobnost přímo na podmnožinách {"$\\Omega$"}, a to jako
            jejich délku:
            {`$$
                P(\\langle a, b \\rangle) = b - a.
            $$`}
            Odtud pravděbodobnost jednobodové množiny je
            {`$$
                P(\\{a\\}) = P(\\langle a, a \\rangle) = a - a = 0.
            $$`}
            To odpovídá našemu předchozímu pozorování, že když už {"$p_i = const$"}, tak {"$const$"} musí být 0,
            tedy {"$p_i = 0$"}.
            </p>

            <p>
            Není to tedy tak, že bychom pravděpodobnosti elementárních jevů neznali - známe je, a jsou 0.
            (Všiměte si, že i když je pravděbodobnost takového jevu 0, tak jev může nastat. To je jeden
            z "paradoxů" práce s nespočetně nekonečnými množinami.)
            Pravděpodobnosti elementárních jevů tedy známe, jsou 0, ale nepomohou nám v definování
            pravděpodobnosti jevů. To musíme udělat jinak.
            </p>

            <p>
            Používá se mechanismus hustoty pravděpodobnosti, kde hustota {"$f$"} je nezáporná
            funkce {"$f: \\Omega \\rightarrow \\mathbb{R}$"}, která splňuje podmínku:
            {`$$
                \\int_{\\Omega} f(x) \\text{d}x = 1.
            $$`}
            To je přímá analogie k diskrétnímu případu, kde součet pravděpodobností je {"$\\sum_i p_i = 1$"}.
            Pravděpodobnost jevu {"$A \\subset \\Omega$"} se pak určí vztahem:
            {`$$
                P(A) = \\int_{A} f(x) \\text{d}x.
            $$`}
            Toje přímá analogie k diskrétnímu případu, kde pravděpodobnost jevu {"$A$"} je dána součtem {"$P(A) = \\sum_{i \\in A} p_i$"}.
            </p>

            <p>
            V našem příkladu je hustota pravděpodobnosti konstatní a rovna 1 na intervalu {"$\\langle 0, 1 \\rangle$"}:
            {`$$
                f(x) = 1 \\quad \\text{pro} \\quad x \\in \\langle 0, 1 \\rangle,
            $$`}
            a pravděbodobnost intervalu {"A = $\\langle a, b \\rangle$"} je rovna:
            {`$$
                P(A) = P(\\langle a, b \\rangle) = \\int_{a}^{b} f(x) \\text{d}x = \\int_{a}^{b} 1 \\text{d}x = b - a.
            $$`}
            </p>

            <h3 id="summary-1">Shrnutí</h3>

            <p>
            Srovnáním diskrétního a spojitého případu jsme viděli,
            že pravděpodobnost je v obou případech pěkně definována na jevech,
            ale postup "od definování pravděpodobnosti elementárních jevů k pravděpodobnosti jevů"
            je možný pouze v diskrétním případě. Protože chceme mít jednotnou (univerzální) definici
            pravděpodobnosti, definujeme ji na jevech.
            </p>

            <h2 id="properties">Vlastnosti pravděpodobnosti</h2>

            <p><i>[Připravujeme - toto je rozpracovaný stav]</i></p>

            <p><i>todo: ilustrace a sumy</i></p>

            <p>
            Z analýzy chování pravděpodobnosti v diskrétním případě
            vidíme, že má několik základních vlastností:
            {`$$\\begin{align}
                P(\\Omega) & = 1, \\\\
                \\quad P(A) & \\geq 0,  \\\\
                \\quad P(A \\cup B) & = P(A) + P(B) \\quad \\text{pro} \\quad A \\cap B = \\emptyset, \\\\
                \\quad P(A^\\complement) & = 1 - P(A), \\\\
                ...
            \\end{align}$$`}
            </p>

            <p>
            My tyto vlastnosti abstrahujeme - abychom univerzálně pokryli diskrétní, spojité, i všechny další případy
            - a řekneme, že jakoukoli funkci {"$P$"} na jevech, která splňuje tyto vlastnosti,
            nazveme pravděpodobností (pravděpodobnostní mírou).
            </p>

            <p>
            Vlastnosti (požadavky) se zapíší v minimalistickém tvaru, a se spočetnou sumou:
            {`$$\\begin{align}
                P(\\Omega) & = 1, && \\\\
                P(A) & \\geq 0,  && \\\\
                P(\\bigcup_{n = 1}^{\\infty} A_n) & = \\sum_{n = 1}^{\\infty} P(A_n) && \\text{pro každou posloupnost množin } \\{A_n\\}_{n=1}^{\\infty} \\\\
                & && \\text{(tj. takovou, že } A_i \\cap A_j = \\emptyset \\text{ pro každé } i \\neq j \\text{).}
            \\end{align}$$`}
            </p>

            <p><i>todo: vysvětlit detaily</i></p>

            <h2 id="notes">Poznámky</h2>

            <p>
            Na stejném {"$\\Omega$"} můžeme definovat různé pravděpodobnosti (pravděpodobnostní míry).
            Uvažujme pokus házení mincí, a rovnou dva scénáře házení:
            </p>

            <ul>
                <li><p>
                Férový hod mincí - v 50% procentech případů padá hlava a v 50% případů padá orel.
                Pravděpodobnostní míru v tomto případě označme {"$P_F$"}.
                </p></li>
                <li><p>
                Neférový hod mincí - např. mince je lehce zmagnetizovaná, a padá na zmagnetizovaný povrch. V 70%
                případů padne hlava, ve 30% případů orel.
                Pravděpodobnostní míru v tomto případě označme {"$P_N$"}.
                </p></li>
            </ul>

            <p>
            V obou případech máme stejné dva elementární jevy: "padne hlava" ({"$h$"}), a "padne orel" ({"$o$"}),
            a v obou případech je {"$\\Omega = \\{h, o\\}$"}. To, co se ve scénářích liší, je pravděpodobnostní míra:
            </p>

            <table className='data-table' id="prob-t1">
                <thead>
                </thead>
                <tbody>
                    <tr>
                        <td>Množina {"$A$"}</td>
                        <td>{"$\\emptyset$"}</td>
                        <td>{"$\\{h\\}$"}</td>
                        <td>{"$\\{o\\}$"}</td>
                        <td>{"$\\{h, o\\}$"}</td>
                    </tr>
                    <tr>
                        <td>{"$P_F(A)$"}</td>
                        <td>0</td>
                        <td>0.5</td>
                        <td>0.5</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>{"$P_N(A)$"}</td>
                        <td>0</td>
                        <td>0.7</td>
                        <td>0.3</td>
                        <td>1</td>
                    </tr>
                </tbody>
            </table>

            <p>
            Vidíme, že se liší pravděpodobnosti některých jevů {"$P_F(\\{h\\}) = 0.5, P_N(\\{h\\}) = 0.7$"},
            a to znamená že ani pravděpodobnostní míry {"$P_F, P_N$"} (jako funkce) nejsou stejné.

            (Aby dva různé předpisy (vzorce, tabulky, algoritmy) definovaly stejnou funkci,
            musejí se jejich hodnoty shodovat na všech argumentech. Např.
            funkce {"$\\sin(2x)$"} a funkce {"$2\\sin(x) \\cos(x)$"} jsou stejné, a to i když mají jiný vzorec.)
            </p>

            <p>
            Vidíme, že pravděpodobnost není "jedna daná funkce", ale naopak že různé experimenty
            mohou být popsány různými pravděpodobnostmi.
            </p>

            <p>
            Q: Můžeme ale o kdejaké množinové funkci
            říct, že je to nějaká pravděpodobnost? A: Ne - musí mít vlastnosti které
            od pravděpodobnosti očekáváme / musí splňovat podmínky, které od funkce
            požadujeme, abychom ji mohli nazvat pravděpodobností.
            </p>

            <p>
            Dejme tomu, že máme funkci {"$P_*$"}, která dává tyto hodnoty: {"$P_*(\\{h\\}) = 0.8, P_*(\\{o\\}) = 0.4$"}.
            V 80% pokusů padne hlava, a ve 40% případech padne orel. To by ale znamenalo,
            že v některých případech musí padnou hlava i orel současně! To je nesmysl.
            To, že padne orel, je opačným jevem k tomu že padne hlava. Padne orel = nepadne hlava.
            Pokud v 80% případů padne hlava, pak to že "hlava nepadne" musí nastat ve 20% případů -
            neboli mělo by platit {"$P_*(\\{o\\}) = 1 - P_*(\\{h\\}) = 1 - 0.8 = 0.2 (\\neq 0.4)$"}.
            </p>
        </div>

        </div>
        {/* <TOC ref={chapterRef} spec={toc} /> */}
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

