import React from 'react';

import Plot from 'react-plotly.js';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';


export const HistogramMeta = {
    title: "Třídění spojitého znaku, histogram",
    shortTitle: "Třídění, histogram",
    path: Path.histogram,
    element: (sectionNumber:string) => <Histogram sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}


const buk = "buk";
const borovice = "borovice";
const dub = "dub";
const javor = "javor";
const jedle = "jedle";
const modřín = "modřín";
const olše = "olše";
const smrk = "smrk";


// buk <- "buk";
// borovice <- "borovice";
// dub <- "dub";
// javor <- "javor";
// jedle <- "jedle";
// modřín <- "modřín";
// olše <- "olše";
// smrk <- "smrk";


interface DataCount {
    n: number;
    countMap: Map<string, number>;
    sortedByName: ([string, number])[];
    sortedByCount: ([string, number])[];
}


function CountingMarker({n}:{n: number}) {
    const numGroups = Math.floor(n / 5 + 0.0001); // eps
    const rest = n - numGroups * 5;
    return (
        <span>
            {[...Array(numGroups).keys()].map(x =>
                    <span key={x}>
                        {"$\\cancel{||||}$"}
                    </span>
            )}
            {[...Array(rest).keys()].map(x =>
                    <span key={numGroups * 5 + x}>
                        {"$|$"}
                    </span>
            )}
        </span>
    );
}

function count<T>(data: T[]): Map<T, number> {
    const d = new Map<T, number>();
    for (const item of data) {
        d.set(item, (d.get(item) || 0) + 1);
    }
    console.log(d);
    return d;
}


function countX(data: string[]): DataCount {
    const d = count(data);

    return {
        n: [...d.values()].reduce((partialSum, a) => partialSum + a, 0),
        countMap: d,
        sortedByName: [...d.entries()].sort((a, b) => a[0].localeCompare(b[0])),
        sortedByCount: [...d.entries()].sort((a, b) => b[1] - a[1]),
    }
}


function parseTokens(text: string, separator:string=','): string[] {
    const tokens = text.split(separator);
    const values = tokens.map(t => t.trim()).filter(x => x !== "");
    console.log("values:");
    console.log(values);
    return values;
}


export function Histogram({sectionNumber}: {sectionNumber: string}) {

    // const [treeData, setTreeData] = React.useState(
    //     [
    //         smrk, smrk, smrk, dub, buk, dub, smrk, jedle, smrk, smrk, buk, buk, smrk, jedle, buk,
    //         smrk, smrk, borovice, buk, buk, smrk, smrk, smrk, javor, javor, buk, javor, dub, olše,
    //         javor, smrk, smrk, buk, smrk, smrk, buk, smrk, buk, dub, modřín, modřín, smrk, smrk,
    //         smrk, smrk, buk, modřín, buk, javor, javor, buk, buk, buk, smrk, smrk, buk, smrk,
    //         modřín, borovice, smrk
    //     ]
    // );

    // const treeCount = React.useMemo(
    //     () => countX(treeData),
    //     [treeData]
    // );

    // React.useEffect(
    //     () => {
    //         textArea.current!.value = treeData.join(", ");
    //     },
    //     []
    // );

    // const textArea = React.useRef<HTMLTextAreaElement>(null);

    // function onSubmit(e: React.SyntheticEvent) {
    //     e.preventDefault();
    //     const text = textArea.current?.value || '';
    //     setTreeData(parseTokens(text));
    // }

    return (
        <MathJaxWrapper>
        <MathJax>
        <div className="chapter-container">
        <div className="centered-content">
        <div className="card">

            <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{HistogramMeta.title}</h1>

            V této kapitole se zabýváme tříděním spojitého kvantitativního znaku
            (a je jedno, zda je intervalový či poměrový).

            <p>
            Spojitý znak může nabývat nekonečného množství hodnot.
            </p>
            <p>
            Získaný datový soubor nejspíš nebude obsahovat opakované hodnoty
            (i když samozřejmě vyloučit se to nedá).
            </p>

            <p>
            <b>Třídění</b> znamená to, že si rozsah pozorovaných hodnot pokryjeme
            systémem navazujících intervalů (tříd), a u každé třídy spočítáme,
            kolik hodnot do ní spadá.
            </p>

            <p>Počet tříd volíme tak, aby nebyl ani příliš velký, ani příliš malý.
            Máme-li např. 100 naměřených hodnot, je zbytečné dělit je do 100 tříd. Mnohé třídy by obsahovaly
            jen jednu hodnotu, nebo by byly prázdné.
            Naopak dělení např. do dvou tříd je příliš hrubé - ztratí se podkladový tvar rozdělení dat.
            Třídy volíme také s ohledem na to, aby měly pěkné středy a hranice - obzvláště pokud výsledná roztříděná
            data jsou prezenotvána lidem, kteří je mají číst a udělat si o nich představu. Třídy s hranicemi 1, 2, 3, 4, 5
            jsou např. v tomto ohledu lepší než třídy s hranicemi 1.1, 2.2, 3.3, 4.4, 5.5.
            </p>

            <p>
            Pokud bychom chtěli nějaký vztah, který nám dá hrubou představu o tom, jaký počet tříd zvolit,
            pak se tradični používá např. Sturgesovo pravidlo. Toto pravidlo, i další, uvádíme v tabulce níže:
            </p>

            <table className="data-table">
                <thead>
                    <tr>
                        <th>Pravidlo</th>
                        <th>Vzorec</th>
                        <th>Pozn.</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Sturgesovo</td>
                        <td>{"$\\lceil 1 + \\log_2(n)\\rceil \\approx \\lceil 1 + 3.3 \\log(n) \\rceil$"}</td>
                        <td>Pro soubor dat symetrického charakteru.</td>
                    </tr>
                    <tr>
                        <td>odmocninové</td>
                        <td>{"$\\langle \\sqrt{n}, 2\\sqrt{n} \\rangle$"}</td>
                        <td>Pro soubor dat asymetrického charakteru.</td>
                    </tr>
                    <tr>
                        <td>Scott</td>
                        <td>{"$\\frac{3.49 \\hat{\\sigma}}{\\sqrt[3]{n}}$"}</td>
                        <td>{"$\\hat{\\sigma}$"} je odhad směrodatné odchylky.</td>
                    </tr>
                    <tr>
                        <td>Freedman-Diaconis</td>
                        <td>{"$2 \\frac{\\mathrm{IQR}(x)}{\\sqrt[3]{n}}$"}</td>
                        <td>IQR(x) je mezikvartilové rozpětí.</td>
                    </tr>

                </tbody>
            </table>

            <p>
            <i>Dále: připravujeme...</i>
            </p>

        </div>
        </div>
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

const layoutDefault = {
    height: 250,
    margin: {
        l: 50,
        r: 50,
        b: 50,
        t: 0,
        pad: 4
    },
}