import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

import { Stats } from 'janstatistics';
import { Plotter } from '~/visual/plot';
import { PlotterR } from './regressionPlotter';

type Coordinate = { x: number; y: number };

// σ

export function ScatterplotSD({data, sameScale}: {data: Coordinate[], sameScale: boolean}) {
    const [statsX, setStatsX] = React.useState<Stats>(new Stats(data.map(d => d.x)));
    const [statsY, setStatsY] = React.useState<Stats>(new Stats(data.map(d => d.y)));
    const svgRef = useRef<SVGSVGElement>(null);

    useEffect(() => {
        setStatsX(new Stats(data.map(d => d.x)));
        setStatsY(new Stats(data.map(d => d.y)));
    }, [data, sameScale]);

    useEffect(() => {
        if (!svgRef.current) {
            return;
        }

        const svg = d3.select(svgRef.current);
        new Plotter().plot(svg, data, statsX, statsY, sameScale);

    }, [data, sameScale, statsX, statsY]);

    return (
        <svg ref={svgRef} width={600} height={600} style={{ border: "1px solid black" }}></svg>
    );
}


export function ScatterplotSDRegression({data, sameScale}: {data: Coordinate[], sameScale: boolean}) {
    const [statsX, setStatsX] = React.useState<Stats>(new Stats(data.map(d => d.x)));
    const [statsY, setStatsY] = React.useState<Stats>(new Stats(data.map(d => d.y)));
    const svgRef = useRef<SVGSVGElement>(null);

    useEffect(() => {
        setStatsX(new Stats(data.map(d => d.x)));
        setStatsY(new Stats(data.map(d => d.y)));
    }, [data, sameScale]);

    useEffect(() => {
        if (!svgRef.current) {
            return;
        }

        const svg = d3.select(svgRef.current);
        new PlotterR().plot(svg, data, statsX, statsY, sameScale);

    }, [data, sameScale, statsX, statsY]);

    return (
        <svg ref={svgRef} width={600} height={600} style={{ border: "1px solid black" }}></svg>
    );
}
