import * as d3 from 'd3';


export const sub = (selection: any, subText: string) => {
    return selection.append("tspan")
        //.attr("baseline-shift", "sub")
        .attr("dy", 2)
        .style("font-size", "0.7em")
        .text(subText);
}
