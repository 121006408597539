import React, { useEffect, useRef, useState } from 'react';

import Plot from 'react-plotly.js';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import { defineTOCElements, TOC, TOCProps } from '~/TOC';


export const BarChartMeta = {
    title: "Třídění diskrétních znaků, sloupcový graf",
    shortTitle: "Třídění, sloupcový graf",
    path: Path.bar_chart,
    element: (sectionNumber:string) => <BarChart sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}


const buk = "buk";
const borovice = "borovice";
const dub = "dub";
const javor = "javor";
const jedle = "jedle";
const modřín = "modřín";
const olše = "olše";
const smrk = "smrk";


// buk <- "buk";
// borovice <- "borovice";
// dub <- "dub";
// javor <- "javor";
// jedle <- "jedle";
// modřín <- "modřín";
// olše <- "olše";
// smrk <- "smrk";

//
// Kapitola 1.1
//

interface DataCount {
    n: number;
    countMap: Map<string, number>;
    sortedByName: ([string, number])[];
    sortedByCount: ([string, number])[];
}


function CountingMarker({n}:{n: number}) {
    const numGroups = Math.floor(n / 5 + 0.0001); // eps
    const rest = n - numGroups * 5;
    return (
        <span>
            {[...Array(numGroups).keys()].map(x =>
                    <span key={x}>
                        {"$\\cancel{||||}$"}
                    </span>
            )}
            {[...Array(rest).keys()].map(x =>
                    <span key={numGroups * 5 + x}>
                        {"$|$"}
                    </span>
            )}
        </span>
    );
}

function count<T>(data: T[]): Map<T, number> {
    const d = new Map<T, number>();
    for (const item of data) {
        d.set(item, (d.get(item) || 0) + 1);
    }
    console.log(d);
    return d;
}


function countX(data: string[]): DataCount {
    const d = count(data);

    return {
        n: [...d.values()].reduce((partialSum, a) => partialSum + a, 0),
        countMap: d,
        sortedByName: [...d.entries()].sort((a, b) => a[0].localeCompare(b[0])),
        sortedByCount: [...d.entries()].sort((a, b) => b[1] - a[1]),
    }
}


function parseTokens(text: string, separator:string=','): string[] {
    const tokens = text.split(separator);
    const values = tokens.map(t => t.trim()).filter(x => x !== "");
    console.log("values:");
    console.log(values);
    return values;
}

const TOCSpec: string[] = [
    "datadiscrete-boxing",
    "datadiscrete-information-loss",
];


export function BarChart({sectionNumber}: {sectionNumber: string}) {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);

    const [treeData, setTreeData] = React.useState(
        [
            smrk, smrk, smrk, dub, buk, dub, smrk, jedle, smrk, smrk, buk, buk, smrk, jedle, buk,
            smrk, smrk, borovice, buk, buk, smrk, smrk, smrk, javor, javor, buk, javor, dub, olše,
            javor, smrk, smrk, buk, smrk, smrk, buk, smrk, buk, dub, modřín, modřín, smrk, smrk,
            smrk, smrk, buk, modřín, buk, javor, javor, buk, buk, buk, smrk, smrk, buk, smrk,
            modřín, borovice, smrk
        ]
    );

    const treeCount = React.useMemo(
        () => countX(treeData),
        [treeData]
    );

    React.useEffect(
        () => {
            textArea.current!.value = treeData.join(", ");
        },
        []
    );

    const textArea = React.useRef<HTMLTextAreaElement>(null);

    function onSubmit(e: React.SyntheticEvent) {
        e.preventDefault();
        const text = textArea.current?.value || '';
        setTreeData(parseTokens(text));
    }


    // // Attempt to typeset any math content after updates
    // const typesetMath = () => {
    //     if (window.MathJax) {
    //     window.MathJax.typesetPromise().catch((err) => console.error('MathJax typesetPromise failed:', err));
    //     }
    // };

    // // Effect to typeset on content change
    // useEffect(() => {
    //     typesetMath();
    // }, [content]); // Dependency array includes `content` to react on its changes

    return (
        <MathJaxWrapper>
        <MathJax dynamic={true}>
        <div className="chapter-container">
        <div className="centered-content">
        <div className="card" ref={chapterRef}>

            <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{BarChartMeta.title}</h1>

            <p>
            Do této kapitoly zahrneme všechny typy znaků, o kterých lze říct, že jsou diskrétní, tj.
            A) všechny kvalitativní znaky (nominální i ordinální)
            a B) diskrétní kvantitativní znaky (diskrétní intervalové i diskrétní poměrové).
            Toto dělení zde až na malý detail nebude hrát roli - důležité je primárně jen to,
            že znaky jsou diskrétní.
            </p>

            <p>
            Diskrétní znak může nabývat jen konečného (nebo maximálně spočetného) množství různých hodnot.
            </p>

            <p>
            Získaný datový soubor pak bude nejspíš obsahovat různé tyto hodnoty opakovaně.
            </p>

            <h2 id="datadiscrete-boxing">Třídění</h2>

            <p>
            <b>Třídění</b> znamená to, že si ke každé možné hodnotě znaku zapíšeme, kolikrát se v datovém souboru vyskytuje.
            </p>

            <div className="example" id="table-binning-trees">
                <p>Lesní správce si chce udělat představu o druzích stromů v lese, který právě dostal na starost.
                Vydá se na náhodnou procházku lesem, a zapisuje si druhy stromů, které po cestě vidí.
                Získaná data vypadají takto:
                </p>

                <textarea style={{width: "100%", height: 100}} ref={textArea}/>
                <div style={{display: "flex", justifyContent: "right", width:"100%"}}>
                    <button onClick={onSubmit}>Přepočítat</button>
                </div>

                <p>
                Počet pozorování (velikost datového souboru): n = {treeCount.n}
                </p>


                <p>Roztříděná data vypadají takto:</p>
                <table className="data-table">
                    <thead>
                        <tr>
                            <th>Druh stromu</th>
                            <th>Abs. četnost<br/>(načítání)</th>
                            <th>Abs. četnost<br/>(výsledek)</th>
                            <th>Rel. četnost<br/>(výsledek)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {treeCount.sortedByName.map(
                            ([tree, ctr]) => (
                                <tr key={tree}>
                                    <td>{tree}</td>
                                    <td><CountingMarker n={ctr}/></td>
                                    <td>{ctr}</td>
                                    <td>{(ctr/treeCount.n).toFixed(3)}</td>
                                </tr>
                        ))}
                    </tbody>
                </table>
                <p>Sloupcový graf absolutních četností:</p>
                <Plot data={[
                        {
                            x: treeCount.sortedByName.map(x => x[0]),
                            y: treeCount.sortedByName.map(x => x[1]),
                            type: 'bar'
                        }
                    ]}
                    layout={layoutDefault}
                />
                <p>Protože znak "druh stromu" je nominální (nikoli ordinální),
                lze si pořadí hodnot znaku zvolit libovolně. Lze je například
                seřadit od nejvyšší četnosti:
                </p>
                <Plot data={[
                        {
                            x: treeCount.sortedByCount.map(x => x[0]),
                            y: treeCount.sortedByCount.map(x => x[1]),
                            type: 'bar'
                        }
                    ]}
                    layout={layoutDefault}
                />
                <p>Takovýto graf připomíná např. prezentaci volebních výsledků,
                kde sloupce odpovídají jednotlivým politickým stranám. Tam jsou
                ale na ose y procenta - což nás vede k následujícímu dodatku.
                </p>

                <p>Pokud by nás zajímaly relativní četnosti, můžeme si i tyto
                zobrazit sloupcovým grafem:
                </p>
                <Plot data={[
                        {
                            x: treeCount.sortedByCount.map(x => x[0]),
                            y: treeCount.sortedByCount.map(x => (x[1]/treeCount.n).toPrecision(3)),
                            type: 'bar',
                            marker: {
                                color: 'rgb(150, 205, 191)',
                                opacity: 0.8,
                            }
                        }
                    ]}
                    layout={layoutDefault}
                />
            </div>

            <p>Nyní můžeme třídění popsat obecně.
            Počet možných hodnot znaku označíme jako {"$m$"}, a tyto hodnoty
            jako {"$x_1, x_2, \\ldots, x_m$"}. Číslo {"$n_j$"} bude udávat
            počet výskytů (opakování) hodnoty {"$x_j$"} v získaném statistickém souboru.
            </p>
            <table className='data-table'>
                <thead>
                    <tr>
                        <th>Hodnota <span>{"$x_j$"}</span></th>
                        <th>Abs. četnost <span>{"$n_j$"}</span></th>
                        <th>Význam</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{"$x_1$"}</td>
                        <td>{"$n_1$"}</td>
                        <td>Hodnota {"$x_1$"} se v datech opakuje (vyskytuje) {"$n_1$"} krát.</td>
                    </tr>
                    <tr>
                        <td>{"$x_2$"}</td>
                        <td>{"$n_2$"}</td>
                        <td>Hodnota {"$x_2$"} se v datech opakuje (vyskytuje) {"$n_2$"} krát.</td>
                    </tr>
                    <tr>
                        <td>{"$\\cdots$"}</td>
                        <td>{"$\\cdots$"}</td>
                        <td>{"$\\cdots$"}</td>
                    </tr>
                    <tr>
                        <td>{"$x_m$"}</td>
                        <td>{"$n_m$"}</td>
                        <td>Hodnota {"$x_m$"} se v datech opakuje (vyskytuje) {"$n_m$"} krát.</td>
                    </tr>
                </tbody>
            </table>
            <p>Součet absolutních četností je velikost souboru: <span>{"$$n = \\sum_{j=1}^m n_j = n_1 + n_2 + \\ldots + n_m$$"}</span></p>

            <table className='data-table'>
                <thead>
                    <tr>
                        <th>Hodnota <span>{"$x_j$"}</span></th>
                        <th>Rel. četnost <span>{"$f_j = \\frac{n_j}{n}$"}</span></th>
                        <th>Význam</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{"$x_1$"}</td>
                        <td>{"$f_1 = \\frac{n_1}{n}$"}</td>
                        <td>Hodnota {"$x_1$"} tvoří {"$\\frac{n_1}{n} \\cdot 100\\%$"} datového souboru.</td>
                    </tr>
                    <tr>
                        <td>{"$x_2$"}</td>
                        <td>{"$f_2 = \\frac{n_2}{n}$"}</td>
                        <td>Hodnota {"$x_2$"} tvoří {"$\\frac{n_2}{n} \\cdot 100\\%$"} datového souboru.</td>
                    </tr>
                    <tr>
                        <td>{"$\\cdots$"}</td>
                        <td>{"$\\cdots$"}</td>
                        <td>{"$\\cdots$"}</td>
                    </tr>
                    <tr>
                        <td>{"$x_m$"}</td>
                        <td>{"$f_m = \\frac{n_m}{n}$"}</td>
                        <td>Hodnota {"$x_m$"} tvoří {"$\\frac{n_m}{n} \\cdot 100\\%$"} datového souboru.</td>
                    </tr>
                </tbody>
            </table>

            <p>Součet relativních četností je 1:
                {"$$\\sum_{j=1}^m f_j = \\sum_{j=1}^m \\frac{n_j}{n} = \\frac{1}{n} \\sum_{j=1}^m \\ n_j = \\frac{1}{n}\\cdot n = 1$$"}
            </p>

            <p>Vedle názvu "sloupcový graf" (bar chart) se ještě někdy setkáme
            s názvem "sloupkový graf". Termín "histogram" je většinou
            rezervován pro roztříděný soubor <em>spojitého</em> znaku.
            </p>

            <p>
            Histogram uvidíte v kapitole X.Y. Srovnání histogramu a sloupcového grafu:
            </p>

            <table className='data-table' id="table-comparison-histogram-bar-chart">
                <thead>
                    <tr>
                        <th>Sloupcový graf</th>
                        <th>Histogram</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Pro diskrétní znak.</td>
                        <td>Pro spojitý znak.</td>
                    </tr>
                    <tr>
                        <td>Má mezery mezi sloupci. To je mj. signál pro toho, kdo se
                            na graf dívá, aby hned věděl, že jde o diskrétní znak.</td>
                        <td>Nemá mezery mezi sloupci.</td>
                    </tr>
                    <tr>
                        <td>Ukazuje absolutní, popř. relativní četnosti.</td>
                        <td>Ukazuje hustotu (hustota = relativní četnost na jednotku osy x, tj. relativní četnost dělená délkou třídy.)
                            Pokud jsou třídy různě dlouhé, potom toto je jediná varianta histogramu.
                            Jsou-li třídy stejně dlouhé, setkáme se i s variantou kdy histogram ukazuje
                            absolutní (a výjimečně relativní) četnosti.
                        </td>
                    </tr>
                    <tr>
                        <td>Velikost plochy sloupce nemá žádný význam.</td>
                        <td>Pokud histogram ukazuje hustotu, potom velikost plochy sloupce je rovna relativní četnosti dané třídy.</td>
                    </tr>
                </tbody>
            </table>

            <p>
            Další možností vizualizace četností diskrétního znaku je koláčový graf (pie chart):
            </p>
            <Plot data={
                [
                    {
                        labels: treeCount.sortedByCount.map(x => x[0]),
                        values: treeCount.sortedByCount.map(x => x[1]),
                        type: "pie",
                        textinfo: "label+percent",
                        insidetextorientation: "radial"
                    }
                ]}
                layout={{
                    height: 500,
                    width: 500
                }}
            />

            <p>
            Pro zobrazení četností se u diskrétních <em>kvantitativních</em> znaků
            někdy také používá "spojnicový graf", zvaný též "polygon četností".
            </p>

            <h2 id="datadiscrete-information-loss">Zachování či ztráta informace</h2>

            <p>
            Při třídění tím způsobem, jak bylo ukázáno výše, nedochází ke ztrátě informace.
            Pokud například původní datový soubor obsahuje 25x hodnotu "smrk", tak roztříděný
            obsahuje dvojici ("smrk", 25). To znamená, že z roztříděného souboru
            můžeme přesně "rekonstruovat" soubor původní (až na pořadí pozorování, které
            však nehraje roli).
            </p>

            <p>
            Toto však přestane platit, jakmile "sloučíme" více hodnot znaku dohromady.

            Zavedeme-li např. třídy "jehličnatý" a "listnatý", potom roztříděný datový soubor
            bude vypadat takto:
            </p>

            <table className="data-table">
                <thead>
                    <tr>
                        <th>Druh stromu</th>
                        <th>Abs. četnost</th>
                        <th>Rel. četnost</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>jehličnatý</td><td>33</td><td>0.55</td>
                    </tr>
                    <tr>
                    <td>listnatý</td><td>27</td><td>0.45</td>
                    </tr>
                </tbody>
            </table>

            <p>Z tohoto roztříděného souboru nelze přesně rekonstruovat původní datový soubor
            - při třídění došlo ke ztrátě informace. Víme, že máme 33 jehličnanů, ale už
            nevíme, kolik z toho bylo smrků, borovic, jedlí a modřínů.
            </p>


            <ul>
                <li>V bezeztrátovém třídění třídy přesně odpovídají jednotlivým možným hodnotám znaku.</li>
                <li>Ztrátové třídění nastane, když zavedeme "hrubší" třídy, které agregují určité skupiny
                    možných hodnot znaku.</li>
            </ul>

            <p>Prakticky se toto někdy dělá - obzvlášť pokud je znak sice diskrétní,
                ale <em>nabývá velkého množství různých hodnot</em>. Např. pokud
                bodový zisk z testu je celé číslo v rozmezí 0 až 100 bodů,
                pak se jedná o diskrétní kvantitativní znak, který může nabývat
                101 různých hodnot. Za účelem vizualizace ale zavedeme např. třídy
                (intervaly) o velikosti 10 bodů: 0-10, 11-20, 21-30, atd.
            </p>
            <p>Pokud je znak kvantitavní (jako body z testu), lze tento postup
            popsat i tak, že se ke němu začneme chovat jako by byl spojitý.
            U kvalitativního znaku ale tento příměr nedává smysl.
            </p>

        </div>
        </div>
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

const layoutDefault = {
    height: 250,
    margin: {
        l: 50,
        r: 50,
        b: 50,
        t: 0,
        pad: 4
    },
}