import React, { useEffect, useRef, useState } from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import { TOC, TOCProps, defineTOCElements } from '~/TOC';

const elem_ev_cups = require('../assets/images/elem_ev_cups.png');
const elem_ev_partition1 = require('../assets/images/elem_ev_partition1.png');
const elem_ev_partition_cups = require('../assets/images/elem_ev_partition_cups.png');
const elem_ev_normal_curve = require('../assets/images/elem_ev_normal_curve.png');


export const ElementaryEventsMeta = {
    title: "Elementární jevy",
    shortTitle: "Elementární jevy",
    path: Path.elementary_events,
    element: (sectionNumber:string) => <ElementaryEvents sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "eev-summary",
    "eev-elementary-events",
    "eev-extension-of-omega",
    "eev-properties",
    "eev-coarseness-and-fineness",
    "eev-randomness-in-experiment",
    "eev-measurement-err-as-sampling",
];

export function ElementaryEvents({sectionNumber}: {sectionNumber: string}) {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);

    return (
        <MathJaxWrapper>
            <MathJax>
            <div className="chapter-container">
            <div className="centered-content">
            <div className="card" ref={chapterRef}>

            <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{ElementaryEventsMeta.title}</h1>

            <h2 id="eev-summary">Shrnutí</h2>

            <p>
            Pod pojmem <strong>pokus</strong> (<strong>experiment</strong>, <strong>měření</strong>)
            rozumíme opakovatelný proces, který má nějaké možné výsledky.
            Může se jednat o fyzikální měření, hod kostkou, náhodný výběr z množiny nějakých objektů,
            dotaz v anketě, atd.
            </p>

            <p>
            Když pokusem získáme nějaký konkrétní výsledek, říkáme, že ten
            výsledek <strong>nastal</strong>, <strong>nastoupil</strong> nebo se <strong>realizoval</strong>.
            </p>

            <p>
            <strong>Elementární jev</strong> je základní/primární výsledek pokusu - je to reprezentace výsledku,
            kterou si zaznamenáme po provedení experimentu jako jeho výstup.
            Množina všech elementárních jevů se značí {"$\\Omega$"}.
            Elementární jevy musí splňovat dvě podmínky:
            </p>

            <ul>
                <li><p>
                <strong>Kompletnost</strong>:
                Každý možný výsledek pokusu je v množině {"$\\Omega$"} reprezentován.
                Čili nemůže se stát, že bychom provedli pokus, něco by vyšlo, a to něco by nebylo v množině {"$\\Omega$"}.
                Množina {"$\\Omega$"} je kompletní - obsahuje všechny možné výsledky pokusu jako svoje prvky.
                </p></li>
                <li><p>
                <strong>Disjunktnost</strong>:
                Elementární jevy se navzájem vylučují. Nikdy se nemůže stát,
                že by výsledek pokusu odpovídal dvěma nebo více různým elementárním jevům.
                Jako výsledek vždy nastane jeden a právě jeden elementární jev.
                Když jako výsledek nastal určitý elementární jev, tak pro každý jiný elementární jev platí, že nenastal.
                </p></li>
            </ul>

            <p>Kompletnost zaručuje, že nějaký elementární jev vždy nastane. Disjunktnost zaručuje, že nikdy nenastane více než jeden
            současně. Dohromady zaručují, že vždy nastane právě jeden elementární jev.
            </p>

            <p><i>Další text toto vysvětluje v detailu a ilustruje na příkladech.</i></p>

            <h2 id="eev-elementary-events">Elementární jevy</h2>

            <p>Když děláme nějaký experiment nebo provádíme nějaké měření, na výstupu je nějaký výsledek,
            který nějak zapíšeme/zaznamenáme. Tento přímý výsledek pokusu nazýváme <strong>elementární jev</strong>. Příklady:
            </p>

            <table className='simple-table simple-table-basic'>
                <thead>
                    <tr>
                        <th>Experiment</th>
                        <th>Příklad výsledku</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Hod kostkou</td>
                        <td>6</td>
                    </tr>
                    <tr>
                        <td>Měření hustoty vzorku {"$[\\mathrm{kg/m}^3]$"}</td>
                        <td>1.001</td>
                    </tr>
                    <tr>
                        <td>Anketa o nejoblíbenější předmět</td>
                        <td>"statistika"</td>
                    </tr>
                    <tr>
                        <td>Hod dvěma kostkami současně</td>
                        <td>dvojice čísel (2, 5)</td>
                    </tr>
                    <tr>
                        <td>Sada n měření odrazivosti na stejném materiálu</td>
                        <td>sada n čísel (0,213 ; 0,210 ; 0,214 ; ... ; 0,210)</td>
                    </tr>
                    <tr>
                        <td>Střelba do terče</td>
                        <td>"zásah"</td>
                    </tr>
                    <tr>
                        <td>Střelba do terče třikrát za sebou</td>
                        <td>("zásah", "zásah", "vedle")</td>
                    </tr>
                    <tr>
                        <td>Měření stavu pacienta (teplota [{'\u2103'}], tlak [mm Hg])</td>
                        <td>(38,1 ; 124/82)</td>
                    </tr>
                    <tr>
                        <td>Absorpční čáry ve spektru náhodně vybrané hvězdy dané hvězdokupy [nm]</td>
                        <td>(420, 480, 641, 713, 780)</td>
                    </tr>
                </tbody>
            </table>

            <p>U každého pokusu dokážeme identifikovat množinu možných výsledků - neboli "jakými všemi růzými způsoby to může vyjít".
            Tato množina se tradičně značí {"$\\Omega$"}, a nazývá se <strong>prostor elementárních jevů</strong>, popř. množina všech elementárních jevů.
            </p>

            <table className='simple-table simple-table-basic table-elementary-events'>
                <thead>
                    <tr>
                        <th>Experiment</th>
                        <th>Množina {"$\\Omega$"} všech možných výsledků</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Hod kostkou</td>
                        <td>{"$\\{1, 2, 3, 4, 5, 6\\}$"}</td>
                    </tr>
                    <tr>
                        <td>Měření hustoty vzorku {"$[\\mathrm{kg/m}^3]$"}</td>
                        <td>Kladná reálná čísla. </td>
                    </tr>
                    <tr>
                        <td>Anketa o nejoblíbenější předmět</td>
                        <td>Názvy všech předmětů vyučovaných na dané univerzitě.</td>
                    </tr>
                    <tr>
                        <td>Hod dvěma kostkami současně</td>
                        <td>Všechny{"$^1$"} dvojice čísel 1-6: {"$\\{(1,1), (1,2), (1,3), ... (6,6)\\}$"}</td>
                    </tr>
                    <tr>
                        <td>Sada n měření odrazivosti na stejném materiálu</td>
                        <td>Všechny vektory délky n, tvořené reálnými čísly z intervalu {"$\\langle0, 1\\rangle$"}.</td>
                    </tr>
                    <tr>
                        <td>Střelba do terče</td>
                        <td>Slova "zásah", "vedle".{"$^2$"}</td>
                    </tr>
                    <tr>
                        <td>Střelba do terče třikrát za sebou</td>
                        <td>Všechny možné trojice slov, kde na každé pozici je buď "zásah" nebo "vedle".{"$^2$"}</td>
                    </tr>
                    <tr>
                        <td>Měření stavu pacienta (teplota [{'\u2103'}], tlak [mm Hg])</td>
                        <td>viz {"$^3$"}</td>
                    </tr>
                    <tr>
                        <td>Absorpční čáry ve spektru náhodně vybrané hvězdy dané hvězdokupy [nm]</td>
                        <td>Všechny vektory konečné délky, na jejichž pozicích jsou kladná reálná čísla. {"$^4$"}</td>
                    </tr>
                </tbody>
            </table>

            <p>
            {"$^1$"} Pokud kostky vzájemně dokážeme rozlišit (např. jedna je modrá a druhá červená),
            pak dokážeme rozpoznat 36 různých elementárních jevů. Ve výsledku {"$(x,y)$"} píšeme konsistentně
            na první místo (x) hodnotu na modré kostce, a na druhé místo (y) hodnotu na červené kostce. Potom např.
            {"$(1, 2)$"} a {"$(2, 1)$"} jsou dva různé elementární jevy.
            Kdyby kostky ale byly identické - vzájemně nerozlišitelné - pak bychom měli jen 21 různých elementárních
            jevů. {"$\\Omega$"} by byla množina všech dvojic {"$(x, y)$"}, kde {"$x \\leq y$"}:
            {"$(1,1), (1,2) ... (1,6), (2,2), (2,3) ... (2,6), (3,3) ... (3,6), (4,4), (4,5), (4,6), (5,5), (5,6), (6,6).$"}
            </p>

            <p>
            {"$^2$"} Pro zpracování dat a následném počítání s nimi je vhodné místo slov využít čísla: 1 pro "zásah" a 0 pro "vedle".
            Při střelbě třikrát za sebou je výsledek v numerickém tvaru (1, 1, 0) vhodný např. proto, že součet (2)
            dělený počtem pokusů (3):  {"$\\sum_i x_i / n = 2/3$"} nám rovnou dává odhad pravděpodobnosti zásahu.
            </p>

            <p>
            {"$^3$"} Formát (38,1 ; 124/82) je vhodný pro vizuální zobrazení výsledků měření. Pro matematické operace
            je ale vhodné použít prostě vektor tří čísel: (38,1 ; 124 ; 82). Potom {"$\\Omega$"} je množina všech vektorů
            délky 3, kde na první pozici jsou čísla z intervalu {"$\\langle 0, 100 \\rangle$"}, na druhé a třetí pozici
            čísla z intervalu {"$\\langle 0, 300 \\rangle$"} (rozsahl intervalů jsme nadhodnotili).
            </p>

            <p>
            {"$^4$"} Místo kladných reálných čísel můžeme zúžit intervaly na konkrétní hodnoty, které se vyskytují ve spektrech,
            nebo hodnotu shora omezit na nějakým maximem (např. 1000 nm, pokud se chceme omezit na viditelmé spektrum, s lehkým přesahem).
            </p>

            <h2 id="eev-extension-of-omega">Rozšření {"$\\Omega$"}</h2>
            <p>
            Poznámky {"$^3$"} a {"$^4$"} výše nám ukazují, že množinu {"$\\Omega$"} si můžeme libovolně rozšířit
            o dodatečné elementární jevy, které nemohou nastat (teplotu těla nebo tlak bereme od nuly...).
            Např. pro hod kostkou bychom mohli použít rozšířenou množinu {"$\\Omega = \\{1, 2, 3, 4, 5, 6, 7\\}$"}.
            Elementární jev 7 by ale nikdy nenastal, a měl by pravděpodobnost 0.
            Toto by nemělo vliv na střední hodnotu ani rozptyl rozdělení, ani na pravděpodobnosti jevů vyjádřených pomocí hodnot 1-6, atd.
            Je to "neškodné" rozšíření. V případě tělesné teploty je např. jasné, že teplota živého člověka nemůže být menší než
            cca 30 stupňů Celsia - kde ale leží hranice přesně? Můžeme vyloučit, že někdo bude mít teplotu 29.5 stupňů Celsia a bude živý?
            Lidské tělo výjimečně dokáže "nemožné", viz&nbsp;
            <a href={"https://en.wikipedia.org/wiki/Gu%C3%B0laugur_Fri%C3%B0%C3%BE%C3%B3rsson"} target="_blank" rel="noopener noreferrer" className="external-link">tento případ</a>.
            </p>

            <h2 id="eev-properties">Vlastnosti elementárních jevů</h2>

            <p>
            Množina {"$\\Omega$"} musí být úplná (kompletní) - musí obsahovat jako své prvky všechny možné elementární jevy,
            tj. všechny možné výsledky pokusu (popř. jejich matematické reprezentace).
            Např. {"$\\Omega = \\{1, 2, 3, 4, 5\\}$"} není prostor elementárních jevů pro hod kostkou, protože 6 je možný výsledek, ale není prvkem {"$\\Omega$"}.
            </p>

            <p>
            Elementární jevy se musí vzájemně vylučovat. Pokud nastane jeden elementární jev, pak nemůže současně nastat žádný jiný.
            Vždy musí nastat právě jeden elementární jev = právě jeden prvek {"$\\omega \\in \\Omega$"}.
            Pokud na kostce padlo číslo 3, tak to znamená že zároveň nepadlo číslo 1, 2, 4, 5, 6.
            Pokud jsme změřili hodnotu hmotnosti vzorku {"$0.250$"} kg, pak jsme zároveň nezměřili hodnotu {"$0.251$"} kg, a žádnou jinou hondotu.
            Pokud jsme se do terče trefili, tak se nemohlo stát, že bychom zároveň minuli. Pokud jsme minuli, tak se nemohlo stát, že bychom se zároveň trefili.
            </p>

            <p>
            Při pokusu tedy vždy <strong>nastoupí</strong> (<strong>nastane</strong>, <strong>realizuje se</strong>) právě
            jeden z elementárních jevů.
            </p>

            <p>Příklad:
            Máme 4 kelímky, dva jsou modré a dva zelené. Ve třech je káva, a v jednom je červené víno:
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={elem_ev_cups} style={{width: "45%"}}/>
            </div>

            <p>
            Uvažujeme pokus náhodného výběru kelímku (zavřeme oči, někdo nám kelímky na stole promíchá,
            my si pak opatrně jeden nahmatáme a vezmeme; otevřeme oči a zjistíme, který jsme vybrali).
            </p>
            <ul>
                <li><p>
                Pokud sledujeme jen vybraný nápoj, je {'\u03A9 = \u007B káva, víno \u007D'}.
                Kompletnost je splněna: Ať si vybereme kterýkoliv kelímek, vždy vybereme buď víno, nebo kávu.
                Disjunktnost je splněna: Když jsme vybrali víno, nevybrali jsme kávu; když jsme vybrali kávu, nevybrali jsme víno.
                </p></li>
                <li><p>
                Pokud sledujeme jen barvu kelímku, je {'\u03A9 = \u007B modrá, zelená \u007D'}.
                Kompletnost je splněna: Ať si vybereme kterýkoliv kelímek, vždy vybereme buď modrý, nebo zelený.
                Disjunktnost je splněna: Když jsme vybrali modrý kelímek, nevybrali jsme zelený; když jsme vybrali zelený, nevybrali jsme modrý.
                </p></li>

                <li><p>
                Množina {'\u03A9 = \u007B modrá, víno \u007D'} by nebyl prostor elementárních jevů.
                Pokud bychom si vybrali zelený kelímek s kávou, tak bychom měli výsledek, který není v množině {"$\\Omega$"}.
                </p></li>

                <li><p>
                Množina {'\u03A9 = \u007B zelená, káva \u007D'} by nebyl prostor elementárních jevů.
                Pokud bychom si vybrali zelený kelímek s kávou, tak by nastaly dva elementární jevy současně - to značí,
                že to nejsou elementární jevy.
                </p></li>

                <li><p>
                Pokud sledujeme obojí, je {'\u03A9 = \u007B (modrá, káva), (zelená, káva), (zelená, víno) \u007D'}.
                Kompletnost i disjunkntost je evidentně splněna.<br/>
                Můžeme i provést rozšíření na kartézský součin: <br/>
                {'\u03A9 = \u007B (modrá, káva), (modrá, víno), (zelená, káva), (zelená, víno) \u007D'}.
                Elementární jev (modrá, víno) sice nikdy nenastane, ale kompletnost ani disjunkntost to nenaruší.
                Kompletnost: Všechny možné výsledky jsou v množině {'\u03A9'}? Ano, (pořád) jsou tam. (A navíc je tam i jeden nemožný.)
                Disjunktnost: Když nastane jeden z elementární jevů, nemůže současně nastat žádný jiný - ano, to pořád platí.
                (<i>"Pokud nastane jev (modrá, víno)"</i> ... tato premisa implikace je vždy nepravdivá, takže implikace je pravdivá.)
                </p></li>

            </ul>

            <h2 id="eev-coarseness-and-fineness">Hrubost a jemnost elementárních jevů</h2>

            <p>
            Pokud si u biatlonistů budeme zaznamenávat výsledek střelby do terče v jednom kole, dostaneme záznamy typu (0, 1, 1, 0, 1),
            kde 0 značí minutí terče a 1 značí zásah.
            {`$$
                \\Omega = \\Big\\{(x_1, x_2, x_3, x_4, x_5) \\mid x_i \\in \\{0, 1\\}\\Big\\}.
            $$`}
            Tento způsob zaznamenávání je "jemný" - zachováváme si celou informaci o průběhu střelby, včetně pořadí.
            </p>

            <p>
            Mohli bychom si ale zaznamenávat jen celkový počet zásahů v kole.
            Tento způsob zaznamenávání je "hrubší" - ztrácíme informaci o průběhu. Už například nemůžeme udělat analýzu, jestli
            se střelci v průběhu střelby zlepšují, nebo zhoršují. Zde máme
            {`$$
                \\Omega = \\{0, 1, 2, 3, 4, 5\\}.
            $$`}
            Tato {"$\\Omega$"} splňuje podmínky elementárních jevů - je kompletní a nové elem. jevy jsou disjunktní.
            Kompletnost: Buď se střelec netrefil ani jednou, nebo jednou, dvakrát ... a nejvýše pětkrát.
            Disjunktnost: Když se střelec trefil třikrát, tak se nemohl trefit dvakrát, nebo čtyřikrát, atd.
            </p>

            <p>Od hrubšího popisu se zpětně k jemnějšímu nedostaneme (ztráta informace).
            Od jemnějšího popisu se k hrubšímu dostaneme <i>rozkladem</i> (particí) množiny {"$\\Omega$"}:
            Elementární jevy (prvky {"$\\Omega$"}) podle nějakého kritéria rozdělíme na skupiny, a to tak, že
            každý prvek patří právě do jedné ze skupin. Skupiny se nepřekrývají (jsou disjunktní), a úplně pokrývají množinu {"$\\Omega$"}.
            Potom každá skupina jako taková je nový elementární jev v novém (hrubším) prostoru elementárních jevů {"$\\Omega'$"}.
            Princip je ilustrován na obrázku níže, včetně toho, co by se stalo, kdyby se skupiny překrývaly, nebo kdyby některé elementární jevy nebyly
            v žádné skupině. V těchto případech konstrukce nefunguje, nedostaneme nový prostor elementárních jevů.
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={elem_ev_partition1} style={{width: "50%"}}/>
            </div>

            <p>V příkladě s kelímky jsme možné scénáře již analyzovali:</p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={elem_ev_partition_cups} style={{width: "50%"}}/>
            </div>

            <p>
            U biatlonistů každému elem. jevu {"$(x_1, x_2, x_3, x_4, x_5)$"} přiřadíme součet jeho
            složek: {"$x_1 + x_2 + x_3 + x_4 + x_5$"}.
            Množinu elem. jevů potom rozložíme na skupiny podle hodnoty součtu: skupina prvků, které mají součet 0,
            skupina se součtem 1... až skupina se součtem 5. Je zřejmé, že je to rozklad:
            Každý prvek má nějaký součet, a tento oučet je jednoznačný = každý prvek patří právě do jedné skupiny.
            </p>

            <p><i>
            Další text diskutuje roli náhodnosti a použití pravděpodbnosti
            ve fyzikálních měřeních a v modelech náhodných pokusů.
            </i></p>

            <h2 id="eev-randomness-in-experiment">Náhodnost v pokusech</h2>

            <p>
            Ne všechna měření jsou náhodná. Pokud např. měříme počet studentů, kteří přišli na danou konkrétní přednášku
            (v daný den, hodinu, do dané učebny) - a to tak, že je spočítáme - tak i když toto měření budeme opakovat,
            měl by nám součet vyjít pokaždé stejně - leda bychom udělali hrubou chybu a spletli se. Pokud je v místnosti
            30 studentů, potom by nám deterministicky měl vyjít součet 30 při každém opakovaném měření (sčítání).
            (Teď neuvažujeme pozdní příchody.)
            </p>

            <p>Měření, která vykazují náhodnost, ji mohou vykazovat z různých důvodů:</p>
            <ul>
                <li><p>Vestavěná náhodnost v přírodních zákonech: kvantová fyzika.</p></li>
                <li><p>
                Souběžný vliv řady faktorů: Např. při měření nějaké veličiny - fluktuace atomosférického tlaku,
                nárazy molekul do vzorku, vibrace podlahy, fluktuace gravitačního pole, atd. Nebo při hodu kostkou
                - vzdálenost ruky od podložky, na kterou hážeme, poloha kostky v ruce, vektor rychlosti udělený kostce,
                rotace udělená kostce...
                </p></li>
                <li><p>Měříme náhodně vybraný prvek z nějaké populace / z nějaké množiny prvků. Každý prvek může mít jinou
                hodnotu atributu, který měříme. Tím, že jsme prvek vybrali náhodně, výsledek měření působí náhodně.
                Jakým mechanismem byl prvek vybrán?: Náhodnost výběru je realizovaná souběžným vlivem řady faktorů - viz bod výše.
                </p>

                <p><i>
                Pokud provádíme pokus s náhodným výběrem z množiny prvků, potom náhodnost výběru realizujeme "zamícháním"
                prvků - při zamíchání se aplikuje velké množství vlivů, které ve výsledku vedou k určitému
                náhodnému uspořádání prvků. Z nich potom klidně můžeme vybrat první - nebo libovolný. Konrétní představa
                je: Zamíchání balíčku karet, zamíchání koulí v osudí, lístků v kloubouku/pytlíku, atd.

                Pokud prvky samotné (fyzicky) nejdou zamíchat, pak zamícháme jejich reprezentace.
                Např. pro náhodný výběr lokality v rámci města promícháme v paměti počítače seznam všech adres v daném městě.
                </i></p>
                </li>
            </ul>



            <p>Podívejme se na pokusy z toho pohledu, jestli v nich náhodnost chceme nebo nechceme:</p>
            <ul>
                <li><p>
                Pokusy/měření s kvantovými jevy: Náhodnost tam je, ať už chceme, nebo nechceme - je to součást přírodních zákonů.
                </p></li>
                <li><p>
                Měření makroskopických fyzikálních veličin (hmotnost, délka, grav. zrychlení, teplota...):
                Náhodnost nechceme, popř. ji chceme minimalizovat = chceme co nejpřesnější měření.
                Superponovaný (sečtený) vliv celé řady mikrofaktorů tam ale stále bude přítomen
                - proto mj. běžně náhodnou odchylku měření od skutečné hodnoty očekáváme normálně rozdělenou (viz centrální limitní věta) -
                takže náhodnosti se v principu nezbavíme.
                </p></li>
                <li><p>
                Náhodný výběr: Chceme si udělat představu o nějaké populaci, která je ale tak velká, že nejsme schopní prozkoumat a popsat
                každého jedince. Potom potřebujeme vybrat jednoho nebo více jedinců z této populace. Abychom
                vyloučili systematické chyby, chceme provést náhodný výběr. Zde tedy náhodnost chceme - v podobě nějakého
                mechanismu (promíchání, losování, hod kostkou, atp.) - který nám umožní provést výběr náhodně.
                </p></li>
                <li>
                <p>
                Makroskopický popis reality: Je-li daná populace (lidí, částic, scénářů, ...) tak velká, že nemůžeme reprezentovat
                každého jedince, pořád ji ještě můžeme popsat statisticky. Zde se náhodnost/pravděpodobnost objevuje
                jako princip v konstrukci našeho modelu, který pro popis reality používáme.
                </p>
                <p>
                Ve statistické fyzice nemůžeme znát přesně polohu a rychlost každé
                částice plynu, ale jsme schopni soubor částic popsat statisticky - makroskopicky: Dokážeme odvodit rozdělení
                rychlostí částic (Maxwell–Boltzmann), což je v podstatě rozdělení pravděpodobnosti, že náhodně vybraná částice bude
                mít danou rychlost. (Výběr takové částice a změření její rychlosti je potom pokusem v rámci našeho pravděpodbnostního modelu.)
                </p>
                <p>
                Časové rozestupy mezi průjezdy vozidel daným místem (pokud není zácpa) lze užitečně popsat exponenciálním rozdělením.
                (Změření času do příjezdu dalšího konkrétního auta je potom pokusem v rámci našeho pravděpodbnostního modelu.)
                </p>
                <p>
                Dynamické systémy, které se řídí soubory diferenciálních rovnic, vykazují různou citlivost
                na počáteční podmínky a na svoje parametry. U některých systémů řešením diferenciálních rovnic dokážeme
                spolehlivě simulovat/předpovídat jejich vývoj. Pokud je ale systém extrémně citlivý, vnímáme to tak, že se chová chaoticky.
                Řešení diferenciálních rovnic nemusí být užitečné ... miniaturní změna hodnot parametrů vede k úplně jinému řešení, a my
                samozřejmě vstupy dokážeme změřit a zadat jen s konečnou přesností - takže se na výsledek nemůžeme spolehnout.
                Takto se v principu chová například počasí. Zde nezbývá než chování systému zkusit popsat statisticky.
                </p>
                </li>
            </ul>

            <p>
            Když teď dáme stranou aplikace v kvantové fyzice,
            tak si v příkladech výše můžete všimnout, že se náhodnost v pokusu může vyskytnout ve dvou "rovinách"
            - jako náhodná chyba měření, a jako hodnota měřené charakteristiky náhodně vybraného prvku.
            </p>

            <p>
            Při opakovaném měření nějaké fyzikální veličiny na jednom konrétním vzorku primárně řešíme
            náhodnou chybu měření - měříme vícekrát, abychom ji zmenšili (tím, že měření zprůměrujeme)
            a odhadli (uděláme intervalový odhad).
            </p>

            <p>
            Pokud naopak budeme měřit energii náhodně zachycených (vybraných) částic z nějakého objemu plynu,
            různé částice budou mít různou energii - to očekáváme. Na základě naměřených hodnot si uděláme představu
            o rozdělení energií částic v plynu. Každou jednotlivou částici ale měříme jen jednou. Neřešíme zde tedy
            chybu jednotlivých měření.
            (Toto je hypotetický příklad - za předpokladu, že bychom byli schopni částice zachycovat individuálně.)
            </p>

            <p>
            Podobně, v příkladě s náhodným intervalem mezi průjezdy aut - když změříme délku intervalu mezi dvěma průjezdy,
            můžeme tam vidět sečtené dvě náhodnosti: 1) ihnerentní náhodnost délky intervalu, 2) náhodnou chybu měření.
            Změřené délky intervalů v sekundách jsou např. (10,1 ; 2,0 ; 5,2 ; 6,8 ; 5,3). Kdyby naše stopky byly přesnější,
            byly bychom změřili třeba (10,15 ; 1,90 ; 5,30 ; 6,75 ; 5,30) - toto by bylo přesnější měření, s menší chybou
            (ale délky intervalů by byly stále stejně náhodné).
            </p>

            <p>
            Chyba měření se naopak nevyskytuje u celočíselných veličin. Pokud budeme zjišťovat počet
            dětí náhodně vybrané rodiny v populaci, pak zjištěné číslo (0, 1, 2, 3, ...) bude přesné.
            </p>

            <p>
            Běžně řešíme náhodnost buď v jedné rovině, anebo v druhé - ale ne v obou
            současně. <strong>Matematický aparát pravděpodobnosti a statistiky je jen jeden, a je pořád stejný</strong> - jen ho
            pokaždé používáme pro jiný účel. Jednou pro model a odhad chyby měření, jindy pro makroskopický popis
            (pomocí rozdělení pravděpodobnosti) nějakého systému, populace, nebo fenoménu.
            </p>

            <h2 id="eev-measurement-err-as-sampling">Chyba měření interpretovaná jako výběr z populace</h2>

            <p><i>(Navazujeme přímo na text výše.)</i> Při troše mentální gymnastiky
            lze jeden případ použití převést na druhý, a ukázat, že je to vlastně
            jedno a totéž. Autor netuší, jak moc užitečné tohle může být, přesto se o to nyní pokusí.
            </p>

            <p>
            Mějme přístroj, kterým chceme velmi přesně změřit hmotnost nějakého vzorku. Chystáme se provést několik opakovaných měření.
            Přístroj je naprogramován tak, aby tuto sekvenci měření provedl sám, např. 10 měření s odstupem 5 sekund mezi měřeními.
            </p>
            <p>
            Pro náš myšlenkový experiment si představme, že k přístroji přijde dítě, které netuší o co jde - přístroj svýma očima
            vidí/vnímá jako "black box", který postupně zobrazí 10 hodnot. A tyto hodnoty se pokaždé trošku liší.
            </p>
            <p>
            U přístroje stojí mudrc, a dítě se ho zeptá: "Co jsou to za čísla? Co tenhle přístroj měří?"
            A mudrc (protože je moudrý, a vidí celý svět z nadhledu a v souvislostech) odpoví:
            </p>
            <p>
            "Tohle je vpravdě magický přístroj. V každém okamžiku bere v potaz stav celého vesmíru - vnímá tu věc, která je na
            něm položená, a vnímá i otřesy podlahy pod nohama, které jsou způsobené pohybem lidí v budově, pohybem aut na silnici, která
            vede za budovou, i pohybem kontinentů na planetě. Vnímá i to, že se Země zrovna přibližuje ke Slunci, a že s celou galaxií rotuje;
            vnímá stav každého atomu a každé molekuly ve vesmíru. A pak tohle všechno přesně změří a zpracuje, a výsledek vyjádří jediným
            číslem na displeji."
            </p>
            <p>
            Dítě je ohromeno, a pak se zeptá: "Ale když ve vesmíru je tolik věcí, které se pořád mění, jaktože se to číslo na displeji mění jen trošku?"
            A mudrc odpoví: "Zrovna na tento přístroj má největší vliv hmotnost té věci co je na něm položená.
            Všechny ostatní věci mají taky svůj vliv, ale je maličký."
            </p>
            <p>
            Náš model reality je tedy takový, že: Vesmír jako celek je v každém okamžiku v jiné konfiguraci (stavu).
            Teoreticky můžeme uvažovat množinu (populaci) všech těchto konfigurací (která je gigantická).
            Přístroj provede měření aktuální konfigurace, a vypíše výsledek. Možné výsledky jsou v principu od {"$-\\infty$"} do {"$+\\infty$"}
            : naše teoretická populace možných výsledků je populace všech reálných čísel.
            Při daném měření zrovna nastává určitá konfigurace vesmíru (příroda ji nějak vybrala), a tím nastává (je vybrán)
            i konkrétní (pro danou konfiguraci pevně* daný) prvek z populace výsledků měření (prvek {"$\\mathbb{R}$"}).<br/>
            <i><span style={{width: 10}}>{`    `}</span> (*) pevně daný až "do kvantové přesnosti."</i>
            </p>
            <p>
            Ne každý prvek této populace je přírodou vybírán stejně často.
            Všimli jsme si, že různé prvky jsou vybírány častěji než jiné, zhruba podle této křivky:
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={elem_ev_normal_curve} style={{width: "50%"}}/>
            </div>

            <p>
            Pokud tedy provedeme několik měření, provedeme několik výběrů z této populace.
            </p>
            <p>
            Přístroj měří naprosto přesně, ale něco jiného než hmotnost vzorku. Měří stav vesmíru v daném okamžiku (do nějž se samozřejmě
            započítá i v nitřní stav přístroje samotného, jeho okolí atd.). Konstrukce přístroje je ale taková, že je nejcitlivější
            (nejvíce reaguje) na hmotnost vzorku, a velmi málo citlivý na všechny ostatní vlivy. V ideálním případě by co největší část
            změřené hodnoty (např. 99.9999%) byla ovlivněna/dána hmotností vzorku, a zbylé vlivy by se odrazily jen v miniaturní části
            (např. 0.0001%) změřené hodnoty. To ale nejde splnit vždy - i když je přístroj na jiné vlivy výrazně méně citlivý, pokud
            je vliv v dané konfiguraci vesmíru dostatečně silný (např. nastane zemětřesení), může tvořit i větší část změřené hodnoty.
            </p>
            <p>
            My chceme ze změřených hodnot vyextrahovat tu složku, která odpovídá hmotnosti vzorku.
            Striktně vzato ale v principu nevíme, kolik z hodnoty je tato složka, a kolik jsou ostatní vlivy.
            Prakticky to uděláme tak, že použijeme statistiku, a ze získaných hodnot uděláme intervalový odhad s určitou úrovní spolehlivosti.
            Teoreticky je složka odpovídající hmotnosti rovna střední hodnotě rozdělení pravděpodbnosti naší populace měření.
            </p>
            <p>
            Pokud si situaci přeformulujeme tak, že "přístroj má měřit hmotnost vzorku" (ale jak víme, realita je jiná - přístroj měří něco trochu jiného, komplexnějšího),
            pak můžeme začít mluvit o chybě měření (odchylka změřené hodnoty od skutečné /ale neznámé/ hmotnosti vzorku).
            </p>
            <p>Matematický model pro tento koncept pak zapíšeme takto:
            {`$$
                \\text{změřená hodnota} = \\text{skutečná hmotnost vzorku} + \\text{chyba měření},
            $$`}
            a chyba měření je náhodná veličina se střední hodnotou 0 a nějakým rozdělením pravděpodbnosti, typicky normálním N(0, {"$\\sigma^2$"}).
            </p>

            <br/>

            <p>Pozorný čtenář by mohl namítnout, že v případě zemětřesení samozřejmě dané měření vyloučíme, protože řekneme, že
            "měření neprobíhá za stejných podmínek", což s radostí přeformulujeme tak, že vlastně "neprovádíme stejný pokus".
            </p>
            <p>
            V praxi samozřejmě eliminujeme různé vlivy tím, že opakované měření provádíme "za stejných podmínek" - např. za stejného
            atmosferického tlaku, nebo ve vakuu, při stejné teplotě měřicího přístroje, za podmínky, že seismometr nevykazuje
            zvýšenou aktivitu, za podmínky že neprobíhá sluneční bouře, atd.
            </p>
            <p>
            Co to ale znamená reálně? Že jsme možné vlivy na měření rozdělili do dvou skupin:<br/>
            1) vlivy, které jsme schopni měřit, identifikovat a eliminovat, a<br/>
            2) všechny ostatní (kterých je pořád nekonečně mnoho).<br/>
            </p>
            <p>
            Vlivy, které zůstaly v druhé skupině, se pořád chovají v principu tak, jak jsme si popsali.
            Jediné, co se změnilo, je, že rozdělení pravděpodobnosti populace výsledků měření má nyní menší rozptyl
            (zbývající vlivy jsou typicky méně silné). To je samozřejmě dobrá věc (při jiné formulaci bychom řekli, že jsme zvýšili přesnost měření).
            V principu ale každý ze zbývajících vlivů může ve vzácném případě být dostatečně silný, aby výrazně ovlivnil měření.
            </p>
            <p>
            Kdybychom (hypoteticky) eliminovali úplně všechny vlivy, zůstaly by jen kvantové jevy.
            </p>

            <p>
            Otázka, zda "provádíme stejný pokus" nebo ne, je relativní záležitost: záleží, na jak přesné úrovni chceme odpovědět.
            Pokud chceme odpovědět <i>absolutně</i> přesně, pak odpověď je vždy "ne" - protože vesmír jako celek není nikdy ve stejné konfiguraci.
            Pokud nakreslíme hranici mezi zkoumaným systémem a zbytkem vesmíru - a to jak fyzickou, tak v množině vlivů, které chceme/nechceme kontrolovat -
            pak pokud systém je ve stejné konfiguraci (do které započítáváme jen vlivy, které kontrolujeme - např. tlak, teplota...),
            pak můžeme říct, že "ano". Co je opakováním stejného pokusu z pohledu jedné aplikace, nemusí být opakováním stejného pokusu z pohledu jiné aplikace
            (kde musíme brát v potaz více vlivů, nebo je kontrolovat s větší přesností).
            </p>

            <p>
            V případě měření může být relativní i otázka, jestli (nebo v jakém smyslu) měříme "stejný" vzorek.
            Pokud opakovaně měříme vodivost měděného vodiče délky 1 metr, tak je nám jedno, jestli mezi měřeními odpaří nějaké atomy do okolí.
            Chápeme to tak, že je to pořád stejný vzorek.
            Pokud bychom ale měřili vodivost vrstvy nanočipu, a mezi měřeními by tato odpařila několik atomů, tak by to asi mělo na změřenou hodnotu vliv
            - nově změřená hodnota by nereprezentovala vlastnosti čipu v "normálním" stavu - a tedy bychom nemohli říct, že jsme prováděli
            stejný pokus - neprováděli jsme měření na typově/kvalitativně stejném vzorku.
            </p>

            <p>

            </p>

        </div>
        </div>
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

