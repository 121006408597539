import React from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';


export const DescriptiveStatisticsMeta = {
    title: "Popisná statistika",
    shortTitle: "Popisná statistika",
    path: Path.descriptive_statistics_intro,
    element: (sectionNumber:string) => <DescriptiveStatistics sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

export function DescriptiveStatistics({sectionNumber}: {sectionNumber: string}) {
    return (
        <MathJaxWrapper>
        <MathJax>
        <div className="chapter-container">
        <div className="centered-content">
        <div className="card">

            <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{DescriptiveStatisticsMeta.title}</h1>

            <p>
            Popisná statistika je statistika na datech.
            Různé zobrazovací techniky (např. histogram) a výpočetní vzorce (např. vzorec pro průměr a rozptyl)
            nám umožňují udělat si představu o povaze dat.
            Na rozdíl od matematické statistiky (inference) zde ještě není přesah v tom smyslu, že
            bychom za daty viděli nějaký pravděpodobnostní model, testovali hypotézy nebo prováděli intervalové odhady.

            Koncepty popisné statistiky se ale ve stejné podobě objevují později i v pravděpodobnosti a inferenci -
            proto jsou ideální náplní na úplný začátek kurzu.
            </p>

        </div>
        </div>
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

