import React, { useEffect, useRef, useState } from 'react';

import { MathJax } from 'better-react-mathjax';

import { Stats } from 'janstatistics';

import { Path } from '~/paths';
import { ScatterplotSD, ScatterplotSDRegression } from '~/applets/ScatterPlot';
import { MathJaxWrapper } from "../MathJaxWrapper";
import { DataGenerator } from '~/applets/DataGenerator';

import { TOC, TOCProps, defineTOCElements } from '~/TOC';


export const RegressionBasicsMeta = {
    title: "Základy regrese",
    shortTitle: "Základy regrese",
    path: Path.regression_basics,
    element: (sectionNumber:string) => <RegressionBasics sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}


const defaultPoints = [
    { x: 10, y: 20 }, { x: 20, y: 40 }, { x: 30, y: 60 }, { x: 40, y: 80 }, { x: 45, y: 33 },
    // Add more data points here
  ];


const TOCSpec = [
    "regbasiscs-sd-line",
    "regression-reg-line",
    "regression-reg-line-summary",
];


export function RegressionBasics({sectionNumber}: {sectionNumber: string}) {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    const [points, setPoints] = useState(defaultPoints);
    const [sameScale, setSameScale] = useState(true);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);

    return (
        <MathJaxWrapper>
        <MathJax>
        <div className="chapter-container">
        <div className="centered-content">
        <div className="card" ref={chapterRef}>

            <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{RegressionBasicsMeta.title}</h1>

            <h2 id="regbasiscs-sd-line">SD přímka</h2>
            <p>Než se pustíme do samotné regrese, uvedeme si takzavanou <strong>SD přímku</strong> (SD line). Tento koncept
                není úplně rozšířený, ale autoři Stark a Freedman jej používají a není úplně bez užitku; viz také&nbsp;
                <a href="https://en.wikipedia.org/wiki/Standard_deviation_line" target="_blank" rel="noopener noreferrer" className="external-link">Wikipedie</a>.
            </p>

            <p>
            SD přímka je definovaná tak, že:
            </p>

            <ul>
                <li>Prochází bodem průměrů {"$(\\bar{x}, \\bar{y})$"}</li>
                <li>Prochází bodem {"$(\\bar{x} + \\sigma_x, \\bar{y} + \\sigma_y)$"} </li>
            </ul>

            <p>
            SD přímka má tedy směrnici {"$a_{\\mathrm{SD}} = \\frac{\\sigma_y}{\\sigma_x}$"}, a prochází body
            {`$$
                \\cdots (\\bar{x} - 2 \\sigma_x, \\bar{y} - 2 \\sigma_y), (\\bar{x} -  \\sigma_x, \\bar{y} -  \\sigma_y), (\\bar{x} , \\bar{y}), (\\bar{x} + \\sigma_x, \\bar{y} + \\sigma_y), (\\bar{x} + 2 \\sigma_x, \\bar{y} + 2 \\sigma_y) \\cdots
            $$`}
            z-skóre těchto bodů je:
            {`$$
                \\cdots (-2, -2), (-1, -1), (0, 0), (1, 1), (2, 2) \\cdots
            $$`}
            </p>

            <p>Pokud bychom data normalizovali na z-skóre, potom by SD přímka procházela bodem {"$(0, 0)$"} a její směrnice by byla rovna 1, tj. byla by to osa 1. a 3. kvadrantu.</p>


            <div style={{width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <ScatterplotSD data={points} sameScale={sameScale}/>
                <DataGenerator onDataGenerated={(data) => setPoints(data.map(d => { return {x: d[0], y: d[1]}; }))}/>
            </div>
            <div>
                <label htmlFor="subscribeCheckbox">Stejné měřítko na osách</label>
                <input
                    type="checkbox"
                    id="subscribeCheckbox"
                    checked={sameScale}
                    onChange={(e) => setSameScale(e.target.checked)}
                />
            </div>


            <h2 id="regression-reg-line">Regresní přímka</h2>

            <p>

            Naměřené hodnoty jsou body {"$(x_i, y_i)$"}.
            Až jimi proložíme regresní přímku {"$y = ax + b$"},
            tato bude mít pro {"$x_i$"} hodnotu {"$y = ax_i + b$"},
            která se ne nutně trefí přesně do {"$y_i$"}.
            Ideální by bylo, aby rozdíly {"$(ax_i + b) - y_i$"} byly co nejmenší.

            Toto se tradičně zapíše jako minilaziační úloha:

            {"$$\\min_{a, b} \\sum_{i=1}^n (ax_i + b - y_i)^2.$$"}
            Minimalizujeme součet čtverců odchylek.
            Funkce v pravo je funkce dvou proměnných {"$a$"} a {"$b$"}; všechny
            ostatní hodnoty {"$x_i$"} a {"$y_i$"} jsou konstanty.
            Jedná se o konvexní funkci, kterou lze derivovat, takže
            existuje globální minimum které najdeme derivováním.
            </p>

            <table style={{fontSize: 12, width: "100%"}}>
                <thead>
                    <tr style={{textAlign: "center"}}>
                        <th>Derivace podle {"$a$"}</th>
                        <th>Derivace podle {"$b$"}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{paddingLeft: 10, paddingRight: 30}}>
                        {`$$\\begin{alignat*}{4}
                            & \\frac{\\partial}{\\partial a} \\sum_{i=1}^n (ax_i && + b && - y_i)^2 & = 0 \\\\[4pt]
                            & 2\\sum_{i=1}^n x_i(ax_i && + b && - y_i) & = 0 \\\\[4pt]
                            & a \\sum_{i=1}^n x_i^2 && + b \\sum_{i=1}^n x_i  && - \\sum_{i=1}^n x_i y_i & = 0 \\\\[4pt]
                            & a \\frac{1}{n} \\sum x_i^2 && + b \\frac{1}{n} \\sum x_i  && - \\frac{1}{n} \\sum x_i y_i & = 0 \\\\[30pt]
                            & a \\frac{1}{n} \\sum x_i^2 && + b \\bar{x} && - \\frac{1}{n} \\sum x_i y_i & = 0 \\\\
                        \\end{alignat*}$$`}
                        </td>

                        <td style={{paddingLeft: 30, paddingRight: 10}}>
                        {`$$\\begin{alignat*}{4}
                            & \\frac{\\partial}{\\partial b} \\sum_{i=1}^n (ax_i && + b && - y_i)^2 & = 0 \\\\[4pt]
                            & 2\\sum_{i=1}^n (ax_i && + b && - y_i) & = 0 \\\\[4pt]
                            & a \\sum_{i=1}^n x_i && + n b && - \\sum_{i=1}^n y_i & = 0 \\\\[4pt]
                            & a \\frac{1}{n} \\sum x_i && + b  && - \\frac{1}{n} \\sum y_i & = 0  \\\\[10pt]
                            & a \\bar{x} && + b  && - \\bar{y} & = 0  \\\\[10pt]
                            & a \\bar{x}^2 && + b \\bar{x} && - \\bar{x} \\bar{y} & = 0
                        \\end{alignat*}$$`}
                        </td>
                    </tr>
                </tbody>
            </table>
            <p>

            {`$$\\begin{align*}
                a  \\frac{1}{n} \\sum x_i^2  - a\\bar{x}^2  -  \\frac{1}{n} \\sum x_i y_i + \\bar{x} \\bar{y} & = 0 \\\\
              \\end{align*}
            $$`}


            {`$$\\begin{align*}
                a  =   \\frac{\\frac{1}{n} \\sum x_i y_i -  \\bar{x} \\bar{y}}{\\frac{1}{n} \\sum x_i^2  - \\bar{x}^2} \\\\
              \\end{align*}
            $$`}

            {`$$\\begin{align*}
                a  = \\frac{\\frac{1}{n} \\sum x_i y_i -  \\bar{x} \\bar{y}}{ \\sigma_x^2} = \\frac{\\frac{1}{n} \\sum x_i y_i -  \\bar{x} \\bar{y}}{ \\sigma_x \\sigma_y} \\cdot \\frac{\\sigma_y}{\\sigma_x} = r \\cdot \\frac{\\sigma_y}{\\sigma_x} \\\\
              \\end{align*}
            $$`}


            Směrnice SD přímky (SD line) je {"$a_{\\mathrm{SD}} = \\frac{\\sigma_y}{\\sigma_x}$"}, a tedy můžeme psát
            {`$$
                a =  r \\cdot \\frac{\\sigma_y}{\\sigma_x} = r \\cdot a_{\\mathrm{SD}}.
            $$`}

            </p>

            <p>
            Tento vzorec ukazuje vztah mezi směrnicí {"$a$"} regresní přímky a směrnicí SD přímky {"$a_{\\mathrm{SD}}$"}.
            Protože {"$r \\in \\langle -1, 1 \\rangle$"}, je směrnice regresní přímky vždy menší než směrnice SD přímky, a platí {"$a \\in \\langle -a_{\\mathrm{SD}}, a_{\\mathrm{SD}} \\rangle .$"}
            </p>

            <div style={{width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <ScatterplotSDRegression data={points} sameScale={sameScale}/>
                <DataGenerator onDataGenerated={(data) => setPoints(data.map(d => { return {x: d[0], y: d[1]}; }))}/>
                <div>
                    <label htmlFor="subscribeCheckbox">Stejné měřítko na osách</label>
                    <input
                        type="checkbox"
                        id="subscribeCheckbox"
                        checked={sameScale}
                        onChange={(e) => setSameScale(e.target.checked)}
                    />
                </div>
            </div>

            <p>
            <span style={{color: 'green'}}>Zeleně čárkovaně</span> je <span style={{color: 'green'}}>SD přímka</span> (rostoucí, se směrnicí {"$\\frac{\\sigma_y}{\\sigma_x}$"}),
            a <span style={{color: 'green'}}>'ozrcadlená' SD přímka</span> (klesající, se směrnicí {"$-\\frac{\\sigma_y}{\\sigma_x}$"}).&nbsp;
            <span style={{color: 'magenta'}}>Fialově</span> je <span style={{color: 'magenta'}}>regresní přímka</span>. Musí procházet oblastí vymezenou shora SD přímkou
            a zdola 'ozrcadlenou' SD přímkou - tj. mezi zelenými čárkovanými čarami.
            </p>

            <p>
            Z rovnice {"$a \\bar{x} + b - \\bar{y} = 0$"} vidíme, že:
            <ul>
                <li>
                regresní přímka prochází bodem průměrů {"$(\\bar{x}, \\bar{y})$"}:
                {`$$
                    \\bar{y} = a \\bar{x} + b,
                $$`}
                </li>
                <li>
                absolutní člen {"$b$"} se určí jako:
                {`$$
                    b = \\bar{y} - a \\bar{x}.
                $$`}
                </li>
            </ul>
            </p>

            <h2 id="regression-reg-line-summary">Regresní přímka - shrnutí</h2>

            <p>
            Regresní přímka má rovnici {"$y = ax + b$"}, kde:
            {`$$\\begin{align*}
                a  & = \\frac{\\frac{1}{n} \\sum x_i y_i -  \\bar{x} \\bar{y}}{ \\sigma_x^2} = r \\cdot \\frac{\\sigma_y}{\\sigma_x}, \\\\
                b & = \\bar{y} - a \\bar{x}.
            \\end{align*}$$`}
            </p>

        </div>
        </div>
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

// & a \\frac{1}{n^2} \\left(\\sum x_i\\right)^2 && + b \\frac{1}{n} \\sum x_i && - \\frac{1}{n^2} \\sum y_i \\sum x_i& = 0  \\\\[10pt]
