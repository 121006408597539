import React, { useEffect, useRef, useState } from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import { TOC, TOCProps, defineTOCElements } from '~/TOC';


export const ConceptComparisonEMeta = {
    title: "Srovnání konceptů (E)",
    shortTitle: "Srovnání konceptů (E)",
    path: Path.concept_comparison_e,
    element: (sectionNumber:string) => <ConceptComparisonE sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}


const TOCSpec = [
    "compe-distribution-mean",
    "compe-random-variable-expectation",
    "compe-dataset-mean",
    "compe-sample-mean",
];

export function ConceptComparisonE({sectionNumber}: {sectionNumber: string}) {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);


    return (
        <MathJaxWrapper>
            <MathJax>
            <div className="chapter-container">
            <div className="centered-content">
            <div className="card" ref={chapterRef}>

            <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{ConceptComparisonEMeta.title}</h1>

            <p>Cílem této sekce je porovnat bok po boku podobné koncepty a vysvětlit, jak spolu souvisí, a čím se liší.</p>

            <table className='data-table'>
                <thead>
                    <tr>
                        <th>pojem</th>
                        <th>značení</th>
                        <th>popis</th>
                        <th>výpočet</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>průměr datového souboru</td>
                        <td>{"$\\bar{x}$"}</td>
                        <td><span style={{color: "navy"}}>číslo</span></td>
                        <td>
                            nerozdtříděný: {"$\\frac{1}{n}\\sum_i x_i$"}<br/><br/>
                            roztříděný: {"$\\sum_i x_i f_i$"}, kde {"$f_i$"} jsou relativní četnosti
                        </td>
                    </tr>
                    <tr>
                        <td>střední hodnota rozdělení</td>
                        <td>{"$\\mu$"}</td>
                        <td><span style={{color: "navy"}}>číslo</span> - statická charakteristika rozdělení</td>
                        <td>{"$\\int x f(x) \\mathrm{d}x; \\quad \\sum_{i} x_i p(x_i)$"}</td>
                    </tr>
                    <tr>
                        <td>střední hodnota náhodné veličiny</td>
                        <td>{"$E(X)$"}</td>
                        <td><span style={{color: "navy"}}>číslo</span> - statická charakteristika rozdělení náhodné veličiny</td>
                        <td>{"$\\int x f(x) \\mathrm{d}x; \\quad \\sum_{i} x_i p(x_i)$"}</td>
                    </tr>
                    <tr>
                        <td>výběrový průměr</td>
                        <td>{"$\\bar{X}$"}</td>
                        <td><span style={{color: "red"}}>náhodná veličina</span> - funkce která každému možnému výsledku náhodného výběru přiřazuje jeho průměr</td>
                        <td>{"$\\frac{1}{n}\\sum_i X_i$"}</td>
                    </tr>
                    <tr>
                        <td>střední hodnota výběrového průměru</td>
                        <td>{"$E(\\bar{X})$"}</td>
                        <td><span style={{color: "navy"}}>číslo</span> - střední hodnota výběrového průměru jakožto náhodné veličiny - je stejná jako střední hodnota původního rozdělení</td>
                        <td>{"$=E(X)$"}</td>
                    </tr>
                </tbody>
            </table>
            <p>
            Pozn.: Všimněte sí, že {"$E(X)$"} se od {"$\\mu$"} liší jen tím, že říkáme, že rozdělení, jehož střední hodnotu počítáme, je konrétně rozdělení náhodné veličiny {"$X$"}.
            Je to tedy jen "objasnění původu" daného rozdělení - výpočet je stejný (je to prostě rozdělení pravděpodobnosti jako každé jiné).
            </p>

            <h3 id="compe-distribution-mean">Střední hodnota rozdělení</h3>
            <p>
            Máme rozdělení pravděpodobnosti dané buď hustotou {"$f(x)$"} (ve spojitém případě), nebo pravděpodobnostní funkcí {"$p(x)$"} (v diskrétním případě).
            Střední hodnota rozdělení je definována jako
            {`$$
                \\mu = \\int x f(x) \\mathrm{d}x, \\qquad  \\mu = \\sum_{x} x p(x) = \\sum_{i} x_i p(x_i)
            $$`}
            Je to číslo, které charakterizuje rozdělení jako celek - fyzikálně je to jeho těžiště.<br/>
            Pozn: V diskrétním případě je suma {"$\\sum_{x} x p(x)$"} vždy přes nejvýše spočetnou množinu hodnot {"$x$"} - to nám dovoluje si je očíslovat indexem {"$i$"} a zapsat v podobě {"$\\sum_{i} x_i p(x_i)$"}.
            </p>

            <p>
            Některá rozdělení pravděpodobnosti se vyskytují tak často, že si zasloužila vlastní název a velkou péči ve výzkumu.
            Příkladem je binomické rozdělení, normální rozdělení, exponenciální rozdělení, atd.
            Pod pojmem daného rozdělení často myslíme celou <strong>"rodinu" rozdělní, parametrizovaných jedním nebo více parametry</strong>.
            Stejně jako pod pojmem "kvadratická funkce" nerozumíme jen jednu konrétní parabolu, ale jakoukoli funkci ve tvaru {"$ax^2 + bx + c$"}, kde {"$a, b, c$"} jsou parametry (s omezením {"$a \\neq 0$"}),
            tak např. pod pojmem binomické rozdělení rozumíme jakékoli rozdělení s parametry {"$n \\geq 1$"} a {"$p \\in \\langle 0, 1\\rangle,$"} definované tak, že
            {`$$
                p(x) = \\binom{n}{x} p^x (1-p)^{n-x} \\quad \\text{pro} \\quad x = 0, 1, 2, \\ldots, n.
            $$`}
            Vzorec nedefinuje jediné rozdělení, ale pro každou konrétní volbu parametrů {"$n$"} a {"$p$"} definuje jedno konkrétní rozdělení. Např. pro {"$n = 10$"} a {"$p = 0.3$"} dostaneme konkrétní rozdělení:
            {`$$
                p(x) = \\binom{10}{x} 0.3^x 0.7^{10-x} \\quad \\text{pro} \\quad x = 0, 1, 2, \\ldots, 10.
            $$`}
            Platí, že střední hodnota binomického rozdělení je
            {`$$
                % \\mu_{Bi(n,p)} = np,
                \\mu = np
            $$`}
            což lze ověřit přímým výpočtem. Místo {"$p(x)$"} bychom klidně mohli psát {"$p_{n,p}(x)$"}, abychom indikovali přítomnost parametrů. Stejně tak místo {"$\\mu$"} bychom mohli psát {"$\\mu_{n,p}$"},
            abychom indikovali, že střední hodnota je různá pro různé volby parametrů.
            </p>
            <p>
            Normální rozdělení je definované svojí hustotou pravděpodobnosti:
            {`$$
                f(x) = \\frac{1}{\\sqrt{2\\pi \\sigma^2}} e^{-\\frac{(x-\\mu)^2}{2\\sigma^2}},
            $$`}
            kde vystupují dva parametry {"$\\mu$"} a {"$\\sigma$"}. Tyto parametry korespondují se střední hodnotou a směrodatnou odchylkou:
            {`$$\\begin{align*}
                \\int x f(x)\\mathrm{d}x &=  \\int x \\frac{1}{\\sqrt{2\\pi \\sigma^2}} e^{-\\frac{(x-\\mu)^2}{2\\sigma^2}} \\mathrm{d}x = \\mu, \\\\
                \\Big ( \\int (x-\\mu)^2 f(x)\\mathrm{d}x\\Big)^{1/2}  &=\\Big (\\int (x-\\mu)^2 \\frac{1}{\\sqrt{2\\pi \\sigma^2}} e^{-\\frac{(x-\\mu)^2}{2\\sigma^2}} \\mathrm{d}x \\Big)^{1/2} = \\sigma,

            \\end{align*}
            $$`}
            což lze opět ověřit přímým výpočtem. Vzorec hustoty je zapsán tak, že přímo využívá střední hodnotu {"$\\mu$"}  a směrodatnou odchylku {"$\\sigma$"} jako parametry
            (srovnejte: v případě binomického rozdělení tomu tak není - má sice také parametry, ale jsou jiné než {"$\\mu$"} a {"$\\sigma$"}). Místo {"$f(x)$"} bychom opět klidně mohli psát {"$f_{\\mu, \\sigma}(x)$"},
            abychom dali najevo přítomnost parametrů.
            </p>
            <p>
            Exponenciální rozdělení je definované svojí hustotou pravděpodobnosti:
            {`$$
                f(x) = \\lambda e^{-\\lambda x},
            $$`}
            kde vystupuje parametr {"$\\lambda$"}, který se často interpretuje jako intenzita událostí za jednotku času (exponenciální
            rozdělení potom udává rozdělení délek intervalů mezi událostmi).
            Střední hodnota exponenciálního rozdělení je {"$\\mu = \\frac{1}{\\lambda}$"}. Pokud je např. intenzita {"$\\lambda = 2$"} události za hodinu,
            pak střední rozestup mezi událostmi je {"$\\mu = \\frac{1}{\\lambda} = 0.5$"} hodiny.
            </p>
            <p>
            Suma sumárum: střední hodnota je číslo. Každé* rozdělení má střední hodnotu.
            Někdy se střední hodnota {"$\\mu$"} přímo vyskytuje v definici (rodiny) rozdělení jako parametr (normální rozdělení), někdy ne (binomické, exponenciální). To nic nemění na tom,
            že rozdělení má střední hodnotu. Pokud se písmeno {"$\\mu$"} vyskytuje jako parametr v definici rozdělení, pak lze předpokládat, že se jedná o střední hodnotu (ale je dobré si to ověřit).
            </p>

            <h3 id="compe-random-variable-expectation">Střední hodnota náhodné veličiny</h3>
            <p>
            Máme-li náhodnou veličinu {"$X: \\Omega \\rightarrow \\mathbb{R}$"},
            potom tato náhodná veličina indukuje rozdělení pravděpobnosti (pravděpodobnostní míru) na {"$\\mathbb{R}$"}.
            Toto rozdělení je popsáno buď pravděpodobnostní funkcí {"$p(x)$"} (v diskrétním případě), nebo hustotou {"$f(x)$"} (ve spojitém případě).
            Střední hodnota {"$E(X)$"}náhodné veličiny {"$X$"} je definována jako střední hodnota tohoto rozdělení (tj. stejně jako v případě výše):
            {`$$
                E(X) = \\int x f(x) \\mathrm{d}x, \\qquad E(X) = \\sum_{x} x p(x)
            $$`}
            </p>
            <p>
            Uvažujme např. náhodný výběr s vracením z množiny deseti koulí, kde 4 jsou černé a 6 je bílých. Potom
            {`$$
                \\Omega = \\left\\{(b, b), (b, c), (c, b), (c, c)\\right\\},
            $$`}
            kde {"$b$"} značí černou kouli a {"$c$"} bílou kouli. Pravděpodobnost je definována takto:
            {`$$
                p((b, b)) = 0.36, \\quad p((b, c)) = 0.24, \\quad  p((c, b)) = 0.24, \\quad  p((c, c)) = 0.16.
            $$`}
            Uvažujme náhodnou veličinu {"$X: \\Omega \\rightarrow \\mathbb{R}$"} označující počet černých koulí ve výběru.
            Tato veličina nabývá hodnot
            {`$$
                x = 0, 1, 2
            $$`}
            s pravděpodobnostmi
            {`$$
                p'(0) = 0.36, \\quad p'(1) = 0.48, \\quad p'(2) = 0.16.
            $$`}
            </p>
            {/* Náhodná veličina {"$X$"} má binomické rozdělení s parametry {"$n = 2$"} a {"$p = 0.4$"}, což se standardně zapisuje jako {"$X \\sim Bi(2, 0.4)$"}. */}
            <p>
            Pro určení střední hodnoty {"$E(X) = 0.8$"} nám stačí použít toto rozdělení pravděpodobnosti na reálné ose: {"$E(X) = 0 \\cdot 0.36 + 1 \\cdot 0.48 + 2 \\cdot 0.16 = 0.8.$"}&nbsp;
            Tj. stačí použít ono "přenesené" či "indukované" rozdělení pravděpodobnosti, a nikoli ono "původní" rozdělení,
            které je definováno pomocí {"p"} na podmnožinách {"$\\Omega$"}.
            </p>
            <p>
            Vlastně můžeme úplně "odříznout" povědomí o tom (znalost toho), jak původní rozdělení vypadá (tj. z jakých vzorů - prvků {"$\\Omega$"} - náhodná veličina zobrazuje), a pracovat jen
            s výsledným rozdělením náhodné veličiny na reálné ose.
            </p>
            <p>
            Tento proces se často objevuje i v opačném gardu. Řekneme, že máme náhodnou veličinu {"$X$"} s daným rozdělením pravděpodobnosti, např. {"$X \\sim \\mathrm{Bi}(2, 0.4)$"}.
            Nevíme ale, na jakém {"$\\Omega$"} je definována, odkud zobrazuje, a jaká je "původní" pravděpodobnost.

            Pro většinu úloh toto vlastně nepotřebujeme vědět - např. střední hodnotu {"$E(X)$"} můžeme určit bez této znalosti:
            {`$$
                E(X) = \\sum_{x=0}^{2} x \\binom{2}{x} 0.4^x 0.6^{2-x} = 0 \\cdot 0.36 + 1 \\cdot 0.48 + 2 \\cdot 0.16 = 0.8.
            $$`}

            <span style={{color: "navy"}}>
            Pokud ale chceme/potřebujeme nějakou reprezentaci této náhondé veličiny, vždy můžeme zvolit přímo
            {"$\\Omega = \\mathbb{R}$"} s daným rozdělením pravděpodobnosti, a {"$X: \\mathbb{R} \\rightarrow \\mathbb{R}$"} jako <strong>identitu</strong>, tj. {"$X(x) = x$"}.
            Tímto způsobem získáme náhodnou veličinu, která má právě dané rozdělení pravděpodobnosti.
            </span>
            </p>

            <h3 id="compe-dataset-mean">Průměr datového souboru</h3>
            <p>
            Jedná se o koncept popisné statistiky. Máme-li soubor čísel {"$(x_1, x_2, \\ldots, x_n)$"}, pak průměr je definován jako
            {`$$
                \\bar{x} = \\frac{1}{n} \\sum_{i=1}^{n} x_i.
            $$`}
            Je to číslo, které charakterizuje konkrétní datový soubor - fyzikálně je to jeho těžiště. Nepotřebujeme k němu žádný pojem pravděpodobnosti, žádné rozdělení.
            </p>

            <p>Pokud bychom datový soubor vzali, zakryli, a potom z něj náhodně vybrali nějaký prvek, posunuli bychom se do scénáře, kdy
            datový soubor je <strong>populace</strong>, a náhodná veličina "vybrané číslo" má nějaké rozdělení pravděpodobnosti.
            Definovali bychom {"$\\Omega = \\{x_1, x_2, \\ldots, x_n\\}$"}, a pravděpodobnost {"$p(x_i) = \\frac{1}{n}$"}, tj. rovnoměrné rozdělení - každý prvek má stejnou pravděpodobnost, že bude vybrán.
            Potom průměr datového souboru se stává střední hodnotou tohoto rovnoměrného rozdělení, a rovněž střední hodnotou náhodné veličiny {"$X(x) = x$"}, která udává vybrané číslo.
            </p>

            <p>U roztříděného souboru si zachováme jen unikátní hondoty: Místo {"$(1, 1, 1, 2, 2, 3, 3, 3, 3, 3)$"} budeme mít {"$(1, 2, 3)$"},
            a k tomu relativní četnosti {"$f_i$"}: {"$(0.3, \\ 0.2, \\ 0.5)$"}. Potom průměr datového souboru je vážený průměr (váhy jsou relativní četnosti):
            {`$$
                \\bar{x} = \\sum_{i=1}^{n} x_i f_i= 1 \\cdot 0.3 + 1 2 \\cdot 0.2 + 3 \\cdot 0.5 = 2.2.
            $$`}

            Dejme tomu, že původní datový soubor vezmeme, zakryjeme, a potom z něj náhodně vybereme prvek (stejný scénář jako výše).
            Je zřejmé, že pravděpodobnost vytažení čísla 1 je 3/10, čísla 2 je 2/10, a čísla 3 je 5/10. Toto je také  rozdělení pravděpodobnostni náhodné veličiny "vybrané číslo".
            Definujeme {"$\\Omega = \\{1, 2, 3\\}$"}, a {"$p(1) = \\frac{3}{10}, \\ p(2) = \\frac{2}{10}, \\ p(3) = \\frac{2}{10}$"}.
            Potom střední hodnota tohoto rozdělení je {"$\\mu = 1 \\cdot \\frac{3}{10} + 2 \\cdot \\frac{2}{10} + 3 \\cdot \\frac{5}{10} = 2.2$"}. Je identická s průměrem datového souboru.
            Střední hodnota náhodné veličiny "vybrané číslo" je rovněž {"$E(X) = 2.2$"}.
            Vidíme že <strong>relativní četnosti v roztříděném souboru dostávají roli pravděpodobností</strong> v okamžiku, kdy ze souboru náhodně vybíráme.
            </p>

            <h3 id="compe-sample-mean">Výběrový průměr</h3>
            <p>Výběrový průměr není číslo, ale je to náhodná veličina. Je to funkce náhodného výběru.
            Pokud například máme náhodný výběr velikosti 3 z množiny čísel {"$\\{1, 2, 3, 4, 5, 6\\}$"}, pak výběrový průměr je náhodná veličina,
            která každému možnému výsledku (např. (1, 2, 6)) přiřazuje průměr těchto čísel (v tomto případě 3).
            Výběrový průměr nemá konstatní hodnotu, ale má jinou hodnotu pro každou možnou realizaci náhodného výběru ((1,1,1), (1,1,2), ...).
            Protože realizace náhodného výběru jsou ohodnoceny pravděpodobnostmi, výběrový průměr má také rozdělení pravděpodobnosti - některé průměry jsou pravděpodobnější než jiné.
            Např. průměr okolo 3 je pravděpodobnější než průměr okolo 1.
            </p>
            <p>Výběrový průměr velikosti {"n"} je definován jako
            {`$$
                \\bar{X}_n = \\frac{1}{n} \\sum_{i=1}^{n} X_i
            $$`}
            kde {"$X_i$"} je i-tá náhodná veličina výběru (tj. i-tý prvek výběru).
            </p>

            <p>
            Pokud provádíme opakovaná nezávislá měření nějakého pokusu, a zapíšeme
            si  postupně výsledky měření (1,12, 3.4, 5.6, 7.8, ...), pak jsme
            získali <strong>realizaci</strong> (jednu kokrétní /vektorovou/ hodnotu) náhodného výběru.
            Výpočet obyčejného datového průměru na těchto datech nám dá konstantní číslo -
            je to <strong>realizace</strong> (konrétní hodnota) výběrového průměru na této realizaci výběru.
            </p>



        </div>
        </div>
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

