import React, { useEffect, useRef, useState } from 'react';


import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import { TOC, TOCProps, defineTOCElements } from '~/TOC';


export const MeanCalculationMeta = {
    title: "Průměr a třídění",
    shortTitle: "Průměr",
    path: Path.mean,
    element: (sectionNumber:string) => <MeanCalculation sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "datamean-raw-data",
    "datamean-boxed-data-discrete",
    "datamean-boxed-data-continuous",
];


export function MeanCalculation({sectionNumber}: {sectionNumber: string}) {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);

    return (
        <MathJaxWrapper>
        <MathJax>
        <div className="chapter-container">
        <div className="centered-content">
        <div className="card" ref={chapterRef}>

            <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{MeanCalculationMeta.title}</h1>
            <p><em>Pozor: Průměr lze počítat jen u <b>kvantitativních</b> znaků.
            Pokud máme kvalitativní znak, jehož hodnoty/úrovně,
            např. ("začátečník", "mírně pokročilý", "středně pokročilý", ...)
            nebo ("smrk", "dub", "buk", ...) si označíme čísly (1, 2, 3, ...),
            pořád platí, že z těchto čísel nemá smysl počítat průměr. Jeho vypovídací hodnota by byla nulová.
            Číselná označení jsou totiž pořád jen nálepky ("1", "2", "3", ...), a nemají matematický (číselný) význam.
            Pokud programujete: jsou to řetězce (string), nikoli čísla (int, number).
            </em></p>
            <h2 id="datamean-raw-data">Neroztříděný soubor</h2>
            <p>U <em>neroztříděného</em> souboru dat počítáme průměr klasicky:</p>
            <span>{
                "$$\\overline{x} = \\frac{1}{n}\\sum_{i=1}^n x_i = \\frac{1}{n}\\left(x_1 + x_2 + \\cdots + x_n\\right) = \\frac{1}{n} x_1 + \\frac{1}{n} x_2 + \\cdots + \\frac{1}{n} x_n$$"
            }</span>
            <p>
            Zde vidíme souvislost s váženým průměrem - všechny váhy jsou stejné a mají hodnotu <span>{"$\\frac{1}{n}$"}</span>.
            Obyčejný průměr je vážený průměr s rovnoměrnými váhami.
            </p>
            <h2 id="datamean-boxed-data-discrete">Roztříděný soubor pro diskrétní znak </h2>
            <p>U <em>roztříděného</em> souboru dat pro <em>diskrétní</em> znak využijeme četnosti jednotlivých hodnot:</p>
            <table className='data-table'>
                <thead>
                    <tr>
                        <th>Hodnota <span>{"$x_j$"}</span></th>
                        <th>Četnost <span>{"$c_j$"}</span></th>
                        <th>Význam</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{"$x_1$"}</td>
                        <td>{"$c_1$"}</td>
                        <td>Hodnota {"$x_1$"} se v datech opakuje (vyskytuje) {"$c_1$"} krát.</td>
                    </tr>
                    <tr>
                        <td>{"$x_2$"}</td>
                        <td>{"$c_2$"}</td>
                        <td>Hodnota {"$x_2$"} se v datech opakuje (vyskytuje) {"$c_2$"} krát.</td>
                    </tr>
                    <tr>
                        <td>{"$\\cdots$"}</td>
                        <td>{"$\\cdots$"}</td>
                        <td>{"$\\cdots$"}</td>
                    </tr>
                    <tr>
                        <td>{"$x_m$"}</td>
                        <td>{"$c_m$"}</td>
                        <td>Hodnota {"$x_m$"} se v datech opakuje (vyskytuje) {"$c_m$"} krát.</td>
                    </tr>
                </tbody>
            </table>
            <p>Počet tříd: <span>{"$m$"}</span>.</p>
            <p>Velikost souboru: <span>{"$$n = \\sum_{j=1}^m c_j = c_1 + c_2 + \\ldots + c_m$$"}</span></p>
            <p>Průměr:</p>
            <span>{
                "$$" +
                "\\overline{x} = \\frac{1}{n}\\sum_{j=1}^m c_j x_j " +
                " = \\frac{1}{n}\\left(c_1 x_1 + c_2 x_2 + \\cdots + c_m x_m\\right)" +
                " = \\frac{c_1}{n} x_1 + \\frac{c_2}{n} x_2 + \\cdots + \\frac{c_m}{n} x_m" +
                "$$"
            }</span>
            <p>Toto je vážený průměr s váhami {"$\\dfrac{c_j}{n}$"}, které se nazývají <em>relativní četnosti</em>,
            a které lze také interpretovat jako pravděpodobnosti, protože jsou nezáporné a jejich součet je 1:
            <span>{"$$\\sum \\frac{c_j}{n} = \\frac{\\sum c_j}{n} = \\frac{\\sum c_j}{\\sum c_j} = 1$$"}</span>
            Pokud by data představovala <em>celou populaci</em> (každý prvek z populace byl analyzován právě jednou),
            potom by <span>{"$\\dfrac{c_j}{n}$"}</span> byla pravděpodobnost, že sledovaný znak na náhodně vybraném prvku z populace má hodnotu <span>{"$x_j$"}</span>.
            </p>
            <p>Pokud by data představovala <em>náhodný výběr</em> z populace, potom to jsou maximálně <em>odhady</em> těchto pravděpodobností
            (v jiném náhodném výběru bychom totiž klidně získali jiné četnosti).
            </p>

            <div className="example">

                <p>Znak může nabývat hodnot 1 až 4. Získali jsme data:
                    <span className='c1'> 1, 1, 1, </span>
                    <span className='c2'>2, 2, </span>
                    <span className='c3'>3, 3, </span>
                    <span className='c4'>4.</span>
                </p>
                <table className='data-table'>
                    <thead>
                        <tr>
                            <th>Hodnota <span>{"$x_j$"}</span></th>
                            <th>Četnost <span>{"$c_j$"}</span></th>
                            <th>Pozn.</th>
                            <th>Souvislost se značením</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span className='c1'>1</span></td>
                            <td>3</td>
                            <td>Hodnota <span className='c1'>1</span> se v datech vyskytuje třikrát: <span className='c1'>1, 1, 1</span>.</td>
                            <td>{"$x_1 = 1, \\ c_1 = 3$"}</td>
                        </tr>
                        <tr>
                            <td><span className='c2'>2</span></td>
                            <td>2</td>
                            <td>Hodnota <span className='c2'>2</span> se v datech vyskytuje dvakrát: <span className='c2'>2, 2</span>.</td>
                            <td>{"$x_2 = 2, \\ c_2 = 2$"}</td>
                        </tr>
                        <tr>
                            <td><span className='c3'>3</span></td>
                            <td>2</td>
                            <td>Hodnota <span className='c3'>3</span> se v datech vyskytuje dvakrát: <span className='c3'>3, 3</span>.</td>
                            <td>{"$x_3 = 3, \\ c_3 = 2$"}</td>
                        </tr>
                        <tr>
                            <td><span className='c4'>4</span></td>
                            <td>1</td>
                            <td>Hodnota <span className='c4'>4</span> se v datech vyskytuje jednou: <span className='c4'>4</span>.</td>
                            <td>{"$x_4 = 4, \\ c_4 = 1$"}</td>
                        </tr>
                    </tbody>
                </table>
                <p>Velikost souboru je <span>{"$n = 8$"}</span> &mdash; to vidíme z:</p>
                <ul>
                <li>původních (neroztříděných) dat, </li>
                <li>ze součtu četností: <span>{"$n = \\sum c_i = c_1 + c_2 + c_3 + c_4 = 3 + 2 + 2 + 1 = 8$"}</span></li>
                </ul>

                <p>Průměr:</p>
                <span>{
                    "$$" +
                    "\\begin{align}" +
                    "    \\overline{x} &= \\frac{1}{8} \\Big( 3 \\cdot 1 + 2 \\cdot 2 + 2 \\cdot 3 + 1 \\cdot 4 \\Big) = \\\\" +
                    "    \\            &= \\frac{3}{8} \\cdot 1 + \\frac{2}{8} \\cdot 2 + \\frac{2}{8} \\cdot 3 + \\frac{1}{8} \\cdot 4 = 2.125" +
                    "\\end{align}" +
                    "$$"
                }</span>
                <p>Pokud bychom počítali průměr z původních dat, vypadalo by to takto:</p>
                <span>{
                    "$$" +
                    "\\begin{align}" +
                    "    \\overline{x} &= \\frac{1}{8} \\Big(1 + 1 + 1 + 2 + 2 + 3 + 3 + 4 \\Big) =  \\\\" +
                    "    \\            &= \\frac{1}{8} \\Big( \\textcolor{red}{(1 + 1 + 1)} + \\textcolor{red}{(2 + 2)} + \\textcolor{red}{(3 + 3)} + \\textcolor{red}{(4)} \\Big) =  \\\\" +
                    "    \\            &= \\frac{1}{8} \\Big( \\textcolor{violet}{3} \\cdot 1 + \\textcolor{violet}{2} \\cdot 2 + \\textcolor{violet}{2} \\cdot 3 + \\textcolor{violet}{1} \\cdot 4 \\Big) = 2.125 \\\\" +
                    "\\end{align}" +
                    "$$"
                }</span>
                Vidíme, že <span style={{color: "red"}}>uzávorkování stejných hodnot</span> přesně odpovídá <span style={{color: "violet"}}>identifikaci četností při třídění</span>.
                <p>
                Součet relativních četností je 1: {"$\\ \\  \\dfrac{3}{8} + \\dfrac{2}{8} + \\dfrac{2}{8} + \\dfrac{1}{8} = \\dfrac{3 + 2 + 2 + 1}{8} = \\dfrac{8}{8} = 1$"}
                </p>
                {/* end of example */}
            </div>
            <p>
            <em>U diskrétního znaku při třídění nedochází ke ztrátě informace. </em>
            Pokud ztratíme původní soubor dat, můžeme ho z roztříděného souboru
            rekonstruovat. Má-li např. hodnota 1 četnost 3, tak víme,
            že v původních datech se vyskytovaly přesně tři jedničky: 1, 1, 1.
            Když toto "rozepsání" provedeme pro každou hodnotu a její četnost, dostaneme původní data
            (eventuelně až na pořadí, které však nemá žádný význam).
            </p>
            <p>
            Pozor: Pokud bychom ale třídění prováděli jako u spojitého znaku, tj. kdyby třídy neodpovídaly
            jednotlivým hodnotám ale intervalům, potom by se mohlo stát, že bychom intervaly
            zvolili příliš široké - tak, že by více různých hodnot sledovaného znaku padlo
            do jedné třídy. Potom by ke ztrátě informace při třídění došlo.
            </p>
            <div className="example">
                Roztřídíme teď data jen do dvou tříd:
                <table className='data-table'>
                    <thead>
                        <tr>
                            <th>Třída</th>
                            <th>Střed třídy {"$x^*_j$"}</th>
                            <th>Četnost <span>{"$c_j$"}</span></th>
                            <th>Poznámka</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{"$\\langle 0.5; 2.5)$"}</td>
                            <td>1.5</td>
                            <td>5</td>
                            <td>Padnou sem pozorování s hodnotami 1 a 2.</td>
                        </tr>
                        <tr>
                            <td>{"$\\langle 2.5; 4.5)$"}</td>
                            <td>3.5</td>
                            <td>3</td>
                            <td>Padnou sem pozorování s hodnotami 3 a 4.</td>
                        </tr>
                    </tbody>
                </table>

                <p>Pokud bychom nyní ztratili původní soubor, při "rekonstrukci"
                bychom už nedostali původní data. "Rekonstruovaná" data by vypadala takto:
                1.5, 1.5, 1.5, 1.5, 1.5, 3.5, 3.5, 3.5.
                Jejich průměr už by nevyšel stejně: {"$(5 \\cdot 1.5 + 3 \\cdot 3.5)/8 = 2.25 \\neq 2.125$"}.
                </p>
            </div>

            <h2 id="datamean-boxed-data-continuous">Roztříděný soubor pro spojitý znak </h2>
            <p>
            Je-li znak spojitý, je nepravděpodobné, že by se přesně stejná hodnota
            v datech vyskytovala dvakrát nebo vícekrát (i když vyloučit to samozřejmě nejde).
            Pokud chceme soubor třídit, má tedy jedině smysl použít třídy v podobě intervalů.
            </p>

            <table className='data-table'>
                <thead>
                    <tr>
                        <th>Třída {"$\\langle a_j; b_j)$"}</th>
                        <th>Střed třídy {"$x^*_j$"}</th>
                        <th>Četnost <span>{"$c_j$"}</span></th>
                        <th>Poznámka</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{"$\\langle a_1; b_1)$"}</td>
                        <td>{"$x^*_1$"}</td>
                        <td>{"$c_1$"}</td>
                        <td>{"$c_1$"} hodnot padne do třídy, jejíž střed je {"$x^*_1$"}</td>
                    </tr>
                    <tr>
                        <td>{"$\\langle a_2; b_2)$"}</td>
                        <td>{"$x^*_2$"}</td>
                        <td>{"$c_2$"}</td>
                        <td>{"$c_2$"} hodnot padne do třídy, jejíž střed je {"$x^*_2$"}</td>
                    </tr>
                    <tr>
                        <td>{"$\\cdots$"}</td>
                        <td>{"$\\cdots$"}</td>
                        <td>{"$\\cdots$"}</td>
                        <td>{"$\\cdots$"}</td>
                    </tr>
                    <tr>
                        <td>{"$\\langle a_m; b_m)$"}</td>
                        <td>{"$x^*_m$"}</td>
                        <td>{"$c_m$"}</td>
                        <td>{"$c_m$"} hodnot padne do třídy, jejíž střed je {"$x^*_m$"}</td>
                    </tr>
                </tbody>
            </table>

            <p>Počet tříd: <span>{"$m$"}</span>.</p>
            <p>Velikost souboru: <span>{"$$n = \\sum_{j=1}^m c_j = c_1 + c_2 + \\ldots + c_m$$"}</span></p>
            <p>Průměr:</p>
            <span>{
                "$$" +
                "\\overline{x} = \\frac{1}{n}\\sum_{j=1}^m c_j x^*_j " +
                " = \\frac{1}{n}\\left(c_1 x^*_1 + c_2 x^*_2 + \\cdots + c_m x^*_m\\right)" +
                " = \\frac{c_1}{n} x^*_1 + \\frac{c_2}{n} x^*_2 + \\cdots + \\frac{c_m}{n} x^*_m" +
                "$$"
            }</span>
            <p>
            Vidíme, že vzorce jsou stejné jako v případě pro roztříděný diskrétní znak,
            rozdíl je pouze ve značení {"$x^*_j$"}, kde nás hvězdička ({"$\ ^*$"}) upozorňuje
            na to, že hodnota je střed třídy, nikoli přesná pozorovaná hodnota
            (jako domu bylo u diskrétního znaku).
            </p>

            <p>
            Opět se jedná o vážený průměr s váhami <span>{"$\\frac{c_j}{n}$"}</span>,
            které se opět nazývají <em>relativní četnosti</em>.
            Data <em>nemohou představovat celou populaci</em> (je-li znak spojitý, populace musí být nekonečná).
            Data tedy představují <em>náhodný výběr</em>, a relativní četnosti jsou potom <em>odhady </em>
            pravděpodobností. Relativní četnost {"$\\frac{c_j}{n}$"} je odhad pravděpodobnosti, že hodnota znaku patří
            do intervalu {"$\\langle a_j; b_j)$"}.
            </p>

            <p>
            Ve spojitém případě není koncept populace užitečný, pokud si populaci představujeme jako
            nějaký soubor jedinců/prvků (i když třeba hodně velký). Místo toho se pracuje s konceptem
            rozdělení pravděpodobnosti, jehož "existenci" předpokládáme (někdy např. vyplývá z fyzikálních zákonů),
            ale nevíme apriori jak vypadá (popř. máme představu, jakého je "typu", ale neznáme některé jeho parametry).
            </p>

            <p>
            Průměr (a jiné charakteristiky) spojitého znaku je přesnější počítat z neroztříděných dat.
            Třídění totiž představuje ztrátu informace, a navíc poloha a velikost tříd ovlivňuje (posouvá) výsledek.
            </p>

            {/* pozn - rozdeleni pravdepodobnosti bude presne definovano pozdeji */}
            {/* pozn - pro spocetny pripad by to potrebovalo detailni diskuzi - N vs Q ; uvest treba priklad s N */}
            {/* priklad kdy pohnu tridami doleva, doprava */}

        </div>
        </div>
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}


