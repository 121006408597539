import { color } from 'd3';
import React, { useEffect, useState } from 'react';

interface IProps { onDataGenerated: (data: Array<[number, number]>) => void; }


export function DataGenerator(props: IProps) {
  const [meanX, setMeanX] = useState<number>(2);
  const [sdX, setSdX] = useState<number>(1.5);
  const [meanY, setMeanY] = useState<number>(5);
  const [sdY, setSdY] = useState<number>(1);
  const [r, setR] = useState<number>(0.6);
  const [n, setN] = useState<number>(20);
  const [data, setData] = useState<Array<[number, number]>>([]);

  const generateData = () => {
    if (!validateInputs()) {
        return;
    }

    const generatedData: Array<[number, number]> = [];
    for (let i = 0; i < n; i++) {
      // Generate X using Box-Muller transform for normal distribution
      let u1 = Math.random();
      let u2 = Math.random();
      let z0 = Math.sqrt(-2.0 * Math.log(u1)) * Math.cos(2.0 * Math.PI * u2);
      let x = z0 * sdX + meanX;

      // Generate Y using correlation
      let z1 = Math.sqrt(-2.0 * Math.log(u1)) * Math.sin(2.0 * Math.PI * u2);
      let y = z1 * Math.sqrt(1 - r * r) * sdY + r * z0 * sdY + meanY;

      generatedData.push([x, y]);
    }
    setData(generatedData);
    props.onDataGenerated(generatedData);
  };

  useEffect(() => {generateData();}, []);


  const validateInputs = () => {
    if (sdX && Number(sdX) <= 0) return false;
    if (sdY && Number(sdY) <= 0) return false;
    if (r && (Number(r) < -1 || Number(r) > 1)) return false;
    if (n && (Number(n) <= 0 || !Number.isInteger(Number(n)))) return false;
    return true;
  };


  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'flex-end' }}>
      <div style={{ marginBottom: '10px' }}>
        <label htmlFor="meanX" style={{ display: 'block' }}>{"$\\bar{x}$"}</label>
        <input id="meanX" type="number" value={meanX} onChange={(e) => setMeanX(+e.target.value)} placeholder="Mean X" />
      </div>
      <div style={{ marginBottom: '10px' }}>
        <label htmlFor="sdX" style={{ display: 'block' }}>{"$s_{x}$"}</label>
        <input id="sdX" type="number" value={sdX} min="0" onChange={(e) => setSdX(+e.target.value)} placeholder="SD X"
        style={sdX && Number(sdX) < 0 ? { color: 'red' } : { color: 'black' }} />
      </div>
      <div style={{ marginBottom: '10px' }}>
        <label htmlFor="meanY" style={{ display: 'block' }}>{"$\\bar{y}$"}</label>
        <input id="meanY" type="number" value={meanY} onChange={(e) => setMeanY(+e.target.value)} placeholder="Mean Y" />
      </div>
      <div style={{ marginBottom: '10px' }}>
        <label htmlFor="sdY" style={{ display: 'block' }}>{"$s_{y}$"}</label>
        <input id="sdY" type="number" value={sdY} min="0" onChange={(e) => setSdY(+e.target.value)} placeholder="SD Y"
        style={sdY && Number(sdY) < 0 ? { color: 'red' } : { color: 'black' }} />
      </div>
      <div style={{ marginBottom: '10px' }}>
        <label htmlFor="r" style={{ display: 'block' }}>korelace {"$r$"}</label>
        <input id="r" type="number" value={r} onChange={(e) => setR(+e.target.value)} placeholder="Correlation" min="-1" max="1"
        style={r && (Number(r) < -1 || Number(r) > 1) ? { color: 'red' } : { color: 'black' }}
        />
      </div>
      <div style={{ marginBottom: '10px' }}>
        <label htmlFor="n" style={{ display: 'block' }}>rozsah {"$n$"}</label>
        <input id="n" type="number" value={n} onChange={(e) => setN(+e.target.value)} placeholder="Sample Size" min="1" max="1000"
               style={n && (Number(n) < 0 || !Number.isInteger(Number(n)) || Number(n) > 1000) ? {color: 'red'} : {color: 'black'}}/>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <button onClick={generateData}>Generuj</button>
      </div>
      {/* <div>
        {data.map((point, index) => (
          <div key={index}>
            ({point[0].toFixed(2)}, {point[1].toFixed(2)})
          </div>
        ))}
      </div> */}
    </div>
  );
};

