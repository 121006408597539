import React from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';

export const InferenceIntroMeta = {
    title: "Inference",
    shortTitle: "Inference",
    path: Path.inference_intro,
    element: (sectionNumber:string) => <InferenceIntro sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

export function InferenceIntro({sectionNumber}: {sectionNumber: string}) {
    return (
        <MathJaxWrapper>
            <MathJax>
            <div className="chapter-container">
            <div className="centered-content">
            <div className="card">

            <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{InferenceIntroMeta.title}</h1>
            <p>
            Inference pojednává mj. o tom, jak korektně odhadovat informace o populaci na základě náhodného výběru
            (z malé podmnožiny populace). A analogicky, jak korektně odhadovat infromace o podkladovém
            rozdělení pravděpodobnosti při pozorování jevů, pro něž používáme makroskopický pravděpodobnostní/statistický popis
            (statistická fyzika, teorie front, ...), nebo o rozdělení náchodné chyby při měření fyzikálních veličin.
            </p>
        </div>
        </div>
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

