import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';

//const root = document.getElementById("react-root-container");

// ReactDOM.render(<App />, root);

const root = createRoot(document.getElementById("react-root-container") as HTMLElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);