import React, { useEffect, useRef, useState } from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import { TOC, TOCProps, defineTOCElements } from '~/TOC';

const MedianOdd = require('../assets/images/median_odd.png');
const MedianEven = require('../assets/images/median_even.png');
const Deciles = require('../assets/images/deciles.png');
const Quartiles = require('../assets/images/quartiles.png');
const MedianDegenerate = require('../assets/images/median_degenerate.png');

const MedianOddAlt = require('../assets/images/median_odd_alt.png');
const MedianEvenAlt = require('../assets/images/median_even_alt.png');

const Staircase = require('../assets/images/staircase.png');


export const QuantilesMeta = {
    title: "Kvantily",
    shortTitle: "Kvantily",
    path: Path.quantiles,
    element: (sectionNumber:string) => <Quantiles sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "dataquantiles-ordered-data",
    "dataquantiles-median",
    "dataquantiles-quantile",
    "dataquantiles-special",
    "dataquantiles-zero-and-one",
    "dataquantiles-illustration-deciles",
    "dataquantiles-illustration-median",
    "dataquantiles-altdef",
];

export function Quantiles({sectionNumber}: {sectionNumber: string}) {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);

    return (
        <MathJaxWrapper>
        <MathJax>
        <div className="chapter-container">
        <div className="centered-content">
        <div className="card" ref={chapterRef}>

            <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{QuantilesMeta.title}</h1>

            <h2 id="dataquantiles-ordered-data">Uspořádaný statistický soubor</h2>

            <p>
            Uspořádání statistického souboru je seřazení jednotlivých číselných hodnot od nejmenší po největší.
            Výsledná posloupnost se nazývá <strong>uspořádaný</strong> statistický soubor. Hondoty v uspořádaném statistickém souboru se označují s indexem
            v závorce: {"$x_{(i)}$"}.
            </p>

            <p>
            Dejme tomu, že jsme nějakým měřením získali následující statistický soubor velikosti {"$n = 10$"}:
            </p>

            <table className="simple-table">
                <tbody>
                <tr>
                    <td>{"$x_1$"}</td>
                    <td>{"$x_2$"}</td>
                    <td>{"$x_3$"}</td>
                    <td>{"$x_4$"}</td>
                    <td>{"$x_5$"}</td>
                    <td>{"$x_6$"}</td>
                    <td>{"$x_7$"}</td>
                    <td>{"$x_8$"}</td>
                    <td>{"$x_9$"}</td>
                    <td>{"$x_{10}$"}</td>
                </tr>
                <tr>
                    <td className='element-4'>5.2</td>
                    <td className='element-8'>7.1</td>
                    <td className='element-1'>1.6</td>
                    <td className='element-2'>3.0</td>
                    <td className='element-10'>9.1</td>
                    <td className='element-9'>8.5</td>
                    <td className='element-3'>3.5</td>
                    <td className='element-7'>6.7</td>
                    <td className='element-5'>6.2</td>
                    <td className='element-5'>5.4</td>
                </tr>
                </tbody>
            </table>

            <p>
            Hodnoty uspořádáme podle velikosti a získáme tak uspořádaný statistický soubor:
            </p>

            <table className="simple-table">
                <tbody>
                <tr>
                    <td>{"$x_{(1)}$"}</td>
                    <td>{"$x_{(2)}$"}</td>
                    <td>{"$x_{(3)}$"}</td>
                    <td>{"$x_{(4)}$"}</td>
                    <td>{"$x_{(5)}$"}</td>
                    <td>{"$x_{(6)}$"}</td>
                    <td>{"$x_{(7)}$"}</td>
                    <td>{"$x_{(8)}$"}</td>
                    <td>{"$x_{(9)}$"}</td>
                    <td>{"$x_{(10)}$"}</td>
                </tr>
                <tr>
                    <td className='element-1'>1.6</td>
                    <td className='element-2'>3.0</td>
                    <td className='element-3'>3.5</td>
                    <td className='element-4'>5.2</td>
                    <td className='element-5'>5.4</td>
                    <td className='element-6'>6.2</td>
                    <td className='element-7'>6.7</td>
                    <td className='element-8'>7.1</td>
                    <td className='element-9'>8.5</td>
                    <td className='element-10'>9.1</td>
                </tr>
                </tbody>
            </table>

            <p>
            Vidíme, že zatímco {"$x_1 = 5.2$"} (první původně naměřená hodnota),
            tak {"$x_{(1)} = 1.6$"} (nejmenší z naměřených hodnot).
            Vidíme také, že např. {"$x_1 = x_{(4)}$"}, tj. první původně naměřená hodnota je čtvrtá nejmenší,
            a {"$x_{(1)} = x_3$"}, tj. nejmenší hodnota byla původně změřena jako třetí.
            </p>

            <p>
            Závorka kolem indexu nám indikuje, že se jedná o uspořádaný statistický soubor. Platí:
            {"$$x_{(1)} \\leq x_{(2)} \\leq x_{(3)} \\leq \\ldots \\leq x_{(n)},$$"}
            {"$x_{(1)}$"} je nejmenší hodnota (minimum),<br/>
            {"$x_{(n)}$"} je největší hodnota (maximum).<br/>
            </p>

            <p>
            Interval {"$\\langle x_{(1)}, x_{(n)} \\rangle$"} se nazývá <strong>variační interval</strong>.<br/>
            Délka variačního intervalu se nazývá <strong>variační rozpětí</strong> a určí se jako {"$x_{(n)} - x_{(1)}$"}.
            </p>

            <p>
            V uspořádaném statistickém souboru samozřejmě nemusí být všechny hodnoty různé - může se stát, že se některé hodnoty <strong>opakují</strong>.
            Přítomnost opakovaných hodnot nemá vliv na logiku kvantilů ani na jejich výpočet.
            Následující data mohou např. představovat výsledky průzkumu počtu členů 10 domácností:
            </p>

            <table className="simple-table">
                <tbody>
                <tr>
                    <td>{"$x_i$"}</td>
                    <td className='element-4'>4</td>
                    <td className='element-3'>3</td>
                    <td className='element-2'>2</td>
                    <td className='element-2'>2</td>
                    <td className='element-6'>6</td>
                    <td className='element-7'>7</td>
                    <td className='element-3'>3</td>
                    <td className='element-4'>4</td>
                    <td className='element-4'>4</td>
                    <td className='element-3'>3</td>
                    <td className='element-8'>8</td>
                </tr>
                <tr>
                    <td>{"$x_{(i)}$"}</td>
                    <td className='element-2'>2</td>
                    <td className='element-2'>2</td>
                    <td className='element-3'>3</td>
                    <td className='element-3'>3</td>
                    <td className='element-3'>3</td>
                    <td className='element-4'>4</td>
                    <td className='element-4'>4</td>
                    <td className='element-4'>4</td>
                    <td className='element-6'>6</td>
                    <td className='element-7'>7</td>
                    <td className='element-8'>8</td>
                </tr>
                </tbody>
            </table>

            <p>
            Zde máme {"$x_{(1)} = x_{(2)} = 2$"}, {"$x_{(3)} = x_{(4)} = x_{(4)} = 3$"}, atd.
            </p>


            <h2 id="dataquantiles-median">Medián</h2>

            <p>
            Medián je kvantil {"$q_{0.5}$"}, a značí se také {"$\\tilde{x}$"}. Je to 'nejjednodušší kvantil' - kterým je ideální začít,
            než si uvedeme obecnou definici kvantilu.

            Zhruba řečeno, medián je hodnota, která dělí <i>uspořádaný</i> statistický soubor
            na dvě stejně velké části - tj. 50% hodnot je vlevo od mediánu a 50% hodnot je vpravo od mediánu.
            </p>

            <p>
            Pro datový soubor o <strong>lichém</strong> počtu hodnot je medián prostřední hodnota.
            {"$$q_{0.5} = x_{\\left(\\lceil \\frac{n}{2} \\rceil\\right)}$$"}

            Symbol {"$\\lceil x \\rceil$"} značí zaokrouhlení argumentu nahoru na nejbližší celé číslo - např. {"$\\lceil 3.14 \\rceil = 4$"}.
            V&nbsp;angličtině se operaci říká  <i>ceiling</i>, programovací jazyky mívají funkci <i>ceil()</i>. Opakem je zaokrouhlení dolů: {"$\\lfloor x \\rfloor$"}, <i>floor()</i>.
            </p>

            <p>
            Např. pro datový soubor s {"$n=7$"} hodnotami je {"$\\lceil \\frac{7}{2} \\rceil = \\lceil 3.5 \\rceil = 4$"},
            takže medián je
            {"$$q_{0.5} = x_{\\left(\\lceil \\frac{n}{2} \\rceil\\right)} = x_{\\left(\\lceil \\frac{7}{2} \\rceil\\right)} = x_{(4)}.$$"}
            </p>

            <table className="simple-table">
                <tbody>
                <tr>
                    <td>{"$x_{(1)}$"}</td>
                    <td>{"$x_{(2)}$"}</td>
                    <td>{"$x_{(3)}$"}</td>
                    <td className='highlight-median'>{"$x_{(4)}$"}</td>
                    <td>{"$x_{(5)}$"}</td>
                    <td>{"$x_{(6)}$"}</td>
                    <td>{"$x_{(7)}$"}</td>
                </tr>
                <tr>
                    <td className='element-1'>1.6</td>
                    <td className='element-2'>3.0</td>
                    <td className='element-3'>3.5</td>
                    <td className='element-4 highlight-median'>5.2</td>
                    <td className='element-5'>5.4</td>
                    <td className='element-6'>6.2</td>
                    <td className='element-7'>6.7</td>
                </tr>
                </tbody>
            </table>

            <p>
            Pozn.: Pro liché {"$n$"} je zápis {"$\\lceil \\frac{n}{2} \\rceil$"} ekvivalentní zápisu {"$\\frac{n+1}{2}$"}.
            Např. pro {"$n=7$"} je {"$\\frac{n+1}{2} = \\frac{7+1}{2} = \\frac{8}{2} = 4.$"}
            </p>

            <p>
            Pro datový soubor o <strong>sudém</strong> počtu hodnot je medián průměrem dvou prostředních hodnot.
            {"$$q_{0.5} = \\frac{x_{\\left(\\frac{n}{2}\\right)} + x_{\\left(\\frac{n}{2}+1\\right)}}{2}$$"}

            Např. pro datový soubor s {"$n=8$"} hodnotami je {"$\\frac{n}{2} = 4$"}, {"$\\frac{n}{2} + 1 = 5$"},
            takže medián je průměrem čtvrté
            a páté hodnoty: {"$q_{0.5} = \\frac{x_{\\left( 4 \\right)} + x_{\\left( 5 \\right)}}{2}$"}
            </p>

            <table className="simple-table">
                <tbody>
                <tr>
                    <td>{"$x_{(1)}$"}</td>
                    <td>{"$x_{(2)}$"}</td>
                    <td>{"$x_{(3)}$"}</td>
                    <td>{"$x_{(4)}$"}</td>
                    <td></td>
                    <td>{"$x_{(5)}$"}</td>
                    <td>{"$x_{(6)}$"}</td>
                    <td>{"$x_{(7)}$"}</td>
                    <td>{"$x_{(8)}$"}</td>
                </tr>
                <tr>
                    <td className='element-1'>1.6</td>
                    <td className='element-2'>3.0</td>
                    <td className='element-3'>3.5</td>
                    <td className='element-4'>5.2</td>
                    <td className='highlight-median'>5.3</td>
                    <td className='element-5'>5.4</td>
                    <td className='element-6'>6.2</td>
                    <td className='element-7'>6.7</td>
                    <td className='element-8'>7.1</td>
                </tr>
                </tbody>
            </table>

            <p>
            Zamysleme se teď nad přesností úvodního vyjádření, že medián
            dělí uspořádaný statistický soubor na dvě stejně velké části.
            </p>

            <p>
            U sudého případu je vidět, že to platí: 4 hodnoty jsou striktně vlevo od mediánu a 4 hodnoty jsou striktně vpravo od mediánu.
            Hodnota {"$(5.3)$"} ale není jediná, která má tuto vlasnost - např. hodnota {"$5.35$"} by toto splňovala také.
            Všechny hodnoty v intervalu {"$(5.2, 5.4)$"} by toto splňovaly.
            Slovní popis tedy není dostatečný na to, aby medián definoval jednoznačně - proto se přidává konvence,
            že bereme hodnotu přesně uprostřed.
            </p>

            <p>
            U lichého případu máme jiný problém:
            </p>

            <ul>
                <li>Striktně vlevo {"$(<)$"} od mediánu máme 3 hodnoty, tj. 43% hodnot: {"$\\frac{3}{7} \\doteq 0.43$"}.</li>
                <li>Striktně vpravo {"$(>)$"} od mediánu máme také 3 hodnoty, tj. 43% hodnot.</li>
                <li>Vlevo od mediánu včetně něj {"$(\\leq)$"} máme 4 hodnoty, tj. 57% hodnot: {"$\\frac{4}{7} \\doteq 0.57$"}.</li>
                <li>Vpravo od mediánu včetně něj {"$(\\geq)$"} máme také 4 hodnoty, tj. 57% hodnot.</li>
            </ul>

            <p>
            Žádné z těchto čísel není 50%. Jak tedy můžeme tvrdit, že medián dělí uspořádaný statistický soubor
            na dvě poloviny? Intuitivně a graficky je zřejmé, co tím myslíme, ale matematicky se
            bohužel jedná o případ, kdy intuitivně jednoduchý koncept vyžaduje složitější definici, než by se
            na první pohled zdálo.

            Slovní popis můžeme zpřesnit např. takto:
            </p>

            <p>
            Medián je takové číslo {"$\\tilde{x}$"}, že počet hodnot v datovém souboru, které jsou {"$\\leq \\tilde{x}$"} je větší nebo roven 50% celkového počtu hodnot,
            a současně počet hodnot, které jsou {"$\\geq \\tilde{x}$"} je také větší nebo roven 50% celkového počtu hodnot.
            Pokud je čísel, které toto kritérium splňují, více, potom tyto hodnoty tvoří interval {"$\\langle x_{(i)}, x_{(i+1)}\\rangle$"} pro nějaké {"$i$"},
            a my za jednoznačnou hondotu mediánu bereme střed tohoto intervalu.
            </p>

            <p>
            Na obrázku níže je vidět, že pro <strong>liché</strong> {"$n$"} existuje jediné číslo, které splňuje toto kritérium - a to prostřední hodnota datového souboru.
            </p>
            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={MedianOdd} style={{width: 280}}/>
            </div>

            <p>
            Na obrázku níže je vidět, že pro <strong>sudé</strong> {"$n$"} existuje celý interval čísel, které splňují toto kritérium - interval
            mezi dvěma prostředními hodnotami datového souboru, včetně jich samých. Za medián bereme průměr těchto dvou hodnot = střed tohoto intervalu.
            </p>
            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={MedianEven} style={{width: 280}}/>
            </div>

            <p>
            Pozn.: Pokud se v datovém souboru vyskytují opakované hodnoty, může se stát, že medián bude jednoznačný i pro sudé {"$n$"} -
            pokud jsou prostřední hodnoty stejné, je interval {"$\\langle x_{(i)}, x_{(i+1)}\\rangle$"} degenerován na jedinou hodnotu.
            Viz ukázka ke konci této kapitoly.
            </p>

            <p>
            Toto zamyšlení nám poslouží jako odrazový můstek k obecné definici kvantilu, která následuje.
            </p>

            <h2 id="dataquantiles-quantile">Kvantil</h2>

            <p>
            Vezměme číslo {"$p$"} v rozmezí od 0 do 1 (bez krajních hodnot).
            Kvantil {"$q_p$"} je hodnota, která dělí uspořádaný statistický soubor na dvě oblasti tak,
            že oblast nalevo od ní obsahuje {"$p \\cdot 100\\%$"} celkového počtu hodnot,
            a oblast napravo od ní obsahuje zbytek, tedy {"$(1-p) \\cdot 100\\%$"} celkového počtu hodnot.
            </p>

            <p>
            Je-li např. {"$p = 0.3$"}, potom vlevo od kvantilu {"$q_{0.3}$"} se nachází 30% hodnot
            a vpravo od něj 70% hodnot.
            </p>

            <p>
            Tento slovní popis trpí přesně stejnými nepřesnostmi, které jsme si ukázali výše u mediánu.
            Uveďme si proto přesný vzorec, a ilustrujme si jej na příkladech:
            {"$$q_p = \\left\\{ \\begin{array}{ll} x_{(\\lceil np \\rceil)} & \\mathrm{pro } \\quad np \\notin \\mathbb{N}, \\\\ \\frac{1}{2}\\left(x_{(np)} + x_{(np+1)}\\right) & \\mathrm{pro } \\quad np \\in \\mathbb{N}. \\end{array} \\right.$$"}
            </p>

            <p>
            Uvažujme statistický soubor o {"$n = 9$"} hodnotách, a {"$p = 0.3$"}. Potom {"$np = 9 \\cdot 0.3 = 2.7$"}.
            Tohle nám v podstatě říká, abychom vzali hodnotu s 'indexem' {"$2.7$"}. Což samozřejmě nejde - index musí být celé číslo -
            takže zaokrouhlíme nahoru na nejbližší celé číslo: {"$\\lceil 2.7 \\rceil = 3$"}.
            Kvantil tedy bude {"$q_{0.3} = x_{(3)}$"}. Detailněji:
            </p>

            <ol>
                <li>Spočítáme {"$np = 9 \\cdot 0.3 = 2.7$"}</li>
                <li>Číslo {"$2.7$"} není celé: {"$2.7 \\notin \\mathbb{N}$"}. To nám říká, abychom použili horní větev vzorce.</li>
                <li>Horní větev po nás chce, abychom číslo {"$2.7$"} zaokrouhlili nahoru: {"$\\lceil np \\rceil = \\lceil 2.7 \\rceil = 3$"}.</li>
                <li>Kvantil tedy bude {"$q_{0.3} = x_{(\\lceil np \\rceil)} = x_{(\\lceil 2.7 \\rceil)} = x_{(3)}$"}. </li>
            </ol>

            <table className="simple-table">
                <tbody>
                <tr>
                    <td>{"$x_{(1)}$"}</td>
                    <td>{"$x_{(2)}$"}</td>
                    <td className='highlight-quantile'>{"$x_{(3)}$"}</td>
                    <td>{"$x_{(4)}$"}</td>
                    <td>{"$x_{(5)}$"}</td>
                    <td>{"$x_{(6)}$"}</td>
                    <td>{"$x_{(7)}$"}</td>
                    <td>{"$x_{(8)}$"}</td>
                    <td>{"$x_{(9)}$"}</td>
                </tr>
                <tr>
                    <td className='element-1'>1.6</td>
                    <td className='element-2'>3.0</td>
                    <td className='element-3 highlight-quantile'>3.5</td>
                    <td className='element-4'>5.2</td>
                    <td className='element-5'>5.4</td>
                    <td className='element-6'>6.2</td>
                    <td className='element-7'>6.7</td>
                    <td className='element-8'>7.1</td>
                    <td className='element-9'>8.5</td>
                </tr>
                </tbody>
            </table>

            <p>
            Uvažujme nyní statistický soubor o {"$n = 10$"} hodnotách, a {"$p = 0.3$"}. Potom {"$np = 10 \\cdot 0.3 = 3$"}.
            Trefili jsme se přesně do indexu, takže za kvantil bereme průměr mezi hodnotou příslušnou tomuto indexu a hodnotou následující:&nbsp;
            {"$q_{0.3} = \\frac{1}{2}\\left(x_{(3)} + x_{(4)}\\right)$"}. Detailněji:
            </p>

            <ol>
                <li>Spočítáme {"$np = 10 \\cdot 0.3 = 3$"}</li>
                <li>Číslo {"$3$"} je celé: {"$3 \\in \\mathbb{N}$"}. To nám říká, abychom použili dolní větev vzorce.</li>
                <li>Dolní větev po nás chce, abychom vzali indexy {"$np = 3$"} a {"$np + 1 = 3 + 1 = 4$"}.</li>
                <li>Kvantil tedy bude {"$q_{0.3} = \\frac{1}{2}\\left(x_{(np)} + x_{(np+1)}\\right) = \\frac{1}{2}\\left(x_{(3)} + x_{(4)}\\right)$"}. </li>
            </ol>

            <table className="simple-table">
                <tbody>
                <tr>
                    <td>{"$x_{(1)}$"}</td>
                    <td>{"$x_{(2)}$"}</td>
                    <td>{"$x_{(3)}$"}</td>
                    <td className='highlight-quantile'></td>
                    <td>{"$x_{(4)}$"}</td>
                    <td>{"$x_{(5)}$"}</td>
                    <td>{"$x_{(6)}$"}</td>
                    <td>{"$x_{(7)}$"}</td>
                    <td>{"$x_{(8)}$"}</td>
                    <td>{"$x_{(9)}$"}</td>
                    <td>{"$x_{(10)}$"}</td>
                </tr>
                <tr>
                    <td className='element-1'>1.6</td>
                    <td className='element-2'>3.0</td>
                    <td className='element-3'>3.5</td>
                    <td className='highlight-quantile'>4.35</td>
                    <td className='element-4'>5.2</td>
                    <td className='element-5'>5.4</td>
                    <td className='element-6'>6.2</td>
                    <td className='element-7'>6.7</td>
                    <td className='element-8'>7.1</td>
                    <td className='element-9'>8.5</td>
                    <td className='element-10'>9.1</td>
                </tr>
                </tbody>
            </table>

            <p>
            Pokud bychom chtěli upřesnit slovní popis kvantilu, vypadalo by to asi takto:
            </p>

            <p>
            Kvantil {"$q_{p}$"} je takové číslo, že počet hodnot v souboru dat, které jsou {"$\\leq q_{p}$"} je větší nebo roven {"$p \\cdot 100\\%$"} celkového počtu hodnot,
            a zároveň počet hodnot, které jsou {"$\\geq q_{p}$"} je větší nebo roven {"$(1-p) \\cdot 100\\%$"} celkového počtu hodnot.
            Pokud toto kritérium splňuje více hodnot, potom tyto hodnoty tvoří interval {"$\\langle x_{(i)}, x_{(i+1)}\\rangle$"} pro nějaké {"$i$"}, a my za jednoznačnou hondotu kvantilu
            volíme střed tohoto intervalu.
            </p>

            <p>
            Ukážeme si nyní grafický postup, kterým můžeme určit kvantil. Je to přesná analogie k slovnímu popisu i k vzorci.
            </p>

            <ol>
                <li>Hodnoty {"$x_{(i)}$"} vyneseme na osu x.</li>
                <li>Zkonstruujeme 'schody': Jdeme zleva, a kdykoli narazíme na další bod {"$x_{(i)}$"}, na ose y poskočíme nahoru o {"$\\frac{1}{n}$"}.
                Tj. jakmile narazíme na {"$x_{(1)}$"}, na ose y poskočíme na {"$\\frac{1}{n}$"}, potom jakmile dojdeme na {"$x_{(2)}$"}, na ose y poskočíme na {"$\\frac{2}{n}$"}, atd.
                </li>
                <li>Na ose y si vyznačíme {"$p$"} určovaného kvantilu, a na této úrovni vedeme vodorovnou čáru zleva doprava,
                až narazíme na schody.
                </li>
                <li>Nastane jeden ze dvou případů:<br/>
                    - Buď na schody narazíme na vertikále - to znamená, že na ose x
                    jsme na pozici {"$x_{(i)}$"} pro nějaké {"$i$"}. Tato hodnota je hledaný kvantil {"$q_p$"}.<br/>
                    - Nebo na schody narazíme přesně na rovině určitého schodu - to znamená, že se s plochou
                    shodiště protínáme (kryjeme) v x-oých souřadnicích {"$\\langle x_{(i)}, x_{(i+1)}\\rangle$"}
                    pro nějaké {"$i$"}. Kvantil je pak průměrem těchto dvou hodnot.
                </li>
            </ol>

            <p>
            Následující grafy ukazují tento princip pro oba příklady výše. V prvním případě je {"$n = 9$"}, takže máme
            devět schodů, jejichž výška je {"$\\frac{1}{9}$"}. Ve druhém případě je {"$n = 10$"}, takže máme deset schodů,
            jejichž výška je {"$\\frac{1}{10}$"}. Grafy jsou vykresleny v měřítku.
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={Staircase} style={{width: 650}}/>
            </div>


            <h2 id="dataquantiles-special">Speciální kvantily: kvartily, percentily atd.</h2>

            <p>To nejtěžší máme za sebou - tato část by už měla být snadná.</p>

            <p>
            Kvantily, které dělí uspořádaný soubor dat na čtvrtiny, se nazývají <strong>kvartily</strong>.
            Kvantil {"$q_{0.25}$"} je <strong>dolní kvartil</strong> (také <strong>první kvartil</strong>), {"$q_{0.5}$"} je medián
            a {"$q_{0.75}$"} je <strong>horní kvartil</strong> (také <strong>třetí kvartil</strong>).
            </p>

            <p>
            Obecně uvažujeme dělení souboru dat na {"$k$"} stejných částí.
            Máme potom kvantily
            {"$$q_{\\frac{1}{k}}, q_{\\frac{2}{k}}, q_{\\frac{3}{k}},  \\ldots, q_{\\frac{k-1}{k}}$$"}
            Ve výpočetním vzorci pro kvantil {"$q_p$"} je {"$p = \\frac{j}{k}$"} pro {"$j = 1, 2, \\ldots, k-1$"}.
            {/* a {"$np = n\\frac{j}{k}$"} pro {"$j = 1, 2, \\ldots, k-1$"}. */}
            </p>

            <table className="data-table">
                <thead>
                    <tr>
                        <th>{"$k$"}</th>
                        <th>název</th>
                        <th>kvantily</th>
                        <th>slovně</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>2</td>
                        <td>medián</td>
                        <td>{"$q_{0.5}$"}</td>
                        <td>medián ; je právě jeden</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>kvartily</td>
                        <td>{"$q_{0.25}, q_{0.5}, q_{0.75}$"}</td>
                        <td>dolní kvartil, medián ('prostřední kvartil'), horní kvartil</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>decily</td>
                        <td>{"$q_{0.1}, q_{0.2}, \\ldots, q_{0.9}$"}</td>
                        <td>první decil, druhý decil, ..., devátý decil</td>
                    </tr>
                    <tr>
                        <td>100</td>
                        <td>percentily</td>
                        <td>{"$q_{0.01}, q_{0.02}, \\ldots, q_{0.99}$"}</td>
                        <td>percentil 1, pecentil 2, ... percentil 99</td>
                    </tr>
                </tbody>
            </table>

            <p>
            Nadšenci do lingvistiky mohou používat tercily (3), kvintily (5), oktily (8), atp.
            </p>

            <p>
            Na obrázku níže je ilustrován význam kvartilů; bílá čísla závorkách jsou indexy {"$(i)$"} hodnot {"$x_{(i)}$"}:
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={Quartiles} style={{width: 550}}/>
            </div>

            <h2 id="dataquantiles-zero-and-one">Kvantily {"$q_0$"} a {"$q_1$"}</h2>

            <p>
            Tyto kvantily jsou v popisné statistice nestandardní a běžně se nedefinují.
            </p>

            <p>
            Problém je ten, že vzorec pro výpočet kvantilu by pro {"$p = 0$"} dal hodnotu {"$q_{0} = \\frac{1}{2}\\left(x_{(0)} + x_{(1)}\\right)$"},
            což je nesmysl - nemáme žádný index {"$0$"} - hondota {"$x_{(0)}$"} neexistuje.
            Řešením je definovat {"$q_0$"} explicitně jako minimum: {"$q_0 = x_{(1)}$"}.
            Jelikož tohle nepřináší žádnou novou informaci, běžně se to nezavádí.
            </p>

            <p>
            Podobně pro {"$p = 1$"}: vzorec dává hodnotu {"$q_{1} = \\frac{1}{2}\\left(x_{(n)} + x_{(n+1)}\\right)$"},
            přičemž index {"$n+1$"} nemáme - hodnota {"$x_{(n+1)}$"} neexistuje.
            Řešením je definovat {"$q_1$"} explicitně jako maximum: {"$q_1 = x_{(n)}$"}.
            A protože tohle nepřináší žádnou novou informaci, běžně se to nezavádí.
            Viz např. <a href='https://en.wikipedia.org/wiki/Quantile'  target="_blank" rel="noopener noreferrer" className="external-link">Wikipedie</a>.
            </p>

            <p>
            Pozn: V teorii pravděpodobnosti (na rozdíl od popisné statistiky) jsou u náhodné veličiny kvantily {"$q_0$"} a {"$q_1$"} definované,
            ale nemusejí nutně existovat (pokud náhodná veličina může nabývat libovolně malých a/nebo libovolně velkých hodnot
            - jako je tomu např. u normálního rozdělení). Pokud bychom pracovali s rozšířenou reálnou osou {"$\\mathbb{R} \\cup \\left\\{-\\infty, +\\infty\\right\\}$"}
            &nbsp;pak bychom mohli říct, že tyto kvantily vždy existují, ale někdy mohou být nekonečné: {"$q_0 = -\\infty$"} a {"$q_1 = +\\infty$"}.
            Význam této konstrukce je maximálně formální - pro zjednodušení zápisů např. v důkazech.
            </p>

            <p>
            Definici kvantilu pomocí pravděpodobnosti si uvedeme později.
            </p>


            {/* Je tedy potřeba upřesnit slovní popis takto:

            Kvantil {"$q_p$"} je  */}

            {/* špatný záznam na české wikipedii https://cs.wikipedia.org/wiki/Kvantil */}

            <h2 id="dataquantiles-illustration-deciles">Ilustrace decilů</h2>
            <p>Obrázek ilustuje polohu decilů pro soubory dat o velikosti 9 až 12.</p>
            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={Deciles} style={{width: 420}}/>
            </div>

            <h2 id="dataquantiles-illustration-median">Poloha mediánu při opakovaných hodnotách v datech</h2>

            <p>
            Může to vypadat divně, ale je to korektní:
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={MedianDegenerate} style={{width: 280}}/>
            </div>

            <h2 id="dataquantiles-altdef">Alternativní definice kvantilu</h2>
            <p>
            Lze použít i ekvivalentní definici kvantilu (rozdíl oproti předchozí definici je zvýrazněn modře):
            </p>
            <p>
            Kvantil {"$q_{p}$"} je takové číslo, že:
            </p>
            <ul>
                <li>počet hodnot, které jsou {"$\\leq q_{p}$"} je větší nebo roven {"$p \\cdot 100\\%$"} celkového počtu hodnot,</li>
                <li>a zároveň počet hodnot, které jsou <span style={{"color": "blue"}}>{"$< q_{p}$"} je menší nebo roven {"$p \\cdot 100\\%$"}</span> celkového počtu hodnot.</li>
            </ul>
            <p>
            Pokud toto kritérium splňuje více hodnot, potom tyto hodnoty tvoří interval {"$\\langle x_{(i)}, x_{(i+1)}\\rangle$"} pro nějaké {"$i$"}, a my za jednoznačnou hondotu kvantilu
            volíme střed tohoto intervalu.
            </p>

            <p>
            Mechanismus fungování této definice ilustrujeme na mediánu {"$q_{0.5}$"} pro sudé a liché {"$n$"}.
            Prázdné kolečko indikuje otevřený interval (hodnota na které stojíme, se nezapočítává) - to odpovídá
            použití <span style={{"color": "blue"}}>ostré nerovnosti {"$<$"}</span> použité v druhém bodu definice.
            </p>
            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={MedianOddAlt} style={{width: 280}}/>
            </div>
            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={MedianEvenAlt} style={{width: 320}}/>
            </div>

        </div>
        </div>
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

