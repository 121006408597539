import React, { useEffect, useRef, useState } from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import { TOC, TOCProps, defineTOCElements } from '~/TOC';

const rv2_Ddiagram = require('../assets/images/rv2-Ddiagram.png');


export const RandomVariable2Meta = {
    title: "Náhodná veličina: E, D",
    shortTitle: "Náhodná veličina: E, D",
    path: Path.random_variable2,
    element: (sectionNumber:string) => <RandomVariable2 sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}


const TOCSpec = [
    "rv2-summary-e",
    "rv2-summary-d",
    "rv2-intro",
    "rv2-dd",
    "rv2-dd-e",
    "rv2-dd-d",
    "rv2-dd-dase",
    "rv2-cd",
    "rv2-cd-e",
    "rv2-cd-d",
];


export function RandomVariable2({sectionNumber}: {sectionNumber: string}) {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);

    return (
        <MathJaxWrapper>
            <MathJax>
            <div className="chapter-container">
            <div className="centered-content">
            <div className="card" ref={chapterRef}>

            <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{RandomVariable2Meta.title}</h1>


            <h2 id="rv2-summary-e">Shrnutí E</h2>

            <p>Střední hodnotu {"$E(X)$"} náhodné veličiny {"$X$"} počítáme takto:</p>

            <table className='simple-table simple-table-ed'>
                <thead>
                    <tr>
                        <th></th>
                        <th>Diskrétní</th>
                        <th>Spojitá</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                        Máme zadáno přenesené rozdělení pravděpodobnosti
                        na prostoru hodnot náhodné veličiny, přes které běží
                        proměnná {"$x.$"}
                        </td>
                        <td>
                            {`$$
                                E(X) = \\sum_{x} x p(x)
                            $$`}
                        </td>
                        <td>
                            {`$$
                                E(X) = \\int_{-\\infty}^{\\infty} x f(x) dx
                            $$`}
                        </td>
                    </tr>
                    <tr>
                        <td>
                        Máme zadáno výchozí rozdělení pravděpodobnosti
                        a náhodnou veličinu {"$X$"} jako funkci (vzorcem).
                        </td>
                        <td>
                            {`$$
                                E(X) = \\sum_{\\omega} X(\\omega) p(\\omega)
                            $$`}
                        </td>
                        <td>
                            {`$$
                                E(X) = \\int_{-\\infty}^{\\infty} X(\\omega) f(\\omega) \\text{d}\\omega
                            $$`}
                        </td>
                    </tr>
                </tbody>
            </table>

            <p><span style={{color: 'chocolate'}}>Př. [diskrétní, přenesené rozdělení]</span>: Náhodná veličina má binomické rozdělení pravděpodobnosti s parametry p=0.5, n=2 -
            tj. nabývá hodnot 0, 1, 2 s pravděpodobnostmi 0.25, 0.5, 0.25.
            {`$$
                E(X) = 0 \\cdot p(0) + 1 \\cdot p(1) + 2 \\cdot p(2) = 0 \\cdot 0.25 + 1 \\cdot 0.5 + 2 \\cdot 0.25 = 1.
            $$`}
            </p>
            <p><span style={{color: 'chocolate'}}> Př. [diskrétní, původní rozdělení]</span>: Střílíme dvakrát do terče, pravděpodobnost zásahu je pokaždé 0.5, střelby jsou nezávislé.
            Máme {"$\\Omega = \\{(0,0), (1,0), (0,1), (0,0)\\}$"}, kde 1 znamená zásah, 0 minutí. Rozdělení je rovnoměrné: {"$p((x,y)) = 0.25$"}.
            Náhodná veličina je počet zásahů, a má vzorec {"$X((x,y)) = x + y$"}.
            {`$$\\begin{align*}
                E(X) & = 0 \\cdot p(0,0) + 1 \\cdot p(1,0) + 1 \\cdot p(0,1) + 2 \\cdot p(1,1) = \\\\
                     & = 0 \\cdot 0.25 + 1 \\cdot 0.25 + 1 \\cdot 0.25 + 2 \\cdot 0.25 = 1.
            \\end{align*}$$`}
            </p>

            <p><span style={{color: 'chocolate'}}> Př. [spojitá, přenesené rozdělení]</span>: Náhodná veličina má normální rozdělení s parametry {"$\\mu = 3$"}, {"$\\sigma = 2$"}.
            {`$$
                E(X) = \\int_{-\\infty}^{\\infty} x f(x) dx = \\int_{-\\infty}^{\\infty} x \\frac{1}{2\\sqrt{2\\pi}} e^{-(x-3)^2/8} dx = 1.
            $$`}
            <i>Substituce {"$x-3 = t$"}, rozdělení na dva integrály, první dá 3, druhý 0 protože integruje lichou funkci na sym. intervalu.</i>
            </p>

            <p><span style={{color: 'chocolate'}}> Př. [spojitá, původní rozdělení]</span>: Máme hustotu {"$f(v)$"} normálního rozdělení s parametry {"$\\mu = 3$"}, {"$\\sigma = 2$"}.
            Náhodná veličina je {"$X(v) = \\frac{1}{2}mv^2$"}.
            {`$$
                E(X) = \\int_{-\\infty}^{\\infty} X(v) f(v) dv = \\int_{-\\infty}^{\\infty} \\frac{1}{2}mv^2 \\frac{1}{2\\sqrt{2\\pi}} e^{-(v-3)^2/8} dv = 5.5m
            $$`}
            Toto může (typově) představovat případ, kdy se nějaké částice pohybují jen v jednom směru (1D), jejich
            rychlosti jsou rozdělené původním (normálním) rozdělením, a my počítáme jejich střední kinetickou energii
            (náhodná veličina je kinetická energie).
            </p>

            {/* \int_{-\infty}^{\infty} X(v) f(v) dv = \int_{-\infty}^{\infty} \frac{1}{2}mv^2 \frac{1}{2\sqrt{2\pi}} e^{\-\frac{(v-3)^2}{8}} \dv */}

            <p>Poznámka k tabulce: Jak vidíme, tak ve druhém řádku lze běžně místo {"$\\omega$"} použít jakýkoli
            jiný symbol pro proměnnou v původním prostoru (např. {"$v$"}). Běžně se také používá i symbol {"$x$"}.
            Máme např. lineární hustotu {"$f(x) = 2x$"} na intervalu {"$\\langle0, 1\\rangle$"},
            a náhodnou veličinu {"$g(x) = x^2$"}. Jaká je střední hodnota {"$E(g)$"}?
            {`$$
                E(g) = \\int_0^1 x^2 \\cdot 2x \\text{d}x = \\int_0^1 2x^3 \\text{d}x = \\left[\\frac{1}{2}x^4\\right]_0^1 = \\frac{1}{2}.
            $$`}
            </p>

            {/* <p>
            Oba případy (řádky) se dají považovat za vyjádření téhož i z tohoto pohledu:
            Pokud v prvním řádku interpretujeme {"$x$"} na první pozici (v součinu {"$xp(x)$"} a {"$xf(x)$"})
            jako hodnotu náhodné veličiny {"$X(x) = x$"} /tj. za hodnotu náhodné veličiny bereme přímo číslo {"$x$"}, bez další transformace/,
            můžeme vztahy zapsat jako {"$E(X) = \\sum_{x} X(x) p(x)$"} a {"$E(X) = \\int_{-\\infty}^{\\infty} X(x) f(x) dx$"},
            což je totéž, co druhý řádek, jen zapsaný v proměnné {"$x$"} místo {"$\\omega$"}.
            </p> */}

            {/*             pokud si v prvním řádku uvědomíme,
            že {"$x$"} na první pozici (v součinu {"$xp(x)$"} a {"$xf(x)$"})
            říká, že za hodnotu náhodné veličiny bereme přímo hodnotu  {"$x$"}.
            To znamená, že vlastně počítáme s náhodnou veličinou {"$X(x) = x$"}.
            Když si na tom místě {"$x$"} napíšeme jako funkci {"$X(x)$"}, získáme druhý řádek napsaný v proměnné {"$x$"} místo {"$\\omega$"}. */}

            <p>
            <strong>Značení</strong>: Místo {"$E(X)$"} se běžně píše {"$EX$"} - závorky se vynechávají.
            Chceme-li střední hodnotu odvozené veličiny {"$Y = X^2$"}, zápis {"$EX^2$"} znamená {"$E(X^2)$"};
            naopak druhá mocnina střední hodnoty veličiny {"$X$"} se značí {"$(EX)^2$"}. Tato dvě čísla obecně nejsou stejná (!).
            V této knize spíše píšeme více závorek, abychom se vyhnuli možné nejednoznačnosti (i když opticky se to někdy čte hůř).
            </p>

            <h2 id="rv2-summary-d">Shrnutí D</h2>

            <p>Rozptyl {"$D(X)$"}náhodné veličiny {"$X$"} je definovaný pomocí střední hodnoty jako:
            {`$$
                D(X) = E\\Big[\\big(X - E(X)\\big)^2\\Big]
            $$`}
            Smysl je ten, že si, a) spočítáme střední hodnotu {"$E(X)$"}, a potom b) definujeme odvozenou náhodnou veličinu {"$Y = (X - E(X))^2$"};
            rozptyl {"$D(X)$"} se pak spočítá jako střední hodnota této odvozené náhodné veličiny: {"$D(X) = E(Y) = E\\Big[\\big(X - E(X)\\big)^2\\Big]$"}.
            </p>
            <p>
            <strong>Značení</strong> se běžně zjednodušuje takto (viz poznámka ke značení střední hodnoty):
            {`$$
                D(X) = E\\big(X - E(X)\\big)^2 = E\\big(X - EX\\big)^2
            $$`}
            </p>
            <p>Manipulací s integrály a sumami se dá ukázat, že obecně platí i alternativní vztah:
            {`$$
                D(X) = E(X^2) - \\big(E(X)\\big)^2
            $$`}
            </p>

            <p>Rozptyl počítáme takto:</p>

            <table className='simple-table simple-table-ed'>
                <thead>
                    <tr>
                        <th></th>
                        <th>Diskrétní</th>
                        <th>Spojitá</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                        Máme zadáno přenesené rozdělení pravděpodobnosti
                        na prostoru hodnot náhodné veličiny, přes které běží
                        proměnná {"$x.$"}
                        </td>
                        <td>
                            {`$$
                                D(X) = \\sum_{x} \\Big(x - E(X)\\Big)^2 p(x)
                            $$`}
                        </td>
                        <td>
                            {`$$
                                D(X) = \\int_{-\\infty}^{\\infty} \\Big(x - E(X)\\Big)^2 f(x) dx
                            $$`}
                        </td>
                    </tr>
                    <tr>
                        <td>
                        Máme zadáno výchozí rozdělení pravděpodobnosti
                        a náhodnou veličinu {"$X$"} jako funkci (vzorcem).
                        </td>
                        <td>
                            {`$$
                                D(X) = \\sum_{\\omega} \\Big(X(\\omega) - E(X)\\Big)^2 p(\\omega)
                            $$`}
                        </td>
                        <td>
                            {`$$
                                D(X) = \\int_{-\\infty}^{\\infty} \\Big(X(\\omega) - E(X)\\Big)^2 f(\\omega) \\text{d}\\omega
                            $$`}
                        </td>
                    </tr>
                </tbody>
            </table>

            <p><span style={{color: 'chocolate'}}>Př. [diskrétní, přenesené rozdělení] - pokračování</span>:
            {`$$
                D(X) = (0 - 1)^2 \\cdot p(0) + (1 - 1)^2 \\cdot p(1) + (2 - 1)^2 \\cdot p(2) = 0.5.
            $$`}
            </p>

            <p><span style={{color: 'chocolate'}}> Př. [diskrétní, původní rozdělení] - pokračování</span>:
            {`$$\\begin{align*}
                D(X) & = (0 - 1)^2 \\cdot p(0,0) + (1 - 1)^2 \\cdot p(1,0) + (1 - 1)^2 \\cdot p(0,1) + (2 - 1)^2 \\cdot p(1,1) = \\\\
                     & = 1 \\cdot 0.25 + 0 \\cdot 0.25 + 0 \\cdot 0.25 + 1 \\cdot 0.25 = 0.5.
            \\end{align*}$$`}
            </p>

            <p><span style={{color: 'chocolate'}}> Př. [spojitá, přenesené rozdělení] - pokračování</span>:
            {`$$
                D(X) = \\int_{-\\infty}^{\\infty} \\Big(x - 3\\Big)^2 f(x) dx = \\int_{-\\infty}^{\\infty} \\Big(x - 3\\Big)^2 \\frac{1}{2\\sqrt{2\\pi}} e^{-(x-1)^2/8} dx = 2
            $$`}
            </p>

            <p><span style={{color: 'chocolate'}}> Př. [spojitá, původní rozdělení] - pokračování</span>:
            {`$$
                D(X) = \\int_{-\\infty}^{\\infty} \\Big(\\frac{1}{2}mv^2 - 5.5m\\Big)^2 \\frac{1}{2\\sqrt{2\\pi}} e^{-(v-3)^2/8} dv = 25.25m^2
            $$`}
            </p>

            {/* \int_{-\infty}^{\infty} \Big(\frac{1}{2}mv^2 - 5.5m\Big)^2 \frac{1}{2\sqrt{2\pi}} e^{-(v-3)^2/8} \dv */}

            <h2 id="rv2-intro">Úvod</h2>

            <p>
            Jak jsme si ukázali v předchozí kapitole, náhodná veličina přenáší rozdělení pravděpodobnosti z prostoru elementárních jevů do prostoru reálných čísel (svých hodnot).
            Máme tedy v principu k dispozici dvě rozdělení - původní (v prostoru elementárních jevů) a přenesené (v prostoru hodnot).
            </p>

            <p>Zde si ukážeme výpočet střední hodnoty {"$E$"} ("expectation") a rozptylu {"$D$"} (z "dispersion") náhodné veličiny.
            Rozptyl se někdy také označuje jako {"$Var$"} ("variance"  /ˈveə.ri.əns/). V angličtině se každopádně používá slovní vyjádření
            'variance', ať už se značí {"$D$"} nebo {"$Var$"}.
            </p>

            <h2 id="rv2-dd">Diskrétní rozdělení</h2>

            <p>Dejme tomu, že máme prostor elementární jevů
            {`$$
                \\Omega = \\{-3, -2, -1, 0, 1, 2, 3\\}
            $$`}
            s pravděpodobnostmi
            </p>
            <table className='simple-table'>
                {/* <thead>
                    <tr>
                        <td></td>
                    </tr>
                </thead> */}
                <tbody>
                    <tr>
                        <td>{"$\\omega$"}</td>
                        <td>{"$-3$"}</td>
                        <td>{"$-2$"}</td>
                        <td>{"$-1$"}</td>
                        <td>{"$0$"}</td>
                        <td>{"$1$"}</td>
                        <td>{"$2$"}</td>
                        <td>{"$3$"}</td>
                    </tr>
                    <tr>
                        <td>{"$P(\\{\\omega\\})$"}</td>
                        <td>{"$0.05$"}</td>
                        <td>{"$0.10$"}</td>
                        <td>{"$0.20$"}</td>
                        <td>{"$0.30$"}</td>
                        <td>{"$0.20$"}</td>
                        <td>{"$0.10$"}</td>
                        <td>{"$0.05$"}</td>
                    </tr>
                </tbody>
            </table>

            <p>
            Uvažujme náhodnou veličinu {"$X$"}, která přiřazuje každému elementárnímu jevu {"$\\omega$"} hodnotu
            {`$$
               X(\\omega) = |\\omega|.
            $$`}
            </p>

            <p>Přenesené rozdělení pravděpodobnosti náhodné veličiny {"$X$"} je:
            </p>
            <table className='simple-table simple-table-ed'>
                <tbody>
                    <tr>
                        <td>{"$x$"}</td>
                        <td>{"$0$"}</td>
                        <td>{"$1$"}</td>
                        <td>{"$2$"}</td>
                        <td>{"$3$"}</td>
                    </tr>
                    <tr>
                        <td>{"$P_X(\\{x\\}) = p(\\{X = x\\})$"}</td>
                        <td>{"$0.30$"}</td>
                        <td>{"$0.40$"}</td>
                        <td>{"$0.20$"}</td>
                        <td>{"$0.10$"}</td>
                    </tr>
                </tbody>
            </table>

            <p>
            Jak je zvykem, místo {"$P(\\{\\omega\\})$"} píšeme zkratkou {"$p(\\omega)$"}, a místo {"$P_X(\\{x\\})$"} píšeme zkratkou {"$p_X(x)$"}.
            </p>

            <h3 id="rv2-dd-e">Střední hodnota</h3>

            <p>Střední hodnota {"$E(X)$"} náhodné veličiny {"$X$"} se počítá buď z
            původního rozdělení {"$p$"}, nebo z přeneseného rozdělení {"$p_X$"}:

            {`$$\\begin{align*}
                E(X) & = \\sum_{\\omega} X(\\omega) p(\\omega), \\\\
                E(X) & = \\sum_{x} x p_X(x).
            \\end{align*}$$`}

            V prvním případě suma probíhá přes všech prvky původního prostoru {"$\\Omega$"}, na každém z nich vyhodnotíme
            funkčním předpisem funkci {"$X$"}, a získanou hodnotu vynásobíme pravděpodobností {"$p(\\omega)$"}.
            V druhém případě suma probíhá už přes různé hodnoty funkce {"$X$"}, které násobíme přenesenými pravděpodobnostmi, které jim přísluší.
            </p>

            <p>V obou případech v sumě stojí součin <strong>hodnoty náhodné veličiny</strong> a pravděpodobnosti.
            V prvním případě je hodnota náhodné veličiny {"$X(\\omega)$"}, v druhém případě je to {"$x$"}, protože
            proměnná {"$x$"} probíhá přes obor hodnot náhodné veličiny {"$X$"}.
            </p>

            <p>Výpočet z původního rozdělení:
            {`$$\\small \\begin{align*}
                E(X) & = \\sum_{\\omega} X(\\omega) p(\\omega) \\\\
                     & = X(-3) p(-3) + X(-2) p(-2) + X(-1) p(-1) + X(0) p(0) + X(1) p(1) + X(2) p(2) + X(3) p(3) \\\\
                     & = |-3| \\cdot 0.05 + |-2| \\cdot 0.10 + |-1| \\cdot 0.20 + |0| \\cdot 0.30 + |1| \\cdot 0.20 + |2| \\cdot 0.10 + |3| \\cdot 0.05 \\\\
                     & = \\textcolor{green}{3 \\cdot 0.05 + 2 \\cdot 0.10 + 1 \\cdot 0.20 + 0 \\cdot 0.30 + 1 \\cdot 0.20 + 2 \\cdot 0.10 + 3 \\cdot 0.05} \\\\
                     & = 1.1
            \\end{align*}$$`}
            </p>

            <p>Výpočet z přeneseného rozdělení:
            {`$$\\begin{align*}
                E(X) & = \\sum_{x} x p_X(x) \\\\
                     & = \\textcolor{blue}{0 \\cdot 0.30 + 1 \\cdot 0.40 + 2 \\cdot 0.20 + 3 \\cdot 0.10} \\\\
                     & = 1.1
            \\end{align*}$$`}
            </p>

            <p>Oba výpočty jsou ekvivalentní, jak vidíme uzávorkováním:
            {`$$\\begin{align*}
                &= \\textcolor{green}{3 \\cdot 0.05 + 2 \\cdot 0.10 + 1 \\cdot 0.20 + 0 \\cdot 0.30 + 1 \\cdot 0.20 + 2 \\cdot 0.10 + 3 \\cdot 0.05} \\\\
                &= 3 \\cdot (0.05 + 0.05) + 2 \\cdot (0.10 + 0.10) +  1 \\cdot (0.20 + 0.20) + 0 \\cdot 0.30 \\\\
                & = \\textcolor{blue}{0 \\cdot 0.30 + 1 \\cdot 0.40 + 2 \\cdot 0.20 + 3 \\cdot 0.10}.
            \\end{align*}$$`}
            Toto uzávorkování přesně odpovídá výpočtu přeneseného rozdělení {"$p_X$"} hodnot náhodné veličiny {"$X$"}.
            </p>

            <p>
            Podle potřeby intuitivně používáme to rozdělení, které máme v dané úloze k dispozici.
            </p>

            <p>
            Když je úloha zadána jako "Máme náhodnou veličinu s nějakým (rovnoměrným, binomickým, ...) rozdělením pravděpodobnosti",
            potom dané rozdělení je rozdělení <strong>hodnot</strong> náhodné veličiny, tedy ono <strong>přenesené</strong> rozdělení,
            a střední hodnotu počítáme jako {"$E(X) = \\sum_x x p_X(x)$"}. Máme zadáno rovnou {"$p_X$"},
            a původní prostror {"$\\Omega$"} nemáme a nepotřebujeme jej.
            </p>

            <p>
            Když je úloha zadána jako "Máme náhodný pokus s nějakými výsledky, které mají nějaké (rovnoměrné, binomické, ...)
            rozdělní pravděpodobnosti, a
            počítáme na nich nějakou hodnotu nějakým vzorcem {"$f$"}", potom dané rozdělení je <strong>původní</strong> rozdělení (ne přenesené).
            Střední hodnotu funkce (tj. náhodné veličiny) {"$g$"} počítáme jako {"$E(g) = \\sum_\\omega g(\\omega) p(\\omega)$"}.
            (Místo {"$g$"} se pro funkci běžně používá symbol {"$X$"}.)
            Alternativně si můžeme výpočet rozdělit na dvě části - v první si spočítáme přenesené rozdělení
            hodnot náhodné veličiny {"$g$"}, v druhé si spočítáme střední hodnotu z tohoto přeneseného rozdělení. Příklad v této
            části ukázal, že je to ekvivalentní.
            </p>

            <h3 id="rv2-dd-d">Rozptyl</h3>

            <p>U rozptylu je situace analogická, s malým detailem navíc. Rozptyl {"$D(X)$"} náhodné veličiny {"$X$"} se počítá buď z
            původního rozdělení {"$p$"}, nebo z přeneseného rozdělení {"$p_X$"}:

            {`$$\\begin{align*}
                D(X) & = \\sum_{\\omega} (X(\\omega) - E(X))^2 p(\\omega), \\\\
                D(X) & = \\sum_{x} (x - E(X))^2 p_X(x).
            \\end{align*}$$`}
            </p>

            <p>Výpočet z původního rozdělení:
            {`$$\\small \\begin{align*}
                D(X) & = \\sum_{\\omega} (X(\\omega) - E(X))^2 p(\\omega) \\\\
                     & = (X(-3) - E(X))^2 p(-3) + (X(-2) - E(X))^2 p(-2) + \\cdots + (X(3) - E(X))^2 p(3) \\\\
                     & = (|-3| - 1.1)^2 \\cdot 0.05 + (|-2| - 1.1)^2 \\cdot 0.10 + \\cdots + (|3| - 1.1)^2 \\cdot 0.05 \\\\
                     & = 3.61 \\cdot 0.05 + 0.81 \\cdot 0.10 + 0.01 \\cdot 0.20 + 1.21 \\cdot 0.30 + 0.01 \\cdot 0.20 + 0.81 \\cdot 0.10 + 3.61 \\cdot 0.05 \\\\
                     & = 0.89
            \\end{align*}$$`}
            </p>
            {/* 3.61 * 0.05 + 0.81 * 0.10 + 0.01 * 0.20 + 1.21 * 0.30 + 0.01 * 0.20 + 0.81 * 0.10 + 3.61 * 0.05 \\\\ */}
            <p>Výpočet z přeneseného rozdělení:
            {`$$\\small \\begin{align*}
                D(X) & = \\sum_{x} (x - E(X))^2 p_X(x) \\\\
                     & = (0 - 1.1)^2 \\cdot 0.30 + (1 - 1.1)^2 \\cdot 0.40 + (2 - 1.1)^2 \\cdot 0.20 + (3 - 1.1)^2 \\cdot 0.10 \\\\
                     & = 1.21 \\cdot 0.30 + 0.01 \\cdot 0.40 + 0.81 \\cdot 0.20 + 3.61 \\cdot 0.10 \\\\
                     & = 0.89
            \\end{align*}$$`}
            </p>
            {/* 1.21 * 0.30 + 0.01 * 0.40 + 0.81 * 0.20 + 3.61 * 0.10  */}

            <p>
            Komentáře u střední hodnoty platí úplně analogicky i pro rozptyl: Podle toho, jak je úloha zadaná, pochopíme
            jestli máme zadáno původní nebo přenesené rozdělení, a podle toho zvolíme vztah pro výpočet.
            </p>

            <h3 id="rv2-dd-dase">Rozptyl jako střední hodnota</h3>

            <p><strong>A.</strong> Ve vzorci pro rozptyl {"$D(X)$"} je vidět, že se jedná o střední hodnotu nějaké funkce {"$Y$"}:
            {`$$\\begin{align*}
                D(X) & = \\sum_{\\omega} \\textcolor{red}{(X(\\omega) - E(X))^2} p(\\omega) =  \\sum_{\\omega} \\textcolor{red}{Y(\\omega)} p(\\omega) = E(\\textcolor{red}{Y}), \\\\
            \\end{align*}$$`}
            kde {"$Y(\\omega) = (X(\\omega) - E(X))^2$"}.
            Máme-li tedy výchozí náhodnou veličinu {"$X$"}, a zajímá nás její rozptyl,
            definujeme si vlastně novou náhodnou veličinu {"$Y = (X - E(X))^2 = (X - \\text{const})^2$"}, a počítáme její střední hodnotu:
            {`$$\\begin{align*}
                D(X) & = E\\Big[\\big(X - E(X)\\big)^2\\Big].
            \\end{align*}$$`}
            </p>

            <p>V našem příkladu je nová veličina {"$Y$"} definovaná jako
            {`$$
                Y(\\omega) = \\big(X(\\omega) - 1.1\\big)^2 = \\big(|\\omega| - 1.1\\big)^2.
            $$`}
            </p>

            <p><strong>B.</strong> Kromě toho si všiměme, že i druhý vztah - tj. {"$D(X) = \\sum_{x} (x - E(X))^2 p_X(x)$"} -
            počítá střední hodnotu nějaké funkce {"$Y'$"}:
            {`$$
                D(X) = \\sum_{x} \\textcolor{purple}{(x - E(X))^2} p_X(x) = \\sum_{x} \\textcolor{purple}{Y'(x)} p_X(x) = E(\\textcolor{purple}{Y'}),
            $$`}
            a to pomocí přeneseného rozdělení {"$p_X$"} veličiny {"$X$"}. Veličina {"$Y'$"} je na tomto prostoru definována jako
            {`$$
                Y'(x) = (x - E(X))^2 = (x - 1.1)^2.
            $$`}
            </p>

            <br/>

            <p><strong>A. (navázání)</strong> Kdybychom chtěli počítat střední hodnotu {"$Y$"} podle přeneseného rozdělení veličiny {"$Y$"},
            vypadalo by to takto:
            {`$$
                E(Y) = \\sum_{y} y p_Y(y).
            $$`}
            </p>
            <p>Výchozí rozdělení {"$p$"}:</p>
            <table className='simple-table simple-table-ed'>
                <tbody>
                    <tr>
                        <td>{"$p(\\omega)$"}</td>
                        <td>{"$0.05$"}</td>
                        <td>{"$0.10$"}</td>
                        <td>{"$0.20$"}</td>
                        <td>{"$0.30$"}</td>
                        <td>{"$0.20$"}</td>
                        <td>{"$0.10$"}</td>
                        <td>{"$0.05$"}</td>
                    </tr>
                    <tr>
                        <td>{"$\\omega$"}</td>
                        <td>{"$-3$"}</td>
                        <td>{"$-2$"}</td>
                        <td>{"$-1$"}</td>
                        <td>{"$0$"}</td>
                        <td>{"$1$"}</td>
                        <td>{"$2$"}</td>
                        <td>{"$3$"}</td>
                    </tr>
                    <tr>
                        <td>{"$X(\\omega) = |\\omega|$"}</td>
                        <td>{"$3$"}</td>
                        <td>{"$2$"}</td>
                        <td>{"$1$"}</td>
                        <td>{"$0$"}</td>
                        <td>{"$1$"}</td>
                        <td>{"$2$"}</td>
                        <td>{"$3$"}</td>
                    </tr>
                    <tr>
                        <td>{"$Y(\\omega) = (X(\\omega) - E(X))^2 = (|\\omega| - 1.1)^2$"}</td>
                        <td>{"$3.61$"}</td>
                        <td>{"$0.81$"}</td>
                        <td>{"$0.01$"}</td>
                        <td>{"$1.21$"}</td>
                        <td>{"$0.01$"}</td>
                        <td>{"$0.81$"}</td>
                        <td>{"$3.61$"}</td>
                    </tr>
                </tbody>
            </table>

            <p>&rarr;&nbsp;Rozdělení hodnot {"$Y$"}, tj. rozdělení {"$p_Y$"}:</p>
            <table className='simple-table simple-table-ed'>
                <tbody>
                    <tr>
                        <td>{"$y$"}</td>
                        <td>{"$0.01$"}</td>
                        <td>{"$0.81$"}</td>
                        <td>{"$1.21$"}</td>
                        <td>{"$3.61$"}</td>
                    </tr>
                    <tr>
                        <td>{"$p_Y(y)$"}</td>
                        <td>{"$0.40$"}</td>
                        <td>{"$0.20$"}</td>
                        <td>{"$0.30$"}</td>
                        <td>{"$0.10$"}</td>
                    </tr>
                </tbody>
            </table>

            <p>{"$E(Y) = \\sum_y y p_Y(y) = 0.01 \\cdot 0.40 + 0.81 \\cdot 0.20 + 1.21 \\cdot 0.30 + 3.61 \\cdot 0.10 = 0.89$"}.</p>

            <p>
            <strong>B. (navázání)</strong> Kdybychom chtěli počítat střední hodnotu {"$Y'$"} podle přeneseného rozdělení veličiny {"$Y'$"},
            vypadalo by to takto (a ve výsedku stejně!):
            {`$$
                E(Y') = \\sum_{y} y p_{Y'}(y).
            $$`}
            </p>

            <p>Výchozí rozdělení {"$p$"}:</p>
            <table className='simple-table simple-table-ed'>
                <tbody>
                    <tr>
                        <td>{"$p(\\omega)$"}</td>
                        <td>{"$0.05$"}</td>
                        <td>{"$0.10$"}</td>
                        <td>{"$0.20$"}</td>
                        <td>{"$0.30$"}</td>
                        <td>{"$0.20$"}</td>
                        <td>{"$0.10$"}</td>
                        <td>{"$0.05$"}</td>
                    </tr>
                    <tr>
                        <td>{"$\\omega$"}</td>
                        <td>{"$-3$"}</td>
                        <td>{"$-2$"}</td>
                        <td>{"$-1$"}</td>
                        <td>{"$0$"}</td>
                        <td>{"$1$"}</td>
                        <td>{"$2$"}</td>
                        <td>{"$3$"}</td>
                    </tr>
                    <tr>
                        <td>{"$X(\\omega) = |\\omega|$"}</td>
                        <td>{"$3$"}</td>
                        <td>{"$2$"}</td>
                        <td>{"$1$"}</td>
                        <td>{"$0$"}</td>
                        <td>{"$1$"}</td>
                        <td>{"$2$"}</td>
                        <td>{"$3$"}</td>
                    </tr>
                </tbody>
            </table>

            <p>&rarr;&nbsp;Rozdělení hodnot {"$X$"}, tj. rozdělení {"$p_X$"}:</p>
            <table className='simple-table simple-table-ed'>
                <tbody>
                    <tr>
                        <td>{"$p_X(x)$"}</td>
                        <td>{"$0.30$"}</td>
                        <td>{"$0.40$"}</td>
                        <td>{"$0.20$"}</td>
                        <td>{"$0.10$"}</td>
                    </tr>
                    <tr>
                        <td>{"$x$"}</td>
                        <td>{"$0$"}</td>
                        <td>{"$1$"}</td>
                        <td>{"$2$"}</td>
                        <td>{"$3$"}</td>
                    </tr>
                    <tr>
                        <td>{"$Y'(x) = (x - E(X))^2 = (x - 1.1)^2$"}</td>
                        <td>{"$1.21$"}</td>
                        <td>{"$0.01$"}</td>
                        <td>{"$0.81$"}</td>
                        <td>{"$3.61$"}</td>
                    </tr>
                </tbody>
            </table>


            <p>&rarr;&nbsp;&rarr;&nbsp;Rozdělení hodnot {"$Y'$"}, tedy rozdělení {"$p_{Y'}$"}:</p>
            <table className='simple-table simple-table-ed'>
                <tbody>
                    <tr>
                        <td>{"$y$"}</td>
                        <td>{"$0.01$"}</td>
                        <td>{"$0.81$"}</td>
                        <td>{"$1.21$"}</td>
                        <td>{"$3.61$"}</td>
                    </tr>
                    <tr>
                        <td>{"$p_{Y'}(y)$"}</td>
                        <td>{"$0.40$"}</td>
                        <td>{"$0.20$"}</td>
                        <td>{"$0.30$"}</td>
                        <td>{"$0.10$"}</td>
                    </tr>
                </tbody>
            </table>

            <p>{"$E(Y') = \\sum_y y p_{Y'}(y) = 0.01 \\cdot 0.40 + 0.81 \\cdot 0.20 + 1.21 \\cdot 0.30 + 3.61 \\cdot 0.10 = 0.89$"}.</p>

            <p>
            Náhodné veličiny {"$Y$"} a {"$Y'$"} mají identické rozdělení pravděpodobnosti - tabulky pro {"$p_Y$"} a {"$p_{Y'}$"} jsou naprosto stejné (!).
            Jenom jsme se k tomuto rozdělení poprvé dostali 'jedním hopem' od {"$p$"}, a podruhé 'na dva hopy' přes {"$p_X$"} (mezizastávka).
            Situace je ilustrována na obrázku níže. V šedých polích je zpočítána transformace (přenos) rozdělení, kdyby nebylo zřemé, jak se dělá.
            <i>Využijte eventuelně zvětšení obrázku/prohlížeče.</i>
            </p>

            <div style={{textAlign: "center", paddingBottom: 10, paddingTop: 20}}>
                <img src={rv2_Ddiagram} style={{width: "100%"}}/>
            </div>

            <h2 id="rv2-cd">Spojité rozdělení</h2>

            <p>Mějme spojité rozdělení na intervalu {"$\\Omega = \\langle-1, 1\\rangle$"} s rovnoměrnou hustotou pravděpodobnosti:
            {`$$
                f(x) = \\frac{1}{2}
            $$`}
            Uvažujme náhodnou veličinu {"$X$"}, která přiřazuje každému bodu intervalu {"$\\Omega$"} hodnotu
            {`$$
                X(\\omega) = \\omega^2.
            $$`}
            Přenesené rozdělení pravděpodobnosti náhodné veličiny {"$X$"} určíme takto:
            {`$$\\begin{align*}
                F_X(x) & = P(\\{X \\leq x\\}) = P(\\{\\omega^2 \\leq x\\}) = P(\\{-\\sqrt{x} \\leq \\omega \\leq \\sqrt{x}\\}) = \\int_{-\\sqrt{x}}^{\\sqrt{x}} f(\\omega) d\\omega = \\\\
                     & = \\int_{-\\sqrt{x}}^{\\sqrt{x}} \\frac{1}{2} d\\omega = \\frac{1}{2} \\Big(\\sqrt{x} - (-\\sqrt{x})\\Big) = \\sqrt{x}.
            \\end{align*}$$`}
            Pak derivujeme:
            {`$$
                f_X(x) = F_X'(x) = \\frac{1}{2\\sqrt{x}},
            $$`}
            tedy hustota rozdělení hodnot náhodné veličiny {"$X$"} je {"$f_X(x) = \\frac{1}{2\\sqrt{x}}$"}, a
            je definovaná na intervalu {"$\\langle 0, 1 \\rangle$"} (obor hodnot náhodné veličiny {"$X$"}, která interval
            {"$\\langle -1, 1 \\rangle$"} zobrazuje na interval {"$\\langle 0, 1 \\rangle$"}).
            <i>Technicky vzato, vzorec pro hustotu není definovaný pro {"$x = 0$"},
            ale protože u spojité veličiny na hodnotě v jediném bodě nezáleží,
            můžeme si ji tam dodefinovat libovolnou hodnotou - na hodnoty integrálů to nebude mít vliv.</i>
            </p>

            <h3 id="rv2-cd-e">Střední hodnota</h3>

            <p>Střední hodnota {"$E(X)$"} náhodné veličiny {"$X$"} se počítá buď z
            původní hustoty {"$f$"}, nebo z přenesené hustoty {"$f_X$"}:
            {`$$\\begin{align*}
                E(X) & = \\int_{-\\infty}^{\\infty} X(\\omega) f(\\omega) \\text{d} \\omega, \\\\
                E(X) & = \\int_{-\\infty}^{\\infty} x f_X(x) \\text{d}x.
            \\end{align*}$$`}
            </p>

            <p>
            V obou případech v integrálu stojí součin <strong>hodnoty náhodné veličiny</strong> a hustoty pravděpodobnosti.
            </p>
            <p>Výpočet z původního rozdělení:
            {`$$\\begin{align*}
                E(X) & = \\int_{-1}^{1} \\omega^2 \\cdot \\frac{1}{2} \\text{d} \\omega = \\frac{1}{2} \\int_{-1}^{1} \\omega^2 \\text{d} \\omega = \\frac{1}{2} \\Big[\\frac{1}{3} \\omega^3\\Big]_{-1}^{1} = \\frac{1}{2} \\Big(\\frac{1}{3} + \\frac{1}{3}\\Big) = \\frac{1}{3}.
            \\end{align*}$$`}
            </p>
            <p>Výpočet z přeneseného rozdělení:
            {`$$\\begin{align*}
                E(X) & = \\int_{0}^{1} x \\cdot \\frac{1}{2\\sqrt{x}} \\text{d}x = \\frac{1}{2} \\int_{0}^{1} x^{1/2} \\text{d}x = \\frac{1}{2} \\Big[\\frac{2}{3} x^{3/2}\\Big]_{0}^{1} = \\frac{1}{2} \\Big(\\frac{2}{3} - 0\\Big) = \\frac{1}{3}.
            \\end{align*}$$`}
            </p>

            <p>Když je úloha zadána jako "Máme náhodnou veličinu s nějakým (rovnoměrným, exponenciálním, normálním, ...) rozdělením pravděpodobnosti",
            potom dané rozdělení je rozdělení <strong>hodnot</strong> náhodné veličiny, tedy ono <strong>přenesené</strong> rozdělení,
            a střední hodnotu počítáme jako {"$E(X) = \\int_{-\\infty}^{\\infty} x f_X(x) \\text{d}x$"}. Máme zadáno rovnou {"$f_X$"},
            a původní prostror {"$\\Omega$"} nemáme a nepotřebujeme jej.
            </p>

            <p>Když je úloha zadána jako "Máme náhodný pokus s nějakými výsledky, které mají nějaké (rovnoměrné, exponenciální, normální, ...)
            rozdělní pravděpodobnosti, a počítáme na nich nějakou hodnotu nějakým vzorcem {"$g$"}", potom dané rozdělení
            je <strong>původní</strong> rozdělení (ne přenesené). Střední hodnotu funkce (tj. náhodné veličiny) {"$g$"} počítáme jako
            {"$E(g) = \\int_{-\\infty}^{\\infty} g(\\omega) f(\\omega) \\text{d}\\omega$"}.<br/>

            Alternativně si můžeme výpočet rozdělit na dvě části - v první si spočítáme přenesené rozdělení
            hodnot náhodné veličiny {"$g$"}, v druhé si spočítáme střední hodnotu z tohoto přeneseného rozdělení.
            Toto se však často nedělá, protože převod hustoty může být 'složitý' - viz příklad v této části.
            </p>

            <h3 id="rv2-cd-d">Rozptyl</h3>

            <p>U rozptylu je situace analogická. Rozptyl {"$D(X)$"} náhodné veličiny {"$X$"} se počítá buď z
            původní hustoty {"$f$"}, nebo z přenesené hustoty {"$f_X$"}:
            {`$$\\begin{align*}
                D(X) & = \\int_{-\\infty}^{\\infty} (X(\\omega) - E(X))^2 f(\\omega) \\text{d} \\omega, \\\\
                D(X) & = \\int_{-\\infty}^{\\infty} (x - E(X))^2 f_X(x) \\text{d}x.
            \\end{align*}$$`}
            </p>

            <p>Výpočet z původního rozdělení:
            {`$$\\small \\begin{align*}
                D(X) & = \\int_{-1}^{1} \\Big(\\omega^2 - \\frac{1}{3}\\Big)^2 \\cdot \\frac{1}{2} \\text{d} \\omega = \\frac{1}{2} \\int_{-1}^{1} \\omega^4 - \\frac{2}{3} \\omega^2 + \\frac{1}{9} \\text{d} \\omega = \\\\
                     & = \\frac{1}{2} \\Big[\\frac{1}{5} \\omega^5 - \\frac{2}{3 \\cdot 3} \\omega^3 + \\frac{1}{9} \\omega\\Big]_{-1}^{1} = \\Big[\\frac{1}{5} \\omega^5 - \\frac{2}{3 \\cdot 3} \\omega^3 + \\frac{1}{9} \\omega\\Big]_{0}^{1} = \\\\
                    & = \\frac{1}{5} - \\frac{2}{9} + \\frac{1}{9} = \\frac{4}{45}.
            \\end{align*}$$`}
            </p>

            <p>Výpočet z přeneseného rozdělení:
            {`$$\\small \\begin{align*}
                D(X) & = \\int_{0}^{1} \\Big(x - \\frac{1}{3}\\Big)^2 \\cdot \\frac{1}{2\\sqrt{x}} \\text{d}x = \\frac{1}{2} \\int_{0}^{1} x^{3/2} - \\frac{2}{3} x^{1/2} + \\frac{1}{9} x^{-1/2} \\text{d}x = \\\\
                     & = \\frac{1}{2} \\Big[\\frac{2}{5} x^{5/2} - \\frac{4}{9} x^{3/2} + \\frac{2}{9} x^{1/2}\\Big]_{0}^{1} = \\frac{1}{5} - \\frac{2}{9} + \\frac{1}{9} = \\frac{4}{45}.
            \\end{align*}$$`}

            </p>
        </div>
        </div>
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

