import React, { useEffect, useRef, useState } from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import { TOC, TOCProps, defineTOCElements } from '~/TOC';


export const LinearRegressionMeta = {
    title: "Lineární regrese",
    shortTitle: "Lineární regrese",
    path: Path.linear_regression,
    element: (sectionNumber:string) => <LinearRegression sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "summary",
];


export function LinearRegression({sectionNumber}: {sectionNumber: string}) {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);

    return (
        <MathJaxWrapper>
        <MathJax>
        <div className="chapter-container">
        <div className="centered-content">
        <div className="card" ref={chapterRef}>

            <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{LinearRegressionMeta.title}</h1>

            {/* <h2 id="summary">Shrnutí</h2> */}

            <p><i>Připravujeme...</i></p>

            Viz přednášky (poznámky, videa), nebo např.

            &nbsp;<a href={"https://www.youtube.com/watch?v=eYTumjgE2IY"} target="_blank" rel="noopener noreferrer" className="external-link">youtube</a>,

            &nbsp;nebo

            &nbsp;<a href={"https://is.muni.cz/do/rect/el/estud/prif/ps13/prav_stat/web_2/pages/06-regresni-analyza.html"} target="_blank" rel="noopener noreferrer" className="external-link">kurz MUNI</a>

            &nbsp;(matematičtější).

        </div>
        </div>
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

