import React, { useEffect, useRef, useState } from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import { TOC, TOCProps, defineTOCElements } from '~/TOC';


export const DistributionMeta = {
    title: "Rozdělení pravděpodobnosti",
    shortTitle: "Rozdělení pravděpodobnosti",
    path: Path.distribution,
    element: (sectionNumber:string) => <Distribution sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "distrib-spec",
    "distrib-spec-discrete",
    "distrib-spec-continuous",
];

export function Distribution({sectionNumber}: {sectionNumber: string}) {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);


    return (
        <MathJaxWrapper>
            <MathJax>
            <div className="chapter-container">
            <div className="centered-content">
            <div className="card" ref={chapterRef}>

            <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{DistributionMeta.title}</h1>

            <p>
            Pojmem "pravděpodobnost" (pravděpodobnostní míra) je obecným termínem
            pro funkci přiřazující každému jevu jeho číslo z intervalu [0,1] a splňující axiomy pravděpodobnosti.
            (Viz kapitola "Pravděpodobnost".)
            </p>

            <p>
            Pojem "rozdělení pravděpodobnosti" se tradičně používá používá pro konrétní pravděpodobnostní míru
            (konkrétně definovanou pravděpodobnost) - např. normální, binomickou, Poissonovu, atd.
            </p>

            <p>Analogie:</p>
            <p>
            Podobně, jako termín "lineární funkce" je obecné označení pro každou funkci z {"$\\mathbb{R}^n$"} do {"$\\mathbb{R}$"},
            která má vlastnosti {"$f(x+y) = f(x) + f(y)$"} a {"$f(\\alpha x) = \\alpha f(x)$"}, a konkrétní
            funkce {"$f(x) = 2x$"}, {"$f(x, y) = x - y$"} jsou konrétními příklady (instancemi, realizacemi)
            lineárních funkcí,
            <br/><br/>
            tak "pravděpodobnost" je obecné označení pro každou funkci z množiny jevů do {"$[0,1]$"}, která splňuje vlastnosti (axiomy) pravděpodobnosti,
            a "rozdělení pravděpodobnosti" jsou konkrétní příklady (instance, realizace) pravděpodobností.
            </p>

            <h2 id="distrib-spec">Specifikace rozdělení</h2>

            <p>
            Když chceme definovat nějaké konkrétní rozdělení pravděpodobnosti, můžeme to udělat různými způsoby:
            </p>

            <ul>
                <li>Tabulkou - výčtem (vhodné jen pro diskrétní rozdělení malého rozsahu).</li>
                <li>Pomocí pravděpodobnostní funkce {"$p(x)$"} (pro diskrétní rozdělení).</li>
                <li>Pomocí hustoty {"$f(x)$"} (pro spojitá rozdělení).</li>
                <li>Pomocí distribuční funkce (pro libovolná{"$^1$"} rozdělení).</li>
                <li>Nějakým pravidlem, popisem, vysvětlením</li>
                <li>...</li>
            </ul>

            <p>
            Ne každý způsob je aplikovatelný v každém kontextu, ale často je možné použít více z nich - v tom případě jsou
            vzájemně převoditelné.
            </p>

            <p>Abychom plně specifikovali rozdělení pravděpodobnosti, musíme jednoznačně definovat
            pravděpodobnost {"$P(A)$"} pro každý jev {"$A$"} z jevového pole.
            Výše uvedené způsoby (kromě tabulkového) jsou "úsporné" způsoby,
            jak toho dosáhnout - nedefinují pravděpodobnost pro každý jev explicitně, ale dávají nějaké pravidlo ("recept", "vzorec", "postup"),
            jak se k ní dostat (to jim neubírá na korektnosti - pravděpodobnost každého jevu {"$A$"} je jimi definována, a jednoznačně).
            Analogií je běžný vzorec funkce - např. vzorec {"$x^2$"} také není tabulkou druhých mocnin všech čisel, ale návodem, jak
            pro zvolené {"$x$"} druhou mocninu spočítat.
            {/* Některé návody jsou složitější než jiné. V případě... */}
            </p>

            <h2 id="distrib-spec-discrete">Specifikace diskrétního rozdělení</h2>

            <p>Ukažme si, jak ekvivalentně definovat malé diskrétní rozdělení pravděpodobnosti.</p>

            <ul>
                <li>
                Elementární jevy: {"$\\Omega = \\{1, 2, 3\\}$"}
                </li>
                <li>
                Jevové pole: {"$\\mathcal{A} = \\mathcal{P}(\\Omega) = \\big\\{\\emptyset, \\{1\\}, \\{2\\}, \\{3\\}, \\{1,2\\}, \\{1,3\\}, \\{2,3\\}, \\Omega \\big\\}$"}
                </li>
            </ul>

            <p>
            <strong>Definice rozdělení pomocí tabulky:</strong>
            </p>
            <table className="simple-table simple-table-basic">
                <tbody>
                    <tr>
                        <td>{"$A:$"}</td>
                        <td>{"$\\emptyset$"}</td>
                        <td>{"$\\{1\\}$"}</td>
                        <td>{"$\\{2\\}$"}</td>
                        <td>{"$\\{3\\}$"}</td>
                        <td>{"$\\{1, 2\\}$"}</td>
                        <td>{"$\\{1, 3\\}$"}</td>
                        <td>{"$\\{2, 3\\}$"}</td>
                        <td>{"$\\Omega$"}</td>
                    </tr>
                    <tr>
                        <td>{"$P(A):$"}</td>
                        <td>0</td>
                        <td>0.1</td>
                        <td>0.3</td>
                        <td>0.6</td>
                        <td>0.4</td>
                        <td>0.7</td>
                        <td>0.9</td>
                        <td>1</td>
                    </tr>
                </tbody>
            </table>
            <p>
            <strong>Definice rozdělení pomocí pravděpodobnostní funkce:</strong>
            {`$$
            p(x) = \\begin{cases}
                0.1 & \\text{ pro } x = 1, \\\\
                0.3 & \\text{ pro } x = 2, \\\\
                0.6 & \\text{ pro } x = 3, \\\\
                0   & \\text{ pro } x \\notin \\Omega.
            \\end{cases}
            $$`}
            Z pravděpodobnostní funkce jsme schopní vygenerovat celou tabulku vztahem {"$P(A) = \\sum_{x \\in A} p(x)$"}.
            Pravděpodobnostní funkce je tedy "kondenzovanou" / "úspornou" formou definice rozdělení.
            </p>

            <p>Z tabulky můžeme naopat získat pravděpodobnostní funkci vztahem
            {`$$
                p(x) = P(\\{x\\}),
            $$`}
            tedy vyčtením pravděpodobností jednoprvkových jevů. Máme např. {"$p(1) = P(\\{1\\}) = 0.1$"}.
            </p>

            <p>
            <strong>Definice rozdělení pomocí distibuční funkce:</strong>
            {`$$
            F(x) = \\begin{cases}
                0 & x < 1, \\\\
                0.1 & 1 \\leq x < 2, \\\\
                0.4 & 2 \\leq x < 3, \\\\
                1 & x \\geq 3.
            \\end{cases}
            $$`}
            Z distribuční funkce je možné získat pravděpodobnostní funkci vztahem
            {`$$
                p(x) = \\lim_{t \\to x^{+}} F(t) - \\lim_{t \\to x^{-}} F(t),
            $$`}
            který je matematickým zápisem pro "výšku schodu" distribuční funkce na pozici {"$x$"}.
            Konkrétně v tomto případě máme tři schody výšek 0.1, 0.3 a 0.6 na pozicích 1, 2 a 3 - a to je vlastně pravděpodnostní funkce /srovnejnte s předpisem {"$p(x)$"} výše/.
            Navíc, na pozicích {"$x$"} mimo 1, 2, 3 není schod, čili výška schodu je tam 0 - což odpovídá řádku "{"$0 \\text{ pro } \\notin \\Omega$"}" v definici {"$p(x)$"}.
            </p>

            <p>Naopak z pravděpodobnostní funkce je možné získat distribuční funkci vztahem
            {`$$
                F(x) = \\sum_{t \\leq x} p(t),
            $$`}
            tedy "průchodem po ose x zleva do prava" a načítáním (kumulováním) postupně nalezených hodnot {"$p(x)$"}.
            </p>
            {/*toto obrázek*/}

            <p>Celkově vidíme, že umíme převádět
            {`$$
                \\text{tabulka} \\leftrightarrow p(x) \\leftrightarrow F(x),
            $$`}
            a tedy všechny tři způsoby definice jsou ekvivalentní.
            </p>

            <h2 id="distrib-spec-continuous">Specifikace spojitého rozdělení na {"$\\mathbb{R}$"}</h2>

            <ul>
                <li>
                Elementární jevy: {"$\\Omega = \\mathbb{R}$"}
                </li>
                <li>
                Jevové pole: Borelovské množiny (zahrnují všechny intervaly a sjednocení intervalů).
                </li>
            </ul>

            <p>
            V tomto případě nemáme možnost definovat rozdělení pomocí tabulky, protože tabulka by musela mít nespočetně mnoho záznamů.
            </p>

            <strong>Definice rozdělení pomocí hustoty:</strong>

            <p>Zvolme si konkrétní příklad:
            {`$$
                f(x) = \\frac{1}{\\pi}\\frac{1}{1+x^2}.
            $$`}
            Tato hustota definuje pravděpodobnost na každém intervalu {"$\\langle a, b\\rangle$"} vztahem:
            {`$$
                P(\\langle a, b\\rangle) = \\int_a^b f(x) \\text{d}x.
            $$`}
            Pozorný čtenář by řekl, že tímto jsme definovali pravděpodobnost na všech intervalech,
            ale zdaleka <strong>ne na všech prvcích (množinách) jevového pole</strong>. To je pravda, ale
            v pokročilé teorii se dokazuje, že intervaly tvoží takzvaný "generátor" jevového pole (borelovských množin),
            a hodnoty pravděpodobnosti na všech množinách jsou jednoznačně dány i tehdy, když jsou dány hodnoty (jen) na generátoru.
            Je to další případ "minimalismu specifikace" - nemusíme definovat pravděpodobnost na všech množinách, stačí na
            nějakém "dostatečně velkém" systému (generátoru). Například pro množinu {"$\\langle 0, 1\\rangle \\cup \\langle 10, 11\\rangle$"},
            která není intervalem, a tedy není pokryta vzorcem nahoře, použijeme vztah:
            {`$$
                P(\\langle 0, 1\\rangle \\cup \\langle 10, 11\\rangle) = P(\\langle 0, 1\\rangle) + P(\\langle 10, 11\\rangle) = \\int_0^1 f(x) \\text{d}x + \\int_{10}^{11} f(x) \\text{d}x.
            $$`}
            To je příklad "rozšíření" pravděpodobnosti z generátoru na celé jevové pole.
            </p>

            <strong>Definice rozdělení pomocí distribuční funkce:</strong>

            <p>Distribuční funkce je v tomto konkrétním případě dána vzorcem
            {`$$
                F(x) = \\frac{1}{\\pi} \\arctan(x) + \\frac{1}{2}.
            $$`}
            Distribuční funkce (spojitá) definuje pravděpodobnost na každém intervalu {"$\\langle a, b\\rangle$"} vztahem:
            {`$$
                P(\\langle a, b\\rangle) = F(b) - F(a).
            $$`}
            Platí stejná poznámka jako výše - tím, že máme definici na intervalech, máme implikovaně definici na celém jevovém poli.
            </p>

            <p>
            Distribuční funkce je definovaná jako nakumulovaná plocha pod hustotou při "průchodu po ose x zleva doprava až po konkrétní {"$x$"}", tj.:
            {`$$
                F(x) = \\int_{-\\infty}^{x} f(t) \\text{d}t.
            $$`}
            Hustota je pak naopak derivací distribuční funkce:
            {`$$
                f(x) = F'(x).
            $$`}
            </p>

            <p>
            Ekvivalence definice pravděpodobnosti {"$P(\\langle a, b\\rangle)$"} pro interval {"$\\langle a, b\\rangle$"}
            pomocí distribuční funkce a hustoty je vidět ze vztahu:
            {`$$
                P(\\langle a, b\\rangle) = \\int_a^b f(x) \\text{d}x = \\int_{-\\infty}^b f(x) \\text{d}x - \\int_{-\\infty}^a f(x) \\text{d}x = F(b) - F(a).
            $$`}
            {/*toto obrázek odečtení ploch*/}
            </p>

            <p>Celkově vidíme, že umíme převádět
            {`$$
                f(x) \\leftrightarrow F(x),
            $$`}
            a tedy tyto dva způsoby definice jsou ekvivalentní.
            </p>


        </div>
        </div>
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

