

export enum Path {
    root = "/",

    // popisná statistika
    descriptive_statistics_intro = "descriptive-statistics-intro",
    data_types = "data-types",
    bar_chart = "bar-chart",
    histogram = "histogram",
    mean = "mean",
    mean_kinds = "mean-kinds",
    quantiles = "quantiles",
    notation = "notation",
    markov_inequality = "markov-inequality",
    variance = "variance",
    moments = "moments",
    chebychev_inequality = "chebychev-inequality",
    regression_basics = "regression-basics",

    // matematický aparát
    math_section = "math-section",
    sets_a = "sets_a",
    sets = "sets",
    mapping = "mapping",
    countability = "countability",

    // pravděpodobnost
    probability_intro = "probability-intro",
    elementary_events = "elementary-events",
    events = "events",
    event_space = "event-space",
    events_independence = "events-independence",
    distribution = "distribution",
    probability = "probability",
    random_vectors = "random-vectors",
    rv_transformations = "rv-transformations",
    random_variable = "random-variable",
    random_variable2 = "random-variable2",
    nahodny_vyber = "nahodny-vyber",
    concept_comparison_e = "concept-comparison-e",
    concept_comparison_d = "concept-comparison-d",
    clt = "clt",
    discrete_or_continuous = "discrete-or-continuous",

    density = "density",
    distribution_function = "distribution-function",

    // inference
    inference_intro = "inference-intro",
    point_estimation = "point-estimation",
    interval_estimation = "interval-estimation",
    hypothesis_testing = "hypothesis-testing",
    linear_regression = "linear-regression",
}